<!-- Start Page Content -->
<div class="container pg-content">
  <div class="pg-header-content">
    <h1 class="pg-title" *ngIf="!contactId">
      {{'Add New Place'|translate}}
    </h1>

    <h1 class="pg-title" *ngIf="contactId">
      {{'EditPlace'|translate}}
    </h1>
  </div>

  <!--Start Contact Form -->
  <div class="col-sm-12 text-center">
    <!-- Success message -->
    <!-- <div class="alert alert-success" role="alert" id="success">
          <p>Your place was sent succssfully! We will be in touch as soon as possible.</p>
      </div> -->
    <!-- Error message -->
    <!--  <div class="alert alert-error" role="alert" id="error">
          <p>Something went wrong, verify the fields or try refreshing the page.</p>
      </div> -->
    <form [formGroup]="placeForm" #myform="ngForm" id="placeForm" name="placeForm" class="form-horizontal contact-form">
      <!-- Text input-->

      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon"><i class="glyphicon glyphicon-pencil"></i></span>
            <input formControlName="title" name="title" id="title" placeholder="{{'Home.place_name'|translate}}"
              class="form-control">

            <span *ngIf="placeForm.controls['title']?.errors?.required && myform.submitted"
              class="error-msg">
              {{'nameReqMes'|translate}}
            </span>
            <span *ngIf="placeForm.controls['title']?.errors?.pattern && myform.submitted"
              class="error-msg">
              {{'nameMustBeCharc'|translate}}.
            </span>
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon"><i class="glyphicon glyphicon-cloud-upload"></i></span>
            <input style="display: none;" formControlName="image_url" (change)="readImage($event)" type="file"
              class="form-control" placeholder="{{'Upload Place Image'|translate}}" id="profile_img">
            <label class="form-control" *ngIf="!photo" style="text-align: start; cursor: pointer;" for="profile_img">
              <i class="fa fa-cloud-upload"></i>{{'Upload Place Image' | translate}}
              <span class="imageName">{{fileName}}</span>
            </label>
            <!-- <ul *ngIf="image_url" class="js-uploader__file-list uploader__file-list">
                  <li class="js-uploader__file-list uploader__file-list">
                      <span class="uploader__file-list__thumbnail">
                          <img id="profileImg" class="thumbnail" id="img_" src="{{image_url}}" (click)="openImg()">  
                      </span>
                      <span class="uploader__file-list__button" id="delete">
                          <a id="close" (click)="closebtn()" class="uploader__icon-button fa fa-times cursor--pointer">
                          </a>
                      </span>
                      
                  </li>
              </ul> -->

              <div *ngIf="photo" class="js-uploader__file-list uploader__file-list">
                <div class="js-uploader__file-list ">
                  <div>
                      <span class="uploader__file-list__thumbnail"  style="width: 90px;">
                        <img id="profileImg" class="thumbnail" id="img_" src="{{photo}}" style="margin-bottom: 0px;">
                      </span>
                    
                      <span class="close">
                        <a id="close" (click)="closeImagebtn()" class="uploader__icon-button fa fa-times cursor--pointer">
                        </a>
                      </span>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon"><i class="glyphicon glyphicon-phone-alt"></i></span>
            <input formControlName="mobile_number" name="phone" id="phone" placeholder="{{'phoneno'|translate}}"
              class="form-control">

            <span
              *ngIf="placeForm.controls['mobile_number']?.errors?.required && myform.submitted"
              class="error-msg">
              {{'phoneReqMes'|translate}}
            </span>
            <span
              *ngIf="placeForm.controls['mobile_number']?.errors?.pattern && myform.submitted"
              class="error-msg">
              {{'phonePatMes'|translate}}.
            </span>
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <textarea name="desc" formControlName="description" id="desc" class="form-control"
              placeholder="{{'Place Description'|translate}}"></textarea>

          </div>
          <span *ngIf="placeForm.controls['description']?.errors?.required && myform.submitted"
            class="error-msg">
            {{'Place Description'|translate}} {{'required'|translate}}
          </span>
        </div>
      </div>

      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon">
              <i class="glyphicon glyphicon-map-marker"></i>
            </span>
            <select formControlName="city_id" name="city_id" id="city_id" class="form-control">
              <option selected disabled value="">{{ 'Home.city_name' | translate}}</option>
              <option value={{city.id}} *ngFor="let city of citieslist">{{city.name}}</option>
            </select>
          </div>

          <span *ngIf="placeForm.controls['city_id']?.errors?.required && myform.submitted"
            class="error-msg">
            {{ 'Home.city_name' | translate}} {{'required'|translate}}
          </span>

        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon"><i class="glyphicon glyphicon-th-list"></i></span>
            <!-- <select  formControlName="category_values_id" name="category_values_id" id="categories"  class="form-control" >
                    <option value="cat.id" *ngFor="let cat of Categorieslist">{{cat.title}}</option>
                  </select> -->
            <!-- <ng-select [items]="items"
                  bindLabel="name"
                  placeholder="Select item"
                  appendTo="body"
                  multiple="true"
                  [(ngModel)]="selected">
                 </ng-select> -->

            <!-- <ng-select #MyNgSelect class="selector" bindLabel="name" appendTo="body" multiple="true" [searchable]="true"
              [clearable]="true" [closeOnSelect]="false" [(ngModel)]="selected" (change)="getValues()"
              [placeholder]="selected.length == 0 ?(currentlang == 'en'?'Category':'القسم') : ''" [ngModelOptions]="{standalone: true}">
              <ng-option [value]="cat.id" *ngFor="let cat of Categorieslist">
                {{cat.title}}
              </ng-option>
            </ng-select> -->

            <ng-multiselect-dropdown
                  [data]="Categorieslist"
                  [settings]="dropdownSettings"
                  formControlName="category_values_id"
                  [(ngModel)]="selectedItems"
                  (onSelect)="onItemSelect($event)"
                  (onSelectAll)="onSelectAll($event)"
                >
            </ng-multiselect-dropdown>



          </div>
          <span *ngIf="placeForm.controls['category_values_id']?.errors?.required && myform.submitted"
            class="error-msg">
            {{ 'categoryReqMes' | translate}}
          </span>
          
        </div>
      </div>

      <!-- Text input-->
      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon"><i  aria-hidden="true" class="fa fa-link"></i></span>
            <input formControlName="link" name="Website Link" id="Website Link" placeholder="{{'Website Link'|translate}}"
              class="form-control">

          </div>

          <span *ngIf="placeForm.controls['link']?.errors?.required && myform.submitted"
            class="error-msg">
            {{'Website Link'|translate}} {{'required'|translate}}
          </span>
        </div>
      </div>



      <!-- Text input-->
      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon"><i class="glyphicon glyphicon-pencil"></i></span>
            <input formControlName="tax_card" name="tax" id="tax" placeholder="{{'Tax card'|translate}}"
              class="form-control">

          </div>

          <span *ngIf="placeForm.controls['tax_card']?.errors?.required && myform.submitted"
            class="error-msg">
            {{'Tax card'|translate}} {{'required'|translate}}
          </span>
        </div>
      </div>

      <!-- file upload -->
      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
            <div class="input-group">
                <span class="input-group-addon"><i class="glyphicon glyphicon-upload"></i></span>
                <input style="display: none;" formControlName="tax_card_file"  accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" (change)="readTaxFile($event)" type="file" class="form-control" placeholder="{{'UploadProjectImage'|translate}}" id="tax_file">
                <label *ngIf="!taxsFile" class="form-control" style="text-align: start; cursor: pointer;"  for="tax_file">
                    <i class="fa fa-cloud-upload"></i>{{'Tax card File' | translate}}
                    <span class="imageName">{{taxFileName}}</span>
                  </label>

                  <div *ngIf="taxsFile" class="js-uploader__file-list uploader__file-list">
                    <div class="js-uploader__file-list ">
                     <div>
                          <span class="uploader__file-list__thumbnail"  style="width: 90px;">
                            <a class="thumbnail" href="{{taxsFile}}" style="margin-bottom: 0px;">File</a>
                          </span>
                     
                          <span class="close">
                            <a id="close" (click)="closeTaxbtn()" class="uploader__icon-button fa fa-times cursor--pointer">
                            </a>
                          </span>
                        </div>
                    </div>
                  </div>
            </div>
        </div>
      </div>

      <!-- Text input-->
      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon"><i class="glyphicon glyphicon-pencil"></i></span>
            <input formControlName="commercial_register" name="reg" id="reg"
              placeholder="{{'Commercial Register'|translate}}" class="form-control">

          </div>

          <span *ngIf="placeForm.controls['commercial_register']?.errors?.required && myform.submitted"
            class="error-msg">
            {{'Commercial Register'|translate}} {{'required'|translate}}
          </span>
        </div>
      </div>

      <!-- file upload -->
      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
            <div class="input-group">
                <span class="input-group-addon"><i class="glyphicon glyphicon-upload"></i></span>
                <input style="display: none;" formControlName="commercial_registry_file"  accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" (change)="readCommercialFile($event)" type="file" class="form-control" placeholder="{{'UploadProjectImage'|translate}}" id="project_file">
                <label *ngIf="!commercialFile" class="form-control" style="text-align: start; cursor: pointer;"  for="project_file">
                    <i class="fa fa-cloud-upload"></i>{{'Commercial Register File' | translate}}
                    <span class="imageName">{{comercialFileName}}</span>
                  </label>

                  <div *ngIf="commercialFile" class="js-uploader__file-list uploader__file-list">
                    <div class="js-uploader__file-list ">
                 <div>
                          <span class="uploader__file-list__thumbnail" style="width: 90px;">
                            <a class="thumbnail" href="{{commercialFile}}" style="margin-bottom: 0px;">File</a>
                          </span>
                       
                          <span class="close">
                            <a id="close" (click)="closeCommercialebtn()" class="uploader__icon-button fa fa-times cursor--pointer">
                            </a>
                          </span>
                        </div>
                    </div>
                  </div>
            </div>
        </div>
      </div>

      <!-- Text input-->
      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon"><i class="glyphicon glyphicon-map-marker"></i></span>
            <input formControlName="address" name="address" id="address" placeholder="{{'Place Address'|translate}}"
              class="form-control">

          </div>

          <span *ngIf="placeForm.controls['address']?.errors?.required && myform.submitted"
            class="error-msg">
            {{'Place Address'|translate}} {{'required'|translate}}
          </span>
        </div>
      </div>

      <!-- Text input-->
      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon"><i class="glyphicon glyphicon-map-marker"></i></span>
            <input name="location" id="location" placeholder="{{'searchLocation' | translate}}"
              class="form-control" type="text" #searchElementRef autocorrect="off" autocapitalize="off"
              spellcheck="off" (keydown.enter)="$event.preventDefault()">
              <span class="input-group-addon"><i class="fas fa-location-arrow" (click)="detectLocation()"></i></span>
          </div>
          
        </div>
      </div>

      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon"><i class="glyphicon glyphicon-map-marker"></i></span>
            
            <agm-map 
              #map
              [latitude]="location.lat" 
              [longitude]="location.lng" 
              style="height: 300px">
              <agm-marker 
                [latitude]="location.lat" 
                [longitude]="location.lng" 
                [markerDraggable]="true" 
                (dragEnd)="markerDragEnd($event)"
              ></agm-marker>
            </agm-map>
          </div>
          <span *ngIf="!latitude && !longitude && myform.submitted"
            class="error-msg">
            {{'location'|translate}} {{'required'|translate}}
          </span>
          <!-- <span *ngIf="placeForm.controls['link']?.errors?.pattern && myform.submitted" class="error-msg">
            {{'locationmustbe' | translate}}
          </span> -->
        </div>
      </div>



      <!-- <div class="form-group">
        <div class="col-md-12">
          <div class="input-group">
            <span class="input-group-addon"><i class="glyphicon glyphicon-map-marker"></i></span>
            <input type="number" formControlName="longitude" name="longitude" id="longitude"
              placeholder="{{'Longitude'|translate}}" class="form-control" required>

          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="col-md-12">
          <div class="input-group">
            <span class="input-group-addon"><i class="glyphicon glyphicon-map-marker"></i></span>
            <input type="number" formControlName="latitude" name="latitude" id="latitude"
              placeholder="{{'latitude'|translate}}" class="form-control" required>

          </div>
        </div>
      </div> -->



      <div class="form-group">
        <div class="col-md-12">
          <div class="input-group">
            <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-thumbs-up"></i></span> -->
            <span class="input-group-addon"> <i class="fa fa-facebook" style="padding: 0px 3px;"></i></span>
            <input formControlName="Facebook" name="Facebook" id="Facebook" placeholder="https://www.facebook.com/"
              class="form-control">
          </div>
          <span *ngIf="placeForm.controls['Facebook']?.errors?.pattern && myform.submitted" class="error-msg">
            {{'facebookmustbe' | translate}}
          </span>
        </div>
      </div>

      <div class="form-group">
        <div class="col-md-12">
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-twitter"></i></span>
            <input formControlName="Twitter" name="Twitter" id="Twitter" placeholder="https://www.Twitter.com/"
              class="form-control">
          </div>
          <span *ngIf="placeForm.controls['Twitter']?.errors?.pattern && myform.submitted" class="error-msg">
            {{'twittermustbe' | translate}}
          </span>
        </div>
      </div>

      <div class="form-group">
        <div class="col-md-12">
          <div class="input-group">
            <span class="input-group-addon"> <i class="fa fa-instagram"></i></span>
            <input formControlName="Instagram" name="Instagram" id="Instagram" placeholder="https://www.Instagram.com/"
              class="form-control">
          </div>
          <span *ngIf="placeForm.controls['Instagram']?.errors?.pattern && myform.submitted" class="error-msg">
            {{'instagrammustbe' | translate}}
          </span>
        </div>
      </div>

      <div class="form-group">
        <div class="col-md-12">
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-linkedin"></i></span>
            <input formControlName="Linkedin" name="Linkedin" id="Linkedin" placeholder="https://www.Linkedin.com/"
              class="form-control">
          </div>
          <span *ngIf="placeForm.controls['Linkedin']?.errors?.pattern && myform.submitted" class="error-msg">
            {{'linkedinmustbe' | translate}}
          </span>
        </div>
      </div>





      <!-- Submit Button -->
      <div class="form-group sub">
        <div class="col-sm-12">
          <button (click)="submitPlace()" type="submit" name="name"
            class="btn btn-warning submit">
            <i *ngIf="isEnabled" class="fa fa-spinner fa-spin"></i> {{'Submit'|translate}}</button>
        </div>
      </div>
    </form>
  </div>
  <!--End Contact Form-->
</div>
<!-- End Page Content -->