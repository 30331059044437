import { Component, OnInit } from '@angular/core';
import { AfterViewChecked, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { ChatService } from 'src/app/Shared/Services/chat.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { UserService } from 'src/app/Shared/Services/user.service';
import {PlacesService} from '../../Shared/Services/places.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-chat-quick',
  templateUrl: './chat-quick.component.html',
  styleUrls: ['./chat-quick.component.scss'],
})
export class ChatQuickComponent implements OnInit {
  AddQuestionResponse;
  questionForm: FormGroup;

  Company_requests_show: any[] = [];
  My_requests_show: any[] = [];

  Company_Contact_Question_ID;

  placeID: any[] = [];
  placename;

  userId;
  userType;

  lang;

  constructor(
    private translate: TranslateService,
    private toaster: ToastrService,
    private service: HttpClientService,
    private activatedRoute: ActivatedRoute,
    private placeservice: PlacesService,
    private chatservice: ChatService,
    private router: Router,
    private userservice: UserService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.userId = +localStorage.getItem('UserID');
    this.userType = localStorage.getItem('userType');

    this.initForm();

    this.activatedRoute.queryParams.subscribe((res) => {
      this.placeID = this.activatedRoute.snapshot.queryParams['id'];
    });
    this.GetCompanyReuests();
  }

  initForm() {
    this.questionForm = new FormGroup({
      question: new FormControl(''),
    });
  }

  GetCompanyReuests() {
    this.chatservice.GetMyRequestsList(this.placeID).subscribe((res: any) => {
      this.My_requests_show = res.content;
      this.placename = res.content[0].contact.name;

      console.log('Company Requests list  ::::::::', this.My_requests_show);
    });
  }

  OpenCompaniesRequests(company_request) {
    this.placename = company_request.contact.name;
    this.placeID = company_request.contact.id;
    this.Company_Contact_Question_ID = company_request.id;

    this.chatservice
      .showCompanyRequest(company_request.id)
      .subscribe((res: any) => {
        this.Company_requests_show = res.content;

        console.log(
          'Company Requests show item ::::::::',
          this.Company_requests_show
        );
      });
  }

  AddFirstCompanyQuestion(formData) {
    console.log(
      'body to send to store first question ::::::::',
      this.userId,
      this.placeID,
      formData
    );

    this.chatservice
      .SendQuickChat(this.userId, this.placeID, formData.question)
      .subscribe((res: any) => {
        this.AddQuestionResponse = res.content;
        this.Company_Contact_Question_ID = res.content.id;
        this.GetCompanyReuests();

        this.questionForm.reset();

        this.chatservice
          .showCompanyRequest(this.Company_Contact_Question_ID)
          .subscribe((res: any) => {
            this.Company_requests_show = res.content;
            this.My_requests_show = res.content;

            console.log(
              'Company Requests show item ::::::::',
              this.Company_requests_show
            );
          });

        console.log(
          'Company Send Question response ::::::::',
          this.AddQuestionResponse
        );

        window.location.reload();
      });

    //  window.location.reload()
  }

  Navigatetoplace(place_ID) {
    this.router.navigate(['../place-details', this.lang], {
      queryParams: {
        id: place_ID,
      },
    });
  }
}
