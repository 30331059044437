    <!-- Start Page Content -->
    <div class="container-fluid pg-header" style="background-image: url(./assets/images/16.jpg);">
    
        <div class="pg-header-content">
          <h1 class="pg-title">
            {{'view emp'|translate}}
  
          </h1>
          <h4 class="pg-subtitle">
            {{user?.full_name}}
          </h4>
          <img *ngIf="!user?.photo" class="img-width" src="assets/images/user_profile.png">
                      <img *ngIf="user?.photo" class="img-width" [src]="user?.photo">
        </div>
      </div>
      
      <div class="container container-margin">
         
          
          <!--Start Contact Form -->
          <div class="col-sm-12">
           
            <form class="form-horizontal contact-form">
              <!-- Text input-->
              <div class="row">
              
              <div class="col-md-4 col-sm-6 ">
              <div class="form-group">
                <div class="inputGroupContainer">
                  <label>{{'Full Name'|translate}}</label>
                <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-user"></i></span>
                <input   name="full_name" [value]="user?.full_name" id="full_name"  class="form-control" disabled [value]="user?.full_name">
      
              </div>
              
              </div>
                </div>
              </div> 
              <div class="col-md-4 col-sm-6 ">
              <div class="form-group">
                <div class="inputGroupContainer">
                  <label>{{'Telcode'|translate}}</label>
                  <div class="input-group">
                  <span class="input-group-addon"><i class="fas fa-globe-europe"></i></span>
                  <input   name="tele_code" id="tele_code"  class="form-control"  disabled [value]="user?.tele_code">
                </div>
                
                 
                </div>
                  </div>
              </div>
  
              <!-- Text input-->
              <div class="col-md-4 col-sm-6 ">
              <div class="form-group">
                <div class="inputGroupContainer">
                  <label>{{'phoneno'|translate}}</label>
                  <div class="input-group">
                      <span class="input-group-addon"><i class="glyphicon glyphicon-phone-alt"></i></span>
                      <input  name="phone" id="phone"  class="form-control" disabled [value]="user?.mobile">
                    </div>
                     
                  </div>
                </div>
              </div>
              <!-- Text input-->
              <div class="col-md-4 col-sm-6 ">
              <div class="form-group">
                <div class="inputGroupContainer">
                  <label>{{'job title'|translate}}</label>
                      <div class="input-group">
                          <span class="input-group-addon"><i class="fa fa-briefcase"></i></span>
                          <input   name="job_title" id="job_title" disabled [value]="user?.job_title" class="form-control" >
                        </div>
                        </div>
                  </div>
              </div>
  
  
              <div class="col-md-4 col-sm-6 ">
              <div class="form-group">
              <!-- Text input-->
              <div class="inputGroupContainer">
                <label>{{'email'|translate}}</label>
                      <div class="input-group">
                          <span class="input-group-addon"><i class="fa fa-envelope-open"></i></span>
                          <input    name="email" id="email"  class="form-control"  disabled [value]="user?.email">
                        </div>
                      </div>
                  </div>
              </div>
              <!-- Text input-->
              <div class="col-md-4 col-sm-6 ">
              <div class="form-group">
                <div class="inputGroupContainer">
                  <label>{{'Address'|translate}}</label>
                      <div class="input-group">
                          <span class="input-group-addon"><i class="glyphicon glyphicon-map-marker"></i></span>
                          <input    name="address" id="address"  class="form-control"  disabled [value]="user?.address">
                        </div>
                        </div>
                  </div>
              </div>
  
              <div class="col-md-4 col-sm-6 ">
              <div class="form-group">
                <div class="inputGroupContainer">
                  <label>{{'Gender'|translate}}</label>
                      <div class="input-group">
                          <span class="input-group-addon">
                              <i class="fas fa-city"></i>
                          </span>
                          <select   name="gender_id" id="gender_id"  class="form-control" disabled [value]="user?.gender_id">
                            <option value="1">{{'Male'|translate}}</option>
                            <option value="2">{{'Female'|translate}}</option>
  
                          </select>
                         
                      </div>
                  </div>
              </div>
            </div>
   
              
              <div class="col-md-4 col-sm-6 ">
                <div class="inputGroupContainer">
                  <label>{{'Birthdate'|translate}}</label>
              <div class="form-group">
                  <div class="input-group">
                      <span class="input-group-addon"><i class="fas fa-calendar-alt"></i></span>
                      <input  type="text" name="birthdate" id="birthdate"  class="form-control" disabled [value]="setDob[0]">
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="col-md-4 col-sm-6">
              <div class="form-group" >
                <label>{{'username'|translate}}</label>
                      <div class="input-group">
                          <span class="input-group-addon"><i class="fas fa-user-circle"></i></span>
                          <input  type="text"   name="username" id="username"  class="form-control" disabled [value]="user?.username" >
                        
                      </div>
                  </div>
              </div>
        
            
             
              <!-- <div class="form-group">
                  <div class="col-sm-12 inputGroupContainer">
                      <div class="input-group">
                          <span class="input-group-addon">
                              <i class="fas fa-tasks"></i>
                          </span>
                          <select  formControlName="city_id" name="city_id" id="city_id"  class="form-control" >
                            <option>permission 1</option>
                            <option>permission 2</option>
                            <option>permission 3</option>
                            <option>permission 4</option>
  
  
                          </select>
                      </div>
                  </div>
              </div> -->
              <!-- Text input-->
            
     
              <!-- <div class="form-group" >
                  <div class="col-md-12">
                      <div class="input-group">
                          <span class="input-group-addon"><i class="fas fa-user-tag"></i></span>
                          <input disabled [value]="user?.user_key"  name="user_key" id="user_key"  class="form-control"  >
                      </div>
                    </div>
              </div> -->
      
             
              <!-- Submit Button -->
             </div>
            </form>
          </div>
          <!--End Contact Form-->
        </div>
        <!-- End Page Content -->
      