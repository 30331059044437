import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  currentLang = new BehaviorSubject<any>(null);

  lang$ = this.currentLang.asObservable();

  lang;

  updateLang(lang) {
    this.currentLang.next(lang);
  }

  changedUser: EventEmitter<string> = new EventEmitter<string>();
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(async (params) => {
      this.lang = params['lang']
        ? params['lang']
        : localStorage.getItem('lang');
    });
    this.updateLang(this.lang);
  }
}
