<div class="row" style="padding: 60px 0px 20px 0px">
    <div class="col-md-1 col-sm-12 col-xs-12"></div>
    <div class="col-md-9 col-sm-12 col-xs-12">
        <h1 class="main-title">{{'What do our corporate services do ?'|translate}}</h1>
    </div>
    <div class="col-md-2 col-sm-12 col-xs-12"></div>

</div>
<div class="row" >

    <div class="col-md-6 col-sm-12 col-xs-12">
         <div class="row">
       
             <div class="col-md-2 col-sm-12 col-xs-12"></div>
             <div class="col-md-4 col-sm-12 col-xs-12">
                <div id="card-container">
                    <div class="flip-card">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <h1>{{'Communication between companies'|translate}}</h1>
                            </div>
                            <div class="flip-card-back">
                                <p>{{'Qpedia makes it easier and faster to communicate between companies'|translate}}</p>
                            
                            </div>
                        </div>
                    </div>


                </div>
             </div>

             <div class="col-md-4 col-sm-12 col-xs-12">
                <div id="card-container">
                    <div class="flip-card">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <h1>{{'set a team'|translate}}</h1>
                            </div>
                            <div class="flip-card-back">
                                <p>{{'Qpedia allows you to find employees, whether primarily or through self-employment'|translate}}</p>
                               
                            </div>
                        </div>
                    </div>


                </div>
             </div>
             <div class="col-md-2 col-sm-12 col-xs-12"></div>

             

         </div>
         <div class="row">


            <div class="col-md-2 col-sm-12 col-xs-12"></div>
            <div class="col-md-4 col-sm-12 col-xs-12">
               <div id="card-container">
                   <div class="flip-card">
                       <div class="flip-card-inner">
                           <div class="flip-card-front">
                               <h1>{{'Increase Sales'|translate}}</h1>
                           </div>
                           <div class="flip-card-back">
                               <p>{{'Qpedia works to increase sales opportunities and increase partnerships between institutions'|translate}}</p>
                             
                           </div>
                       </div>
                   </div>


               </div>
            </div>

            <div class="col-md-4 col-sm-12 col-xs-12">
               <div id="card-container">
                   <div class="flip-card">
                       <div class="flip-card-inner">
                           <div class="flip-card-front">
                               <h1>{{'Inquiry and support'|translate}}</h1>
                           </div>
                           <div class="flip-card-back">
                               <p>{{'Providing the best customer service and providing full support to companies'|translate}}</p>
                              
                           </div>
                       </div>
                   </div>


               </div>
            </div>
            <div class="col-md-2 col-sm-12 col-xs-12"></div>
            

        </div>
    </div>
    
    <div class="col-md-6 col-sm-6 col-xs-12">
        <img class="main-img" src="assets/images/services-img.jpg">
        <div class="beside">
            <!-- <h4 class="img-text">{{'Ready to hear some more?'|translate}}</h4> -->
            <!-- <a class="img-button"> {{'LET US TALK'|translate}}</a> -->
        </div>
        
    </div>

</div>