import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslateCacheService } from 'ngx-translate-cache';
import { LanguageService } from './Shared/Services/language.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import firebase from 'firebase';
import { environment } from '../environments/environment';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @Inject(PLATFORM_ID) private platformId: Object;

  lang: string;
  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
    private translateCacheService: TranslateCacheService,
    private router: Router,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta
  ) {
    // localStorage.setItem('lang', 'ar');
    this.lang = localStorage.getItem('lang');
    // change style dir 'rtl and ltr' for app
    this.languageService.lang$.subscribe((lang) => {
      // if language is arabic 'ar' change dir from ltr to rtl and set translate to ar
      if (lang == 'ar') {
        document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
        this.renderer.addClass(this.document.body, 'rtl');
        translateService.setDefaultLang(lang);
        translateService.use(lang);
        // if language is english 'en' change dir from rtl to ltr and set translate to en
      } else if (lang == 'en') {
        document.getElementsByTagName('html')[0].removeAttribute('dir');
        this.renderer.removeClass(this.document.body, 'rtl');
        translateService.setDefaultLang(lang);
        translateService.use(lang);
      }
    });
  }

  ngOnInit() {
    // localStorage.setItem('lang', 'ar');
    this.lang = localStorage.getItem('lang');

    firebase.initializeApp(environment.baseConfig);
    this.meta.addTag({ name: 'description', content: 'Qpedia Website' });
    this.meta.addTag({ name: 'twitter:title', content: 'Qpedia Website' });
    this.meta.addTag({
      name: 'twitter:description',
      content: 'Find Your Place',
    });
    this.meta.addTag({
      name: 'twitter:image',
      content: 'https://admin.qpedia.com/public/img/avaters/sales.png',
    });
    this.meta.addTag({
      name: 'twitter:text:description',
      content: 'Qpedia  Website',
    });
    this.meta.addTag({ property: 'og:type', content: 'article' });
    this.meta.addTag({ property: 'og:site_name', content: '@Qpedia' });
    this.meta.addTag({ property: 'og:title', content: 'Qpedia Website' });
    this.meta.addTag({
      property: 'og:image',
      content: 'https://admin.qpedia.com/public/img/avaters/sales.png',
    });
    this.meta.addTag({
      property: 'og:url',
      content: 'http://195.154.154.113:4000/',
    });

    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event: NavigationEnd) => {
        window.scroll(0, 0);
      });
    }
  }

  public get title() {
    return 'qpedia';
  }

  onActivate(e, outlet) {
    outlet.scrollTop = 0;
  }

  navigateToPage(url, lang) {
    this.router.navigate([url, lang]);
  }
}
