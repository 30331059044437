import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService
  ) { }

  get(url, queryParams, headers) {
    return this.httpClient.get(`${environment.base_url}/${url}`, {
      params: queryParams,
      headers: headers,
    });
  }

  post(url, body, queryParams, headers?): Observable<string | any> {
    let language = this.translateService.currentLang;
    return this.translateService.onLangChange.pipe(
      // Map the event to the new language
      map((event) => event.lang),
      // Start with the current language
      startWith(language),
      // Switch to a new observable that calls the API with the language
      switchMap((lang) =>
        this.httpClient.post(
          `${environment.base_url}/${url}`,
          {
            ...body,
            lang_id: lang == 'ar' ? 2 : 1,
          },
          {
            params: queryParams,
            headers: headers ? headers : {},
          }
        )
      )
    );
  }

  put(url, body, queryParams) {
    return this.httpClient.put(`${environment.base_url}/${url}`, body, {
      params: queryParams,
    });
  }

  patch(url, body, queryParams) {
    return this.httpClient.patch(`${environment.base_url}/${url}`, body, {
      params: queryParams,
    });
  }

  delete(url, queryParams) {
    return this.httpClient.delete(`${environment.base_url}/${url}`, {
      params: queryParams,
    });
  }
}
