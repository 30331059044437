<!-- Start Blog Section -->
<div class="container-fluid blog blog-home">
    <div class="row row-title">
      <h1>Tips and Tricks</h1>
      <h4>We have some suggestions to you</h4>
    </div>
    <div class="row listing white">
      <!-- Start Blog Item Col -->
      <div class="col-sm-3">
        <div class="listing-item">
          <div class="listing-item-date">
            <div class="day">12</div>
            <div class="month">Feb</div>
          </div>
          <a routerLink="Place-Details" href="#" class="listing-item-link">
            <div class="listing-item-title-centralizer">
              <div class="listing-item-title">
                From Beach Glitz to Urban Ritz, Miami's Hotels Shine
              </div>
            </div>
            <img alt="" src="./assets/images/blog/14.jpg" />
          </a>
          <div class="listing-item-data">
            <div class="listing-item-excerpt">
              The best relaxing experience
            </div>
          </div>
          <div class="listing-category-name">
            <a routerLink="Categories" href="#">Relax</a>
          </div>
        </div>
      </div>
      <!-- End Blog Item Col -->
      <!-- Start Blog Item Col -->
      <div class="col-sm-3">
        <div class="listing-item">
          <div class="listing-item-date">
            <div class="day">07</div>
            <div class="month">Feb</div>
          </div>
          <a routerLink="Place-Details" href="#" class="listing-item-link">
            <div class="listing-item-title-centralizer">
              <div class="listing-item-title">
                Fill Your Bags with Buys from Boston's Best Shopping Spots
              </div>
            </div>
            <img alt="" src="./assets/images/blog/15.jpg" />
          </a>
          <div class="listing-item-data">
            <div class="listing-item-excerpt">
              Staple items, unique pieces
            </div>
          </div>
          <div class="listing-category-name">
            <a routerLink="Categories" href="#">Shop</a>
          </div>
        </div>
      </div>
      <!-- End Blog Item Col -->
      <!-- Start Blog Item Col -->
      <div class="col-sm-3">
        <div class="listing-item">
          <div class="listing-item-date">
            <div class="day">02</div>
            <div class="month">Feb</div>
          </div>
          <a routerLink="Place-Details" href="#" class="listing-item-link">
            <div class="listing-item-title-centralizer">
              <div class="listing-item-title">
                Fall In Love All Over Again During These Romantic Activities in NYC
              </div>
            </div>
            <img alt="" src="./assets/images/blog/17.jpg" />
          </a>
          <div class="listing-item-data">
            <div class="listing-item-excerpt">
              Take romance to the next level
            </div>
          </div>
          <div class="listing-category-name">
            <a routerLink="Categories" href="#">Fun and Entertainment</a>
          </div>
        </div>
      </div>
      <!-- End Blog Item Col -->
      <!-- Start Blog Item Col -->
      <div class="col-sm-3">
        <div class="listing-item">
          <div class="listing-item-date">
            <div class="day">23</div>
            <div class="month">Jan</div>
          </div>
          <a href="#" class="listing-item-link">
            <div class="listing-item-title-centralizer">
              <div class="listing-item-title">
                Get Your Sourdough On at Goat Hill Pizza
              </div>
            </div>
            <img alt="" src="./assets/images/blog/16.jpg" />
          </a>
          <div class="listing-item-data">
            <div class="listing-item-excerpt">
              Spreading the joy to West Portal
            </div>
          </div>
          <div class="listing-category-name">
            <a routerLink="Categories" href="#">Food and Drink</a>
          </div>
        </div>
      </div>
      <!-- End Blog Item Col -->
    </div>
  </div>
  <!-- End Blog Section -->