<div class="container-fluid pg-header" style="background-image: url(./assets/images/16.jpg);">
    <div class="pg-header-content">
      <h1 class="pg-title">
        {{'Home.users'|translate}}
      </h1>
      <h4 class="pg-subtitle">
        
      </h4>
    </div>
    <!-- <div class="pg-header-icon icon-bubbles"></div> -->
</div>
  <!-- End Page Header -->
  <!-- Start Contact Map -->
<div class="container-fluid map-contact">
    <div class="container page_bg pt-50 pb-50 pr-50 pl-50 container-margin">
        <div class="row">
            <div class="col-md-12">
                <div class="panel panel-form">
                    <div class="panel-heading">
                        <button class="search-btn" *ngIf="userList?.length>0" (click)="open(content)">{{'Advanced Filter'|translate}}</button>
                        <button class="search-btn" [routerLink]="['/create-job', currentlang]">{{'add job'|translate}}</button>
                        <button class="search-btn" [routerLink]="['/company-jobs', currentlang]">{{'company jobs'|translate}}</button>
                 
                    </div>
                    <div class="panel-body">
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade active in " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" >
                                <div class="table-responsive" style="width:100%">

                                
                                <table class="table" cellspacing="0" *ngIf="userList?.length>0">
                                    <thead>
                                        <tr>
                                            <th class="wide">{{'Name'|translate}}</th>
                                            <th>{{'Image'|translate}}</th>
                                            <th class="wide">{{'Email'|translate}}</th>
                                            <th>{{'Mobile'|translate}}</th>
                                            <th class="wide">{{'Job'|translate}}</th>
                                            <th>{{'Status'|translate}}</th>
                                            <th  class="wide">{{'usercondition'|translate}}</th>
                                            <th>{{'Actions'|translate}}</th>

                                        </tr>
                                    </thead> 
                                    <tbody>
                                        <tr *ngFor="let user of userList | paginate: { itemsPerPage: pageSize, currentPage: p, totalItems: totalapi }">
                                            <td >{{user?.full_name}}</td>
                                            <td>
                                                <img *ngIf="!user?.photo" class="img-width" src="assets/images/user_profile.png">
                                           <img *ngIf="user?.photo" class="img-width" [src]="user?.photo">

                                            <td >
                                               {{user?.email}}
                                            </td>
                                            <td>
                                                {{user?.mobile}}
                                            </td>
                                            <td class="date">
                                                {{user?.job_title}}
                                            </td>
                                            <td>
                                                <i *ngIf="user?.is_active == 1" class="fa fa-check"></i>
                                                <i *ngIf="user?.is_active == 0" class="fa fa-times"></i>
                                            </td>
                                            <td>
                                               
                                                {{'Non-company employees' | translate}}
                                                 <button (click)="open(user?.id, user?.full_name)" class="req-btn">
                                                    {{'sendRequest'|translate}}</button>
                                            </td>
                                            <td>
                                                <div class="btns-cell-td">

                                                <button (click)="navigateView(user)" class="view-btn"><i class="fa fa-eye"></i></button>
                                                <!-- <button (click)="navigateUpdate(user)" class="edit-btn"><i class="fa fa-edit"></i></button> -->
                                                <!-- <button (click)="delete(user)" class="delete-btn"><i class="fa fa-trash"></i></button> -->
                                          </div>
                                            </td>
                                        </tr>

                               
                                    </tbody>
                                </table>
                            </div>
                                <h2 *ngIf="userList?.length==0">{{'no result'|translate}}</h2>
                                <div class="col-md-12 col-sm-12 text-center" *ngIf="userList?.length>0">
                                    <pagination-controls 
                                               (pageChange)="onPageChange($event)"
                                               previousLabel="{{'previous'|translate}}"
                                               nextLabel="{{'next'|translate}}">
                                    </pagination-controls>
                            
                                  </div>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
     
           
        
    </div>
   
</div>

<div class="col-lg-12">

    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'search' |translate}}</h4>
        <button type="button" style="opacity: 1;outline: none;" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true" class="cancel-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="searchForm"(ngSubmit)="search(searchForm.value)">
            <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-user"></i></span>
                <input formControlName="full_name" placeholder="{{'Full Name'|translate}}" class="form-control"  >
              
              </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                <div class="input-group">
                <span class="input-group-addon"><i class="fas fa-globe-europe"></i></span>
                <input formControlName="tele_code" placeholder="{{'Country Code'|translate}}" class="form-control">
      
                
              </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                <div class="input-group">
                    <span class="input-group-addon"><i class="glyphicon glyphicon-phone-alt"></i></span>
                    <input formControlName="mobile" name="phone" id="phone" placeholder="{{'phoneno'|translate}}" class="form-control">
    
                    
                </div>
              </div>
            </div>

            <div class="form-group">

                <div class="col-sm-6 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-envelope-open"></i></span>
                        <input  placeholder="{{'email'|translate}}" class="form-control"  >

                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon">
                            <i class="fas fa-chart-bar"></i>
                        </span>
                        <select  formControlName="is_approved" class="form-control" >
                            <option  value="" disabled selected> اختر الحالة </option>

                            <option  *ngFor="let item of approvedList" [value]="item.id">{{item.name}}</option>

                      
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group" >
                <div class="col-md-6">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fas fa-user-circle"></i></span>
                        <input  type="text"  formControlName="username"  name="username" id="username" placeholder="{{'username'|translate}}" class="form-control"  >
      
                    </div>
                </div>
            </div>
             <div class="modal-footer">
        <button type="submit" class="btn btn-outline-dark submit-btn" >{{'Submit'|translate}}</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">{{'cancel'|translate}}</button>

      </div>
        </form>
      </div>
    
    </ng-template>
    
   
    
</div>


<div class="col-lg-12">

    <!-- <ng-template #jobRequest let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'sendRequest' |translate}}</h4>
        <button type="button" style="opacity: 1;outline: none;" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true" class="cancel-btn">&times;</span>
        </button>
      </div>
      <div class="modal-body req">
        <form [formGroup]="requestForm"(ngSubmit)="sendRequest(requestForm.value)">

            <div class="form-group clear-fix">
                <div class="col-sm-8 inputGroupContainer" [ngStyle]="currentlang == 'ar' ? '{\'float\': right;}' : '' " [ngClass]="currentlang == 'ar' ? 'float-right' : '' ">
                    <div class="input-group">
                        <span class="input-group-addon" [ngClass]="currentlang == 'ar' ? 'ar-icon' : '' ">
                            <i class="fas fa-chart-bar"></i>
                        </span>
                        <select  formControlName="job_id" class="form-control" [ngClass]="currentlang == 'ar' ? 'ar-select' : '' ">
                            <option  value="" disabled selected> {{'choose Job' | translate}}</option>

                            <option  *ngFor="let job of jobs" [value]="job.id">{{job.title}}</option>
                        </select>

                        
                    </div>
                    <p *ngIf="jobs.length == 0" class="text-danger">
                        {{'noproject' | translate}}
                        <button (click)="gotoJob()"><i class="fa fa-plus"></i></button>
                    </p>
                </div>
            </div>

          
             <div class="modal-footer">
        <button type="submit" class="btn btn-outline-dark submit-btn" >{{'Submit'|translate}}</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">{{'cancel'|translate}}</button>

      </div>
        </form>
      </div>
    
    </ng-template> -->
    
    <p-dialog header="{{'sendRequest' |translate}}" [(visible)]="displayResponsive" [style]="{width: '50vw'}" [baseZIndex]="10000" 
    [draggable]="false" [resizable]="false">
    <h4>{{userName}}</h4>
    <form [formGroup]="requestForm">
    
        <div class="form-group clear-fix">
            <div class="col-sm-8 inputGroupContainer"
                [ngClass]="currentlang == 'ar' ? 'float-right' : '' ">
                <div class="input-group">
                    <span class="input-group-addon" [ngClass]="currentlang == 'ar' ? 'ar-icon' : '' ">
                        <i class="fas fa-chart-bar"></i>
                    </span>
                    <select formControlName="job_id" class="form-control"
                        [ngClass]="currentlang == 'ar' ? 'ar-select' : '' ">
                        <option value="" disabled selected> {{'choose Job' | translate}}</option>
    
                        <option *ngFor="let job of jobs" [value]="job.id">{{job.title}}</option>
                    </select>
                    
    
                </div>
                <p *ngIf="jobs.length == 0" class="text-danger">
                    {{'noproject' | translate}}
                    <button (click)="gotoJob()"><i class="fa fa-plus"></i></button>
                </p>

                <span *ngIf="!isValid">
                    {{'jobReqMes' | translate}}
                </span>
            </div>
        </div>
    
    </form>
        <ng-template pTemplate="footer">
            <button type="submit" (click)="sendRequest(requestForm)" class="btn btn-outline-dark submit-btn" >{{'Submit'|translate}}</button>
            <button type="button" class="btn btn-outline-dark" (click)="displayResponsive=false">{{'cancel'|translate}}</button>
        </ng-template>
    </p-dialog>
    
</div>