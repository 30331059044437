import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';


@Injectable({
  providedIn: 'root'
})
export class CitiesService {

  constructor(private httpClientService: HttpClientService) { }


  GetCityList() {

    let body={
    }
    return this.httpClientService.post('api/cities', body,  {});
  }

  GetCountryCodeList() {

    let body={
    }
    return this.httpClientService.post('api/countries', body,  {});
  }

  getServices(currentPage, lang_id){
    let headers = new Headers();
    headers.set('Content-Type', 'application/json');

    return this.httpClientService.get('api/services',{page:currentPage, lang_id: lang_id},{});
  }



}
