<div class="container page_bg pt-50 pb-50 pr-50 pl-50">
    <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-12 pull-left">
                <h5 class="main-title text-left"> تفاصيل الطلب</h5>
              </div>
            <div class="col-md-6 col-sm-6 col-xs-12 pull-left text-right">
                حالة الطلب: 
                <span class="status approved">approved</span>
            </div>
    </div>
    <div class="row">
        <div class="card">
            <div class="card-title">
                <b class="pull-left bold"> رقم الطلب: <u>123</u> </b>
                <div class="pull-right date">
                    <span> <i class="fa fa-calendar"></i> 12/12/2020 </span>
                    <span> <i class="fa fa-time"></i> 12:00 pm</span>
                </div>
            </div>
            <div class="card-body">
               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                
                <table class="table" cellspacing="0">
                    <tbody>
                        <tr>
                            <th width="30%">نوع الطلب</th>
                            <td>تبديل اطارات</td>
                        </tr>
                        <tr>
                            <th width="30%">موديل السيارة</th>
                            <td>فيرنا 2012</td>
                        </tr>
                        <tr>
                            <th width="30%">مقاس الاطار</th>
                            <td>24</td>
                        </tr>
                        <tr>
                            <th width="30%">الصناعة</th>
                            <td>اندويسي</td>
                        </tr>
                        <tr>
                            <th width="30%">عدد الاطارات</th>
                            <td>4</td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="card-footer">

                <div class="panel-group messages">
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h4 class="panel-title">
                          <a data-toggle="collapse" href="#collapse1"><i class="fa fa-envelope"></i> الرسائل الخاصة بالطلب</a>
                        </h4>
                      </div>
                      <div id="collapse1" class="panel-collapse collapse">
                        <div class="panel-body">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-6 col-xs-12 pull-left">
                                            السعر المقترح : <b>1200 SAR</b>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-12  pull-right date">
                                            <span> <i class="fa fa-calendar"></i> 12/12/2020 </span>
                                            <span> <i class="fa fa-time"></i> 12:00 pm</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                                            </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                      </div>
                    </div>
                  </div>

            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <br>
            <button type="button" class="button reply" data-toggle="modal" data-target="#myModal">
                    <i class="fa fa-envelope"></i> ارسال رد 
            </button>
            <button class="button cancel"> <i class="fa fa-times"></i> الغاء الطلب</button>
        </div>
    </div>
</div>



<!-- Modal -->
<div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
  
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header clearfix">
            <h5 class="modal-title pull-right">ارسال رد</h5>
          <button type="button" class="close pull-left" data-dismiss="modal">&times;</button>
          
        </div>
        <div class="modal-body">
          <form>
              <fieldset>
                <label for="fname">السعر المقترح</label>
                <input type="text" class="form-control" id="price" name="price"><br><br>
                <label for="lname">نص الرسالة:</label>
                <textarea class="form-control" id="msg" name="msg">

                </textarea>
              </fieldset>
          </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="button reply" data-dismiss="modal">ارسال</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">الغاء</button>
        </div>
      </div>
  
    </div>
  </div>