import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';


import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/Shared/Services/authentication.service';
import { CategoriesService } from 'src/app/Shared/Services/categories.service';
import { CitiesService } from 'src/app/Shared/Services/cities.service';
import { PlacesService } from 'src/app/Shared/Services/places.service';
import { AgmMap, MapsAPILoader, MouseEvent } from '@agm/core';
import { LanguageService } from 'src/app/Shared/Services/language.service';

declare var $: any;
declare var google;

@Component({
  selector: 'app-place-create',
  templateUrl: './place-create.component.html',
  styleUrls: ['./place-create.component.scss'],
})
export class PlaceCreateComponent implements OnInit {
  profile_img = '';

  placeForm: FormGroup;
  citieslist;
  Categorieslist: any[] = [];

  image_url: any;
  fileName: string = '';
  listArray = [];
  currentlang: string;
  isEnabled: boolean = false;
  type: any;
  taxFile: any;
  ComercialFile: any;
  comercialFileName: string = '';
  taxFileName: string = '';
  contactId: number;
  cityId: number;
  cityName: string;
  photo;
  img = true;
  commercialFile: any;
  taxsFile: any;
  selected: any[] = [];
  dropdownSettings = {};
  selectedItems = [];

  geocoder: any;
  public location: Location = {
    lat: 30.043408537674175,
    lng: 31.30309544531249,
    marker: {
      lat: 30.043408537674175,
      lng: 31.30309544531249,
      draggable: true,
    },
    zoom: 5,
  };
  address: string = '';
  latitude: any;
  longitude: any;
  lang;

  @ViewChild(AgmMap, { static: true }) map: AgmMap;
  @ViewChild('searchElementRef')
  public searchElementRef: ElementRef;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private placesService: PlacesService,
    private cityservice: CitiesService,
    private categoryservice: CategoriesService,
    private translateservice: TranslateService,
    public mapsApiLoader: MapsAPILoader,
    private languageService: LanguageService,
    private ngZone: NgZone
  ) {
    this.mapsApiLoader = mapsApiLoader;
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.location.lat = place.geometry.location.lat();
          this.latitude = place.geometry.location.lat();
          this.location.lng = place.geometry.location.lng();
          this.longitude = place.geometry.location.lng();
          this.location.zoom = 12;
        });
      });
    });
  }

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });

    console.log('the Selected', this.selected);
    if (!this.authService.getToken()) {
      this.router.navigate(['login-register', this.currentlang], {
        queryParams: { redirect_url: 'Place-New' },
      });
    }
    this.initForm();
    this.route.queryParams.subscribe((res) => {
      this.contactId = +res['id'];
      console.log(this.contactId);
      if (this.contactId > 0) {
        this.placesService.GetPlace(this.contactId).subscribe((response) => {
          console.log('RESPONSE:', response['content'][0]);
          this.updateForm(response['content'][0]);
          this.cityName = response['content'][0].city;
        });
      }
    });

    this.getcitieslist();
    this.getcategorieslist();
    this.currentlang = localStorage.getItem('lang');

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
    let arr: any[] = [];
    this.selectedItems.forEach((ele) => {
      this.Categorieslist.forEach((element) => {
        if (element.id == ele.id) {
          // this.selected.push(element);
          // console.log('match', element);
          arr.push(element);
        }
      });
    });

    this.selected = arr;
    console.log('selected', this.selected);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  initForm() {
    this.placeForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      mobile_number: new FormControl('', [Validators.required]),
      image_url: new FormControl(''),
      city_id: new FormControl('', [Validators.required]),
      category_values_id: new FormControl('', [Validators.required]),
      tax_card: new FormControl('', [Validators.required]),
      commercial_register: new FormControl('', [Validators.required]),
      commercial_registry_file: new FormControl(''),
      tax_card_file: new FormControl(''),
      link: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      // latitude : new FormControl('', [Validators.required]),
      // longitude : new FormControl('', [Validators.required]),
      Facebook: new FormControl('', [
        Validators.pattern('^.*(?:facebook.com|fb.me).*$'),
      ]),
      Twitter: new FormControl('', [
        Validators.pattern('^.*(?:twitter.com).*$'),
      ]),
      Instagram: new FormControl('', [
        Validators.pattern('^.*(?:instagram.com).*$'),
      ]),
      Linkedin: new FormControl('', [
        Validators.pattern('^.*(?:linkedin.com).*$'),
      ]),
    });
  }

  updateForm(data) {
    this.placeForm.get('title').setValue(data.title);
    this.placeForm.get('description').setValue(data.description);
    // this.placeForm.get('latitude').setValue(data.latitude);
    // this.placeForm.get('longitude').setValue(data.longitude);
    this.placeForm
      .get('mobile_number')
      .setValue(data.more[2].value[12].value.value_string);
    this.placeForm.get('address').setValue(data.address);
    this.placeForm.get('link').setValue(data.link);
    this.placeForm.get('tax_card').setValue(data.tax_card);
    this.placeForm
      .get('commercial_register')
      .setValue(data.commercial_register);

    data.categories.forEach((element) => {
      element.title = element.value;
      console.log('Element', element);
    });
    this.placeForm.get('category_values_id').setValue(data.categories);
    this.placeForm.get('city_id').setValue(this.cityId);
    this.photo = data.image;
    this.selectedItems = data.categories;
    this.commercialFile = data.commercial_registry_file;
    this.taxsFile = data.tax_card_file;
    this.selected = data.categories;
    this.location.lat = data.latitude;
    this.location.lng = data.longitude;
    this.placeForm.get('Facebook').setValue(data.social_media[0].pivot.link);
    this.placeForm.get('Twitter').setValue(data.social_media[1].pivot.link);
    this.placeForm.get('Instagram').setValue(data.social_media[2].pivot.link);
    this.placeForm.get('Linkedin').setValue(data.social_media[3].pivot.link);
  }

  closeImagebtn() {
    this.photo = '';
    this.img = false;
  }

  closeCommercialebtn() {
    this.commercialFile = '';
    this.img = false;
  }

  closeTaxbtn() {
    this.taxsFile = '';
    this.img = false;
  }

  submitPlace() {
    this.isEnabled = true;
    if (this.placeForm.valid) {
      this.getcatvaluesid();
      let vals: any[] = [];
      this.selected.forEach((ele) => {
        vals.push(ele.id);
      });
      console.log('Vals', vals);
      let submitObject;
      submitObject = {
        title: this.placeForm.value['title'],
        description: this.placeForm.value['description'],
        mobile_number: this.placeForm.value['mobile_number'],
        image: this.image_url,
        city_id: this.placeForm.value['city_id'],
        category_values_id: vals,
        tax_card: this.placeForm.value['tax_card'],
        commercial_register: this.placeForm.value['commercial_register'],
        commercial_registry_file: this.ComercialFile,
        tax_card_file: this.taxFile,
        link: this.placeForm.value['link'],
        address: this.placeForm.value['address'],
        latitude: this.latitude,
        longitude: this.longitude,
        social_media: [
          {
            social_media_type: 1,
            link: this.placeForm.value['Facebook'],
          },
          {
            social_media_type: 2,
            link: this.placeForm.value['Twitter'],
          },
          {
            social_media_type: 6,
            link: this.placeForm.value['Instagram'],
          },
          {
            social_media_type: 3,
            link: this.placeForm.value['Linkedin'],
          },
        ],
        is_public: 1,
      };

      if (submitObject.image_url == undefined) {
        delete submitObject.image_url;
      }
      if (submitObject.tax_card_file == undefined) {
        delete submitObject.tax_card_file;
      }
      if (submitObject.commercial_registry_file == undefined) {
        delete submitObject.commercial_registry_file;
      }

      console.log('Data to be sent ', submitObject);
      console.log('Categories to be sent ', this.selectedItems);

      if (this.contactId) {
        submitObject.id = this.contactId;

        this.placesService.updatePlace(submitObject).subscribe(
          (res: any) => {
            if (res.status.code === 200) {
              this.isEnabled = false;
              if (this.currentlang == 'en') {
                this.toastService.success('Your place Updated succssfully!');
              } else {
                this.toastService.success('تم تعديل المكان بنجاح');
              }
              this.router.navigate(['../my-profile', this.lang]);
              console.log('Form Values=======>', submitObject);
              console.log(res);
            } else if (res.status.code === 403) {
              this.isEnabled = false;
              let validation_errors = res.status.validation_errors;
              if (validation_errors.length == 0) {
                this.toastService.error(res.status.error_details);
              } else {
                validation_errors.forEach((element) => {
                  if (element.field == 'title') {
                    this.toastService.error(element.message);
                  } else if (element.field == 'description') {
                    this.toastService.error(element.message);
                  } else if (element.field == 'mobile_number') {
                    this.toastService.error(element.message);
                  } else if (element.field == 'latitude') {
                    this.toastService.error(element.message);
                  } else if (element.field == 'longitude') {
                    this.toastService.error(element.message);
                  } else if (element.field == 'address') {
                    this.toastService.error(element.message);
                  } else if (element.field == 'category_values_id') {
                    this.toastService.error(element.message);
                  } else if (element.field == 'city_id') {
                    this.toastService.error(element.message);
                  } else if (element.field == 'commercial_register') {
                    this.toastService.error(element.message);
                  } else if (element.field == 'tax_card') {
                    this.toastService.error(element.message);
                  } else if (element.field == 'link') {
                    this.toastService.error(element.message);
                  }
                });
              }
            } else {
              this.isEnabled = false;
              console.log(res.status.error_details);
              this.toastService.error(res.status.error_details);
            }
          },
          (error) => {
            console.log(error);
            let validation_errors = error.error.status.validation_errors;
            validation_errors.forEach((element) => {
              if (element.field == 'title') {
                this.toastService.error(element.message);
              } else if (element.field == 'description') {
                this.toastService.error(element.message);
              } else if (element.field == 'mobile_number') {
                this.toastService.error(element.message);
              } else if (element.field == 'latitude') {
                this.toastService.error(element.message);
              } else if (element.field == 'longitude') {
                this.toastService.error(element.message);
              } else if (element.field == 'address') {
                this.toastService.error(element.message);
              } else if (element.field == 'category_values_id') {
                this.toastService.error(element.message);
              } else if (element.field == 'city_id') {
                this.toastService.error(element.message);
              } else if (element.field == 'commercial_register') {
                this.toastService.error(element.message);
              } else if (element.field == 'tax_card') {
                this.toastService.error(element.message);
              } else if (element.field == 'link') {
                this.toastService.error(element.message);
              }
            });
          }
        );
      } else {
        this.placesService.createPlace(submitObject).subscribe(
          (res: any) => {
            if (res.status.code === 200) {
              this.isEnabled = false;
              this.toastService.success(
                'Your place was sent succssfully! We will be in touch as soon as possible.'
              );
              this.router.navigate(['../my-profile', this.lang]);
              console.log('Form Values=======>', submitObject);
              console.log(res);
            } else if (res.status.code === 403) {
              this.isEnabled = false;
              let validation_errors = res.status.validation_errors;
              validation_errors.forEach((element) => {
                if (element.field == 'title') {
                  this.toastService.error(element.message);
                } else if (element.field == 'description') {
                  this.toastService.error(element.message);
                } else if (element.field == 'mobile_number') {
                  this.toastService.error(element.message);
                } else if (element.field == 'latitude') {
                  this.toastService.error(element.message);
                } else if (element.field == 'longitude') {
                  this.toastService.error(element.message);
                } else if (element.field == 'address') {
                  this.toastService.error(element.message);
                } else if (element.field == 'category_values_id') {
                  this.toastService.error(element.message);
                } else if (element.field == 'city_id') {
                  this.toastService.error(element.message);
                } else if (element.field == 'commercial_register') {
                  this.toastService.error(element.message);
                } else if (element.field == 'tax_card') {
                  this.toastService.error(element.message);
                } else if (element.field == 'link') {
                  this.toastService.error(element.message);
                }
              });
            } else {
              this.isEnabled = false;
              console.log(res.status.error_details);
              this.toastService.error(res.status.error_details);
            }
          },
          (error) => {
            console.log(error);
            let validation_errors = error.error.status.validation_errors;
            validation_errors.forEach((element) => {
              if (element.field == 'title') {
                this.toastService.error(element.message);
              } else if (element.field == 'description') {
                this.toastService.error(element.message);
              } else if (element.field == 'mobile_number') {
                this.toastService.error(element.message);
              } else if (element.field == 'latitude') {
                this.toastService.error(element.message);
              } else if (element.field == 'longitude') {
                this.toastService.error(element.message);
              } else if (element.field == 'address') {
                this.toastService.error(element.message);
              } else if (element.field == 'category_values_id') {
                this.toastService.error(element.message);
              } else if (element.field == 'city_id') {
                this.toastService.error(element.message);
              } else if (element.field == 'commercial_register') {
                this.toastService.error(element.message);
              } else if (element.field == 'tax_card') {
                this.toastService.error(element.message);
              } else if (element.field == 'link') {
                this.toastService.error(element.message);
              }
            });
          }
          // , err => {
          //   console.log(err)
          //   this.toastService.error("Something went wrong, verify the fields or try refreshing the page.")
          // }
        );
      }
    } else {
      this.isEnabled = false;
    }
  }

  getcitieslist() {
    this.cityservice.GetCityList().subscribe((res: any) => {
      this.citieslist = res.content;
      console.log(this.citieslist);
      if (this.contactId) {
        this.citieslist.forEach((ele) => {
          if (ele.name == this.cityName) {
            this.cityId = ele.id;
            console.log('The City ID:-', this.cityId);
            this.placeForm.get('city_id').setValue(this.cityId);
          }
        });
      }
    });
  }

  getcategorieslist() {
    this.categoryservice.GetCategoryList().subscribe((res: any) => {
      this.Categorieslist = res.content;
      //  console.log(this.Categorieslist)
    });
  }

  items = [
    { id: 1, name: 'Python' },
    { id: 2, name: 'Node Js' },
    { id: 3, name: 'Java' },
    { id: 4, name: 'PHP', disabled: true },
    { id: 5, name: 'Django' },
    { id: 6, name: 'Angular' },
    { id: 7, name: 'Vue' },
    { id: 8, name: 'ReactJs' },
  ];

  readImage(event) {
    var files = event.target.files[0];
    var reader = new FileReader();
    this.fileName = files.name;
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.img = true;
    this.image_url = 'data:image/jpeg;base64,' + btoa(binaryString);
    // console.log(this.image_url )
  }

  // closebtn(index) {
  //   this.image_url ='';
  //   $("#image_url").val('')

  // }

  openImg() {
    var win = window.open();
    win.document.write(
      '<iframe src="' +
        this.image_url +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    );
  }

  // convert image to base64

  getValues() {
    // console.log(this.selected);
    // this.listArray.push(this.selected)
    // console.log ("categories array======>",this.listArray)
    console.log('the Selected', this.selected);
  }

  getcatvaluesid() {
    this.listArray.push(this.selected);
    console.log('categories array======>', this.listArray);
  }

  getcatvalues(event, id) {
    if (event.target.checked) {
      this.listArray.push({ category_values_id: id });
      console.log('categories array=============>', this.listArray);
    } else {
      let index = this.listArray.findIndex(
        (element) => element.category_values_id == id
      );
      this.listArray.splice(index, 1);
      console.log('categories array=============>', this.listArray);
    }
  }

  readTaxFile(event) {
    var files = event.target.files[0];
    this.type = files.type;
    this.taxFileName = files.name;

    var reader = new FileReader();
    reader.onload = this.handleTaxFile.bind(this);
    reader.readAsBinaryString(files);
  }

  handleTaxFile(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.taxFile = 'data:' + this.type + ';base64,' + btoa(binaryString);
    console.log('The pdf file:-', this.taxFile);
  }

  readCommercialFile(event) {
    var files = event.target.files[0];
    this.type = files.type;
    this.comercialFileName = files.name;

    var reader = new FileReader();
    reader.onload = this.handleComercialFile.bind(this);
    reader.readAsBinaryString(files);
  }

  handleComercialFile(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.ComercialFile = 'data:' + this.type + ';base64,' + btoa(binaryString);
    console.log('The pdf file:-', this.taxFile);
  }
  markerDragEnd($event: MouseEvent) {
    console.log('dragEnd', $event);
    this.location.lat = $event.coords.lat;
    this.location.lng = $event.coords.lng;
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.location.lat, this.location.lng);
  }

  getAddress(latitude, longitude) {
    this.geocoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        console.log(results);
        console.log(status);
        if (status === 'OK') {
          if (results[0]) {
            this.location.zoom = 12;
            this.address = results[0].formatted_address;
            // this.getAddresses(this.address);
            console.log('Address is:- ', this.address);
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  detectLocation() {
    window.navigator.geolocation.getCurrentPosition((pos) => {
      console.log('Latitude ', pos.coords.latitude);
      console.log('Longitude ', pos.coords.longitude);

      this.location.lat = pos.coords.latitude;
      this.location.lng = pos.coords.longitude;
      this.latitude = pos.coords.latitude;
      this.longitude = pos.coords.longitude;
      this.getAddress(this.location.lat, this.location.lng);
    });
  }

  // getAddresses(placeId){
  //   var placesService = new google.maps.places.PlacesService(this.map);
  //   /* Get place details */
  //   placesService.getDetails(
  //     {placeId: placeId}, (placeResult, status) => {
  //       if(status === 'OK') {
  //       console.log('Results ', placeResult)
  //       var photos = placeResult.photos;
  //       var urls = []; // we will store the urls here

  //       photos.forEach((photo) => {
  //         urls.push(photo.getUrl({
  //           maxWidht: 500, // at least set one or the other - mandatory
  //           maxHeight: undefined
  //         }));
  //       });

  //     /* You have your URLs here !! */
  //     }
  // });
  // }
}


interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

interface Location {
  lat: number;
  lng: number;
  viewport?: Object;
  zoom: number;
  address_level_1?: string;
  address_level_2?: string;
  address_country?: string;
  address_zip?: string;
  address_state?: string;
  marker?: Marker;
}
