<!-- Start Page Content -->
<div class="container pg-content">
    <div class="pg-header-content">
        <h1 class="pg-title">
            {{service_name}}  {{'From Qpedia'|translate}}
        </h1>
        <h4 class="pg-subtitle" style="padding: 0px 40px;">
            {{'The request will reach all companies that provide the service, and you will be answered soon'|translate}}
        </h4>
    </div>

    <!--Start Contact Form -->
    <div class="col-sm-12 text-center">

        <form [formGroup]="itemForm" #myform="ngForm" (ngSubmit)="create(itemForm.value)" class="form-horizontal contact-form">
            <!-- Text input-->

            <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-user"></i></span>
                        <input formControlName="name" [(ngModel)]="query" (ngModelChange)="search(query)" name="name"
                            id="name" placeholder="{{'name'|translate}}" class="form-control">

                        <span *ngIf="itemForm.controls['name']?.errors?.required && myform.submitted"
                            class="error-msg">
                            {{'name'|translate}} {{'required'|translate}}
                        </span>

                    </div>
                </div>
            </div>

            <div *ngIf="result.length > 0 && show == true">
                <ul>
                    <li *ngFor="let res of result" (click)="setName(res.name)">
                        {{res.name}}
                    </li>
                </ul>
            </div>

            <!-- Text input-->
            <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-bars"></i></span>
                        <input formControlName="title" name="title" id="title"
                            placeholder="{{'Requests.TitleRequests'|translate}}" class="form-control">

                        <span *ngIf="itemForm.controls['title']?.errors?.required && myform.submitted"
                            class="error-msg">
                            {{'Requests.TitleRequests'|translate}} {{'required'|translate}}
                        </span>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-sm-12 inputGroupContainer">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="glyphicon glyphicon-th-list"></i></span>
                    
                    <ng-multiselect-dropdown
                          [data]="citieslist"
                          [settings]="dropdownSettings"
                          formControlName="cities"
                          [(ngModel)]="selectedItems"
                          (onSelect)="onItemSelect($event)"
                          (onSelectAll)="onSelectAll($event)"
                        >
                    </ng-multiselect-dropdown>
        
        
        
                  </div>
                  <span *ngIf="itemForm.controls['cities']?.errors?.required && myform.submitted"
                    class="error-msg">
                    {{ 'categoryReqMes' | translate}}
                  </span>
                  
                </div>
              </div>
            <!-- Text input-->
            <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-briefcase"></i></span>
                        <textarea formControlName="description" name="description" id="description"
                            placeholder="{{'description'|translate}}" class="form-control"></textarea>

                        <span *ngIf="itemForm.controls['description']?.errors?.required && myform.submitted"
                            class="error-msg">
                            {{'description'|translate}} {{'required'|translate}}
                        </span>
                    </div>
                </div>
            </div>

            <!-- <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                    <div class="input-group">
                        <input type="checkbox" formControlName="all_cities_included">
                        <label>{{'All Citites'}}</label>
                    </div>
                </div>
            </div> -->


            <!-- Submit Button -->
            <div class="form-group sub">
                <div class="col-sm-12">
                    <button type="submit" name="name"
                        class="btn btn-warning submit"><i *ngIf="isEnabled" class="fa fa-spinner fa-spin"></i>
                        {{'Submit'|translate}}</button>
                </div>
            </div>
        </form>
        
    </div>
    <!--End Contact Form-->
</div>
<!-- End Page Content -->