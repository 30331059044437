import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HomeService } from 'src/app/Shared/Services/home.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-request-types',
  templateUrl: './request-types.component.html',
  styleUrls: ['./request-types.component.scss']
})
export class RequestTypesComponent implements OnInit {

  pageSize: number;
  totalPages: number;
  p: number = 1;
  totalapi: number;
  requestsList:any[]=[];
  currentlang;

  constructor(
    private toastr:ToastrService,
    private router:Router,
    private modalService: NgbModal,
    private service:HttpClientService,
    private homeService:HomeService
  ) {}

  ngOnInit(): void {
    this.currentlang = localStorage.getItem('lang');

    // this.getrequests();
    this.getTypes();

  }

  getrequests(page?)
  {
    let parameters = new HttpParams();
    let pagination={'pagination':10};

      if(page){
        parameters= parameters.set('page',page+"");

      }
      this.requestsList=[
        {id:1,name:"Call"},
        {id:2,name:"Meeting"},
        {id:3,name:"Other"}
      ]
    // this.service.post('api/employees',pagination,parameters).subscribe((res:any)=>
    // {
    //   console.log("user =>",res);
    //   this.requestsList=res.content;
    //   this.totalapi = res.pagination.total;
    //   this.pageSize = res.pagination.per_page;
    //   this.p = res.pagination.current_page

    // })
  }


  editTask(id: number){
    this.router.navigate(['update-request-types', id]);
  }

    onPageChange(event){
      this.p=event;
      this.getrequests(this.p+"");
  }

  deleteSelected()
  {
    Swal.fire({
      title: 'Are you sure you want to delete selected request ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {

        Swal.fire({
          icon: 'success',
          title: 'The requests deleted successfully !',
          showConfirmButton: false,
          timer: 1500

        });
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })
  }

  getTypes() {
    this.homeService.getTypes().subscribe(
      res => {
        console.log('The types', res);
        this.requestsList = res['content'];
      }
    );
  }

}




