<section class="video-section">
 
  <div class="container">
      <div class="video-content">
          <div class="text-white" data-aos="fade-up" data-aos-delay="200" data-aos-easing="ease">
              <!-- <div class="create">Qpedia home page main section that give us berief summary of the </div> -->
              <div class="fun">
                  {{'main-section'|translate}}
                  
                </div>
              <a class="reserve-btn">
                  {{'Reserve'|translate}}
              </a>
          </div>
      </div>
  </div>

  <div class="video-container">
      <div class="overlay"></div>
      <img class="main-img" src="assets/images/13.jpg">
      <!-- <video id="video" playsinline="" preload="auto" muted="" autoplay="autoplay" loop="loop"
          poster="https://selfieboothco.com/wp-content/webp-express/webp-images/doc-root/wp-content/uploads/2020/08/firefox_yzkvz8kjfw.jpg.webp"
          width="100%" height="auto">
          <source src="https://selfieboothco.com/wp-content/uploads/video/v1selfieboothco.mp4" type="video/mp4">
          <source src="https://selfieboothco.com/wp-content/uploads/video/v1selfieboothco.webm" type="video/webm">
      </video> -->
  </div>


</section>