<section>
    <div class="background">
         <div class="container">
             <div class="row">
                <div class="col-md-3 col-sm-2 col-xs-1"></div>

                <div class="col-md-6 col-sm-8 col-xs-10">
                    <div class="card">

                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
                                <img class="logo" src="assets/images/logo.png">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center;">

                            <div class="steps">
                                <button class="step-number step-selected">1</button>
                                <button class="link"></button>
                                <button class="step-number" id="stepcircle">2</button>
                            </div>

                            <h2 id="titlestep1">{{'Enter Your Personal Information'|translate}}</h2>
                            <p  id="title2step1" class="grey-title">{{'You Can edit it later'|translate}}</p>

                            <form id="step1" class="form1" [formGroup]="registerForm1" (ngSubmit)="SubmitForm(registerForm1)">
                              <div id="captcha"></div>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label >{{'Full Name'|translate}} <span class="required">*</span></label>
                                            <input  type="text"  formControlName="full_name" name="fullname" id="register-fullname" tabindex="1" class="form-control" placeholder="{{'Full Name'|translate}}" value="">

                                            <span *ngIf="registerForm1.controls['full_name']?.errors?.required " class="error-msg">
                                                {{'nameReqMes'|translate}}
                                            </span>

                                            <span *ngIf="registerForm1.controls['full_name']?.errors?.pattern"  class="error-msg">
                                                {{'nameMustBeCharc'|translate}}.
                                            </span>

                                            <span *ngIf="errorList?.full_name" class="error-msg">
                                                {{ errorList?.full_name}}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label>{{'phoneno'|translate}} <span class="required">*</span></label><br>

                                            <ngx-intl-tel-input
                                                [cssClass]="'col-md-12 form-control'"
                                                [customPlaceholder]="currentlang == 'en' ? 'Please Enter Your Phone Number' : 'من فضلك قم بادخال رقم الهاتف'"
                                                [preferredCountries]="preferredCountries"
                                                [enableAutoCountrySelect]="true" [searchCountryFlag]="true"
                                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                                [selectedCountryISO]="CountryISO.Egypt" [maxLength]="15"
                                                [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                                [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="mobile">
                                            </ngx-intl-tel-input><br>
                                            <span *ngIf="registerForm1.controls['mobile']?.errors?.required " class="error-msg">
                                                {{'phoneReqMes'|translate}}
                                            </span>
                                            <span *ngIf="(registerForm1.controls['mobile']?.value?.number?.length < 10 || registerForm1.controls['mobile']?.value?.number?.length > 11) && registerForm1.controls['mobile']?.value?.dialCode == '+20' " class="error-msg">
                                                {{'phonePatMes'|translate}}.
                                            </span>
                                            <span *ngIf="registerForm1.controls['mobile']?.value?.number?.length != 8 && registerForm1.controls['mobile']?.value?.dialCode == '+966' " class="error-msg">
                                                {{'phonePatMes'|translate}}.
                                            </span>
                                        </div>

                                    </div>

                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label>{{'Password'|translate}} <span class="required">*</span></label>
                                            <input  type="password" formControlName="password" name="password" id="register-password" tabindex="10" class="form-control" placeholder="{{'Password'|translate}}">

                                            <span *ngIf="registerForm1.controls['password']?.errors?.required " class="error-msg">
                                                {{'passwordReqMes'|translate}}
                                            </span>
                                            <span *ngIf="registerForm1.controls['password']?.errors?.minlength " class="error-msg">
                                                {{'passwordMinLengMes'|translate}}.
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label>{{'Job'|translate}} <span class="required">*</span></label>
                                            <select formControlName="job" name="job" tabindex="10" class="form-control" >
                                                <option>{{'Choose a job'|translate}}</option>
                                                <option *ngFor="let job of jobs" [value]="job.id">{{job.title}}</option>
                                                <!-- <option *ngFor="let job of jobs" [value]="job.title">{{job.title}}</option> -->

                                            </select>
                                            <span *ngIf="registerForm1.controls['job']?.errors?.required " class="error-msg">
                                                {{'jobReqMes'|translate}}
                                            </span>

                                        </div>

                                    </div>

                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                       <button class="submit-btn"  type="submit">{{'Create Account'|translate}}</button>
                                    </div>


                                </div>

                            </form>

                            <div id="step2" class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <h1 class="bold-title"> {{'Bravo'|translate}}</h1>
                                    <h2 class="mar-top">{{'New account has been created'|translate}}</h2>
                                    <p class="grey-title">{{'You Can Now Use Qpedia Easily'|translate}}</p>
                                </div>

                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <img class="sucess-img" src="assets/images/sucess-individual-register.jpg">
                                </div>

                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <button class="submit-btn" (click)="NavigateToHome()">{{'Go To Homepage'|translate}}</button>
                                </div>

                            </div>

                            </div>
                        </div>


                    </div>
                </div>

                <div class="col-md-3 col-sm-2 col-xs-1"></div>

             </div>
         </div>
    </div>
</section>
