import { Component, OnInit, HostListener } from '@angular/core';
import { CategoriesService } from '../../Shared/Services/categories.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AddingMetaService } from 'src/app/Shared/Services/adding-meta.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  CategoriesData;
  page_no: number = 1;
  p: number = 1;
  lang: string;

  constructor(
    private catservice: CategoriesService,
    private languageService: LanguageService,
    private router: Router,
    private meta: AddingMetaService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.getAllCategories();

    this.meta.updateMeta({
      compTitle: 'Categories',
    });
  }

  getAllCategories() {
    this.catservice.GetCategoryList().subscribe((res: any) => {
      this.CategoriesData = res.content;
      console.log(this.CategoriesData);
    });
  }

  // @HostListener("window:scroll")
  onWindowScroll($event) {
    // console.log("scrolling function working=====>",$event);
    let pos =
      (document.documentElement.scrollTop || document.body.scrollTop) +
      document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
    if (pos == max) {
      //Do your action here
      this.page_no++;
      console.log('Page Number ===========>', this.page_no);
    }
  }

  navigateplaces(item) {
    this.router.navigate(['categories/places', this.lang], {
      queryParams: {
        category_value_id: item.id,
      },
    });
  }
}
