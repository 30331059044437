import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/Shared/Services/authentication.service';
import { CategoriesService } from 'src/app/Shared/Services/categories.service';
import { CitiesService } from 'src/app/Shared/Services/cities.service';
import { HomeService } from 'src/app/Shared/Services/home.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';
import { PlacesService } from 'src/app/Shared/Services/places.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-place-view',
  templateUrl: './place-view.component.html',
  styleUrls: ['./place-view.component.scss'],
})
export class PlaceViewComponent implements OnInit {
  profile_img = '';

  placeForm: FormGroup;
  citieslist;
  Categorieslist;

  image_url: any;
  fileName: string = '';
  listArray = [];
  currentlang: string;
  isEnabled: boolean = false;
  type: any;
  taxFile: any;
  ComercialFile: any;
  comercialFileName: string = '';
  taxFileName: string = '';
  contactId: number;
  cityId: number;
  cityName: string;
  city: string = '';
  photo;
  img = true;
  commercialFile: any;
  taxsFile: any;

  projects: any[] = [];
  products: any[] = [];
  services: any[] = [];
  IndividualRequests: any[] = [];
  OffersRequests: any[] = [];
  relatedContacts: any[] = [];
  dropdownSettings = {};
  selectedItems = [];
  geocoder: any;
  public location: Location = {
    lat: 30.043408537674175,
    lng: 31.30309544531249,
    marker: {
      lat: 30.043408537674175,
      lng: 31.30309544531249,
      draggable: false,
    },
    zoom: 5,
  };

  pageSize: number = 10;
  p1: number = 1;
  p2: number = 1;
  p3: number = 1;
  p4: number = 1;
  p5: number = 1;
  p6: number = 1;

  totalRelated: number;
  totalProjects: number;
  totalProducts: number;
  totalServices: number;
  totalIndividualRequests: number;
  totalOffersRequests: number;

  lang;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private http: HttpClientService,
    private placesService: PlacesService,
    private homeService: HomeService,
    private cityservice: CitiesService,
    private categoryservice: CategoriesService,
    private languageService: LanguageService,
    private translateservice: TranslateService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    console.log('the Selected', this.selected);
    if (!this.authService.getToken()) {
      this.router.navigate(['login-register', this.currentlang], {
        queryParams: { redirect_url: 'Place-New' },
      });
    }
    this.initForm();
    this.route.queryParams.subscribe((res) => {
      this.contactId = +res['id'];

      this.getPlaceServices(1);
      this.getPlaceIndividualRequests(1);
      this.getPlaceOffersRequests(1);

      this.getPlaceProjects(1);
      this.getPlaceProducts(1);
      this.getRelatedContacts(1);
      console.log(this.contactId);
      if (this.contactId > 0) {
        this.placesService.GetPlace(this.contactId).subscribe((response) => {
          console.log('RESPONSE:', response['content'][0]);
          this.updateForm(response['content'][0]);
          this.cityName = response['content'][0].city;
        });
      }
    });

    this.getcitieslist();
    this.getcategorieslist();
    this.currentlang = localStorage.getItem('lang');

    this.dropdownSettings = {
      singleSelection: true,
      closeDropDownOnSelection: true,
      disabled: true,
      idField: 'id',
      textField: 'value',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  getPlaceServices(page?) {
    this.placesService
      .getPlaceServices(this.contactId, page)
      .subscribe((res: any) => {
        this.services = res['content'];
        this.totalServices = res.pagination?.total;
        this.p4 = res.pagination?.current_page;
      });
  }

  getPlaceIndividualRequests(page?) {
    this.placesService
      .getIndividualRequests(this.contactId, page)
      .subscribe((res: any) => {
        this.IndividualRequests = res.content.data;
        console.log('The Individual Requests:----', this.IndividualRequests);
        this.totalIndividualRequests = res.pagination?.total;
        this.p5 = res.pagination?.current_page;
      });
  }

  getPlaceOffersRequests(page?) {
    this.placesService
      .getOffersRequests(this.contactId, page)
      .subscribe((res: any) => {
        this.OffersRequests = res['content'];
        this.totalOffersRequests = res.pagination?.total;
        this.p6 = res.pagination?.current_page;
        console.log(
          'The Asked Questions to this place:----',
          this.OffersRequests
        );
      });
  }

  onServicesChange(event) {
    this.p4 = event;
    this.getPlaceServices(this.p4 + '');
  }

  onIndividualRequestsChange(event) {
    this.p5 = event;
    this.getPlaceIndividualRequests(this.p5 + '');
  }
  onOfferRequestChange(event) {
    this.p6 = event;
    this.getPlaceOffersRequests(this.p6 + '');
  }

  getPlaceProducts(page?) {
    this.placesService
      .getPlaceProducts(this.contactId, page)
      .subscribe((res: any) => {
        this.products = res['content'];
        this.totalProducts = res.pagination?.total;
        this.p3 = res.pagination?.current_page;
        console.log('User products :::::::-', this.products);
      });
  }
  onProductsChange(event) {
    this.p3 = event;
    this.getPlaceProducts(this.p3 + '');
  }

  getPlaceProjects(page?) {
    this.placesService
      .getPlaceProjects(this.contactId, page)
      .subscribe((res: any) => {
        this.projects = res['content'];
        this.totalProjects = res.pagination?.total;
        this.p2 = res.pagination?.current_page;
        console.log('user projects :::::::-', this.projects);
      });
  }
  onProjectsChange(event) {
    this.p2 = event;
    this.getPlaceProjects(this.p2 + '');
  }

  getRelatedContacts(page?) {
    this.homeService
      .getRelatedContacts(this.contactId, page)
      .subscribe((res: any) => {
        this.relatedContacts = res['content'];
        this.totalRelated = res.pagination?.total;
        this.p1 = res.pagination?.current_page;
      });
  }

  onRelatedChange(event) {
    this.p1 = event;
    this.getRelatedContacts(this.p1 + '');
  }

  initForm() {
    this.placeForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      mobile_number: new FormControl('', []),
      image_url: new FormControl(''),
      city_id: new FormControl('', [Validators.required]),
      category_values_id: new FormControl('', [Validators.required]),
      tax_card: new FormControl('', [Validators.required]),
      commercial_register: new FormControl('', [Validators.required]),
      commercial_registry_file: new FormControl(''),
      tax_card_file: new FormControl(''),
      link: new FormControl(''),
      address: new FormControl('', [Validators.required]),
      // latitude : new FormControl('', [Validators.required]),
      // longitude : new FormControl('', [Validators.required]),
      Facebook: new FormControl(''),
      Twitter: new FormControl(''),
      Instagram: new FormControl(''),
      Linkedin: new FormControl(''),
    });
  }

  updateForm(data) {
    this.placeForm.get('title').setValue(data.title);
    this.placeForm.get('description').setValue(data.description);
    // this.placeForm.get('latitude').setValue(data.latitude);
    // this.placeForm.get('longitude').setValue(data.longitude);
    this.placeForm
      .get('mobile_number')
      .setValue(data.more[2].value[12].value.value_string);
    this.placeForm.get('address').setValue(data.address);
    this.placeForm
      .get('link')
      .setValue(data.more[2].value[10].value.value_string);
    this.placeForm.get('tax_card').setValue(data.tax_card);
    this.placeForm
      .get('commercial_register')
      .setValue(data.commercial_register);
    this.placeForm.get('category_values_id').setValue(data.categories);
    this.placeForm.get('city_id').setValue(this.cityId);
    this.photo = data.image;
    this.commercialFile = data.commercial_registry_file;
    this.taxsFile = data.tax_card_file;
    this.selected = data.categories;
    this.selectedItems = data.categories;
    this.location.lat = +data.latitude;
    this.location.lng = +data.longitude;
    this.city = data.city;
    this.placeForm.get('Facebook').setValue(data.social_media[0]?.pivot.link);
    this.placeForm.get('Twitter').setValue(data.social_media[1]?.pivot.link);
    this.placeForm.get('Instagram').setValue(data.social_media[2]?.pivot.link);
    this.placeForm.get('Linkedin').setValue(data.social_media[3]?.pivot.link);
  }

  closeImagebtn() {
    this.photo = '';
    this.img = false;
  }

  closeCommercialebtn() {
    this.commercialFile = '';
    this.img = false;
  }

  closeTaxbtn() {
    this.taxsFile = '';
    this.img = false;
  }

  submitPlace() {
    this.isEnabled = true;
    this.getcatvaluesid();
    let submitObject;
    submitObject = {
      title: this.placeForm.value['title'],
      description: this.placeForm.value['description'],
      mobile_number: this.placeForm.value['mobile_number'],
      image_url: this.image_url,
      city_id: this.placeForm.value['city_id'],
      category_values_id: this.selected,
      tax_card: this.placeForm.value['tax_card'],
      commercial_register: this.placeForm.value['commercial_register'],
      commercial_registry_file: this.ComercialFile,
      tax_card_file: this.taxFile,
      link: this.placeForm.value['link'],
      address: this.placeForm.value['address'],
      // "latitude":this.placeForm.value['latitude'],
      // "longitude":this.placeForm.value['longitude'],
      Facebook: this.placeForm.value['Facebook'],
      Twitter: this.placeForm.value['Twitter'],
      Instagram: this.placeForm.value['Instagram'],
      Linkedin: this.placeForm.value['Linkedin'],
      is_public: 1,
    };

    if (this.contactId) {
      submitObject.id = this.contactId;
    }
    this.placesService.createPlace(submitObject).subscribe(
      (res: any) => {
        if (res.status.code === 200) {
          this.isEnabled = false;
          this.toastService.success(
            'Your place was sent succssfully! We will be in touch as soon as possible.'
          );
          this.router.navigate(['', this.currentlang]);
          console.log('Form Values=======>', submitObject);
          console.log(res);
        } else if (res.status.code === 403) {
          this.isEnabled = false;
          let validation_errors = res.status.validation_errors;
          validation_errors.forEach((element) => {
            if (element.field == 'title') {
              this.toastService.error(element.message);
            } else if (element.field == 'description') {
              this.toastService.error(element.message);
            } else if (element.field == 'mobile_number') {
              this.toastService.error(element.message);
            } else if (element.field == 'latitude') {
              this.toastService.error(element.message);
            } else if (element.field == 'longitude') {
              this.toastService.error(element.message);
            } else if (element.field == 'address') {
              this.toastService.error(element.message);
            } else if (element.field == 'category_values_id') {
              this.toastService.error(element.message);
            } else if (element.field == 'city_id') {
              this.toastService.error(element.message);
            } else if (element.field == 'commercial_register') {
              this.toastService.error(element.message);
            } else if (element.field == 'tax_card') {
              this.toastService.error(element.message);
            }
          });
        } else {
          this.isEnabled = false;
          console.log(res.status.error_details);
          this.toastService.error(res.status.error_details);
        }
      }
      // , err => {
      //   console.log(err)
      //   this.toastService.error("Something went wrong, verify the fields or try refreshing the page.")
      // }
    );
  }

  getcitieslist() {
    this.cityservice.GetCityList().subscribe((res: any) => {
      this.citieslist = res.content;
      console.log(this.citieslist);
      if (this.contactId) {
        this.citieslist.forEach((ele) => {
          if (ele.name == this.cityName) {
            this.cityId = ele.id;
          }
        });
      }
    });
  }

  getcategorieslist() {
    this.categoryservice.GetCategoryList().subscribe((res: any) => {
      this.Categorieslist = res.content;
      //  console.log(this.Categorieslist)
    });
  }

  items = [
    { id: 1, name: 'Python' },
    { id: 2, name: 'Node Js' },
    { id: 3, name: 'Java' },
    { id: 4, name: 'PHP', disabled: true },
    { id: 5, name: 'Django' },
    { id: 6, name: 'Angular' },
    { id: 7, name: 'Vue' },
    { id: 8, name: 'ReactJs' },
  ];
  selected = [];

  readImage(event) {
    var files = event.target.files[0];
    var reader = new FileReader();
    this.fileName = files.name;
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.img = true;
    this.image_url = 'data:image/jpeg;base64,' + btoa(binaryString);
    // console.log(this.image_url )
  }

  // closebtn(index) {
  //   this.image_url ='';
  //   $("#image_url").val('')

  // }

  openImg() {
    var win = window.open();
    win.document.write(
      '<iframe src="' +
        this.image_url +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    );
  }

  // convert image to base64

  getValues() {
    // console.log(this.selected);
    // this.listArray.push(this.selected)
    // console.log ("categories array======>",this.listArray)
    console.log('the Selected', this.selected);
  }

  getcatvaluesid() {
    this.listArray.push(this.selected);
    console.log('categories array======>', this.listArray);
  }

  getcatvalues(event, id) {
    if (event.target.checked) {
      this.listArray.push({ category_values_id: id });
      console.log('categories array=============>', this.listArray);
    } else {
      let index = this.listArray.findIndex(
        (element) => element.category_values_id == id
      );
      this.listArray.splice(index, 1);
      console.log('categories array=============>', this.listArray);
    }
  }

  readTaxFile(event) {
    var files = event.target.files[0];
    this.type = files.type;
    this.taxFileName = files.name;

    var reader = new FileReader();
    reader.onload = this.handleTaxFile.bind(this);
    reader.readAsBinaryString(files);
  }

  handleTaxFile(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.taxFile = 'data:' + this.type + ';base64,' + btoa(binaryString);
    console.log('The pdf file:-', this.taxFile);
  }

  readCommercialFile(event) {
    var files = event.target.files[0];
    this.type = files.type;
    this.comercialFileName = files.name;

    var reader = new FileReader();
    reader.onload = this.handleComercialFile.bind(this);
    reader.readAsBinaryString(files);
  }

  handleComercialFile(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.ComercialFile = 'data:' + this.type + ';base64,' + btoa(binaryString);
    console.log('The pdf file:-', this.taxFile);
  }

  navigateUpdate(data) {
    console.log(data);
    this.router.navigate(['service-create', this.currentlang], {
      queryParams: { id: data.id },
    });
  }

  navigateProduct(data) {
    this.router.navigate(['product-create', this.currentlang], {
      queryParams: { id: data.id },
    });
  }

  navigateProject(data) {
    this.router.navigate(['project-new', this.currentlang], {
      queryParams: { id: data.id },
    });
  }

  navigateContact(data) {
    this.router.navigate(['related-create', this.currentlang], {
      queryParams: { id: data.id, paceId: this.contactId },
    });
  }

  deleteService(data) {
    Swal.fire({
      title: this.translateservice.instant('delete_service'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translateservice.instant('delete'),
      cancelButtonText: this.translateservice.instant('cancel'),
    }).then((result) => {
      if (result.value) {
        this.http
          .post('api/contacts_services/destroy', { id: data.id }, {})
          .subscribe((res: any) => {
            console.log(res);
            if (res.status.code == 200) {
              if (this.currentlang == 'en') {
                Swal.fire({
                  icon: 'success',
                  title: ' ',
                  text: 'Service deleted successfully',
                });
              } else if (this.currentlang == 'ar') {
                Swal.fire({
                  icon: 'success',
                  title: ' ',
                  text: 'تمت ازاله الخدمة',
                });
              }
              this.getPlaceServices();
            } else {
              this.toastService.error(res.status.message);
            }
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  deleteProduct(data) {
    Swal.fire({
      title: this.translateservice.instant('delete_product'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translateservice.instant('delete'),
      cancelButtonText: this.translateservice.instant('cancel'),
    }).then((result) => {
      if (result.value) {
        this.http
          .post('api/contacts_products/destroy', { id: data.id }, {})
          .subscribe((res: any) => {
            console.log(res);
            if (res.status.code == 200) {
              if (this.currentlang == 'en') {
                Swal.fire({
                  icon: 'success',
                  title: ' ',
                  text: 'Product deleted successfully',
                });
              } else if (this.currentlang == 'ar') {
                Swal.fire({
                  icon: 'success',
                  title: ' ',
                  text: 'تمت ازاله المنتج',
                });
              }
              this.getPlaceProducts();
            } else {
              this.toastService.error(res.status.message);
            }
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  deleteProject(data) {
    Swal.fire({
      title: this.translateservice.instant('delete_project'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translateservice.instant('delete'),
      cancelButtonText: this.translateservice.instant('cancel'),
    }).then((result) => {
      if (result.value) {
        this.http
          .post('api/contacts_projects/destroy', { id: data.id }, {})
          .subscribe((res: any) => {
            console.log(res);
            if (res.status.code == 200) {
              if (this.currentlang == 'en') {
                Swal.fire({
                  icon: 'success',
                  title: ' ',
                  text: 'Project deleted successfully',
                });
              } else if (this.currentlang == 'ar') {
                Swal.fire({
                  icon: 'success',
                  title: ' ',
                  text: 'تمت ازاله المشروع',
                });
              }
              this.getPlaceProjects();
            } else {
              this.toastService.error(res.status.message);
            }
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  deleteContact(data) {
    Swal.fire({
      title: this.translateservice.instant('delete_contact'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translateservice.instant('delete'),
      cancelButtonText: this.translateservice.instant('cancel'),
    }).then((result) => {
      if (result.value) {
        this.http
          .post('api/cms/delete_related_contact', { id: data.id }, {})
          .subscribe((res: any) => {
            console.log(res);
            if (res.status.code == 200) {
              if (this.currentlang == 'en') {
                Swal.fire({
                  icon: 'success',
                  title: ' ',
                  text: 'Related Contact deleted successfully',
                });
              } else if (this.currentlang == 'ar') {
                Swal.fire({
                  icon: 'success',
                  title: ' ',
                  text: ' تمت ازاله جهة الاتصال المتعلقة',
                });
              }
              this.getRelatedContacts();
            } else {
              this.toastService.error(res.status.message);
            }
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  createContact() {
    this.router.navigate(['related-create', this.currentlang], {
      queryParams: { placeId: this.contactId },
    });
  }

  createProject() {
    this.router.navigate(['project-new', this.currentlang], {
      queryParams: { placeId: this.contactId },
    });
  }

  createProduct() {
    this.router.navigate(['product-create', this.currentlang], {
      queryParams: { placeId: this.contactId },
    });
  }

  createService() {
    this.router.navigate(['service-create', this.currentlang], {
      queryParams: { placeId: this.contactId },
    });
  }

  openProject() {
    this.router.navigate(['project-new', this.lang]);
  }

  openService() {
    this.router.navigate(['service-create', this.lang]);
  }

  openRelated() {
    this.router.navigate(['related-create', this.lang]);
  }

  openProduct() {
    this.router.navigate(['product-create', this.lang]);
  }

  openJob() {
    this.router.navigate(['create-job', this.lang], {
      queryParams: { placeId: this.contactId },
    });
  }
}

interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

interface Location {
  lat: number;
  lng: number;
  viewport?: Object;
  zoom: number;
  address_level_1?: string;
  address_level_2?: string;
  address_country?: string;
  address_zip?: string;
  address_state?: string;
  marker?: Marker;
}
