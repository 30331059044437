import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CitiesService } from 'src/app/Shared/Services/cities.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { ProjectsService } from 'src/app/Shared/Services/projects.service';
import {CategoriesService} from '../../Shared/Services/categories.service';
import Swal from 'sweetalert2';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-service-create',
  templateUrl: './service-create.component.html',
  styleUrls: ['./service-create.component.scss'],
})
export class ServiceCreateComponent implements OnInit {
  serviceForm: FormGroup;
  typeImage: any;
  imageName: string = '';
  image_url: any;
  file_base64: any;
  type: any;
  contactlist: any[] = [];
  isEnabled: boolean = false;
  lang: string;
  CitiesData: any[] = [];
  serviceId: number;
  query: string;
  result: any[] = [];
  show: boolean = true;
  showText: boolean = true;
  photo;
  selectedItems = [];
  dropdownSettings = {};
  categoryID: any;
  contactId: any;
  placeId: any;
  val: any;
  categorylist: any;

  config: any = {};

  constructor(
    private projectservice: ProjectsService,
    private httpClientService: HttpClientService,
    private toaster: ToastrService,
    private cityservice: CitiesService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private languageService: LanguageService,
    private categoryservice: CategoriesService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.config = {
      placeholder: this.translate.instant('selectPlace'),
      search: false,
      displayKey: 'title',
    };
    this.initForm();

    this.route.queryParams.subscribe((res) => {
      this.serviceId = +res['id'];
      this.placeId = +res['placeId'];
      this.serviceForm.get('contact_id').setValue(this.placeId);
      if (this.serviceId) {
        this.httpClientService
          .post('api/contacts_services/show', { id: this.serviceId }, [])
          .subscribe((res) => {
            this.setForm(res['content']);
            this.placeId = res['content'].list[0].contact_id;
          });
      }
    });

    this.getCategories();
    this.getCities();
    this.projectservice.GetcontactsList().subscribe((res: any) => {
      this.contactlist = res.content;
      this.contactlist.forEach((ele) => {
        if (ele.id == this.placeId) {
          this.val = ele;
        }
      });
      console.log('contact list:-', this.contactlist);
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  getCategories() {
    this.categoryservice.GetCategoryList().subscribe((res: any) => {
      this.categorylist = res.content;
      //  console.log("The category list ::::::::", this.categorylist)
    });
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  initForm() {
    this.serviceForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      name_ar: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      price: new FormControl('', [Validators.required]),
      image: new FormControl(''),
      contact_id: new FormControl('', [Validators.required]),
      cities: new FormControl('', [Validators.required]),
      category_id: new FormControl('', [Validators.required]),
    });
  }

  setForm(data) {
    this.serviceForm.patchValue({
      name: data.list[0].name,
      name_ar: data.list[0].name_ar,
      description: data.list[0].description,
      price: data.list[0].price,
      contact_id: data.list[0].contact_id,
      cities: data.list[0].cities,
      category_id: data.list[0].category_id,
    });

    this.photo = data.list[0].image;
    this.categoryID = data.list[0].category_id;
    this.contactId = data.list[0].contact_id;
    this.placeId = this.contactId;
    this.show = false;
    this.val = data.list[0].cms;
  }

  closebtn() {
    this.photo = '';
    // this.img =false;
  }

  readImage(event) {
    var files = event.target.files[0];
    this.typeImage = files.type;
    // console.log(this.typeImage);

    this.imageName = files.name;
    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.image_url = 'data:' + this.typeImage + ';base64,' + btoa(binaryString);
  }

  getCities() {
    this.cityservice.GetCityList().subscribe((res: any) => {
      this.CitiesData = res.content;
      console.log(this.CitiesData);
    });
  }

  onSubmit() {
    this.isEnabled = true;
    if (this.showText == false) {
      this.serviceForm.get('name_ar').clearValidators();
      this.serviceForm.get('name_ar').updateValueAndValidity();
    }
    if (this.serviceForm.valid) {
      this.serviceForm.value.image = this.image_url;
      let cities: any[] = [];
      this.serviceForm.value.cities.forEach((ele) => {
        cities.push(ele.id);
      });
      this.serviceForm.value.cities = cities;
      // console.log('Cities', cities);
      console.log('Result', this.serviceForm.value);

      if (this.serviceId) {
        let obj: any = {};
        obj = this.serviceForm.value;
        obj.id = this.serviceId;
        obj.category_id = this.categoryID;
        obj.contact_id = this.val.id;

        if (obj.image == undefined) {
          delete obj.image;
        }

        console.log('obj', obj);
        this.httpClientService
          .post('api/contacts_services/update', obj, [])
          .subscribe(
            (res: any) => {
              this.isEnabled = false;
              console.log('service create :::::', res);

              if (res.status.code == 400) {
                this.toaster.error(res.status.error_details);
              } else if (res.status.code == 403) {
                let validation_errors = res.status.validation_errors;
                validation_errors.forEach((element) => {
                  if (element.field == 'name_ar') {
                    this.toaster.error(element.message);
                  } else if (element.field == 'name') {
                    this.toaster.error(element.message);
                  } else if (element.field == 'price') {
                    this.toaster.error(element.message);
                  } else if (element.field == 'description') {
                    this.toaster.error(element.message);
                  } else if (element.field == 'image') {
                    this.toaster.error(element.message);
                  } else if (element.field == 'contact_id') {
                    this.toaster.error(element.message);
                  } else if (element.field == 'cities') {
                    this.toaster.error(element.message);
                  } else if (element.field == 'category_id') {
                    this.toaster.error(element.message);
                  }
                });

                res.status.validation_errors.forEach((ele) => {
                  // this.toaster.error(ele.message[0]);
                });
              } else if (res.status.code == 200) {
                // this.toaster.success('تم تعديل الخدمة بنجاح');
                Swal.fire({
                  title: this.translate.instant('serviceUpdate'),
                  icon: 'success',
                  timer: 1500,
                });
                this.router.navigate(['place-view', this.lang], {
                  queryParams: { id: obj.contact_id },
                });
              }
            },
            (error) => {
              this.isEnabled = false;
              console.log(error);
              let validation_errors = error.error.status.validation_errors;
              validation_errors.forEach((element) => {
                if (element.field == 'name_ar') {
                  this.toaster.error(element.message);
                } else if (element.field == 'name') {
                  this.toaster.error(element.message);
                } else if (element.field == 'price') {
                  this.toaster.error(element.message);
                } else if (element.field == 'description') {
                  this.toaster.error(element.message);
                } else if (element.field == 'image') {
                  this.toaster.error(element.message);
                } else if (element.field == 'contact_id') {
                  this.toaster.error(element.message);
                } else if (element.field == 'cities') {
                  this.toaster.error(element.message);
                } else if (element.field == 'category_id') {
                  this.toaster.error(element.message);
                }
              });
            }
          );
      } else {
        let obj: any = {};
        obj = this.serviceForm.value;
        obj.contact_id = this.val.id;
        this.httpClientService
          .post('api/contacts_services/store', obj, [])
          .subscribe(
            (res: any) => {
              this.isEnabled = false;
              console.log(res);
              if (res.status.code == 400) {
                this.toaster.error(res.status.error_details);
              } else if (res.status.code == 403) {
                // res.status.validation_errors.forEach(
                //   ele=>{
                //     this.toaster.error(ele.message[0]);
                //   }
                // );
              } else if (res.status.code == 200) {
                if (this.lang == 'ar') {
                  this.toaster.success('تم إنشاء الخدمة بنجاح');
                } else {
                  this.toaster.success('Service Created Successfully');
                }
                this.router.navigate(['place-view', this.lang], {
                  queryParams: { id: obj.contact_id },
                });
              }
            },
            (error) => {
              this.isEnabled = false;
              console.log(error);
              // if(this.lang == 'ar'){
              //   this.toaster.error('حدث خطأ ما');
              // }else{
              //   this.toaster.error('Error happened')
              // }

              let validation_errors = error.error.status.validation_errors;
              validation_errors.forEach((element) => {
                if (element.field == 'name_ar') {
                  this.toaster.error(element.message);
                } else if (element.field == 'name') {
                  this.toaster.error(element.message);
                } else if (element.field == 'price') {
                  this.toaster.error(element.message);
                } else if (element.field == 'description') {
                  this.toaster.error(element.message);
                } else if (element.field == 'image') {
                  this.toaster.error(element.message);
                } else if (element.field == 'contact_id') {
                  this.toaster.error(element.message);
                } else if (element.field == 'cities') {
                  this.toaster.error(element.message);
                } else if (element.field == 'category_id') {
                  this.toaster.error(element.message);
                }
              });
            }
          );
      }
    } else {
      this.isEnabled = false;
    }
  }

  gotoPlace() {
    this.router.navigate(['place-new', this.lang]);
  }

  search(query?: string) {
    if (query == '') {
      this.show = true;
      this.query = '';
    } else {
      if (query.length >= 3) {
        this.httpClientService
          .get('api/services', { name: query }, {})
          .subscribe((res) => {
            console.log('The Response:- ', res);
            this.result = res['content'];

            if (this.result.length == 0) {
              this.showText = true;
            }
          });
      }
    }
  }

  setName(name: string) {
    this.show = false;
    this.showText = false;
    this.query = name;
  }
}
