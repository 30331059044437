<app-header></app-header>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="default"
  color="#fff"
  type="ball-zig-zag-deflect"
  [fullScreen]="true"
></ngx-spinner>

<router-outlet (activate)="onActivate($event, outlet)" #outlet></router-outlet>

<app-footer></app-footer>
