import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ProjectsService } from 'src/app/Shared/Services/projects.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.scss'],
})
export class ProjectCreateComponent implements OnInit {
  contactlist: any[] = [];

  placeForm: FormGroup;
  image_url: any;
  file_base64: any;
  type: any;
  typeImage: any;
  imageName: string = '';
  fileName: string = '';
  isEnabled: boolean = false;
  lang: string;
  projectId: any;
  theProject: any;
  photo: any;
  file: any;
  placeId: any;
  val: any;

  config = {};

  constructor(
    private translateservice: TranslateService,
    private projectservice: ProjectsService,
    public toastService: ToastrService,
    private router: Router,
    private languageService: LanguageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    console.log('The User ID From Login :-', localStorage.getItem('UserID'));

    this.config = {
      placeholder: this.translateservice.instant('selectPlace'),
      search: false,
      displayKey: 'title',
    };
    this.initForm();
    this.projectservice.GetcontactsList().subscribe((res: any) => {
      this.contactlist = res.content;
      let place;
      this.contactlist.forEach((ele) => {
        if (ele.id == this.placeId) {
          place = ele;
          this.val = ele;
        }
      });
      console.log('The Place is:-', place);
      console.log('contact list:-', this.contactlist);
    });

    this.route.queryParams.subscribe((res) => {
      this.projectId = +res['id'];
      this.placeId = +res['placeId'];
      this.placeForm.get('cms_id').setValue(this.placeId);
      if (this.projectId) {
        let body = { id: this.projectId };
        this.projectservice.getProject(body).subscribe((res) => {
          this.theProject = res['content'];
          this.setForm(res['content']);
        });
      }
    });
  }

  initForm() {
    this.placeForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      cms_id: new FormControl('', [Validators.required]),
      image_url: new FormControl(''),
      file_url: new FormControl(''),
    });
  }

  setForm(data) {
    this.placeForm.patchValue({
      title: data.list[0].name,
      description: data.list[0].description,
      cms_id: data.list[0].cms_id,
    });

    this.photo = data.list[0].image;
    this.file = data.list[0].document;
    this.val = data.list[0].cms;
    this.placeId = data.list[0].cms_id;
    // this.show = false;
  }

  closebtn() {
    this.photo = '';
    // this.img =false;
  }

  closeFilebtn() {
    this.file = '';
  }

  readImage(event) {
    var files = event.target.files[0];
    this.typeImage = files.type;
    // console.log(this.typeImage);

    this.imageName = files.name;
    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }

  readFile(event) {
    var files = event.target.files[0];
    this.type = files.type;
    this.fileName = files.name;
    console.log(this.type);

    var reader = new FileReader();
    reader.onload = this.handleFile.bind(this);
    reader.readAsBinaryString(files);
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.image_url = 'data:' + this.typeImage + ';base64,' + btoa(binaryString);
    console.log('The image file:-', this.image_url);
  }

  handleFile(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.file_base64 = 'data:' + this.type + ';base64,' + btoa(binaryString);
    console.log('The pdf file:-', this.file_base64);
  }

  submitPlace() {
    this.isEnabled = true;
    if (this.placeForm.valid) {
      let submitObject: any = {
        name: this.placeForm.value['title'],
        description: this.placeForm.value['description'],
        cms_id: this.val.id,
        image: this.image_url,
        document: this.file_base64,
      };

      console.log(submitObject);
      if (this.projectId) {
        submitObject.id = this.projectId;
        this.projectservice.updateProject(submitObject).subscribe(
          (res: any) => {
            console.log('Add Project API Response:-', res);
            if (res.status.code == 200) {
              this.isEnabled = false;
              this.toastService.success(res.status.message);
              Swal.fire({
                title:
                  this.lang == 'en'
                    ? 'Project Update Successfully'
                    : 'تمت تحديث المشروع',
                icon: 'success',
                showCancelButton: true,
                // confirmButtonText: 'Ok',
                // cancelButtonText: 'Cancel',
                timer: 3000,
              });
              this.router.navigate(['place-view', this.lang], {
                queryParams: { id: submitObject.cms_id },
              });
            } else if (res.status.code == 403) {
              this.isEnabled = false;
              let validation_errors = res.status.validation_errors;
              validation_errors.forEach((element) => {
                if (element.field == 'name') {
                  this.toastService.error(element.message);
                } else if (element.field == 'description') {
                  this.toastService.error(element.message);
                } else if (element.field == 'places') {
                  this.toastService.error(element.message);
                } else if (element.field == 'image') {
                  this.toastService.error(element.message);
                } else if (element.field == 'cms_id') {
                  this.toastService.error(element.message);
                }
              });
            } else if (res.status.code == 400) {
              this.isEnabled = false;
              this.toastService.error(res.status.error_details);
            } else {
              this.isEnabled = false;
              this.toastService.error(res.status.error_details);
            }
          },
          (error) => {
            this.isEnabled = false;
            console.log(error);

            let validation_errors = error.error.status.validation_errors;
            validation_errors.forEach((element) => {
              if (element.field == 'name') {
                this.toastService.error(element.message);
              } else if (element.field == 'description') {
                this.toastService.error(element.message);
              } else if (element.field == 'places') {
                this.toastService.error(element.message);
              } else if (element.field == 'image') {
                this.toastService.error(element.message);
              } else if (element.field == 'cms_id') {
                this.toastService.error(element.message);
              }
            });
          }
        );
      } else {
        this.projectservice.Addproject(submitObject).subscribe(
          (res: any) => {
            console.log('Add Project API Response:-', res);
            if (res.status.code == 200) {
              this.isEnabled = false;
              this.toastService.success(res.status.message);
              Swal.fire({
                title:
                  this.lang == 'en'
                    ? 'Project Added Successfully'
                    : 'تمت إضافة المشروع',
                icon: 'success',
                showCancelButton: true,
                // confirmButtonText: 'Ok',
                // cancelButtonText: 'Cancel',
                timer: 3000,
              });
              this.router.navigate(['place-view', this.lang], {
                queryParams: { id: submitObject.cms_id },
              });
            } else if (res.status.code == 403) {
              this.isEnabled = false;
              let validation_errors = res.status.validation_errors;
              validation_errors.forEach((element) => {
                if (element.field == 'name') {
                  this.toastService.error(element.message);
                } else if (element.field == 'description') {
                  this.toastService.error(element.message);
                } else if (element.field == 'places') {
                  this.toastService.error(element.message);
                } else if (element.field == 'image') {
                  this.toastService.error(element.message);
                } else if (element.field == 'cms_id') {
                  this.toastService.error(element.message);
                }
              });
            } else if (res.status.code == 400) {
              this.isEnabled = false;
              this.toastService.error(res.status.error_details);
            } else {
              this.isEnabled = false;
              this.toastService.error(res.status.error_details);
            }
          },
          (error) => {
            this.isEnabled = false;
            console.log(error);

            let validation_errors = error.error.status.validation_errors;
            validation_errors.forEach((element) => {
              if (element.field == 'name') {
                this.toastService.error(element.message);
              } else if (element.field == 'description') {
                this.toastService.error(element.message);
              } else if (element.field == 'places') {
                this.toastService.error(element.message);
              } else if (element.field == 'image') {
                this.toastService.error(element.message);
              } else if (element.field == 'cms_id') {
                this.toastService.error(element.message);
              }
            });
          }
        );
      }
    } else {
      this.isEnabled = false;
    }
  }

  gotoPlace() {
    this.router.navigate(['place-new', this.lang]);
  }
}
