import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-view-employee',
  templateUrl: './view-employee.component.html',
  styleUrls: ['./view-employee.component.scss'],
})
export class ViewEmployeeComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private service: HttpClientService,
    private languageService: LanguageService
  ) {}
  user;
  setDob = [];
  lang;
  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    let body = {
      id: this.activatedRoute.snapshot.queryParams['id'],
    };
    this.service.post('api/users/show', body, {}).subscribe((res: any) => {
      this.user = res.content[0];
      console.log('user =>', this.user);
      this.setDob = this.user.birthdate.split(' ');
      console.log(this.setDob);
    });
  }
}
