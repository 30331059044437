import { Component, OnInit } from '@angular/core';
import {UserService} from '../../Shared/Services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl,FormBuilder,Validators } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {AuthenticationService} from '../../Shared/Services/authentication.service';
import {CitiesService} from '../../Shared/Services/cities.service';
import Swal from 'sweetalert2';
import { LanguageService } from 'src/app/Shared/Services/language.service';
import { DatePipe } from '@angular/common';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { query } from '@angular/animations';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { AddingMetaService } from 'src/app/Shared/Services/adding-meta.service';
import { HttpParams } from '@angular/common/http';

// import * as firebase from 'firebase';



@Component({
  selector: 'app-login-new',
  templateUrl: './login-new.component.html',
  styleUrls: ['./login-new.component.scss']
})
export class LoginNewComponent implements OnInit {

  loginForm: FormGroup;
  lang;
  lang_id;
  currentlang: string;

  services: any[] =[];
  codelist;

  index = 1;
  LoginRoleId ;
  RuleIDCheck;
  isEnabled: boolean = false;
  isLoginValid: boolean = false;
  phoneValid: string = '';


	preferredCountries: CountryISO[] = [CountryISO.Egypt, CountryISO.SaudiArabia];
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;

  separateDialCode = false;

  notificationList: any[] = [];
   counter = 0;

  // public recaptchaVerifier: firebase.auth.RecaptchaVerifier;


  constructor(private userservice:UserService,
    private translateservice:TranslateService,
    private toastrService: ToastrService,
    private authservice:AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cityservices:CitiesService,
    public translate: TranslateService,
    private languageService: LanguageService,
    public datePipe: DatePipe,
    private httpClientService: HttpClientService,
    private service: HttpClientService,
    private meta: AddingMetaService) {

       /* REPLACE CONFIG DETAILS BELOW WITH YOURS */
       const firebaseConfig = {
        apiKey: "AIzaSyBLG9yIWdPr3JqEkeVuOpMQu_Q2je0ojgI",
        authDomain: "qpedia-8a3e3.firebaseapp.com",
        projectId: "qpedia-8a3e3",
        storageBucket: "qpedia-8a3e3.appspot.com",
        messagingSenderId: "55095221706",
        appId: "1:55095221706:web:6814de188d7e90320e0333"
      };
        // if (!firebase.apps.length) {
        //   firebase.initializeApp(firebaseConfig);
        // }

    }

  ngOnInit(): void {

    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    console.log("the lang is :::::::::", this.lang)
    
    if(this.lang == 'en'){
      this.lang_id = 1;
    }else{
      this.lang_id = 2;
    }

    this.initForm();
    this.getCountryTelephoneCode();
    this.currentlang = localStorage.getItem('lang');

    this.getServices(1, this.lang_id);
    this.meta.updateMeta({
      compTitle: 'Login/Register'
    });

    console.log('prefered Country', this.preferredCountries);

  //  this.RuleIDCheck = localStorage.getItem('RuleID');
  //  console.log("Rule ID Check:----",this.RuleIDCheck)
  
      let body = {};
      let param = new HttpParams();
      param = param.set('per_page', '1000');
      this.service
        .post('api/notifications', body, { pramas: param })
        .subscribe((res: any) => {
          this.notificationList = res.content;
          this.counter = res.pagination.counter;
        });

  }


  initForm(){

    this.loginForm = new FormGroup({
      mobile: new FormControl('', [Validators.required]),
      tele_code: new FormControl(''),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(9)])
    })
  }


  login() {
    this.isEnabled = true;
    this.phoneValid = this.loginForm.controls.mobile.status;
    console.log('Login value ',this.loginForm.controls.mobile.status);
    console.log(this.loginForm)
  
    this.isLoginValid = this.validateMobile(this.loginForm.value.mobile);
    console.log(this.isLoginValid)

    if(this.loginForm.valid){
      let user;
      if(this.LoginRoleId == 1){
        user = {
          mobile: this.loginForm.value.mobile.number,
          tele_code: this.loginForm.value.mobile.dialCode,
          password: this.loginForm.value.password,
          rule_id: 5
        }

      }
      else{
        user = {
          mobile: this.loginForm.value.mobile.number,
          tele_code: this.loginForm.value.mobile.dialCode,
          password: this.loginForm.value.password,
          rule_id: 3
      }
      localStorage.setItem('Loggedin','Corporate')
    // if(this.RuleIDCheck == "Individual"){
    //   user = {
    //     mobile: this.loginForm.value.mobile.number,
    //     tele_code: this.loginForm.value.mobile.dialCode,
    //     password: this.loginForm.value.password,
    //     rule_id:this.LoginRoleId
    //   }
    // }else{
    //   user = {
    //     mobile: this.loginForm.value.mobile.number,
    //     tele_code: this.loginForm.value.mobile.dialCode,
    //     password: this.loginForm.value.password,
    //     rule_id: 3
    //   }



    }

    console.log('user is:- ', user);
    if(this.loginForm.value.mobile.dialCode == "+20" && (this.loginForm.value.mobile.number?.length < 10 || this.loginForm.value.mobile.number?.length > 11)){
      console.log('Wrong Egyptian Number');
      this.isEnabled = false;
    }else if(this.loginForm.value.mobile.dialCode == "+966" && (this.loginForm.value.mobile.number?.length < 8 || this.loginForm.value.mobile.number?.length > 9)){
      console.log('Wrong KSA Number');
      this.isEnabled = false;
    }
    else{
      this.userservice.login(user).subscribe((res:any) => {
        if (res.status.code == 200){

          this.isEnabled = false;
          if(res.content.related_company != null){
            this.authservice.sethasTasks(res.content.related_company.requests?'1':'0');
          }
          if(res.content.attached_cv != null){
            localStorage.setItem('hasCv', '1');
          }
          // console.log("The User Id :-",localStorage.getItem('UserID'))
          if((res.content.is_mobile_verified == 1 && res.content.user_type == 1) || (res.content.user_type == 3 || res.content.user_type == 2)){
            console.log('Current lang', this.currentlang);
            this.userservice.handleResponse(res);
            console.log("The Response of login API:-",res);
            this.authservice.setToken(res.content.api_token);
            localStorage.setItem('userType', res.content.user_type);
            localStorage.setItem('cv', res.content.attached_cv);
            this.languageService.changedUser.emit(res.content.user_type);
            localStorage.setItem('UserID',res.content.id);
            this.router.navigate(['', this.currentlang]);
          }

          else if (res.content.is_mobile_verified == 0 && res.content.user_type == 1 && res.content.is_active == 1){
            if(this.currentlang == 'en'){
              this.toastrService.error("Your Mobile needs to be verified");
              this.userservice.getOtp(this.loginForm.value.mobile.e164Number);
            }else{
              this.toastrService.error("رقم الهاتف يحتاج الى التفعيل");
              this.userservice.getOtp(this.loginForm.value.mobile.e164Number);
            }
            localStorage.setItem('mobile', this.loginForm.value.mobile.number);
            localStorage.setItem('telecode', this.loginForm.value.mobile.dialCode);
            // this.router.navigate(['Verification-code', this.currentlang]);
          }

          else if (res.content.is_mobile_verified == 0 && res.content.user_type == 1 && res.content.is_active == 0){
            if(this.currentlang == 'en'){
              this.toastrService.error("Your Account is in Active");
            }else{
              this.toastrService.error("حسابك غير نشط");
            }
          }

        }
        else if(res.status.code == 403) {
          this.toastrService.error(res.status.error_details)
        }
        else if(res.status.code == 401) {
          // this.toastrService.error(res.status.error_details)
          // console.log("login error ::::::::::" , res.status.error_details)

          this.isEnabled = false;
          let validation_errors = res.status.validation_errors;
          validation_errors.forEach(element => {
            if(element.field == 'email')
             {this.toastrService.error(element.message)}
            else if (element.field == 'password')
             {this.toastrService.error(element.message)}
             else if (element.field == "mobile")
             {this.toastrService.error(element.message)}


          })

        }

        else{
          this.isEnabled = false;
          // this.toastrService.error(res.status.error_details)
          let validation_errors = res.status.validation_errors;
          validation_errors.forEach(element => {
            if(element.field == 'email')
             {this.toastrService.error(element.message)}
            else if (element.field == 'password')
             {this.toastrService.error(element.message)}
             else if (element.field == "mobile")
             {this.toastrService.error(element.message)}


          })


        }


      },error=>{
        this.isEnabled = false;
        console.log(error.error.errors)
        if (error.error.status.error_details != "validation errors") {
          console.log("empty validation array ::::::::::::",error.error.status.validation_errors)
          console.log("the error is  ::::::::::::",error.error.status.error_details)

          Swal.fire({
            icon: 'error',
            title: error.error.status.error_details,
            text: '',
            timer: 2500,
          });

          // this.toastrService.error(error.error.status.error_details)
        }
        else {

          let validation_errors = error.error.status.validation_errors;
          validation_errors.forEach(element => {
            if(element.field == 'email')
            //  {this.toastrService.error(element.message)}
            {
              Swal.fire({
                icon: 'error',
                title: element.message,
                text: '',
                timer: 2500,
              });
            }

            else if (element.field == 'password'){
              Swal.fire({
                icon: 'error',
                title: element.message,
                text: '',
                timer: 2500,
              });
            }
            //  {this.toastrService.error(element.message)}
            
             else if (element.field == "mobile")
            //  {this.toastrService.error(element.message)}
             Swal.fire({
              icon: 'error',
              title: element.message,
              text: '',
              timer: 2500,
            });


          })

        }



        if(error.error.errors.email){
           this.toastrService.error(error.error.errors.email[0]); // toasr services
        }
        else if (error.error.errors.password){
           this.toastrService.error(error.error.errors.password[0]); // toasr services
        }
        else if (error.error.errors.mobile){
            this.toastrService.error(error.error.errors.mobile[0]); // toasr services
        }
      }
      )
    }

    }
    else{

        if(this.lang == 'en'){
          Swal.fire({
            icon: 'error',
            title: '',
            text: 'Invalid Mobile Number',
            timer: 2500,
          });
        }
        else{
          Swal.fire({
            icon: 'error',
            title: '',
            text: 'رقم الجوال غير صالح',
            timer: 2500,
          });
        }
      this.isEnabled = false;
    }
    
  }

  getCountryTelephoneCode(){
    this.cityservices.GetCountryCodeList().subscribe((res:any)=>{
        this.codelist = res.content;
        console.log(this.codelist)
    })
  }

  RememberMe(event){
    if(event.target.checked){
      localStorage.setItem('UserEmail',this.loginForm.value['email'])
      localStorage.setItem('UserPassword',this.loginForm.value['password'])
    }
    else{
      localStorage.removeItem('UserEmail');
      localStorage.removeItem('UserPassword')

    }
  }

  getServices(page: number, lang_id){
    this.cityservices.getServices(page, lang_id).subscribe(
      (res:any)=>{
        this.services = res['content'];
      },
      error=>{
        console.log(error);
      }
    );
  }

  validateMobile(phone){
    let EGYPT_REGEX = /^01[0-2,5]\d{1,8}$/; // Regular Expression 1
    let KSA_REGEX = /^[0-9]\d{1,7}$/;         // Regular Expression 2

    return ((EGYPT_REGEX.test(phone?.nationalNumber) && phone.dialCode == "+20") || (KSA_REGEX.test(phone?.nationalNumber) && phone.dialCode == "+966")) ? true : false;
  }

  onLoginItemChange(event){
    console.log(event);
    this.LoginRoleId = event;
  }

  getNextBatch() {
    this.index = this.index + 1;
    this.cityservices.getServices(this.index, this.lang_id).subscribe(
      (res:any[])=>{
       res['content'].forEach(element => {
         this.services.push(element);
       });
      },
      error=>{
        console.log(error);
      }
    );
}

onKey(event: string){
  console.log('Eveeeeeent',event);
  console.log(event.search(' '));
  if(event.length >=3){
    this.httpClientService.get('api/services',{'name':event},{}).subscribe(
      res=>{
        console.log("The Response:- ", res);
        this.services = res['content'];
      }
    );
  }
}

}
