<!-- Start Page Header -->
<div
  class="container-fluid pg-header"
  style="background-image: url(./assets/images/12.jpg)"
>
  <div class="pg-header-content">
    <h1 class="pg-title">
      {{ placename }}
    </h1>
  </div>
  <div class="pg-header-logo">
    <!-- <img src="https://cdn2.f-cdn.com/contestentries/365819/10129715/56e657a9c8e95_thumb900.jpg"> -->
  </div>
</div>
<!-- End Page Header -->

<!-- Start Single Blog Content -->
<div class="container blog single-block">
  <div class="row">
    <!-- Start Single Content -->
    <div class="col-md-9 text-center">
      <div class="post-content margin-top text-justify">
        <div class="row clear-fix" style="position: relative">
          <div class="col-md-3" [ngClass]="lang == 'ar' ? 'image-ar' : ''">
            <!-- <a data-lightbox="gallery">
              <img [alt]="placename" [src]="placeimage" class="thumbnail" />
            </a> -->
          </div>

          <div
            class="col-md-8"
            [ngClass]="lang == 'ar' ? 'text-ar' : 'text-en'"
          >
            <!-- <p class="">
              {{placecontent}}

            </p> -->

            <!-- <p *ngIf="address" class="desc" ><span class="text-styling"><i class="fa fa-map-marker"></i>
              {{'Address' | translate}} : </span>
              <span>
                {{address}}
              </span>
            </p> -->
            <!-- <p *ngIf="description"  class="desc"><span class="text-styling">
              <i class="fa fa-bars"></i>{{'description' | translate}} : </span>
              <span>

                {{description}}
              </span>
              </p>
            <p *ngIf="phoneNumber" class="desc" ><span class="text-styling"><i class="fa fa-phone"></i>
              {{'phoneno' | translate}}: </span>
              <span>

                {{phoneNumber}}
              </span>
            </p>

            <p *ngIf="phoneNumber" class="desc" ><span class="text-styling"><i class="fa fa-bars"></i>
              {{'Home.categories' | translate}}: </span>
              <span *ngFor="let cat of placecategory">
                <a (click)="gotoCategory(cat.id)">{{cat.value}}</a>
              </span>
            </p> -->
          </div>
          <!-- <div class="col-md-12 text-center">
            <button class="view-btn task-btn" (click)="openPlace(lattiude, longitude)">
              {{'showlocation' | translate}}
            </button>
            <button *ngIf="userType==3 && hasTasks==1" (click)="addTask()" class="view-btn task-btn">
              <i class="fa fa-plus"></i>
              {{'create task' | translate}}
            </button>

            <button *ngIf="userType==1" (click)="addQuestion()" class="view-btn task-btn">
              <i class="fa fa-plus"></i>
              {{'add question' | translate}}
            </button>

          </div> -->
        </div>

        <!-- <p class="clearfix"></p> -->
      </div>

      <div class="post-comtent margin-top">
        <agm-map [latitude]="lat" [longitude]="lng">
          <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map>
      </div>

      <!-- Start Author Block -->
      <!-- <div class="row d-flex author-row">
      <blockquote class="col-md-6 author-block" *ngFor="let placesusers of RelatedUsers"> -->

      <!-- <div class="text-left blockquote-data">

          <div  class="data">
          <a class="author-name">
            <i class="fas fa-user"></i>
            <span>
              {{'name' | translate}}: {{placesusers.name}}
            </span>
          </a>
          </div>
          <div class="data">
            <i class="fa fa-calendar"></i>
            <span>
              {{'Date' | translate}}: {{placesusers.created_at | date}}
            </span>

          </div>
          <div class="data">

              <i class="fas fa-align-center"></i>
              <span>
                {{'position' | translate}}: {{placesusers.position}}
              </span>
          </div>
          <div  class="data">
              <i class="fas fa-phone-alt"></i>
              <span>

                {{'phoneno' | translate}}: {{placesusers.mobile}}
              </span>
          </div>

      </div> -->

      <!--
   <div class="text-left">
          <a href="#" class="author-name"><i class="fas fa-user"></i> {{'name' | translate}}: {{placesusers.name}}</a> <br>
          <span class="data fa fa-calendar"> {{'Date' | translate}}: {{placesusers.created_at | date}}</span>
          <div class="author-description">
            <h5 class="text-left"><i class="fas fa-signal"></i> {{'position' | translate}}: {{placesusers.position}}</h5>
            <p><i class="fas fa-phone-alt"></i> {{'phoneno' | translate}}: {{placesusers.mobile}}</p>
          </div>
        </div>
 -->

      <!-- </blockquote>
    </div> -->

      <!-- <div class="iconized-separator">
        <div class="mask"></div>
        <span><i class="fa fa-star-half-empty"></i></span>
      </div> -->
    </div>

    <!-- End Single Content -->
  </div>
</div>
<!-- End Single Blog Content -->

<div class="row row-padd">
  <div class="col-md-9 col-sm-12 col-xs-12">
    <!-- Start tabs -->
    <div class="tabset-section">
      <tabset [justified]="true">
        <tab heading="{{ 'About_Us' | translate }}">
          <div class="row about-card">
            <div class="col-md-2 col-sm-2 col-xs-12 pull-left">
              <img
                [alt]="placename"
                [src]="placeimage"
                class="about-card-img"
              />
            </div>

            <div class="col-md-10 col-sm-10 col-xs-10">
              <h2 *ngIf="placecontent" class="place-content">
                {{ placecontent }}
              </h2>
              <h6 *ngIf="description" class="place-address">
                {{ description }}
              </h6>

              <p *ngIf="address" class="place-description">
                <span class="text-styling">
                  <i class="fas fa-map-marker-alt"></i>
                </span>
                <span>
                  {{ address }}
                </span>
              </p>

              <p *ngIf="phoneNumber" class="place-description">
                <span class="text-styling">
                  <i class="fa fa-phone"></i>
                </span>
                <span>
                  {{ phoneNumber }}
                </span>
              </p>
              <p *ngIf="placecategory" class="place-description">
                <span class="text-styling">
                  <i class="fa fa-bars"></i>
                </span>
                <span *ngFor="let cat of placecategory">
                  <a (click)="gotoCategory(cat.id)">{{ cat.value }}</a>
                </span>
              </p>

              <button
                class="view-btn task-btn"
                (click)="openPlace(lattiude, longitude)"
              >
                {{ "showlocation" | translate }}
              </button>
              <button
                *ngIf="userType == 3 && hasTasks == 1"
                (click)="addTask()"
                class="view-btn task-btn"
              >
                <i class="fa fa-plus"></i>
                {{ "create task" | translate }}
              </button>

              <!-- <button
                *ngIf="userType == 1"
                (click)="addQuestion()"
                class="view-btn task-btn"
              >
                <i class="fa fa-plus"></i>
                {{ "chat" | translate }}
              </button> -->

              <button class="view-btn task-btn" (click)="goToSite()">
                {{ "View Website" | translate }}
              </button>
            </div>
          </div>

          <div
            class="row justify-content-start"
            *ngFor="let placesusers of RelatedUsers"
          >
            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="data">
                <a class="author-name">
                  <!-- <i class="fas fa-user"></i> -->
                  <span>
                    {{ "name" | translate }}: {{ placesusers.name }}
                  </span>
                </a>
              </div>
            </div>

            <!-- <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="data">
                <i class="fa fa-calendar"></i>
                <span>
                  {{ "Date" | translate }}: {{ placesusers.created_at | date }}
                </span>
              </div>
            </div> -->

            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="data">
                <!-- <i class="fas fa-align-center"></i> -->
                <span>
                  {{ "position" | translate }}: {{ placesusers.position }}
                </span>
              </div>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="data">
                <!-- <i class="fas fa-phone-alt"></i>  -->
                <span>
                  {{ "phoneno" | translate }}: {{ placesusers.mobile }}
                </span>
              </div>
            </div>
          </div>
        </tab>
        <tab heading="{{ 'Home.projects' | translate }}">
          <div
            *ngIf="projects.length > 0; else noProjects"
            class="row listing white squared w-100"
          >
            <div
              class="col-md-3 col-sm-12"
              *ngFor="
                let item of projects
                  | paginate : { itemsPerPage: 10, currentPage: p }
              "
            >
              <div class="listing-item">
                <!-- <a class="listing-item-link">
                        <div class="listing-item-title-centralizer">
                          <div class="listing-item-title">
                            {{'title' | translate}}: {{item.name}}
                          </div>
                        </div>
                        <img [alt]="item.name" [src]="item.image?item.image:'assets/images/logo.png'" />
                      </a> -->
                <div class="listing-item-link">
                  <img
                    [alt]="item.name"
                    [src]="item.image ? item.image : 'assets/images/logo.png'"
                  />
                </div>
                <div class="listing-item-data">
                  <div class="listing-item-excerpt">
                    {{ item.name }}
                  </div>
                  <div class="listing-item-excerpt">
                    {{ item.description }}
                  </div>
                  <!-- <div class="listing-item-excerpt">
                          {{'Company name' | translate}}: {{comapnyName}}
                        </div> -->
                </div>
              </div>
            </div>
          </div>
        </tab>
        <tab heading="{{ 'Home.products' | translate }}">
          <div
            *ngIf="products.length > 0; else noProducts"
            class="row listing white squared w-100"
          >
            <div
              class="col-md-3 col-sm-12"
              *ngFor="
                let item of products
                  | paginate : { itemsPerPage: 10, currentPage: p }
              "
            >
              <div class="listing-item">
                <div class="listing-item-link">
                  <img
                    [alt]="item.name"
                    [src]="item.image ? item.image : 'assets/images/logo.png'"
                  />
                </div>

                <!-- <a class="listing-item-link">
                        <div class="listing-item-title-centralizer">
                          <div class="listing-item-title">
                            {{item.name}}
                          </div>
                        </div>
                        <img [alt]="item.name" [src]="item.image?item.image:'assets/images/logo.png'" />
                      </a> -->
                <div class="listing-item-data">
                  <div class="listing-item-excerpt">
                    {{ item.name }}
                  </div>
                  <!-- <div class="listing-item-excerpt">
                          {{'description' | translate}}: {{item.description}}
                        </div> -->
                  <!-- <div class="listing-item-excerpt">
                          {{'Company name' | translate}}: {{comapnyName}}
                        </div> -->
                  <div class="listing-item-excerpt" style="color: #1db9e1">
                    {{ item.price }} {{ "LE" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab>
        <tab heading="{{ 'Home.servicess' | translate }}">
          <div
            *ngIf="services.length > 0; else noServices"
            class="row listing white squared w-100"
          >
            <div
              class="col-md-3 col-sm-12"
              *ngFor="
                let item of services
                  | paginate : { itemsPerPage: 10, currentPage: p }
              "
            >
              <div class="listing-item">
                <!-- <a class="listing-item-link">
                        <div class="listing-item-title-centralizer">
                          <div class="listing-item-title">
                            {{item.name}}
                          </div>
                        </div>
                        <img [alt]="item.name" [src]="item.image?item.image:'assets/images/logo.png'" />
                      </a> -->
                <div class="listing-item-link">
                  <img
                    [alt]="item.name"
                    [src]="item.image ? item.image : 'assets/images/logo.png'"
                  />
                </div>

                <div class="listing-item-data">
                  <div class="listing-item-excerpt">
                    {{ item.name }}
                  </div>
                  <div class="listing-item-excerpt">
                    {{ item.description }}
                  </div>
                  <!-- <div class="listing-item-excerpt">
                          {{'Company name' | translate}}: {{comapnyName}}
                        </div> -->
                  <!-- <div class="listing-item-excerpt">
                          {{'Home.cities' | translate}}:
                          <span *ngFor="let city of item.cities">{{city.name}}, </span>
                          <span *ngIf="item?.cities?.length == 0">{{'noCities' | translate}}</span>
                        </div> -->
                  <div class="listing-item-excerpt">
                    {{ item.price }} {{ "LE" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab>
        <tab heading="{{ 'Jobs' | translate }}">
          <div
            *ngIf="jobs.length > 0; else noJobs"
            class="row listing white squared w-100"
          >
            <div class="col-md-3 col-sm-12" *ngFor="let item of jobs">
              <div class="listing-item">
                <a class="listing-item-link">
                  <div class="listing-item-title-centralizer">
                    <!-- <div class="listing-item-title">
                            {{item.name}}
                          </div> -->
                  </div>
                  <img
                    [alt]="item.title"
                    [src]="
                      item.company.logo
                        ? item.company.logo
                        : 'assets/images/logo.png'
                    "
                  />
                </a>
                <div class="listing-item-data">
                  <div class="listing-item-excerpt">
                    {{ "title" | translate }}: {{ item.title }}
                  </div>
                  <div class="listing-item-excerpt">
                    {{ item.description }}
                  </div>
                  <!-- <div class="listing-item-excerpt">
                          {{'Company name' | translate}}: {{item.company.name}}
                        </div> -->
                  <div class="listing-item-excerpt">
                    {{ "salary" | translate }}: {{ item.salary }}
                    {{ "LE" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab>
      </tabset>
    </div>
    <!-- End tabs -->
  </div>

  <!-- Start Sidebar -->
  <div class="col-md-3 col-sm-12 col-xs-12">
    <img
      [alt]="placename"
      [src]="placeimage"
      class="thumbnail"
      style="height: 185px; width: 100%"
    />
    <h3 style="color: #1db9e1; font-weight: 500; margin-bottom: 30px">
      {{ placename }}
    </h3>

    <button class="ask-btn" (click)="QuickQuestion()">
      <i
        class="fa fa-comments"
        aria-hidden="true"
        style="font-size: 18px; padding: 0px 7px"
      ></i>
      {{ "Ask question" | translate }}
    </button>

    <div class="widget-area">
      <!--Start Widget Container-->
      <div class="widget-container">
        <!--Start Widget-->
        <div class="widget news-categories text-center">
          <!--start widget-->
          <div class="block">
            <div class="widget-title">
              <div class="the-title">
                {{ "Home.categories" | translate }}
              </div>
            </div>
            <div class="widget-content">
              <ul>
                <li class="item" *ngFor="let cat of placecategory">
                  <a (click)="gotoCategory(cat.id)">{{ cat.value }}</a>
                </li>
                <li *ngIf="placecategory?.length == 0" class="no-result">
                  {{ "no result" | translate }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!--End Widget-->
        <!--Start Widget-->
        <!-- <div class="widget text-center">
            <div class="block">
              <div class="widget-title">
                <div class="the-title">
                  {{'Tax card'|translate}}
                </div>
              </div>
              <div class="widget-content">
                  <div>{{PlaceTextCardData}}</div>
                  <a *ngIf="PlaceTaxCardFile" [href]="PlaceTaxCardFile" target="_blank">{{'downloadfile' | translate}}</a>
              </div>

              <div *ngIf="!PlaceTextCardData && !PlaceTaxCardFile"  class="no-result">
                {{'no result' | translate}}
              </div>
            </div>
          </div> -->
        <!--End Widget-->

        <!--Start Widget-->
        <!-- <div class="widget text-center">
            <div class="block">
              <div class="widget-title">
                <div class="the-title">
                  {{'Commercial Register'|translate}}

                </div>
              </div>
              <div class="widget-content">
                <div>{{placeCommercialRegisterData}}</div>
                <a *ngIf="placeCommercialRegisterFile" [href]="placeCommercialRegisterFile" target="_blank">{{'downloadfile' | translate}}</a>
              </div>

              <div *ngIf="!placeCommercialRegisterData && !placeCommercialRegisterFile" class="no-result">
                {{'no result' | translate}}
              </div>
            </div>
          </div> -->
        <!--End Widget-->

        <!--Start Widget-->
        <div
          class="widget sidebar-social"
          *ngIf="
            placefacebooklink ||
            placetwitterlink ||
            placeinstagramlink ||
            placepaintrestlink
          "
        >
          <!--start widget-->
          <div class="block">
            <div class="widget-title">
              <div class="the-title">
                {{ "FOLLOW US" | translate }}
              </div>
            </div>
            <div class="widget-content">
              <div class="social-networks">
                <a
                  *ngIf="placefacebooklink != ''"
                  href="{{ placefacebooklink }}"
                  class="fa fa-facebook"
                  target="_blank"
                ></a>
                <a
                  *ngIf="placetwitterlink != ''"
                  href="{{ placetwitterlink }}"
                  class="fa fa-twitter"
                  target="_blank"
                ></a>
                <a
                  *ngIf="placeinstagramlink != ''"
                  href="{{ placeinstagramlink }}"
                  class="fa fa-instagram"
                  target="_blank"
                ></a>
                <a
                  *ngIf="placepaintrestlink != ''"
                  href="{{ placepaintrestlink }}"
                  class="fa fa-pinterest"
                  target="_blank"
                ></a>
              </div>
            </div>
          </div>
        </div>
        <!--End Widget-->
        <!--Start Widget-->
        <div class="widget tags text-center" *ngIf="placeskeywords?.length > 0">
          <!--start widget-->
          <div class="block">
            <div class="widget-title">
              <div class="the-title">
                {{ "TAGS" | translate }}
              </div>
            </div>
            <div class="widget-content list zoom-text">
              <ul class="" *ngIf="placeskeywords?.length > 0">
                <li *ngFor="let keyword of placeskeywords">
                  <a *ngIf="keyword.name != ''">{{ keyword.name }}</a>
                </li>
              </ul>
              <span *ngIf="placeskeywords?.length == 0">{{
                "no result" | translate
              }}</span>
            </div>
          </div>
        </div>
        <!--End Widget-->
      </div>
      <!--End Widget Container-->
    </div>
  </div>
  <!-- End Sidebar -->
</div>
<!-- Start Listings Container  -->

<!-- <div class="container proj" *ngIf="projects?.length > 0">
  <h2>{{'Home.projects' | translate}}</h2>
  <div class="custom-separator"></div>

  <div class="row listing white squared w-100">
    <div class="col-md-4 col-sm-4 col-xs-6"
      *ngFor="let item of projects | paginate: { itemsPerPage: 10, currentPage: p }">
      <div class="listing-item">
        <a class="listing-item-link">
          <div class="listing-item-title-centralizer">
            <div class="listing-item-title">
              {{item.name}}
            </div>
          </div>
          <img [src]="item.image?item.image:'assets/images/logo.png'" />
        </a>
        <div class="listing-item-data">
          <div class="listing-item-excerpt">
            {{item.description}}
          </div>
        </div>
      </div>
    </div>
    <h2 *ngIf="projects.length == 0"> {{'Requests.NoRequest'|translate}} </h2>
  </div>
</div> -->

<!-- Start Listings Container  -->

<!-- <div class="container prod"  *ngIf="products?.length > 0">
  <h2>{{'Home.products' | translate}}</h2>
  <div class="custom-separator"></div>

  <div class="row listing white squared w-100">

    <div class="col-md-4 col-sm-4 col-xs-6"
      *ngFor="let item of products | paginate: { itemsPerPage: 10, currentPage: p }">
      <div class="listing-item">
        <a class="listing-item-link">
          <div class="listing-item-title-centralizer">
            <div class="listing-item-title">
              {{item.name}}
            </div>
          </div>
          <img [src]="item.image?item.image:'assets/images/logo.png'" />
        </a>
        <div class="listing-item-data">
          <div class="listing-item-excerpt">
            {{item.description}}
          </div>
        </div>
      </div>
    </div>
    <h2 *ngIf="products.length == 0"> {{'Requests.NoRequest'|translate}} </h2>
  </div>
</div> -->

<!--<div class="container paginatation">

 <div class="row text-center" *ngIf="products?.length > 0">
  <div class="col-sm-12">
    <pagination-controls (pageChange)="p = $event" previousLabel="{{'previous'|translate}}"
      nextLabel="{{'next'|translate}}">
    </pagination-controls>
  </div>
</div>
</div> -->
<!-- Start Listings Container  -->

<!-- <div class="container services" *ngIf="services?.length > 0">
  <h2>{{'Home.servicess' | translate}}</h2>
  <div class="custom-separator"></div>

  <div class="row listing white squared w-100">

    <div class="col-md-4 col-sm-4 col-xs-6"
      *ngFor="let item of services | paginate: { itemsPerPage: 10, currentPage: p }">
      <div class="listing-item">
        <a class="listing-item-link">
          <div class="listing-item-title-centralizer">
            <div class="listing-item-title">
              {{item.name}}
            </div>
          </div>
          <img [src]="item.image?item.image:'assets/images/logo.png'" />
        </a>
        <div class="listing-item-data">
          <div class="listing-item-excerpt">
            {{item.description}}
          </div>
        </div>
      </div>
    </div>
    <h2 *ngIf="services.length == 0"> {{'Requests.NoRequest'|translate}} </h2>
  </div>
</div> -->
<!--<div class="container  paginatation">

 <div class="row text-center" *ngIf="services?.length > 0">
  <div class="col-sm-12">
    <pagination-controls (pageChange)="p = $event" previousLabel="{{'previous'|translate}}"
      nextLabel="{{'next'|translate}}">
    </pagination-controls>
  </div>
</div>
</div>
 -->

<!-- Start Related Articles -->
<div class="container related-places" *ngIf="RelatedPlaces.length > 0">
  <h3 style="font-size: 18px; font-weight: 500">
    {{ "Related Places" | translate }}
  </h3>
  <!-- <div class="separator-medium"></div> -->
  <div class="row listing white squared w-100">
    <!-- Start Blog Item Col -->
    <div
      class="col-lg-3 col-md-3 col-sm-4 col-xs-12"
      *ngFor="let relatedplace of RelatedPlaces | slice : 0 : 12"
    >
      <!-- <div class="listing-item">

        <a (click)="navigateplace(relatedplace)" class="listing-item-link">
          <div class="listing-item-title-centralizer">
            <div class="listing-item-title">
              {{relatedplace.title}}
            </div>
          </div>
          <img [alt]="relatedplace.title" [src]="relatedplace.image" />
        </a>

        <a (click)="navigateplace(relatedplace)">
            <div class="list-title">
              {{relatedplace.title}}
            </div>

            <div class="data">
              <div class="listing-item-excerpt">
                {{relatedplace.address}}
              </div>
            </div>
        </a>

        <div class="listing-category-name">
             <a href="#">{{relatedplace.categories[0].value}}</a>
        </div>

      </div> -->

      <div
        class="card"
        style="cursor: pointer"
        (click)="navigateplace(relatedplace)"
      >
        <img [alt]="relatedplace.title" [src]="relatedplace.image" />
        <h3>{{ relatedplace.title }}</h3>
        <p>{{ relatedplace.categories[0].value }}</p>
        <h4>{{ relatedplace.address }}</h4>
      </div>
    </div>
    <!-- End Blog Item Col -->
  </div>

  <div
    class="col-md-12 col-sm-12 text-center"
    *ngIf="RelatedPlaces?.length > 0"
  >
    <pagination-controls
      (pageChange)="onPageChange($event)"
      previousLabel="{{ 'previous' | translate }}"
      nextLabel="{{ 'next' | translate }}"
    >
    </pagination-controls>
  </div>
</div>

<div class="separator-short"></div>
<!-- End Related Article -->

<!-- <ul class="nav nav-tabs" id="myTab" role="tablist">

  <li class="nav-item" *ngIf="projects?.length > 0">
    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
     {{'Home.projects' | translate}}
    </a>
  </li>
  <li class="nav-item" *ngIf="products?.length > 0">
    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
      <h2>{{'Home.products' | translate}}</h2>
    </a>
  </li>
  <li class="nav-item"  *ngIf="products?.length > 0">
    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
      <h2>{{'Home.servicess' | translate}}</h2>
    </a>
  </li>
</ul>

<div class="tab-content" id="myTabContent" >
  <div *ngIf="projects?.length > 0" class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
    <div class="row listing white squared w-100">
      <div class="col-md-4 col-sm-4 col-xs-6"
        *ngFor="let item of projects | paginate: { itemsPerPage: 10, currentPage: p }">
        <div class="listing-item">
          <a class="listing-item-link">
            <div class="listing-item-title-centralizer">
              <div class="listing-item-title">
                {{item.name}}
              </div>
            </div>
            <img [src]="item.image?item.image:'assets/images/logo.png'" />
          </a>
          <div class="listing-item-data">
            <div class="listing-item-excerpt">
              {{item.description}}
            </div>
          </div>
        </div>
      </div>
      <h2 *ngIf="projects.length == 0"> {{'Requests.NoRequest'|translate}} </h2>
    </div>
  </div>
  <div *ngIf="products?.length > 0" class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <div class="row listing white squared w-100">

      <div class="col-md-4 col-sm-4 col-xs-6"
        *ngFor="let item of products | paginate: { itemsPerPage: 10, currentPage: p }">
        <div class="listing-item">
          <a class="listing-item-link">
            <div class="listing-item-title-centralizer">
              <div class="listing-item-title">
                {{item.name}}
              </div>
            </div>
            <img [src]="item.image?item.image:'assets/images/logo.png'" />
          </a>
          <div class="listing-item-data">
            <div class="listing-item-excerpt">
              {{item.description}}
            </div>
          </div>
        </div>
      </div>
      <h2 *ngIf="products.length == 0"> {{'Requests.NoRequest'|translate}} </h2>
    </div>
  </div>
  <div  *ngIf="products?.length > 0" class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
    <div class="row listing white squared w-100">

      <div class="col-md-4 col-sm-4 col-xs-6"
        *ngFor="let item of services | paginate: { itemsPerPage: 10, currentPage: p }">
        <div class="listing-item">
          <a class="listing-item-link">
            <div class="listing-item-title-centralizer">
              <div class="listing-item-title">
                {{item.name}}
              </div>
            </div>
            <img [src]="item.image?item.image:'assets/images/logo.png'" />
          </a>
          <div class="listing-item-data">
            <div class="listing-item-excerpt">
              {{item.description}}
            </div>
          </div>
        </div>
      </div>
      <h2 *ngIf="services.length == 0"> {{'Requests.NoRequest'|translate}} </h2>
    </div>
  </div>
</div> -->

<ng-template #noProjects>
  <div class="text-center">
    <img class="no-data-image" src="/assets/images/no-data.svg" alt="no-data" />
  </div>
  <h3 class="no-data-title">
    {{ "NoProjects" | translate }}
  </h3>
</ng-template>

<ng-template #noProducts>
  <div class="text-center">
    <img class="no-data-image" src="/assets/images/no-data.svg" alt="no-data" />
  </div>
  <h3 class="no-data-title">
    {{ "NoProducts" | translate }}
  </h3>
</ng-template>

<ng-template #noServices>
  <div class="text-center">
    <img class="no-data-image" src="/assets/images/no-data.svg" alt="no-data" />
  </div>
  <h3 class="no-data-title">
    {{ "NoServices" | translate }}
  </h3>
</ng-template>

<ng-template #noJobs>
  <div class="text-center">
    <img class="no-data-image" src="/assets/images/no-data.svg" alt="no-data" />
  </div>
  <h3 class="no-data-title">
    {{ "NoJobs" | translate }}
  </h3>
</ng-template>
