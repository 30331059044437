import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  check_login;
  constructor() { }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
    // this.check_login = localStorage.getItem('token')
  }

  sethasTasks(value){
    localStorage.setItem('hasTasks', value);
  }

  gethasTasks(){
    return localStorage.getItem('hasTasks');
  }

  setEmail(email){
    localStorage.setItem('email', email)
  }
  getEmail(){
    return localStorage.getItem('email');
  }

  setEmailVerificationCode(verifycode){
    localStorage.setItem('verifycode', verifycode)
  }
  getEmailVerificationCode(){
    return localStorage.getItem('verifycode');
  }
}
