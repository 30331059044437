import { Component, OnInit } from '@angular/core';
import {UserService} from '../../Shared/Services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl,FormBuilder,Validators } from '@angular/forms';
import {Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {AuthenticationService} from '../../Shared/Services/authentication.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.scss'],
})
export class VerificationCodeComponent implements OnInit {
  forgetpasswordForm: FormGroup;
  email;
  mobile;
  telecode;
  isEnabled: boolean = false;
  lang: string;

  currentlang;

  constructor(
    private userservice: UserService,
    private translateservice: TranslateService,
    private toastrService: ToastrService,
    private authservice: AuthenticationService,
    private languageService: LanguageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.initForm();
    this.mobile = localStorage.getItem('mobile');

    this.telecode = localStorage.getItem('telecode');
    console.log(this.email);

    this.currentlang = localStorage.getItem('lang');
  }

  initForm() {
    this.forgetpasswordForm = new FormGroup({
      // email: new FormControl('', [Validators.required, Validators.email]),
      mobile_verification_code: new FormControl('', [Validators.required]),
      // new_password: new FormControl('', [Validators.required, Validators.minLength(9)]),
      // confirm_new_password: new FormControl('', [Validators.required, Validators.minLength(9)])
    });
  }

  ForgetPassword() {
    this.isEnabled = true;
    let submitObject = {
      mobile_verification_code:
        this.forgetpasswordForm.value['mobile_verification_code'],
      mobile: this.mobile,
      tele_code: this.telecode,
    };
    localStorage.setItem(
      'verification-code',
      this.forgetpasswordForm.value['mobile_verification_code']
    );
    this.userservice.VerifyMobile(submitObject).subscribe(
      (res: any) => {
        this.isEnabled = false;
        if (res.status.code === 200) {
          // this.authservice.setEmailVerificationCode(this.forgetpasswordForm.value['email_verification_code'])
          if (this.lang == 'en') {
            this.toastrService.success('Mobile Verified Successfully'); // toasr services
          } else {
            this.toastrService.success('تم تفعيل رقم الهاتف بنجاح'); // toasr services
          }

          this.router.navigate(['forget-password', this.currentlang]);
        } else {
          this.toastrService.error(res.status.error_details); // toasr services
        }

        // this.toastrService.success("sent"); // toasr services
        //   this.router.navigate(['/Forget-Password'])
      },
      (error) => {
        this.isEnabled = false;
        console.log(error.error_details);
        // if(error.validation_errors.field == 'email'){
        //   this.toastrService.error(error); // toasr services
        // }
      }
    );
  }
}
