<div
  class="container-fluid pg-header"
  style="background-image: url(././assets/images/11.jpg)"
>
  <div class="pg-header-content">
    <h1 class="pg-title">
      {{ "Home.products" | translate }}
    </h1>
    <h3 class="services-desc">{{ "Find Our Products" | translate }}</h3>
    <p class="services-desc2">
      {{
        "Discover all of our products. Take a look at our complete product list here"
          | translate
      }}<
    </p>
  </div>

  <!-- <div class="pg-header-icon">
      <i class="fa fa-briefcase"></i>
    </div> -->
</div>

<!-- Start Listings Container  -->
<div class="container-fluid listing-block listings-has-sidebar">
  <div class="search">
    <div class="row">
      <div class="col-md-6">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="price"
          (ngModelChange)="filterByPrice($event)"
          on-focusout="menuHide($event)"
          on-focus="menuShow()"
          placeholder="Product Price"
        />
      </div>
      <div class="col-md-6">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="query"
          (ngModelChange)="search($event)"
          on-focusout="menuHide($event)"
          on-focus="menuShow()"
          placeholder="Search for Product"
        />

        <!-- <ul *ngIf="(searchedProduct.length > 0 || searchedProduct.length != null) && showMenu"
                    id="search_id2" class="list-group">
                    <a *ngFor="let product of searchedProduct"
                        class="list-group-item text-left">
                        <img width="40px" height="40px"
                            [src]="product?.image" [alt]="product?.name">
                        <span class="search-text">
                            {{ product.name }}
                        </span>
                    </a>
                </ul> -->
      </div>
    </div>
  </div>

  <div class="listing grid2 no-addr-on-mobile white">
    <!-- Start Listing -->

    <!-- Start Listing Item Col -->
    <div class="row">
      <div
        class="col-md-4"
        *ngFor="
          let item of products | paginate : { itemsPerPage: 10, currentPage: p }
        "
      >
        <div
          class="listing-item"
          style="cursor: pointer"
          (click)="navigateplace(item)"
        >
          <div class="listing-item-link">
            <img [alt]="item.name" [src]="item.image" loading="lazy" />
            <div class="listing-item-title-centralizer">
              <div class="listing-item-title">
                {{ item.name }}
              </div>

              <div class="listing-item-data">
                <div class="listing-item-excerpt">
                  <p style="font-size: 13px; line-height: 17px">
                    {{ item.description }}
                  </p>
                  <p style="color: gray">
                    {{ "price" | translate }}: {{ item.price }}
                  </p>
                  <!-- <p *ngIf="item.salary">{{'salary' | translate}}: {{item.salary}} {{'currency' | translate}}</p>
                      <p *ngIf="item.notes">{{'notes' | translate}}: {{item.notes}}</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="products.length == 0" class="col-md-12 text-center">
        {{ "NoProducts" | translate }}
      </div>
    </div>
    <!-- End Listing Item Col -->
    <div class="row text-center" *ngIf="products.length > 0">
      <div class="col-sm-12">
        <pagination-controls
          (pageChange)="p = $event"
          previousLabel="{{ 'previous' | translate }}"
          nextLabel="{{ 'next' | translate }}"
        >
        </pagination-controls>
      </div>
    </div>
    <!-- End Listing -->
    <div class="col-md-1"></div>
  </div>
</div>
<!-- End Listings Container  -->
