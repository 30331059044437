    <!-- Start Page Content -->
    <div class="container pg-content">
        <div class="pg-header-content">
            <h1 class="pg-title" *ngIf="!id">
              {{'Requests.add_request_type'|translate}}
            </h1>
            <h1 class="pg-title" *ngIf="id">
              {{'Requests.edit_request_type'|translate}}
            </h1>
          </div>
        
         <!--Start Contact Form -->
        <div class="col-sm-12 text-center">
         
          <form [formGroup]="requestForm"(ngSubmit)="create(requestForm.value)" class="form-horizontal contact-form">
            <!-- Text input-->
            
            <div class="form-group">
              <div class="col-sm-6 inputGroupContainer">
              <div class="input-group">
              <span class="input-group-addon"><i class="fas fa-ellipsis-v"></i></span>
              <input formControlName="name_en"  name="name" id="name" placeholder="{{'Requests.TypeRequests'|translate}} - {{'english'|translate}}" class="form-control"  >
    
              <span *ngIf="requestForm.controls['name_en'].touched && requestForm.controls['name_en']?.errors?.required" class="error-msg">
                  {{'required'|translate}}
              </span>
              <!-- <span *ngIf="requestForm.controls['full_name'].touched && requestForm.controls['full_name']?.errors?.pattern" class="error-msg">
                  {{'nameMustBeCharc'|translate}}.
              </span> -->
            </div>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-6 inputGroupContainer">
              <div class="input-group">
              <span class="input-group-addon"><i class="fas fa-ellipsis-v"></i></span>
              <input formControlName="name_ar"  name="name_ar" id="name_ar" placeholder="{{'Requests.TypeRequests'|translate}} - {{'arabic'|translate}}" class="form-control"  >
    
              <span *ngIf="requestForm.controls['name_ar'].touched && requestForm.controls['name_ar']?.errors?.required" class="error-msg">
                  {{'required'|translate}}
              </span>
              <!-- <span *ngIf="requestForm.controls['full_name'].touched && requestForm.controls['full_name']?.errors?.pattern" class="error-msg">
                  {{'nameMustBeCharc'|translate}}.
              </span> -->
            </div>
              </div>
            </div>
          


           
            <!-- Submit Button -->
            <div class="form-group sub">
              <div class="col-sm-12">
                <button [disabled]="!requestForm.valid || isEnabled" type="submit" name="name" class="btn btn-warning submit" >
                  <i *ngIf="isEnabled" class="fa fa-spinner fa-spin"></i> {{'Submit'|translate}}</button>
              </div>
            </div>
          </form>
        </div>
        <!--End Contact Form-->
      </div>
      <!-- End Page Content -->
    