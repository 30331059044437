<section>
    <div class="background">
         <div class="container">


             <div class="row">
                <div class="col-md-3 col-sm-2 col-xs-1"></div>


                <div class="col-md-6 col-sm-8 col-xs-10">

                    <div class="card">
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
                                <img class="logo" src="assets/images/logo.png">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12" style="text-align: center;">

                                    <div class="steps">
                                        <button class="step-number step-selected">1</button>
                                        <button class="link"></button>
                                        <button class="step-number" id="stepcircle2">2</button>
                                        <button class="link"></button>
                                        <button class="step-number" id="stepcircle3">3</button>
                                        <button class="link"></button>
                                        <button class="step-number" id="stepcircle4">4</button>


                                    </div>

                                    <div id="form1">

                                        <h2 >{{'Enter Corporate Information'|translate}}</h2>
                                        <p   class="grey-title">{{'You Can edit it later'|translate}}</p>
                                        <form class="form1" [formGroup]="registerForm1" (ngSubmit)="SubmitForm1(registerForm1)" >
                                            <div class="row">

                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <div class="form-group">
                                                        <label >{{'Download the company visual identity'|translate}} <span class="required">*</span></label>
                                                        <input  style="display: none;" formControlName="logo" (change)="readImage($event)" type="file" class="form-control" placeholder="{{'Upload Place Image'|translate}}" id="profile_img"  accept=".png, .jpg, .jpeg" >
                                                        <label class="form-control" style="text-align: start; cursor: pointer;"  for="profile_img">
                                                            <i class="fa fa-cloud-upload"></i>{{'upload Image' | translate}}
                                                            <span class="imageName">{{fileName}}</span>
                                                        </label>

                                                    </div>
                                                </div>

                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <div class="form-group">
                                                        <label >{{'Corporate Name'|translate}} <span class="required">*</span></label>
                                                        <input  type="text"  formControlName="full_name" name="Corporate_Name" id="register-fullname" tabindex="1" class="form-control" placeholder="{{'Corporate Name'|translate}}" value="">

                                                        <span *ngIf="registerForm1.controls['full_name']?.errors?.required " class="error-msg">
                                                            {{'nameReqMes'|translate}}
                                                        </span>

                                                        <!-- <span *ngIf="registerForm1.controls['full_name']?.errors?.pattern"  class="error-msg">
                                                            {{'nameMustBeCharc'|translate}}.
                                                        </span> -->

                                                        <!-- <span *ngIf="errorList?.full_name" class="error-msg">
                                                            {{ errorList?.full_name}}
                                                        </span> -->
                                                    </div>
                                                </div>

                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <div class="form-group">
                                                        <label>{{'phoneno'|translate}} <span class="required">*</span></label><br>

                                                        <ngx-intl-tel-input
                                                            [cssClass]="'col-md-12 form-control'"
                                                            [customPlaceholder]="currentlang == 'en' ? 'Please Enter Your Phone Number' : 'من فضلك قم بادخال رقم الهاتف'"
                                                            [preferredCountries]="preferredCountries"
                                                            [enableAutoCountrySelect]="true" [searchCountryFlag]="true"
                                                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                                            [selectedCountryISO]="CountryISO.Egypt" [maxLength]="15"
                                                            [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                                            [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="mobile">
                                                        </ngx-intl-tel-input><br>
                                                        <span *ngIf="registerForm1.controls['mobile']?.errors?.required " class="error-msg">
                                                            {{'phoneReqMes'|translate}}
                                                        </span>
                                                        <span *ngIf="(registerForm1.controls['mobile']?.value?.number?.length < 10 || registerForm1.controls['mobile']?.value?.number?.length > 11) && registerForm1.controls['mobile']?.value?.dialCode == '+20' " class="error-msg">
                                                            {{'phonePatMes'|translate}}.
                                                        </span>
                                                        <span *ngIf="registerForm1.controls['mobile']?.value?.number?.length != 8 && registerForm1.controls['mobile']?.value?.dialCode == '+966' " class="error-msg">
                                                            {{'phonePatMes'|translate}}.
                                                        </span>
                                                    </div>

                                                </div>

                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <div class="form-group">
                                                        <label>{{'email'|translate}} <span class="required">*</span></label>
                                                        <input  type="email" name="email" formControlName="email" id="register-email" tabindex="3" class="form-control" placeholder="{{'email'|translate}}" value="">

                                                        <span *ngIf="registerForm1.controls['email']?.errors?.required" class="error-msg">
                                                            {{'emailReqMes'|translate}}
                                                        </span>
                                                        <span *ngIf="registerForm1.controls['email']?.errors?.email " class="error-msg">
                                                            {{'emailmustbe'|translate}}.
                                                        </span>
                                                    </div>
                                                </div>


                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <div class="form-group">
                                                        <label>{{'Password'|translate}} <span class="required">*</span></label>
                                                        <input  type="password" formControlName="password" name="password" id="register-password" tabindex="10" class="form-control" placeholder="{{'Password'|translate}}">

                                                        <span *ngIf="registerForm1.controls['password']?.errors?.required " class="error-msg">
                                                            {{'passwordReqMes'|translate}}
                                                        </span>
                                                        <span *ngIf="registerForm1.controls['password']?.errors?.minlength " class="error-msg">
                                                            {{'passwordMinLengMes'|translate}}.
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <button class="submit-btn"  type="submit">{{'next'|translate}}</button>
                                                </div>


                                            </div>

                                        </form>

                                    </div>


                                    <div id="form2">

                                        <h2 >{{'Enter Corporate Information'|translate}}</h2>
                                        <p   class="grey-title">{{'You Can edit it later'|translate}}</p>
                                        <form class="form1" [formGroup]="registerForm2" (ngSubmit)="SubmitForm2(registerForm2)">
                                             <div class="row">

                                                  <div class="col-md-12 col-sm-12 col-xs-12" style="margin-bottom: 15px;">
                                                      <div class="form-group">
                                                        <label >{{'corporate provide'|translate}} <span class="required">*</span></label><br>
                                                        <input type="radio" formControlName="product" id="html" name="fav_language" value="HTML" (click)="showproductdropdown()">
                                                        <label for="html" class="checkbox-mar"> {{'product'|translate}}</label>
                                                        <input type="radio" formControlName="services" id="css" name="fav_language" value="CSS" (click)="showservicedropdown()">
                                                        <label for="css" class="checkbox-mar"> {{'service'|translate}}</label>

                                                      </div>
                                                  </div>

                                                  <div class="col-md-12 col-sm-12 col-xs-12" style="margin-bottom: 15px;" id="service_dropdown">
                                                      <div class="form-group">
                                                          <select formControlName="services" name="service_id" tabindex="10" class="form-control">
                                                                 <option>{{'Choose Service Type'|translate}}</option>
                                                                 <option *ngFor="let item of services" value={{item.id}}>{{item.name}}</option>
                                                          </select>
                                                      </div>
                                                  </div>

                                                  <div class="col-md-12 col-sm-12 col-xs-12" style="margin-bottom: 15px;" id="productt_dropdown">
                                                    <div class="form-group">
                                                        <select formControlName="products" name="product_id" tabindex="10" class="form-control">
                                                               <option>{{'Choose Product Type'|translate}}</option>
                                                               <option *ngFor="let item of products" value={{item.id}}>{{item.name}}</option>
                                                        </select>
                                                    </div>
                                                  </div>

                                                  <div class="col-md-12 col-sm-12 col-xs-12" style="margin-bottom: 15px;">
                                                      <div class="form-group">
                                                        <label >{{'Corporate description'|translate}} <span class="required">*</span></label>
                                                        <textarea  rows="4" cols="50"  formControlName="company_description" name="description" id="description" tabindex="1" class="form-control" placeholder="{{'Corporate description'|translate}}" value="">
                                                        </textarea>
                                                      </div>
                                                  </div>

                                                  <div class="col-md-6 col-sm-12 col-xs-12" style="margin-bottom: 15px;">
                                                      <div class="form-group">
                                                        <label >{{'tax file'|translate}} <span class="required">*</span></label>
                                                        <input   formControlName="tax_card"  type="text" class="form-control"   >
                                                        <!-- <label class="form-control" style="text-align: start; cursor: pointer;"  for="profile_img2">
                                                            <i class="fa fa-cloud-upload"></i>{{'upload file' | translate}}
                                                            <span class="imageName">{{fileName2}}</span>
                                                        </label> -->

                                                        <span *ngIf="registerForm2.controls['tax_card']?.errors?.required " class="error-msg">
                                                            {{'taxfileReqMes'|translate}}
                                                        </span>


                                                      </div>
                                                  </div>

                                                  <div class="col-md-6 col-sm-12 col-xs-12" style="margin-bottom: 15px;">
                                                      <div class="form-group">
                                                        <label >{{'commerial register file'|translate}} <span class="required">*</span></label>
                                                        <input   formControlName="commercial_record"  type="text" class="form-control" >
                                                        <!-- <label class="form-control" style="text-align: start; cursor: pointer;"  for="profile_img3">
                                                            <i class="fa fa-cloud-upload"></i>{{'upload file' | translate}}
                                                            <span class="imageName">{{fileName3}}</span>
                                                        </label> -->

                                                        <span *ngIf="registerForm2.controls['commercial_record']?.errors?.required " class="error-msg">
                                                            {{'commerialfileReqMes'|translate}}
                                                        </span>

                                                      </div>
                                                  </div>

                                                  <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <button class="submit-btn"  type="submit">{{'next'|translate}}</button>
                                                  </div>


                                             </div>
                                        </form>

                                    </div>



                                    <div id="form3">

                                        <h2 >{{'Enter Corporate Information'|translate}}</h2>
                                        <p   class="grey-title">{{'You Can edit it later'|translate}}</p>
                                        <form class="form1" [formGroup]="registerForm3" (ngSubmit)="SubmitForm3(registerForm3)">
                                            <div class="row"   formArrayName="students" *ngFor="let fields of registerForm3.get('students')['controls']; let i=index" style="margin:15px 0px">

                                                <div class="col-md-12 col-sm-12 col-xs-12" [formGroupName]="i">
                                                    <div class="form-group">
                                                        <label >{{'corporate location'|translate}} <span class="required">*</span></label><br>
                                                        <input ngx-google-places-autocomplete formControlName="address" [options]='options'  (onAddressChange)="AddressChange($event)" class="form-control" style="margin-bottom: 15px;"  value="{{address}}" />
                                                            <!-- {{ address }} -->

                                                            <agm-map
                                                            #map
                                                            [latitude]="location.lat"
                                                            [longitude]="location.lng"
                                                            style="height: 200px">
                                                            <agm-marker
                                                              [latitude]="location.lat"
                                                              [longitude]="location.lng"
                                                              [markerDraggable]="true"
                                                              (dragEnd)="markerDragEnd($event)"
                                                            ></agm-marker>
                                                          </agm-map>
                                                        <!-- <agm-map [latitude]="lat" [longitude]="lng">
                                                            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                                                        </agm-map> -->
                                                        <!-- <div class="input-group" style="display: flex; width: 100%;">
                                                            <a href="https://www.google.com/maps/"  target="_blank"> <i class="fas fa-map-marker-alt map-icon"></i> </a>
                                                            <input  type="text"  formControlName="latitude" name="Corporate_website"  tabindex="11" class="form-control" placeholder="{{'corporate website'|translate}}" value="">
                                                        </div> -->
                                                    </div>
                                                </div>

                                                <div class="col-md-12 col-sm-12 col-xs-12" style="margin:5px 0px">
                                                    <a  class="add-btn" (click)="add()"> <i class="fa fa-plus icon-padding"></i> {{'Add Branches Websites'|translate}}</a>
                                                    <a *ngIf="i > 0" class="delete-btn" (click)="removeProgram(i)"> <i class="fa fa-times"></i></a>

                                                </div>

                                            </div>

                                            <div class="row" style="margin: 30px 0px 0px 0px;">
                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <div class="form-group">
                                                        <label> {{'Social Links'|translate}} <span class="required">*</span></label>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="row"  formArrayName="socials" *ngFor="let fields of registerForm3.get('socials')['controls']; let j=index" style="margin:15px 0px">

                                                <div class="col-md-6 col-sm-12 col-xs-12" [formGroupName]="j">
                                                    <div class="form-group">
                                                         <select class="form-control" formControlName="social_media_type">
                                                             <option value="1">{{'facebook'|translate}}</option>
                                                             <option value="2">{{'twitter'|translate}}</option>
                                                             <option value="3">{{'linkedin'|translate}}</option>
                                                             <option value="4">{{'youtube'|translate}}</option>
                                                             <option value="5">{{'vimeo'|translate}}</option>
                                                             <option value="6">{{'instagram'|translate}}</option>
                                                             <option value="7">{{'pinterest'|translate}}</option>


                                                         </select>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-sm-12 col-xs-12" [formGroupName]="j">
                                                    <div class="form-group">
                                                        <input  type="text"  formControlName="value"   tabindex="90" class="form-control" placeholder="https://www.com" >
                                                    </div>
                                                </div>

                                                <div class="col-md-12 col-sm-12 col-xs-12" style="margin:5px 0px">
                                                    <a  class="add-btn" (click)="addSocialinks()"> <i class="fa fa-plus icon-padding"></i> {{'Add Social Media Links'|translate}}</a>
                                                    <a *ngIf="j > 0" class="delete-btn" (click)="removeSocial(j)"> <i class="fa fa-times"></i></a>

                                                </div>

                                            </div>

                                            <div class="row">
                                                <div class="col-md-12 col-sm-12 col-xs-12">
                                                    <button class="submit-btn"  type="submit">{{'next'|translate}}</button>
                                                  </div>
                                            </div>

                                        </form>

                                    </div>



                                    <div id="form4">
                                        <div class="row">
                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <h1 class="bold-title"> {{'Bravo'|translate}}</h1>
                                                <h2 class="mar-top">{{'New account has been created for your company'|translate}}</h2>
                                                <p class="grey-title">{{'You can now use Qpedia and succeed and develop your company with ease'|translate}}</p>
                                            </div>

                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <img class="sucess-img" src="assets/images/corporate-sucess.jpg">
                                            </div>

                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                <button class="submit-btn" (click)="NavigateToHome()">{{'Go To Homepage'|translate}}</button>
                                            </div>
                                        </div>

                                    </div>



                            </div>
                        </div>



                    </div>

                </div>


                <div class="col-md-3 col-sm-2 col-xs-1"></div>
             </div>


        </div>
    </div>
  <div id="captcha"></div>
</section>
