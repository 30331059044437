import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/Shared/Services/authentication.service';


@Component({
  selector: 'app-services-individual',
  templateUrl: './services-individual.component.html',
  styleUrls: ['./services-individual.component.scss']
})
export class ServicesIndividualComponent implements OnInit {

  constructor(public authService: AuthenticationService,) { }

  ngOnInit(): void {
  }

}
