<div class="container">
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <h1 class="main-title">{{ "Our Services" | translate }}</h1>
    </div>
  </div>
  <div class="row" style="margin: 25px 0px">
    <div class="col-md-6 col-sm-6 col-xs-12">
      <img class="service-img" src="assets/images/2.jpg" />
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12">
      <h2 class="sevice-title">{{ "Corporate Services" | translate }}</h2>
      <p class="sevice-paragraph">
        {{ "corporate Paragraph" | translate }}
      </p>
      <a class="sevice-btn" [routerLink]="['../services-corporate', lang]">{{
        "Read More" | translate
      }}</a>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-sm-6 col-xs-12 left-pad">
      <h2 class="sevice-title">{{ "Individual Services" | translate }}</h2>
      <p class="sevice-paragraph">
        {{ "Individual Paragraph" | translate }}
      </p>
      <a class="sevice-btn" [routerLink]="['../services-individual', lang]">{{
        "Read More" | translate
      }}</a>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12">
      <img class="service-img img-t" src="assets/images/2.jpg" />
    </div>
  </div>
</div>
