import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  closeResult = '';
  pageSize: number;
  totalPages: number;
  p: number = 1;
  totalapi: number;
  userList:any[]=[];
  userId: number;
  currentlang;
  searchForm:FormGroup;
  requestForm:FormGroup;
  isValid: boolean = true;
  approvedList=[
    {"id":"0","name":"موظفين تم ارسال اليهم طلب الانضمام للشركة "},
    {"id":"1","name":"موظفين تابعين للشركة "},
    {"id":"2","name":"موظفيين لم يتم الموافقة على طلب الشركة "},
    {"id":"3","name":"موظفين غير تابعين الشركة "},
  ];

  jobs: any[] = [];
  displayResponsive: boolean = false;
  userName;
  constructor(
    private toastr:ToastrService,
    private router:Router,
    private modalService: NgbModal,
    private service:HttpClientService
  ) {}

  ngOnInit(): void {
    this.currentlang = localStorage.getItem('lang');

    this.getUser();
    this.initForm();
    this.initFormRequest();
    this.getJobs();
  }
  initFormRequest()
  {
    this.requestForm=new FormGroup({
      job_id: new FormControl('', [Validators.required]),
  }
    )
}
  initForm()
  {
    this.searchForm=new FormGroup({
      full_name: new FormControl(''),
      username: new FormControl(''),
      tele_code: new FormControl(''),
      mobile: new FormControl(''),
      is_approved: new FormControl(''),
      email: new FormControl('')
      
  }
    )
}
  open(userId?, userName?) {
    this.userId = userId;
    this.userName = userName;
    // this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });

    this.displayResponsive = true;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  getUser(page?)
  {
    let parameters = new HttpParams();
    let pagination={'pagination':10};

      if(page){
        parameters= parameters.set('page',page+"");

      }
    this.service.post('api/users',pagination,parameters).subscribe((res:any)=>
    {
      console.log("user =>",res); 
      this.userList=res.content;
      this.totalapi = res.pagination?.total;
      this.pageSize = res.pagination?.per_page;
      this.p = res.pagination?.current_page

    })
  }

  navigateView(item) {
    this.router.navigate(['../view-user', this.currentlang], {
      queryParams: {
        id: item.id
      }
    })
  
  }

  sendRequest(formdata)
  {
    if(!formdata.valid){
      console.log('Error Happened');
      this.isValid = false;
    }else{
      let data = formdata.value;
    data.job_id = +data.job_id;
    data.user_id = this.userId
    console.log(data);

    this.service.post('api/company_users/send', data, []).subscribe(
      (res: any)=>{
        console.log('Response', res);
        this.getUser();
        this.displayResponsive = false;
        if(res.status.code == 200){
          if(this.currentlang == 'en'){
            this.toastr.success('Request Sent Successfully');
          }else{
            this.toastr.success('تم إرسال الطلب بنجاح');
          }
        }
        
      },
      error=>{
        console.log('Error', error);
        this.toastr.error(error.error.error.message);
        this.displayResponsive = false;
      }
    );
    }
    
  }

  search(formdata)
  {
    let body={
      full_name:'',
      username:'',
      tele_code:'',
      mobile:'',
      is_approved:'',
      email:'',
      
      

    };
    console.log(formdata);
    this.service.post('api/employees',formdata,{}).subscribe((res:any)=>
    {
      this.modalService.dismissAll();
      console.log("user =>",res); 
      this.userList=res.content;


    })

    }
    onPageChange(event){
      this.p=event;
      this.getUser(this.p+"");
  }
  
  getJobs() {
    this.service.post('api/company_jobs',{},[]).subscribe(
      res=>{
        console.log('The Jobs Are:-', res['content']);
        this.jobs = res['content'];
      }
    );
  }

  gotoJob(){
    this.router.navigate(['create-job', this.currentlang]);
  }
 
}

 


