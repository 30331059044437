import { LanguageService } from 'src/app/Shared/Services/language.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/Shared/Services/home.service';
@Component({
  selector: 'app-create-request-types',
  templateUrl: './create-request-types.component.html',
  styleUrls: ['./create-request-types.component.scss'],
})
export class CreateRequestTypesComponent implements OnInit {
  requestForm: FormGroup;

  currentlang: string;
  lang: string;
  id: number;
  requestsList: any[] = [];
  request;
  isEnabled: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private languageService: LanguageService,
    private homeService: HomeService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.requestsList = [
      { id: '1', name_ar: 'Pentavalue Soft', name_en: 'accountant' },
      { id: '2', name_r: 'Pentavalue Soft', name_en: 'accountant 1' },
      { id: '3', name_ar: 'Pentavalue Soft', name_en: 'accountant 2' },
    ];
    this.requestForm = new FormGroup({
      name_ar: new FormControl('', [Validators.required]),
      name_en: new FormControl('', [Validators.required]),
    });

    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      if (this.id) {
        this.getrequest(this.id);
      } else {
      }
    });

    this.currentlang = localStorage.getItem('lang');
  }

  getrequest(id: number) {
    console.log(id);
    console.log('request list', this.requestsList);

    this.requestsList.forEach((element) => {
      if (id == element.id) {
        this.request = element;
      }
    });
    console.log(this.requestForm);

    this.requestForm.patchValue({
      name_ar: this.request.name_ar,
      name_en: this.request.name_en,
    });
  }

  create(formdata) {
    this.isEnabled = true;
    if (!this.id) {
      console.log('create request :-');
    } else {
      console.log('update request :-');
    }
  }
}
