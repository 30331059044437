    <!-- Start Page Content -->
    <div class="container pg-content">
        <div class="pg-header-content">
            <h1 class="pg-title">
              {{'update emp'|translate}}
            </h1>
          </div>
        
        <!--Start Contact Form -->
        <div class="col-sm-12 text-center">
         
          <form [formGroup]="userForm"(ngSubmit)="create(userForm.value)" class="form-horizontal contact-form">
            <!-- Text input-->
            
            <div class="form-group">
              <div class="col-sm-6 inputGroupContainer">
              <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-user"></i></span>
              <input [value]="user?.full_name" formControlName="full_name" [value]="user?.full_name"  name="full_name" id="full_name" placeholder="{{'Full Name'|translate}}" class="form-control"  >
    
              <span *ngIf="userForm.controls['full_name'].touched && userForm.controls['full_name']?.errors?.required" class="error-msg">
                  {{'required'|translate}}
              </span>
              <!-- <span *ngIf="userForm.controls['full_name'].touched && userForm.controls['full_name']?.errors?.pattern" class="error-msg">
                  {{'nameMustBeCharc'|translate}}.
              </span> -->
            </div>
              </div>
            </div>
            <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                <div class="input-group">
                <span class="input-group-addon"><i class="fas fa-globe-europe"></i></span>
                <div>
                    <p-dropdown placeholder="{{'Select Tel code' | translate}}" 
                    [style]="{'width':'100%','text-align': 'start','font-size':'16px','height': '35px','padding':'3px'}"
                    [options]="codelist" filter=true  formControlName="tele_code" optionLabel="tele_code" optionValue="tele_code"></p-dropdown>

                </div>        
                <span *ngIf="userForm.controls['tele_code'].touched && userForm.controls['tele_code']?.errors?.required" class="error-msg">
                    {{'required'|translate}}
                </span>
               
              </div>
                </div>
            </div>

            <!-- Text input-->
            <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                <div class="input-group">
                    <span class="input-group-addon"><i class="glyphicon glyphicon-phone-alt"></i></span>
                    <input [value]="user?.mobile"  formControlName="mobile" name="phone" id="phone" placeholder="{{'phoneno'|translate}}" class="form-control">
    
                    <span *ngIf="userForm.controls['mobile'].touched && userForm.controls['mobile']?.errors?.required" class="error-msg">
                      {{'required'|translate}}
                  </span>
                 
                </div>
              </div>
            </div>
            <!-- Text input-->
            <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-briefcase"></i></span>
                        <input [value]="user?.job_title"  formControlName="job_title" name="job_title" id="job_title" placeholder="{{'job'|translate}}" class="form-control" >
    
                      </div>
                </div>
            </div>


            <!-- Text input-->
            <div class="form-group">
                <div class="col-sm-12 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-envelope-open"></i></span>
                        <input [value]="user?.email"  formControlName="email"  name="email" id="email" placeholder="{{'email'|translate}}" class="form-control"  >
                        <span *ngIf="userForm.controls['email'].touched && userForm.controls['email']?.errors?.required" class="error-msg">
                            {{'required'|translate}}
                        </span>
                         <span *ngIf="userForm.controls['email'].touched && userForm.controls['email']?.errors?.email" class="error-msg">
                  {{'emailmustbe'|translate}}.
              </span>
                    </div>
                </div>
            </div>
            <!-- Text input-->
            <div class="form-group">
                <div class="col-sm-12 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="glyphicon glyphicon-map-marker"></i></span>
                        <input [value]="user?.address"  formControlName="address"  name="address" id="address" placeholder="{{'Address'|translate}}" class="form-control"  >

                      </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-sm-12 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon">
                            <i class="fas fa-city"></i>
                        </span>
                        <select [value]="user?.gender_id" formControlName="gender_id" name="gender_id" id="gender_id"  class="form-control" >
                          <option value="1">{{'Male'|translate}}</option>
                          <option value="2">{{'Female'|translate}}</option>

                        </select>
                        <span *ngIf="userForm.controls['gender_id'].touched && userForm.controls['gender_id']?.errors?.required" class="error-msg">
                            {{'required'|translate}}
                        </span> 
                    </div>
                </div>
            </div>
 
            <!-- Text input-->
            <!-- <div class="form-group">
                  <div class="col-sm-12 inputGroupContainer">
                      <div class="input-group">
                          <span class="input-group-addon"><i class="fas fa-calendar-alt"></i></span>
                          
                          <div class="input-group" style="display:flex">
                            <input [value]="user?.full_name" class="form-control" (click)="d.toggle()" placeholder="{{'Birthdate'|translate}}" name="dp" formControlName="birthdate" ngbDatepicker #d="ngbDatepicker">
                                   
                            
                       </div>                        
                          <span *ngIf="userForm.controls['birthdate'].touched && userForm.controls['birthdate']?.errors?.required" class="error-msg">
                            {{'required'|translate}}
                        </span>               
                        </div>
                  </div>
            </div> -->
            <div class="form-group">
                <div class="col-sm-12 inputGroupContainer">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fas fa-calendar-alt"></i></span>
                    <input [value]="setDob[0]"  formControlName="birthdate" type="date" placeholder="{{'Date'|translate}}" class="form-control">
                    <span *ngIf="userForm.controls['birthdate'].touched && userForm.controls['birthdate']?.errors?.required" class="error-msg">
                        {{'required'|translate}}
                    </span> 
                    <!-- <span *ngIf="taskForm.controls['mobile_number'].touched && taskForm.controls['mobile_number']?.errors?.required" class="error-msg">
                      {{'phoneReqMes'|translate}}
                  </span>
                  <span *ngIf="taskForm.controls['mobile_number'].touched && taskForm.controls['mobile_number']?.errors?.pattern" class="error-msg">
                      {{'phonePatMes'|translate}}.
                  </span> -->
                </div>
              </div>
            </div>

            <div class="form-group" >
                <div class="col-md-12">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fas fa-user-circle"></i></span>
                        <input [value]="user?.username"  type="text" formControlName="username"  name="username" id="username" placeholder="{{'username'|translate}}" class="form-control"  >
                        <span *ngIf="userForm.controls['username'].touched && userForm.controls['username']?.errors?.required" class="error-msg">
                            {{'required'|translate}}
                        </span>
                    </div>
                </div>
            </div>
      
            <!-- <div class="form-group">
                <div class="col-md-12">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fas fa-unlock-alt"></i></span>
                        <input [value]="user?.password"  type="password" formControlName="password"  name="password" id="password" placeholder="{{'Password'|translate}}" class="form-control"  >
                        <span *ngIf="userForm.controls['password'].touched && userForm.controls['password']?.errors?.required" class="error-msg">
                            {{'required'|translate}}
                        </span>
                      </div>
                </div>
            </div> -->
           
            <!-- <div class="form-group">
                <div class="col-sm-12 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon">
                            <i class="fas fa-tasks"></i>
                        </span>
                        <select  formControlName="city_id" name="city_id" id="city_id"  class="form-control" >
                          <option>permission 1</option>
                          <option>permission 2</option>
                          <option>permission 3</option>
                          <option>permission 4</option>


                        </select>
                    </div>
                </div>
            </div> -->
            <!-- Text input-->
            <div class="form-group">
                <div class="col-sm-12 inputGroupContainer">
                <div class="input-group">
                    <span class="input-group-addon"><i class="glyphicon glyphicon-cloud-upload"></i></span>
                    <input *ngIf="!photo" formControlName="photo" (change)="readImage($event)" type="file" class="form-control" placeholder="{{'Upload Place Image'|translate}}" id="profile_img"   >
    
                    <div *ngIf="photo" class="js-uploader__file-list uploader__file-list">
                      <div class="js-uploader__file-list ">
                          <div class="row">
                              <div class="col-md-8">
                                 <span class="uploader__file-list__thumbnail" style="width: 90px;">
                              <img id="profileImg" class="thumbnail" id="img_" src="{{photo}}" style="margin-bottom: 0px;">  
                              
                          </span>  
                              </div>
                              <div class="col-md-4">
                       
                           
                                <span class="close">
                             
                               <a id="close" (click)="closebtn()" class="uploader__icon-button fa fa-times cursor--pointer">
                               </a>
                         
                         </span>  
                             </div>
                          </div>
                         
                        
                          
                        </div>
                    </div>
                  </div>
              </div>
            </div>
   
            <!-- <div class="form-group" >
                <div class="col-md-12">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fas fa-user-tag"></i></span>
                        <input [value]="user?.user_key"  formControlName="user_key"   name="user_key" id="user_key"  placeholder="{{'user key'|translate}}" class="form-control"  >
                        <span *ngIf="userForm.controls['user_key'].touched && userForm.controls['user_key']?.errors?.required" class="error-msg">
                            {{'required'|translate}}
                        </span>
                    </div>
                  </div>
            </div> -->
    
            <!-- <div class="form-group" >
                <div class="col-md-12">
                    <div class="input-group active">
                        <input [checked]="user?.is_active" type="checkbox" value="1" formControlName="is_active" tabindex="3" class="" name="active" id="active">
                        <label for="active"  class="active_label">{{'Active'|translate}}</label>     
                   </div>
                  </div>
            </div> -->

            <div class="form-group" >
                <div class="col-md-12">
                    <div class="input-group active">
                        <mat-slide-toggle formControlName="is_active" (change)="getEvent($event)">{{'Activate the employee'|translate}}</mat-slide-toggle>
                        <!-- <input type="checkbox" value="1" formControlName="is_active" tabindex="3" class="" name="active" id="active">
                        <label for="active"  class="active_label">{{'Active'|translate}}</label>      -->
                   </div>
                  </div>
            </div>
            <!-- Submit Button -->
            <div class="form-group sub">
              <div class="col-sm-12">
                <button   type="submit" name="name" class="btn btn-warning submit" >{{'Submit'|translate}}</button>
              </div>
            </div>
          </form>
        </div>
        <!--End Contact Form-->
      </div>
      <!-- End Page Content -->
    