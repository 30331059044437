    <!-- Start Page Content -->
    <div class="container pg-content">
        <div class="pg-header-content">
            <h1 class="pg-title">
              {{'add employee'|translate}}
            </h1>
          </div>
        
        <!--Start Contact Form -->
        <div class="col-sm-12 text-center">
         
          <form [formGroup]="userForm" #myform="ngForm" (ngSubmit)="create(userForm)" class="form-horizontal contact-form">
            <!-- Text input-->
            
            <div class="form-group">
              <div class="col-sm-6 inputGroupContainer">
              <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-user"></i></span>
              <input formControlName="full_name"  name="full_name" id="full_name" placeholder="{{'Full Name'|translate}}" class="form-control"  >
    
              <span *ngIf="userForm.controls['full_name']?.errors?.required && myform.submitted" class="error-msg">
                  {{'nameReqMes'|translate}}
              </span>
              <span *ngIf="errorList?.full_name" class="error-msg">
                {{ errorList?.full_name}}</span>
              <!-- <span *ngIf="userForm.controls['full_name'].touched && userForm.controls['full_name']?.errors?.pattern" class="error-msg">
                  {{'nameMustBeCharc'|translate}}.
              </span> -->
              
            </div>
              </div>
            </div>
            <div class="form-group">
                
                <div class="col-sm-12">
                <div class="input-group">
                    <span class="input-group-addon"><i class="glyphicon glyphicon-phone-alt"></i></span>
                    <!-- <input  formControlName="mobile" name="phone" id="phone" placeholder="{{'phoneno'|translate}}" class="form-control"> -->
                    <ngx-intl-tel-input 
                        [cssClass]="'col-md-12 form-control'"
                        [customPlaceholder]="currentlang == 'en' ? 'Please Enter Your Phone Number' : 'من فضلك قم بادخال رقم الهاتف'" 
                        [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                        [selectedCountryISO]="CountryISO.Egypt" [maxLength]="15"
                        [phoneValidation]="true" [separateDialCode]="separateDialCode"
                        [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="mobile">
                    </ngx-intl-tel-input>
                    <span *ngIf="userForm.controls['mobile']?.errors?.required && myform.submitted" class="error-msg">
                        {{'phoneReqMes'|translate}}
                    </span>
                    <span *ngIf="errorList?.mobile" class="error-msg">
                        {{ errorList?.mobile}}
                    </span>
                    <span *ngIf="isValid && myform.submitted" class="error-msg">
                        {{'phonePatMes'|translate}}.
                    </span>
                </div>
              </div>
            </div>
            <!-- Text input-->
            <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-briefcase"></i></span>
                        <input  formControlName="job_title" name="job_title" id="job_title" placeholder="{{'job'|translate}}" class="form-control" >
                        <span *ngIf="errorList?.job_title" class="error-msg">
                            {{ errorList?.job_title}}</span>
                      </div>
                </div>
            </div>


            <!-- Text input-->
            <div class="form-group">
                <div class="col-sm-12 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-envelope-open"></i></span>
                        <input  formControlName="email"  name="email" id="email" placeholder="{{'email'|translate}}" class="form-control"  >
                        <span *ngIf="userForm.controls['email']?.errors?.required && myform.submitted" class="error-msg">
                            {{'emailReqMes'|translate}}
                        </span>
                         <span *ngIf="userForm.controls['email']?.errors?.email && myform.submitted" class="error-msg">
                  {{'emailmustbe'|translate}}.
              </span>
              <span *ngIf="errorList?.email" class="error-msg">
                {{ errorList?.email}}</span>
                    </div>
                </div>
            </div>
            <!-- Text input-->
            <div class="form-group">
                <div class="col-sm-12 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="glyphicon glyphicon-map-marker"></i></span>
                        <input  formControlName="address"  name="address" id="address" placeholder="{{'Address'|translate}}" class="form-control"  >
                        <span *ngIf="userForm.controls['address']?.errors?.required && myform.submitted" class="error-msg">
                            {{'addressReqMes'|translate}}
                        </span>
                        <span *ngIf="errorList?.address" class="error-msg">
                            {{ errorList?.address}}</span>
                      </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-sm-12 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon">
                            <i class="fa fa-male"></i><i class="fa fa-female"></i>
                        </span>
                        <select   formControlName="gender_id" name="gender_id" id="gender_id"  class="form-control" >
                            <option   value=""  disabled selected>{{'Select gender'|translate}}</option>
                         
                            <option value="1">{{'Male'|translate}}</option>
                          <option value="2">{{'Female'|translate}}</option>

                        </select>
                        <span *ngIf="userForm.controls['gender_id']?.errors?.required && myform.submitted" class="error-msg">
                            {{'genderReqMes'|translate}}
                        </span> 
                    </div>
                </div>
            </div>
 
            <!-- Text input-->
            <!-- <div class="form-group">
                  <div class="col-sm-12 inputGroupContainer">
                      <div class="input-group">
                          <span class="input-group-addon"><i class="fas fa-calendar-alt"></i></span>
                          
                          <div class="input-group" style="display:flex">
                            <input class="form-control" (click)="d.toggle()" placeholder="{{'Birthdate'|translate}}" name="dp" formControlName="birthdate" ngbDatepicker #d="ngbDatepicker">
                                   
                            
                       </div>                        
                          <span *ngIf="userForm.controls['birthdate'].touched && userForm.controls['birthdate']?.errors?.required" class="error-msg">
                            {{'required'|translate}}
                        </span>               
                        </div>
                  </div>
            </div> -->
            <div class="form-group">
                <div class="col-sm-12 inputGroupContainer">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-calendar-alt"></i></span>
                    <input  formControlName="birthdate" type="date" placeholder="{{'Date'|translate}}" class="form-control">
                    <span *ngIf="userForm.controls['birthdate']?.errors?.required && myform.submitted" class="error-msg">
                        {{'birthdateReqMes'|translate}}
                    </span> 
                    <span *ngIf="errorList?.date" class="error-msg">
                        {{ errorList?.date}}</span>
                    <!-- <span *ngIf="taskForm.controls['mobile_number'].touched && taskForm.controls['mobile_number']?.errors?.required" class="error-msg">
                      {{'phoneReqMes'|translate}}
                  </span>
                  <span *ngIf="taskForm.controls['mobile_number'].touched && taskForm.controls['mobile_number']?.errors?.pattern" class="error-msg">
                      {{'phonePatMes'|translate}}.
                  </span> -->
                </div>
              </div>
            </div>

            <div class="form-group" >
                <div class="col-md-12">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-user-circle"></i></span>
                        <input  type="text" formControlName="username"  name="username" id="username" placeholder="{{'username'|translate}}" class="form-control"  >
                        <span *ngIf="userForm.controls['username']?.errors?.required && myform.submitted" class="error-msg">
                            {{'usernameReqMes'|translate}}
                        </span>
                          <span *ngIf="errorList?.username" class="error-msg">
                    {{ errorList?.username}}</span>
                    </div>
                </div>
              
            </div>
      
            <div class="form-group">
                <div class="col-md-12">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-unlock-alt"></i></span>
                        <input  type="password" formControlName="password"  name="password" id="password" placeholder="{{'Password'|translate}}" class="form-control"  >
                        <span *ngIf="userForm.controls['password']?.errors?.required && myform.submitted" class="error-msg">
                            {{'passwordReqMes'|translate}}
                        </span>
                        <span *ngIf="userForm.controls['password']?.errors?.minlength && myform.submitted" class="error-msg">
                            {{'passwordMinLengMes'|translate}}.
                        </span>
                        <span *ngIf="errorList?.password" class="error-msg">
                            {{ errorList?.password}}</span>
                      </div>
                </div>
            </div>
           
            <!-- <div class="form-group">
                <div class="col-sm-12 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon">
                            <i class="fas fa-tasks"></i>
                        </span>
                        <select  formControlName="city_id" name="city_id" id="city_id"  class="form-control" >
                          <option>permission 1</option>
                          <option>permission 2</option>
                          <option>permission 3</option>
                          <option>permission 4</option>


                        </select>
                    </div>
                </div>
            </div> -->
            <!-- Text input-->
            <div class="form-group">
                <div class="col-sm-12 inputGroupContainer">
                <div class="input-group">
                    <span class="input-group-addon"><i class="glyphicon glyphicon-cloud-upload"></i></span>
                    <input  style="display: none;" formControlName="photo" (change)="readImage($event)" type="file" class="form-control" placeholder="{{'Upload Place Image'|translate}}" id="profile_img"   >
                    <label class="form-control" style="text-align: start; cursor: pointer;"  for="profile_img">
                        <i class="fa fa-cloud-upload"></i>{{'upload Image' | translate}}
                        <span class="imageName">{{fileName}}</span>
                    </label>
                    <!-- <ul *ngIf="photo" class="js-uploader__file-list uploader__file-list">
                      <li class="js-uploader__file-list uploader__file-list">
                          <span class="uploader__file-list__thumbnail">
                              <img id="profileImg" class="thumbnail" id="img_" src="{{photo}}" (click)="openImg()">  
                          </span>
                          <span class="uploader__file-list__button" id="delete">
                              <a id="close" (click)="closebtn()" class="uploader__icon-button fa fa-times cursor--pointer">
                              </a>
                          </span>
                          
                      </li>
                  </ul> -->
                  </div>
              </div>
            </div>
   
            <!-- <div class="form-group" >
                <div class="col-md-12">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fas fa-user-tag"></i></span>
                        <input  formControlName="user_key"   name="user_key" id="user_key" placeholder="{{'user key'|translate}}" class="form-control"  >
                        <span *ngIf="userForm.controls['user_key'].touched && userForm.controls['user_key']?.errors?.required" class="error-msg">
                            {{'required'|translate}}
                        </span>
                        <span *ngIf="errorList?.user_key" class="error-msg">
                            {{ errorList?.user_key}}</span>
                    </div>
                  </div>
            </div> -->
    
            <div class="form-group" >
                <div class="col-md-12">
                    <div class="input-group active">
                        <mat-slide-toggle formControlName="is_active" (change)="getEvent($event)">{{'Activate the employee'|translate}}</mat-slide-toggle>
                        <!-- <input type="checkbox" value="1" formControlName="is_active" tabindex="3" class="" name="active" id="active">
                        <label for="active"  class="active_label">{{'Active'|translate}}</label>      -->
                   </div>
                  </div>
            </div>
            <!-- Submit Button -->
            <div class="form-group sub">
              <div class="col-sm-12">
                <button   type="submit" name="name" class="btn btn-warning submit" ><i *ngIf="isEnabled" class="fa fa-spinner fa-spin"></i> {{'Submit'|translate}}</button>
              </div>
            </div>
          </form>
        </div>
        <!--End Contact Form-->
      </div>
      <!-- End Page Content -->
    