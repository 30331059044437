<div class="listing-item">
    <div class="listing-item-rating">4.2</div>
    <a routerLink="Place-Details" href="#" class="listing-item-link">
      <div class="listing-item-title-centralizer">
        <div class="listing-item-title">
            {{'Home.place_name' | translate}}
        </div>
      </div>
      <img alt="" src="./assets/images/trending/2.jpg" />
    </a>
    <div class="listing-item-data">
      <a class="listing-item-address" href="#">
        {{'Home.place_address' | translate}}
      </a>
      <!-- <div class="listing-item-excerpt">
        The love of coffee shines all the woody ambience
      </div> -->
    </div>
    <div class="listing-category-name">
      <a routerLink="Categories" href="#">{{'Home.category_name' | translate}}</a>
    </div>
  </div>