import { Component, OnInit } from '@angular/core';

import { LoaderService } from "../../Services/loader.service";
import { Subject } from "rxjs";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  website_main_colour;

  color = "primary";
  mode = "indeterminate";
  value = 50;

  loading: boolean;

  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((v) => {
      // console.log(v);
      this.loading = v;
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (
      (document.querySelector(".custom-spinner circle") as HTMLElement).style
    ) {
      (
        document.querySelector(".custom-spinner circle") as HTMLElement
      ).style.stroke = this.website_main_colour;
    }
  }


}
