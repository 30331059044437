import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AddJobsComponent } from './pages/add-jobs/add-jobs.component';
import { AllProductsComponent } from './pages/all-products/all-products.component';
import { AllServicesComponent } from './pages/all-services/all-services.component';
import { AngularFireModule } from '@angular/fire';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BlogComponent } from './components/blog/blog.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CategoriesComponent } from './pages/categories/categories.component';
import { ChatComponent } from './pages/chat/chat.component';
import { ChatQuickComponent } from './pages/chat-quick/chat-quick.component';
import { CitiesComponent } from './pages/cities/cities.component';
import { ClientsComponent } from './components/clients/clients.component';
import { ClientsSliderComponent } from './components/clients-slider/clients-slider.component';
import { CompanyJobsComponent } from './pages/company-jobs/company-jobs.component';
import { CorporateLoginComponent } from './components/corporate-login/corporate-login.component';
import { CreateEmployeeComponent } from './pages/create-employee/create-employee.component';
import { CreateRequestTypesComponent } from './pages/create-request-types/create-request-types.component';
import { EmployeeComponent } from './pages/employee/employee.component';
import { FeatPostComponent } from './components/feat-post/feat-post.component';
import { Flipcards3Component } from './components/flipcards3/flipcards3.component';
import { Flipcards4Component } from './components/flipcards4/flipcards4.component';
import { FooterComponent } from './components/footer/footer.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { FrontCategoriesComponent } from './components/front-categories/front-categories.component';
import { HeaderComponent } from './components/header/header.component';
import { HeroSearchComponent } from './components/hero-search/hero-search.component';
import { HomeCategoriesComponent } from './components/home-categories/home-categories.component';
import { HomeCategoryComponent } from './components/home-category/home-category.component';
import { HomeComponent } from './pages/home/home.component';
import { HomeJobsComponent } from './components/home-jobs/home-jobs.component';
import { HomeMainComponent } from './components/home-main/home-main.component';
import { HomeProductsComponent } from './components/home-products/home-products.component';
import { HomeProjectsComponent } from './components/home-projects/home-projects.component';
import { HomeRecentPlacesComponent } from './components/home-recent-places/home-recent-places.component';
import { HomeServiceComponent } from './components/home-service/home-service.component';
import { HomeServicesComponent } from './components/home-services/home-services.component';
import { HowWorksComponent } from './components/how-works/how-works.component';
import { HttpClientInterceptor } from './Shared/Services/http-client.interceptor';
import { IndividualFlipcards3Component } from './components/individual-flipcards3/individual-flipcards3.component';
import { IndividualFlipcards4Component } from './components/individual-flipcards4/individual-flipcards4.component';
import { IndividualLoginComponent } from './components/individual-login/individual-login.component';
import { IndividualSliderParagraphComponent } from './components/individual-slider-paragraph/individual-slider-paragraph.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { LoginComponent } from './pages/login/login.component';
import { LoginNewComponent } from './pages/login-new/login-new.component';
import { MainComponent } from './components/main/main.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { OtpVerificationComponent } from './pages/otp-verification/otp-verification.component';
import { PlaceCircleComponent } from './components/place-circle/place-circle.component';
import { PlaceCreateComponent } from './pages/place-create/place-create.component';
import { PlaceDetailsComponent } from './pages/place-details/place-details.component';
import { PlaceGridComponent } from './components/place-grid/place-grid.component';
import { PlaceSquareComponent } from './components/place-square/place-square.component';
import { PlaceViewComponent } from './pages/place-view/place-view.component';
import { PlacesComponent } from './pages/places/places.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ProductCreateComponent } from './pages/product-create/product-create.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProjectCreateComponent } from './pages/project-create/project-create.component';
import { RecentsComponent } from './components/recents/recents.component';
import { RegisterCorporateComponent } from './pages/register-corporate/register-corporate.component';
import { RegisterIndividualComponent } from './pages/register-individual/register-individual.component';
import { RegisterNewComponent } from './pages/register-new/register-new.component';
import { RelatedContactComponent } from './pages/related-contact/related-contact.component';
import { RequestTypesComponent } from './pages/request-types/request-types.component';
import { RequestViewComponent } from './pages/request-view/request-view.component';
import { RequestsListComponent } from './pages/requests-list/requests-list.component';
import { ServiceCreateComponent } from './pages/service-create/service-create.component';
import { ServiceItemComponent } from './pages/service-item/service-item.component';
import { ServicesComponent } from './pages/services/services.component';
import { ServicesCorporateComponent } from './pages/services-corporate/services-corporate.component';
import { ServicesIndividualComponent } from './pages/services-individual/services-individual.component';
import { SharedModule } from './Shared/shared.module';
import { SliderParagraphComponent } from './components/slider-paragraph/slider-paragraph.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { TaskViewComponent } from './pages/task-view/task-view.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { TasksCreateComponent } from './pages/tasks-create/tasks-create.component';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TrendingComponent } from './components/trending/trending.component';
import { UpdateProfileComponent } from './pages/update-profile/update-profile.component';
import { UserUpdateComponent } from './pages/user-update/user-update.component';
import { UserViewComponent } from './pages/user-view/user-view.component';
import { UsersComponent } from './pages/users/users.component';
import { UsersCreateComponent } from './pages/users-create/users-create.component';
import { VerificationCodeComponent } from './pages/verification-code/verification-code.component';
import { ViewEmployeeComponent } from './pages/view-employee/view-employee.component';
import { ViewJobComponent } from './pages/view-job/view-job.component';
import { environment } from '../environments/environment';

// Your initialization function
export function initializeApp() {
  return () => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'ar');
    }
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const firebaseConfig = {
  apiKey: 'AIzaSyCycZq1olLfQGGa9rr_P8zoeu9-Ps--DHw',
  authDomain: 'qpedia-co.firebaseapp.com',
  databaseURL: 'https://qpedia-co.firebaseio.com',
  projectId: 'qpedia-co',
  storageBucket: 'qpedia-co.appspot.com',
  messagingSenderId: '797542914282',
  appId: '1:797542914282:web:7d4a38404574fd3b0f9f93',
  measurementId: 'G-2DD70VK070',
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    MainComponent,
    FrontCategoriesComponent,
    TrendingComponent,
    FooterComponent,
    HowWorksComponent,
    BlogComponent,
    PlaceGridComponent,
    CategoriesComponent,
    OtpVerificationComponent,
    CitiesComponent,
    BreadcrumbComponent,
    HeroSearchComponent,
    PricingComponent,
    ForgotPasswordComponent,
    LoginComponent,
    PlaceDetailsComponent,
    ProfileComponent,
    PlaceCreateComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    PlaceSquareComponent,
    FeatPostComponent,
    PlaceCircleComponent,
    PlacesComponent,
    ForgetPasswordComponent,
    VerificationCodeComponent,
    UpdateProfileComponent,
    ProjectCreateComponent,
    RequestsListComponent,
    TasksComponent,
    TasksCreateComponent,
    UsersComponent,
    UsersCreateComponent,
    RequestViewComponent,
    UserViewComponent,
    UserUpdateComponent,
    TaskViewComponent,
    EmployeeComponent,
    CreateEmployeeComponent,
    ViewEmployeeComponent,
    CompanyJobsComponent,
    AddJobsComponent,
    ViewJobComponent,
    RequestTypesComponent,
    CreateRequestTypesComponent,
    ProductCreateComponent,
    ServiceCreateComponent,
    ServicesComponent,
    ServiceItemComponent,
    PlaceViewComponent,
    RelatedContactComponent,
    NotificationsComponent,
    JobsComponent,
    ChatComponent,
    RecentsComponent,
    AllProductsComponent,
    AllServicesComponent,
    ServicesIndividualComponent,
    ServicesCorporateComponent,
    ClientsComponent,
    StatisticsComponent,
    TestimonialsComponent,
    HomeCategoriesComponent,
    HomeServicesComponent,
    Flipcards4Component,
    SliderParagraphComponent,
    ClientsSliderComponent,
    Flipcards3Component,
    IndividualFlipcards4Component,
    IndividualFlipcards3Component,
    IndividualSliderParagraphComponent,
    IndividualLoginComponent,
    CorporateLoginComponent,
    HomeMainComponent,
    HomeCategoryComponent,
    HomeServiceComponent,
    HomeRecentPlacesComponent,
    HomeProductsComponent,
    HomeProjectsComponent,
    HomeJobsComponent,
    LoginNewComponent,
    RegisterCorporateComponent,
    RegisterIndividualComponent,
    RegisterNewComponent,
    ChatQuickComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.baseConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
