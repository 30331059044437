import { Component, OnInit } from '@angular/core';
import { AddingMetaService } from 'src/app/Shared/Services/adding-meta.service';
import { HomeService } from 'src/app/Shared/Services/home.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  seo: any[] = [];
  config: any[] = [];

  homeData: any;

  currentLang;

  lang;

  constructor(
    private meta: AddingMetaService,
    private homeService: HomeService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });

    this.homeService.GetHomepageData(this.currentLang).subscribe((res: any) => {
      this.homeData = res.content;
    });

    this.getHomeData();
  }

  getHomeData() {
    this.homeService.getHomeData().subscribe((res: any) => {
      this.seo = res.content[0].seo;
      this.seo.forEach((element) => {
        if (element.title == 'Title') {
          this.config.push(element.keyword);
        }
        if (element.title == 'Description') {
          this.config.push(element.keyword);
        }
      });

      let seoConfig = {
        title: this.config[0],
        description: this.config[1],
        compTitle: this.config[0],
      };
      console.log('Config issss===>', seoConfig);
      this.meta.updateMeta(seoConfig);
    });
  }
}
