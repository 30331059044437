    <!-- Start Page Content -->
    <div class="container pg-content">
        <div class="pg-header-content">
            <h1 class="pg-title" *ngIf="!productId">
              {{'AddRelatedContact'|translate}}
            </h1>
            <h1 class="pg-title" *ngIf="productId">
                {{'EdditRelatedContact'|translate}}
              </h1>
          </div>
        
        <!--Start Contact Form -->
        <div class="col-sm-12 text-center">
   
          <form [formGroup]="contactForm" #myform="ngForm" (ngSubmit)="onSubmit()" id="projectForm" name="projectForm" class="form-horizontal contact-form">
                <!-- ٍselect-->
                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="fa fa-map-marker"></i></span>
                            <!-- <select  name="places" formControlName="cms_id"  id="places" class="form-control" required>
                                <option disabled selected value="">{{'Home.selectPlace' | translate}}</option>
                                <option  value={{item.id}} *ngFor="let item of contactlist"> {{item.title}} </option>
                            </select> -->

                            <ngx-select-dropdown
                                formControlName="cms_id"
                                [multiple]="false"
                                [config]="config"
                                [options]="contactlist"
                                [required]="true"
                                [(ngModel)]="val"
                            ></ngx-select-dropdown>
                        </div>
                        <p *ngIf="contactlist?.length == 0" class="text-danger">
                            {{'noPlace' | translate}}
                            <button (click)="gotoPlace()" class="btn btn-view">
                                <i class="fa fa-plus"></i>
                            </button>
                        </p>
                        <span *ngIf="!val && myform.submitted"
                            class="error-msg">
                            {{'place' | translate}} {{'required'|translate}}
                        </span>
                    </div>
                </div>

                <!-- Text input-->
                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="glyphicon glyphicon-pencil"></i></span>
                            <input formControlName="name"  name="title" id="title" placeholder="{{'relatedName'|translate}}" class="form-control"  required>
                        </div>
                        <span *ngIf="contactForm.controls['name']?.errors?.required && myform.submitted"
                            class="error-msg">
                            {{'relatedName'|translate}} {{'required'|translate}}
                        </span>
                    </div>
                </div>

                <!-- Text input-->
                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="glyphicon glyphicon-pencil"></i></span>
                            <input formControlName="position"  name="position" id="position" placeholder="{{'position'|translate}}" class="form-control"  required>
                        </div>
                        <span *ngIf="contactForm.controls['position']?.errors?.required && myform.submitted"
                            class="error-msg">
                            {{'position'|translate}} {{'required'|translate}}
                        </span>
                    </div>
                </div>

                <!-- Text input-->
                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="glyphicon glyphicon-pencil"></i></span>
                            <input formControlName="mobile"  name="mobile" id="mobile" placeholder="{{'phoneno'|translate}}" class="form-control"  required>
                        </div>
                        <span *ngIf="contactForm.controls['mobile']?.errors?.required && myform.submitted"
                            class="error-msg">
                            {{'phoneReqMes'|translate}}
                        </span>
                        <span *ngIf="contactForm.controls['mobile']?.errors?.pattern && myform.submitted"
                            class="error-msg">
                            {{'phonePatMes'|translate}}
                        </span>
                    </div>
                </div>

        
                <!-- Submit Button -->
                <div class="form-group sub">
                <div class="col-sm-12">
                    <button type="submit" name="name" class="btn btn-warning submit" >
                        <i *ngIf="isEnabled" class="fa fa-spinner fa-spin"></i> {{'Submit'|translate}}</button>
                </div>
                </div>
          </form>
        </div>
        <!--End Contact Form-->
      </div>
      <!-- End Page Content -->
    