<!-- Start Footer -->
<footer>
  <div class="footer-info">
    <div class="container-fluid">
      <div class="row black-bg">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <ul>
            <li>
              <a [routerLink]="['/', lang]" (click)="collapseNav()">{{
                "Home.home" | translate
              }}</a>
            </li>

            <li>
              <a [routerLink]="['about', lang]" (click)="collapseNav()">{{
                "Home.about" | translate
              }}</a>
            </li>

            <li *ngIf="authService.getToken()">
              <a [routerLink]="['my-profile', lang]" (click)="collapseNav()">{{
                "My Profile" | translate
              }}</a>
            </li>

            <li>
              <a [routerLink]="['categories', lang]" (click)="collapseNav()">{{
                "Home.categories" | translate
              }}</a>
            </li>

            <li>
              <a
                [routerLink]="['about/contact', lang]"
                (click)="collapseNav()"
                >{{ "Home.contact" | translate }}</a
              >
            </li>

            <li>
              <a
                [routerLink]="['privacy-policy', lang]"
                (click)="collapseNav()"
                >{{ "Home.Privacy Policy" | translate }}</a
              >
            </li>

            <li>
              <a
                [routerLink]="['terms-of-use', lang]"
                (click)="collapseNav()"
                >{{ "Home.Terms of Use" | translate }}</a
              >
            </li>
          </ul>
        </div>

        <div class="col-md-12 col-sm-12 col-xs-12 same-line">
          <div class="footer-contact-data">
            <i
              *ngIf="phone?.length > 0"
              class="fa fa-phone"
              aria-hidden="true"
            ></i>
            <span>
              {{ phone }}
            </span>
          </div>

          <div><span class="stick"> | </span></div>

          <div class="social-networks">
            <a
              *ngIf="Facebook_link"
              [href]="Facebook_link"
              class="fa fa-facebook"
              target="_blank"
            ></a>
            <a
              *ngIf="Twitter_Link"
              [href]="Twitter_Link"
              class="fa fa-twitter"
              target="_blank"
            ></a>
            <a
              *ngIf="Instagram_link"
              [href]="Instagram_link"
              class="fa fa-instagram"
              target="_blank"
            ></a>
            <a
              *ngIf="Paintrest_Link"
              [href]="Paintrest_Link"
              class="fa fa-pinterest"
              target="_blank"
            ></a>
            <a
              *ngIf="linkedin_link"
              [href]="linkedin_link"
              class="fa fa-linkedin"
              target="_blank"
            ></a>
          </div>
        </div>
      </div>

      <div class="row grey-bg2">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="copyright">
            <a href="https://pentavalue.com/en">
              {{ "Home.pentavalue" | translate }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- End Footer -->
