import { Component, OnInit } from '@angular/core';
import { UserService } from '../../Shared/Services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../Shared/Services/authentication.service';
import { CitiesService } from '../../Shared/Services/cities.service';
import Swal from 'sweetalert2';
import { LanguageService } from 'src/app/Shared/Services/language.service';
import { DatePipe } from '@angular/common';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { query } from '@angular/animations';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { AddingMetaService } from 'src/app/Shared/Services/adding-meta.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  registerForm: FormGroup;
  codelist;
  lang
  currentlang: string;
  remembercheck = false;
  date;
  searchText = '';
  term: string;
  services: any[] = [];
  cities: any[] = [];
  errors: any[] = [];
  errorList;
  isRegistered: boolean = false;
  isEnabled: boolean = false;
  isUser: boolean = false;
  isHidden: boolean = true;
  hidden: boolean = true;
  isLogged: boolean = false;
  isChoosed: boolean = false;
  istoRegistere: boolean = false;
  isLoginValid: boolean = false;
  isRegisterValid: boolean = false;
  selectedCityCode: string;
  phoneValid: string = '';

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Egypt, CountryISO.SaudiArabia];
  LoginRoleId = 3;
  RegisterRoleId = 3;
  show: boolean = true;
  offset = 0;
  index = 1;
  value: any;
  lang_id;

  constructor(private userservice: UserService,
    private translateservice: TranslateService,
    private toastrService: ToastrService,
    private authservice: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cityservices: CitiesService,
    public translate: TranslateService,
    private languageService: LanguageService,
    public datePipe: DatePipe,
    private httpClientService: HttpClientService,
    private meta: AddingMetaService
  ) {
    this.cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' }
    ];
    this.getCountryTelephoneCode();

  }

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    if (this.lang == 'en') {
      this.lang_id = 1;
    } else {
      this.lang_id = 2;
    }
    this.initForm();
    this.initRegisterForm();
    this.getCountryTelephoneCode();
    this.currentlang = localStorage.getItem('lang');

    this.getServices(1, this.lang_id);
    this.meta.updateMeta({
      compTitle: 'Login/Register'
    });

  }

  selectUser() {
    this.isUser = true;
    this.isHidden = false;
    this.istoRegistere = true;
  }

  selectCompany() {
    this.isUser = false;
    this.isHidden = false;
    this.istoRegistere = true;
  }

  loginUser() {
    this.isLogged = true;
    this.hidden = false;
    this.isChoosed = true;
  }

  loginCompany() {
    this.isLogged = false;
    this.hidden = false;
    this.isChoosed = true;
  }


  initForm() {

    this.loginForm = new FormGroup({
      mobile: new FormControl('', [Validators.required]),
      tele_code: new FormControl(''),
      password: new FormControl('', [Validators.required, Validators.minLength(8)])
    })
  }


  login() {
    this.isEnabled = true;
    this.phoneValid = this.loginForm.controls.mobile.status;
    console.log('Login value ', this.loginForm.controls.mobile.status);
    console.log(this.loginForm)
    this.isLoginValid = this.validateMobile(this.loginForm.value.mobile);
    console.log(this.isLoginValid)
    if (this.loginForm.valid) {
      let user;
      if (this.LoginRoleId == 1) {
        user = {
          mobile: this.loginForm.value.mobile.number,
          tele_code: this.loginForm.value.mobile.dialCode,
          password: this.loginForm.value.password,
          rule_id: 5
        }
      } else {
        user = {
          mobile: this.loginForm.value.mobile.number,
          tele_code: this.loginForm.value.mobile.dialCode,
          password: this.loginForm.value.password,
          rule_id: 3
        }
      }

      console.log('user is:- ', user);
      if (this.loginForm.value.mobile.dialCode == "+20" && (this.loginForm.value.mobile.number?.length < 10 || this.loginForm.value.mobile.number?.length > 11)) {
        console.log('Wrong Egyptian Number');
        this.isEnabled = false;
      } else if (this.loginForm.value.mobile.dialCode == "+966" && (this.loginForm.value.mobile.number?.length < 8 || this.loginForm.value.mobile.number?.length > 9)) {
        console.log('Wrong KSA Number');
        this.isEnabled = false;
      } else {
        this.userservice.login(user).subscribe((res: any) => {
          if (res.status.code === 200) {

            this.isEnabled = false;
            if (res.content.related_company != null) {
              this.authservice.sethasTasks(res.content.related_company.requests ? '1' : '0');
            }
            if (res.content.attached_cv != null) {
              localStorage.setItem('hasCv', '1');
            }
            // console.log("The User Id :-",localStorage.getItem('UserID'))
            if ((res.content.is_mobile_verified == 1 && res.content.user_type == 1) || (res.content.user_type == 3 || res.content.user_type == 2)) {
              console.log('Current lang', this.currentlang);
              this.userservice.handleResponse(res);
              console.log("The Response of login API:-", res)
              this.authservice.setToken(res.content.api_token);
              localStorage.setItem('userType', res.content.user_type);
              this.languageService.changedUser.emit(res.content.user_type);
              localStorage.setItem('UserID', res.content.id);
              this.router.navigate(['', this.currentlang]);
            } else if (res.content.is_mobile_verified == 0 && res.content.user_type == 1 && res.content.is_active == 1) {
              if (this.currentlang == 'en') {
                this.toastrService.error("Your Mobile needs to be verified");
              } else {
                this.toastrService.error("رقم الهاتف يحتاج الى التفعيل");
              }
              localStorage.setItem('mobile', this.loginForm.value.mobile.number);
              localStorage.setItem('telecode', this.loginForm.value.mobile.dialCode);
              this.router.navigate(['verification-code', this.currentlang]);

            } else if (res.content.is_mobile_verified == 0 && res.content.user_type == 1 && res.content.is_active == 0) {
              if (this.currentlang == 'en') {
                this.toastrService.error("Your Account is in Active");
              } else {
                this.toastrService.error("حسابك غير نشط");
              }
            }

          }
          else if (res.status.code === 403) {
            this.isEnabled = false;
            let validation_errors = res.status.validation_errors;
            validation_errors.forEach(element => {
              if (element.field == 'email') { this.toastrService.error(element.message) }
              else if (element.field == 'password') { this.toastrService.error(element.message) }
              else if (element.field == "mobile") { this.toastrService.error(element.message) }


            })

          }

          else {
            this.isEnabled = false;
            this.toastrService.error(res.status.error_details)
          }


        }, error => {
          this.isEnabled = false;
          console.log(error.error.errors)
          if (error.error.errors.email) {
            this.toastrService.error(error.error.errors.email[0]); // toasr services
          }
          else if (error.error.errors.password) {
            this.toastrService.error(error.error.errors.password[0]); // toasr services
          }
        }
        )
      }

    } else {
      this.isEnabled = false;
    }
  }

  initRegisterForm() {
    this.registerForm = new FormGroup({
      full_name: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      email: new FormControl('', [Validators.required, Validators.email]),

      tele_code: new FormControl(''),
      mobile: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),

      birthdate: new FormControl(''),
      gender_id: new FormControl(''),


      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirm_password: new FormControl('', [Validators.required, Validators.minLength(8)]),

      facebook: new FormControl('', [Validators.pattern('^.*(?:facebook\.com|fb\.me).*$')]),
      twitter: new FormControl('', [Validators.pattern('^.*(?:twitter\.com).*$')]),
      instagram: new FormControl('', [Validators.pattern('^.*(?:instagram\.com).*$')]),
      linkedin: new FormControl('', [Validators.pattern('^.*(?:linkedin\.com).*$')]),
      services: new FormControl('')



    })
  }

  register() {
    this.isRegistered = true;
    // console.log("registration form => ",this.registerForm.value)
    // if(this.registerForm.valid){
    localStorage.setItem('telecoderegister', this.registerForm.value['tele_code'])
    this.isRegisterValid = this.validateMobile(this.registerForm.value.mobile);
    let servicess: any[] = [];
    if (this.registerForm.value.services != []) {
      this.registerForm.value.services.forEach(ele => {
        servicess.push(ele.id);
      });
    }

    if (this.registerForm.value['birthdate']) {
      this.date = this.datePipe.transform(this.registerForm.value['birthdate'], 'yyyy-MM-dd')
    }


    let submitobj;

    if (this.RegisterRoleId == 1) {
      submitobj = {
        "full_name": this.registerForm.value['full_name'],
        "email": this.registerForm.value['email'],
        "rule_id": "5",
        // "tele_code":this.updateprofileform.value['tele_code'],
        "mobile": this.registerForm.value.mobile.number,
        "password": this.registerForm.value['password'],
        "confirm_password": this.registerForm.value['confirm_password'],
        "user_type": 1,
        "tele_code": this.registerForm.value.mobile.dialCode
      }
    } else {
      submitobj = {
        "full_name": this.registerForm.value['full_name'],
        "email": this.registerForm.value['email'],
        "rule_id": "3",
        // "tele_code":this.updateprofileform.value['tele_code'],
        "mobile": this.registerForm.value.mobile.number,
        "birthdate": this.date,
        "gender_id": this.registerForm.value['gender_id'],
        "password": this.registerForm.value['password'],
        "confirm_password": this.registerForm.value['confirm_password'],
        "facebook": this.registerForm.value['facebook'],
        "twitter": this.registerForm.value['twitter'],
        "instagram": this.registerForm.value['instagram'],
        "linkedin": this.registerForm.value['linkedin'],
        "services": servicess,
        "user_type": 3,
        "tele_code": this.registerForm.value.mobile.dialCode
      }
    }

    // console.log("obj :-",submitobj);


    this.userservice.registeruser(submitobj).subscribe((res: any) => {
    // console.log ("Date format :- ",submitobj.birthdate)
      if (res.status.code === 200) {
        this.isRegistered = false;
        if (localStorage.getItem('lang') == 'en') {
          Swal.fire({
            icon: 'success',
            title: 'You registered successfully !',
            showConfirmButton: false,
            timer: 1500

          });
        }
        else if (localStorage.getItem('lang') == 'ar') {

          Swal.fire({
            icon: 'success',
            title: '!تم التسجيل بنجاح',
            showConfirmButton: false,
            timer: 1500

          });
        }

        if (res.content.user_type != 3) {
          Swal.fire({
            icon: 'warning',
            title: this.translate.instant('needverify'),
            showConfirmButton: false,
            timer: 1500

          });

          localStorage.setItem('mobile', this.registerForm.value.mobile.number);
          localStorage.setItem('telecode', this.registerForm.value.mobile.dialCode);
          this.router.navigate(['verification-code', this.currentlang]);
        } else {
          this.router.navigate(['', this.currentlang]);
        }




      }
      else if (res.status.code === 401) {
        this.errors = res.status.validation_errors;
        //  this.toastrService.error('Error',this.errors.toString())
        this.errorList = {};
        this.errors.forEach(element => {
          this.errorList[element.field] = element.message;
          this.toastrService.error('Error', element.message);
          this.isRegistered = false;
        });


      }

      else {
        this.toastrService.error('Error', res.status.error_details);
        this.isRegistered = false;
      }


    },
      error => {
        this.isRegistered = false;
        console.log('ERROR', error.error.errors)
        this.toastrService.error(error.error.errors); // toasr services

        if (error.validation_errors.field == 'email') {
          this.toastrService.error(error.validation_errors.message); // toasr services
        }
        else if (error.validation_errors.field == 'mobile') {
          this.toastrService.error(error.validation_errors.message); // toasr services
        }

      }
    )
    // }else{
    // this.isRegistered = false;
    // }
  }


  getCountryTelephoneCode() {
    this.cityservices.GetCountryCodeList().subscribe((res: any) => {
      this.codelist = res.content;
      console.log(this.codelist)
    })
  }

  onSearchChange(searchValue: string): void {
    console.log(searchValue);



  }
  onchange(id) {
    console.log("change :- ", id);

  }

  RememberMe(event) {
    if (event.target.checked) {
      localStorage.setItem('UserEmail', this.loginForm.value['email'])
      localStorage.setItem('UserPassword', this.loginForm.value['password'])
    }
    else {
      localStorage.removeItem('UserEmail');
      localStorage.removeItem('UserPassword')

    }
  }

  getServices(page: number, lang_id) {
    this.cityservices.getServices(page, lang_id).subscribe(
      (res: any) => {
        this.services = res['content'];
      },
      error => {
        console.log(error);
      }
    );
  }

  validateMobile(phone) {
    let EGYPT_REGEX = /^01[0-2,5]\d{1,8}$/; // Regular Expression 1
    let KSA_REGEX = /^[0-9]\d{1,7}$/;         // Regular Expression 2

    return ((EGYPT_REGEX.test(phone?.nationalNumber) && phone.dialCode == "+20") || (KSA_REGEX.test(phone?.nationalNumber) && phone.dialCode == "+966")) ? true : false;
  }

  onLoginItemChange(event) {
    console.log(event);
    this.LoginRoleId = event;
  }

  onRegisterItemChange(event) {
    console.log(event);
    this.RegisterRoleId = event;
    if (this.RegisterRoleId == 1) {
      this.show = false;
    } else {
      this.show = true;
    }
  }

  getNextBatch() {
    this.index = this.index + 1;
    this.cityservices.getServices(this.index, this.lang_id).subscribe(
      (res: any[]) => {
        res['content'].forEach(element => {
          this.services.push(element);
        });
      },
      error => {
        console.log(error);
      }
    );
  }

  onKey(event: string) {
    console.log('Eveeeeeent', event);
    console.log(event.search(' '));
    if (event.length >= 3) {
      this.httpClientService.get('api/services', { 'name': event }, {}).subscribe(
        res => {
          console.log("The Response:- ", res);
          this.services = res['content'];
        }
      );
    }
  }

}
