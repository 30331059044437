import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../Services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, tap } from 'rxjs/operators';
import {
  makeStateKey,
  StateKey,
  TransferState,
} from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

const AUTH_URLS = [
  'api/statistics/profile',
  'api/cms/store',
  'api/profile/update',
  'api/contacts_projects/store',
  'api/negotiations/received',
  'api/notifications',
  'api/negotiations',
  'api/notifications/read',
  'api/requests/update',
  'api/negotiations/sent',
  'api/negotiations/close',
  'api/negotiations/update',
  'api/employees',
  'api/company_users/send',
  'api/employees/store',
  'api/users',
  'api/users/show',
  'api/users/update',
  'api/request_types',
  'api/requests/store',
  'api/users/destroy',
  'api/requests',
  'api/requests/show',
  'api/requests/statuses',
  'api/company_jobs',
  'api/company_jobs/show',
  'api/company_jobs/destroy',
  'api/contacts_products/store',
  'api/contacts_services/store',
  'api/company_jobs/destroyall',
  'api/items/store',
  'api/contacts_services/update',
  'api/cms/destroy',
  'api/cms/update',
  'api/contacts_projects/destroy',
  'api/contacts_products/destroy',
  'api/contacts_projects/update',
  'api/contacts_products/update',
  'api/items',
  'api/contacts_services/destroy',
  'api/cms/edit_related_contact',
  'api/cms/add_related_contact',
  'api/cms/delete_related_contact',
  'api/company_users/store',
  'api/contacts_question/store',
  'api/contacts_question',
  'api/negotiations/cancel',
  'api/company_jobs',
  'api/validation',
  'api/contacts_question/list',
  'api/contacts_question/show',
  'api/contacts_question/answers/store',
  'api/negotiations/show',
  'api/negotiations/store',
];

const negotiations_urls = ['api/negotiations/list'];

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  count: number = 0;
  constructor(
    private toastrService: ToastrService,
    private translate: TranslateService,
    private authservice: AuthenticationService,
    private spinner: NgxSpinnerService,
    private transfareState: TransferState,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // exclude translations get requests
    console.log('REQ', req);
    if (
      req.url == 'https://api.qpedia.com/public/api/cms' ||
      req.url == 'https://api.qpedia.com/public/api/services' ||
      req.url == 'https://api.qpedia.com/public/api/items'
    ) {
      this.spinner.hide();
      return next.handle(req);
    }

    const key: StateKey<string> = makeStateKey<string>(req.url);
    if (this.transfareState.hasKey(key)) {
      let res = this.transfareState.get(key, {});
      this.transfareState.remove(key);
      if (res) {
        return of(
          new HttpResponse({
            body: res,
            status: 200,
          })
        );
      }
    }
    this.spinner.show();
    this.count++;
    if (req.url.indexOf('i18n') <= -1) {
      let headers = req.headers.set(
        'X-localization',
        this.translate.currentLang
      );
      AUTH_URLS.forEach((URL) => {
        if (
          req.url.indexOf(URL) > -1 &&
          req.url !== 'https://api.qpedia.com/public/api/company_jobs/all' &&
          req.url !== 'https://api.qpedia.com/public/api/company_jobs' &&
          req.url !== 'https://api.qpedia.com/public/api/validation'
        ) {
          headers = headers.set('access-token', localStorage.getItem('token'));
        }
      });

      const authReq = req.clone({ headers });
      return next.handle(authReq).pipe(
        tap(
          (event: any) => {
            // console.log(event);
            if (isPlatformServer(this.platformId)) {
              if (
                event instanceof HttpResponse &&
                (event.status == 200 || event.status == 202)
              ) {
                this.transfareState.set(key, event.body);
              }
            }
          },

          (error: any) => {
            console.log('th error from interceptor:::::', error.error);
            if (error?.error?.status == 200) {
              // this.toastrService.success(error.error.status.message);
            } else if (error?.error?.status == 403) {
              console.log(
                '403 error from interceptor file :::::::',
                error.error.status.error_details
              );
            }
          }
        ),
        finalize(() => {
          this.count--;

          if (this.count == 0) this.spinner.hide();
        })
      );
    } else {
      return next.handle(req).pipe(
        tap(
          (event: any) => {
            // console.log(event);
            if (isPlatformServer(this.platformId)) {
              if (
                event instanceof HttpResponse &&
                (event.status == 200 || event.status == 202)
              ) {
                this.transfareState.set(key, event.body);
              }
            }
          },

          (error: any) => {
            console.log('th error from interceptor:::::', error.error);
            if (error?.error?.status == 200) {
              // this.toastrService.success(error.error.status.message);
            } else if (error?.error?.status == 403) {
              // this.toastrService.error(error.error.status.error_details);
              console.log(
                '403 error from interceptor file :::::::',
                error.error.status.error_details
              );
            }
          }
        ),
        finalize(() => {
          this.count--;

          if (this.count == 0) this.spinner.hide();
        })
      );
    }
  }
}
