import { Component, OnInit, Input } from '@angular/core';
import { HomeService } from '../../Shared/Services/home.service';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-home-service',
  templateUrl: './home-service.component.html',
  styleUrls: ['./home-service.component.scss'],
})
export class HomeServiceComponent implements OnInit {
  @Input() servicesList: any[];

  CategoryData: any[] = [];

  searchQuery: string;
  searchRes: any[] = [];
  services: any[];
  contacts: any[];
  jobs: any[];
  companies: any[];
  products: any[];
  projects: any[];
  searchSubscription: any;
  isVisible: boolean = false;
  display = false;
  showSpin: boolean = false;

  lang;

  constructor(
    private homeservice: HomeService,
    private router: Router,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
  }

  navigateplacedetails(item) {
    this.router.navigate(['../place-details', this.lang], {
      queryParams: {
        id: item.contact_id,
      },
    });
  }

  getCategoriesList() {
    this.homeservice.GetCategoryList().subscribe((res: any) => {
      this.CategoryData = res.content;
    });
  }

  search(query: string) {
    if (query) {
      if (query.length >= 3) {
        this.showSpin = true;
        if (this.searchSubscription) {
          this.searchSubscription.unsubscribe();
        }
        this.homeservice.globalSearch(query).subscribe((res: any) => {
          if (res['content'].contacts.length > 0) {
            res['content'].contacts.forEach((element) => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'contacts',
              };
              this.searchRes.push(obj);
            });
          }

          if (res['content'].services.length > 0) {
            res['content'].services.forEach((element) => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'services',
              };
              this.searchRes.push(obj);
            });
          }

          if (res['content'].jobs.length > 0) {
            res['content'].jobs.forEach((element) => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'jobs',
              };
              this.searchRes.push(obj);
            });
          }

          if (res['content'].companies.length > 0) {
            res['content'].companies.forEach((element) => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'companies',
              };
              this.searchRes.push(obj);
            });
          }

          if (res['content'].products.length > 0) {
            res['content'].products.forEach((element) => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'products',
              };
              this.searchRes.push(obj);
            });
          }

          if (res['content'].projects.length > 0) {
            res['content'].projects.forEach((element) => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'projects',
              };
              this.searchRes.push(obj);
            });
          }
          console.log('Search Results=========>', this.searchRes);
          if (this.searchRes.length == 0) {
            this.isVisible = false;
          } else {
            this.isVisible = true;
          }
          this.showSpin = false;
        });
      }
    } else {
      this.isVisible = true;
      this.searchRes = [];
      this.showSpin = false;
      return;
    }
  }

  SearchSubmit(query) {
    if (query) {
      if (query.length >= 3) {
        this.showSpin = true;
        if (this.searchSubscription) {
          this.searchSubscription.unsubscribe();
        }
        this.homeservice.globalSearch(query).subscribe((res: any) => {
          if (res['content'].contacts.length > 0) {
            res['content'].contacts.forEach((element) => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'contacts',
              };
              this.searchRes.push(obj);
            });
          }

          if (res['content'].services.length > 0) {
            res['content'].services.forEach((element) => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'services',
              };
              this.searchRes.push(obj);
            });
          }

          if (res['content'].jobs.length > 0) {
            res['content'].jobs.forEach((element) => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'jobs',
              };
              this.searchRes.push(obj);
            });
          }

          if (res['content'].companies.length > 0) {
            res['content'].companies.forEach((element) => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'companies',
              };
              this.searchRes.push(obj);
            });
          }

          if (res['content'].products.length > 0) {
            res['content'].products.forEach((element) => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'products',
              };
              this.searchRes.push(obj);
            });
          }

          if (res['content'].projects.length > 0) {
            res['content'].projects.forEach((element) => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'projects',
              };
              this.searchRes.push(obj);
            });
          }
          console.log('Search Results=========>', this.searchRes);
          if (this.searchRes.length == 0) {
            this.isVisible = false;
          } else {
            this.isVisible = true;
          }
          this.showSpin = false;
        });
      } else {
        this.isVisible = true;
        this.searchRes = [];
        this.showSpin = false;
        return;
      }
    } else {
      this.isVisible = true;
      this.searchRes = [];
      this.showSpin = false;
      return;
    }
  }

  MenuHide() {
    document.getElementById('search_id2').classList.toggle('hide_div');
  }

  navigateplaces(item) {
    this.router.navigate(['categories/places', this.lang], {
      queryParams: {
        category_value_id: item.id,
      },
    });
  }

  navigateplace(item) {
    this.router.navigate(['../place-details', this.lang], {
      queryParams: {
        id: item.id,
      },
    });
  }
  // showSection(){
  //   this.display = true;
  // }

  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }
}
