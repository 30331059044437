import { Component, OnInit, HostListener } from '@angular/core';
import { PlacesService } from '../../Shared/Services/places.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CitiesService } from '../../Shared/Services/cities.service';
import { CategoriesService } from '../../Shared/Services/categories.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';
import { Title } from '@angular/platform-browser';
import { AddingMetaService } from 'src/app/Shared/Services/adding-meta.service';
// import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module


declare var $: any;



@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.scss']
})
export class PlacesComponent implements OnInit {
  totalItems: number;
  places: any[] = [];
  CitiesData;
  CategoriesData;
  TagsData;
  listArray = [];
  emptylistArray: any[] = [];
  nextpageparam;
  cityID;
  pageSize: number;
  nextPage: number;
  totalPages: number;
  p: number = 1;
  totalapi: number;
  nextpagecity: number;
  config: any;
  totalCount: number;
  perPage: number;
  page = 1;
  lang: string = '';
  userType: number;
  hasTasks: number;
  isVerified: boolean = false;
  catId: number;
  val;
  isSelected: any;
  tagId: any;

  constructor(private placeservice: PlacesService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cityservice: CitiesService,
    private languageService: LanguageService,
    private catservice: CategoriesService,
    private meta: AddingMetaService) { }

  ngOnInit(): void {
    // this.totalItems = 1;
    this.activatedRoute.queryParams.subscribe(
      res => {
        this.catId = +res['category_value_id'];
        this.cityID = +res['city_id'];
        this.tagId = +res['tag_id'];
        if (this.cityID) {
          console.log('CatID', this.cityID);
          this.GetPlacesByinitcity();
        }

        // if(this.tagId){
        //   this.getTagsvalues('', this.tagId);
        // }

      }
    );
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.userType = +localStorage.getItem('userType');
    this.hasTasks = +localStorage.getItem('hasTasks');
    this.languageService.currentLang.subscribe(
      res => {
        this.lang = res;
      }
    );
    console.log("total number of pages", this.totalItems)

    // this.GetPlacesofCity();

      this.totalItems = 1;
      this.GetplacesOfCategory(event);
      this.getCitiesList();
      this.getAllCategories();
      this.getTagsList();
    this.GetPlacesByinitcity();

    this.config = {
      id: 'placesID',
      itemsPerPage: this.perPage,
      currentPage: this.page,
      totalItems: this.totalCount
    };

    this.meta.updateMeta({
      compTitle: 'Categories'
    });

  }

  getEvent(event) {
    console.log('Event is:- ', event);
    let isChecked: number;
    if (event == 'A') {
      isChecked = 1;
    } else {
      isChecked = 0;
    }
    this.placeservice.getVerifiedPlaces(1, isChecked, this.catId).subscribe(
      res => {
        this.places = res['content'];
        this.totalCount = res['pagination'].total;
        this.perPage = res['pagination'].per_page;
        this.p = res['pagination'].current_page;
        this.updateConfig();
      }
    );
  }


  getCitiesList() {
    this.cityservice.GetCityList().subscribe((res: any) => {
      this.CitiesData = res.content;
    })
  }

  getAllCategories() {
    this.catservice.GetCategoryList().subscribe((res: any) => {
      this.CategoriesData = res.content;
    })
  }

  navigateplace(item) {
    this.router.navigate(['../place-details', this.lang], {
      queryParams: {
        id: item.id
      }
    })

  }

  getTagsList() {
    this.placeservice.GetKeywordList().subscribe((res: any) => {
      this.TagsData = res.content;
      /* this.totalapi = res.pagination.total;
      this.pageSize = res.pagination.per_page;
      this.p = res.pagination.current_page */
      // console.log("Tags Data======>",this.TagsData)
    })
  }


  loadMore(event) {
    console.log(event)
    var rect = document.getElementById('anchor').getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    // Only completely visible elements return true:
    // Partially visible elements return true:
    var isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    if (isVisible) {
      this.GetplacesOfCategory(event)
    }
  }


  GetPlacesByinitcity() {

    if (this.activatedRoute.snapshot.queryParams['city_id']) {
      this.cityID = this.activatedRoute.snapshot.queryParams['city_id'];
      this.val = this.cityID;
      this.placeservice.GetPlacesListByCity(+this.activatedRoute.snapshot.queryParams['city_id'], 0, 1).subscribe((res: any) => {
        // this.totalPages = res.pagination.last_page;
        // this.nextPage = res.pagination.current_page + 1 < this.totalPages ? res.pagination.current_page + 1 : this.totalPages;
        // this.places = this.places.concat(res.content);
        this.places = res.content;
        this.totalapi = res.pagination.total;
        this.pageSize = res.pagination.per_page;
        this.p = res.pagination.current_page
        this.totalCount = res.pagination.total;
        this.perPage = res.pagination.per_page;
        this.updateConfig();
      })
    }
  }


  getTagsvalues(event, tagID) {

    if (document.getElementById(`tagID_${tagID}`).classList.contains('activetag')) {

      document.getElementById(`tagID_${tagID}`).classList.remove('activetag');

      this.listArray.forEach((element, i) => {
        if (element == tagID) {
          this.listArray.splice(i, 1);
        }
        console.log("Tags array after remove=============>", this.listArray)


      });

    }
    else {
      document.getElementById(`tagID_${tagID}`).classList.add('activetag');
      this.listArray.push(tagID);
      this.GetPlacesofTag(this.listArray);
      console.log("Tags array=============>", this.listArray);
    }






  }

  GetplacesOfCategory(pageeventnumber) {
    if (this.activatedRoute.snapshot.queryParams['category_value_id']) {
      this.nextPage = pageeventnumber ? pageeventnumber : 1;

      this.placeservice.GetPlacesList(this.activatedRoute.snapshot.queryParams['category_value_id'], this.nextPage).subscribe((res: any) => {
        // this.totalPages = res.pagination.last_page;
        // this.nextPage = res.pagination.current_page + 1 < this.totalPages ? res.pagination.current_page + 1 : this.totalPages;
        // this.places = this.places.concat(res.content);
        this.places = res.content;
        this.isVerified = res.content.verified;
        // this.total = res.pagination.last_page;
        this.totalapi = res.pagination.total;
        this.pageSize = res.pagination.per_page;
        this.p = res.pagination.current_page;
        this.totalCount = res.pagination.total;
        this.perPage = res.pagination.per_page;
        this.updateConfig();
        //  console.log ("Total No. Of Pages From API =============>",this.totalapi)
        //  console.log ("The Current Page Is ==========>",this.p)
        //  console.log ("The Pagesize from API =============>",this.pageSize)

      })
    }
  }

  updateConfig() {
    this.config = {
      id: 'placesID',
      itemsPerPage: this.perPage,
      currentPage: this.page,
      totalItems: this.totalCount
    };
  }

  GetPlacesofTag(listArray) {
    let cat_id: any;
    if (this.activatedRoute.snapshot.queryParams['category_value_id']) {
      cat_id = this.activatedRoute.snapshot.queryParams['category_value_id'];
    } else {
      cat_id = 0;
    }
    console.log("array of city", this.activatedRoute.snapshot.queryParams['category_value_id'])

    this.nextPage = this.nextpagecity;
    this.placeservice.GetPlacesListByTag(this.nextPage, listArray, cat_id).subscribe((res: any) => {
      this.places = res.content;
      console.log("Places of selected Tag", this.places)
      // console.log("Places Response==========>",res)
    })
  }

  GetPlacesofCity(id) {
    // this.nextPage = this.nextpagecity;
    // console.log(id)
    let cat_id: any;
    if (this.activatedRoute.snapshot.queryParams['category_value_id']) {
      cat_id = this.activatedRoute.snapshot.queryParams['category_value_id'];
    } else {
      cat_id = 0;
    }
    this.placeservice.GetPlacesListByCity(id, cat_id, this.nextPage).subscribe((res: any) => {
      this.places = res.content;
      this.totalapi = res.pagination.total;
      this.pageSize = res.pagination.per_page;
      this.p = res.pagination.current_page;
      this.cityID = id;
      this.totalCount = res.pagination.total;
      this.perPage = res.pagination.per_page;
      this.updateConfig();

    })
  }

  pageChanged(pageeventnumber: any): void {
    // console.log('Page changed to: ' + event.page);
    // console.log('Number items per page: ' + event.itemsPerPage);
    this.page = pageeventnumber;
    console.log("pagination event", pageeventnumber)

    // this.GetplacesOfCategory(pageeventnumber);
    // this.GetPlacesofTag(this.listArray);
    this.nextPage = pageeventnumber;
    this.GetPlacesofCity(this.cityID);

  }

  CategoryFilterFunction(id) {
    console.log("Event ", id)
    this.nextPage = 1;
    this.placeservice.GetPlacesList(id, this.nextPage).subscribe((res: any) => {
      this.places = res.content;
      this.totalapi = res.pagination.total;
      this.pageSize = res.pagination.per_page;
      this.p = res.pagination.current_page;
      // console.log ("category id ====>",id)
      // console.log(res)
    })
  }

  addTask(id) {
    this.router.navigate(['tasks-create', this.lang], { queryParams: { 'id': id } });
  }

  addQuestion(id) {
    this.router.navigate(['chat', this.lang]);
  }
}
