
<div class="container-fluid pg-header" style="background-image: url(././assets/images/11.jpg);">
    
    <div class="pg-header-content">
      <h1 class="pg-title">
        {{'Home.cities'|translate}}
      </h1>
      <h4 class="pg-subtitle">
        {{'cat_title'|translate}}
      </h4>
    </div>
    <!-- <div class="pg-header-icon icon-map-marker"></div> -->
  </div>


  <!-- Start Page Intro -->
  <!-- <div class="container page-intro">
    Choose a city to check local listings
  </div> --> 
  <!-- End Page Intro -->
  <!-- Start City Listing -->
  <div class="container-fluid pg-content cities text-center">
    <div class="row">
      <div class="col-sm-6" *ngFor="let item of CitiesData  | paginate: { itemsPerPage: 10, currentPage: p }">
        <a  (click)="navigateplaces(item)"  [style.backgroundImage]="'url(' + item.photo + ')'">
          <div class="city-data">
            <div class="centralizer">
              <div class="city-name">
                {{item.name}}
              </div>
              <div class="ribbon">{{item.contacts_count}} {{'places'| translate}}</div>
            </div>
          </div>
        </a>
      </div>

      <!-- <div class="col-sm-6">
        <a routerLink="Categories" href="#" style="background-image:url(./assets/images/cities/2.jpg)">
          <div class="city-data">
            <div class="centralizer">
              <div class="city-name">
                Chicago
              </div>
              <div class="ribbon">63 Listings</div>
            </div>
          </div>
        </a>
      </div> -->

      <!-- <div class="col-sm-6">
        <a routerLink="Categories" href="#" style="background-image:url(./assets/images/cities/3.jpg)">
          <div class="city-data">
            <div class="centralizer">
              <div class="city-name">
                Miami
              </div>
              <div class="ribbon">28 Listings</div>
            </div>
          </div>
        </a>
      </div> -->

      <!-- <div class="col-sm-6">
        <a routerLink="Categories" href="#" style="background-image:url(./assets/images/cities/4.jpg)">
          <div class="city-data">
            <div class="centralizer">
              <div class="city-name">
                New Orleans
              </div>
              <div class="ribbon">19 Listings</div>
            </div>
          </div>
        </a>
      </div> -->

      <!-- <div class="col-sm-6">
        <a routerLink="Categories" href="#" style="background-image:url(./assets/images/cities/5.jpg)">
          <div class="city-data">
            <div class="centralizer">
              <div class="city-name">
                New York City
              </div>
              <div class="ribbon">41 Listings</div>
            </div>
          </div>
        </a>
      </div> -->

      <!-- <div class="col-sm-6">
        <a routerLink="Categories" href="#" style="background-image:url(./assets/images/cities/6.jpg)">
          <div class="city-data">
            <div class="centralizer">
              <div class="city-name">
                Orlando
              </div>
              <div class="ribbon">112 Listings</div>
            </div>
          </div>
        </a>
      </div> -->

      <!-- <div class="col-sm-6">
        <a routerLink="Categories" href="#" style="background-image:url(./assets/images/cities/7.jpg)">
          <div class="city-data">
            <div class="centralizer">
              <div class="city-name">
                San Francisco
              </div>
              <div class="ribbon">22 Listings</div>
            </div>
          </div>
        </a>
      </div> -->

      <!-- <div class="col-sm-6">
        <a routerLink="Categories" href="#" style="background-image:url(./assets/images/cities/8.jpg)">
          <div class="city-data">
            <div class="centralizer">
              <div class="city-name">
                Washington
              </div>
              <div class="ribbon">69 Listings</div>
            </div>
          </div>
        </a>
      </div> -->

    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12">
        <pagination-controls 
                   (pageChange)="p = $event"
                   previousLabel="{{'previous'|translate}}"
                   nextLabel="{{'next'|translate}}">
        </pagination-controls>

      </div>
    </div>
  </div>
  <!-- End City Listing -->

