import { Component, OnInit } from '@angular/core';
import { CategoriesService } from '../../Shared/Services/categories.service';
import { HomeService } from '../../Shared/Services/home.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { element } from 'protractor';

@Component({
  selector: 'app-home-categories',
  templateUrl: './home-categories.component.html',
  styleUrls: ['./home-categories.component.scss']
})
export class HomeCategoriesComponent implements OnInit {

  CategoryData: any[] = [];

  searchQuery: string;
  searchRes: any[] = [];
  contacts: any[];
  services: any[];
  jobs: any[];
  companies: any[];
  products: any[];
  projects: any[];
  searchSubscription: any;
  isVisible: boolean = false;
  currentlang;
  display = false;
  showSpin: boolean = false;

  constructor(private categoryservice: CategoriesService,
    private homeservice: HomeService,
    private translateservice: TranslateService,
    private router: Router,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.currentlang = localStorage.getItem('lang');

    this.getCategoriesList();

  }

  getCategoriesList() {
    this.homeservice.GetCategoryList().subscribe((res: any) => {
      this.CategoryData = res.content
    })
  }

  search(query: string) {
    if (query) {
      if (query.length >= 3) {
        this.showSpin = true;
        if (this.searchSubscription) {
          this.searchSubscription.unsubscribe();
        }
        this.homeservice.globalSearch(query).subscribe((res: any) => {

          if (res['content'].contacts.length > 0) {
            res['content'].contacts.forEach(element => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'contacts'
              }
              this.searchRes.push(obj);
            });
          }

          if (res['content'].services.length > 0) {
            res['content'].services.forEach(element => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'services'
              }
              this.searchRes.push(obj);
            });
          }

          if (res['content'].jobs.length > 0) {
            res['content'].jobs.forEach(element => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'jobs'
              }
              this.searchRes.push(obj);
            });
          }

          if (res['content'].companies.length > 0) {
            res['content'].companies.forEach(element => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'companies'
              }
              this.searchRes.push(obj);
            });
          }

          if (res['content'].products.length > 0) {
            res['content'].products.forEach(element => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'products'
              }
              this.searchRes.push(obj);
            });
          }

          if (res['content'].projects.length > 0) {
            res['content'].projects.forEach(element => {
              let obj = {
                id: element.id,
                title: element.title,
                category: 'projects'
              }
              this.searchRes.push(obj);
            });
          }
          console.log("Search Results=========>", this.searchRes);
          if (this.searchRes.length == 0) {
            this.isVisible = false;
          } else {
            this.isVisible = true;
          }
          this.showSpin = false;
        });
      }

    }
    else {
      this.isVisible = true;
      this.searchRes = [];
      this.showSpin = false;
      return;
    }
  }

  MenuHide() {
    document.getElementById("search_id2").classList.toggle("hide_div");
  }

  navigateplaces(item) {
    this.router.navigate(['categories/places', this.currentlang], {
      queryParams: {
        category_value_id: item.id
      }
    })

  }

  navigateplace(item) {
    this.router.navigate(['../place-details', this.currentlang], {
      queryParams: {
        id: item.id
      }
    })

  }
  // showSection(){
  //   this.display = true;
  // }

  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }


}
