import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import {
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {
  TranslateCacheModule,
  TranslateCacheService,
  TranslateCacheSettings,
} from 'ngx-translate-cache';
import { HttpClientModule } from '@angular/common/http';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ToastrModule } from 'ngx-toastr';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgSelectModule } from '@ng-select/ng-select';
import { DpDatePickerModule } from 'ng2-date-picker';
import { DatePipe } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { FilterPipe } from '../pipes/filter.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MatSelectModule } from '@angular/material/select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TimeFormat } from '../pipes/timeFormat.pipe';
import { ReversePipe } from '../pages/chat/reverse.pipe';
import { ScrollToBottomDirective } from '../pages/chat/scroll-to-bottom.directive';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatStepperModule, MatAutocompleteModule } from '@angular/material';
import { NgOtpInputModule } from 'ng-otp-input';
import { LoaderComponent } from './components/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material';
import { AgmCoreModule } from '@agm/core'; // Angular Google Maps

@NgModule({
  declarations: [
    LoaderComponent,
    TimeFormat,
    ReversePipe,
    ScrollToBottomDirective,
    FilterPipe,
  ],
  imports: [
    TranslateCacheModule.forRoot({
      cacheService: {
        provide: TranslateCacheService,
        useFactory: (translateService, translateCacheSettings) => {
          return new TranslateCacheService(
            translateService,
            translateCacheSettings
          );
        },
        deps: [TranslateService, TranslateCacheSettings],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD3HziFPDuj312mPv_J7jkTqsidehDPX6M',
      libraries: ['places'],
    }),
    CommonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatRadioModule,
    BrowserTransferStateModule,
    NgbModule,
    HttpClientModule,
    CalendarModule,
    MultiSelectModule,
    NgSelectModule,
    NgOtpInputModule,
    NgxPaginationModule,
    DpDatePickerModule,
    DropdownModule,
    InfiniteScrollModule,
    DialogModule,
    MatSelectModule,
    MatSelectInfiniteScrollModule,
    NgxMatSelectSearchModule,
    MatSlideToggleModule,
    NgxIntlTelInputModule,
    MatAutocompleteModule,
    NgxSpinnerModule,
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 2000,
      progressBar: false,
      enableHtml: true,
    }),
    NgMultiSelectDropDownModule.forRoot(),
    Ng2SearchPipeModule,
    SelectDropDownModule,
    MatInputModule,
    MatCardModule,
    CarouselModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatStepperModule,
    GooglePlaceModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    CommonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatRadioModule,
    BrowserTransferStateModule,
    NgbModule,
    HttpClientModule,
    CalendarModule,
    MultiSelectModule,
    NgSelectModule,
    NgOtpInputModule,
    NgxPaginationModule,
    DpDatePickerModule,
    DropdownModule,
    InfiniteScrollModule,
    DialogModule,
    MatSelectModule,
    MatSelectInfiniteScrollModule,
    NgxMatSelectSearchModule,
    MatSlideToggleModule,
    NgxIntlTelInputModule,
    MatAutocompleteModule,
    NgxSpinnerModule,
    Ng2SearchPipeModule,
    SelectDropDownModule,
    MatInputModule,
    MatCardModule,
    CarouselModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatStepperModule,
    GooglePlaceModule,
    MatProgressSpinnerModule,
    BsDatepickerModule,
    TabsModule,
    ToastrModule,
    NgMultiSelectDropDownModule,
    TranslateCacheModule,
    AgmCoreModule,
    TranslateModule,
    TimeFormat,
    ReversePipe,
    ScrollToBottomDirective,
    FilterPipe,
  ],
  providers: [
    DatePipe,
  ]
})
export class SharedModule { }
