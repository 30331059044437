import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-individual-slider-paragraph',
  templateUrl: './individual-slider-paragraph.component.html',
  styleUrls: ['./individual-slider-paragraph.component.scss']
})
export class IndividualSliderParagraphComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoplay:true,
    dots: true,
    navSpeed: 1500,
    navText: ['', ''],
    rtl: true ,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    },
    // nav: true
  }


}
