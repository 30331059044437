<section>
  <div class="background">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-2 col-xs-1"></div>

        <div class="col-md-6 col-sm-8 col-xs-10">
          <div class="card">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <h2 class="login-titl">
                  {{ "Register new account" | translate }}
                </h2>
                <p class="register-label">
                  {{ "Do you have account in qpedia ?" | translate
                  }}<span
                    ><a
                      class="register-link"
                      [routerLink]="['../../login', lang]"
                      >{{ "Login" | translate }}
                    </a></span
                  >
                </p>
              </div>
            </div>

            <div class="row">
              <div
                class="col-md-12 col-sm-12 col-xs-12"
                style="text-align: center"
              >
                <h2>{{ "Choose register type" | translate }}</h2>
              </div>
            </div>

            <div class="row beside-cards">
              <!-- <div class="col-md-1 col-sm-1 col-xs-1"></div> -->
              <div
                class="register-type-card"
                id="individual"
                (click)="NavigateIndividualRegister()"
              >
                <a>
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <h3>{{ "Individual" | translate }}</h3>
                </a>
              </div>
              <div
                class="register-type-card"
                id="Corporate"
                (click)="NavigateCorporateRegister()"
              >
                <a>
                  <i class="fa fa-building" aria-hidden="true"></i>
                  <h3>{{ "Corporate" | translate }}</h3>
                </a>
              </div>
              <!-- <div class="col-md-1 col-sm-1 col-xs-1"></div> -->
            </div>

            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <button class="next-btn" id="next">
                  {{ "next" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-sm-2 col-xs-1"></div>
      </div>
    </div>
  </div>
</section>
