import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';


@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor(private httpClientService: HttpClientService) { }

  GetcontactsList() {

    let body={
      "created_by":localStorage.getItem('UserID'),
    }
    return this.httpClientService.post('api/cms', body,{});
  }

  Addproject(formvalue){
    return this.httpClientService.post('api/contacts_projects/store', formvalue,{});

  }

  updateProject(formvalue){
    return this.httpClientService.post('api/contacts_projects/update', formvalue,{});

  }

  getProject(id){
    return this.httpClientService.post('api/contacts_projects/show', id, []);
  }

  getProduct(id){
    return this.httpClientService.post('api/contacts_products/show', id, []);
  }

}
