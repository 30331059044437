import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/Shared/Services/authentication.service';

@Component({
  selector: 'app-services-corporate',
  templateUrl: './services-corporate.component.html',
  styleUrls: ['./services-corporate.component.scss']
})
export class ServicesCorporateComponent implements OnInit {

  constructor(public authService: AuthenticationService,) { }

  ngOnInit(): void {
  }

}
