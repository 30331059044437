import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { FaqComponent } from './pages/about/components/faq/faq.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { CitiesComponent } from './pages/cities/cities.component';
import { LoginComponent } from './pages/login/login.component';
import { ContactComponent } from './pages/about/components/contact/contact.component';
import { PlaceCreateComponent } from './pages/place-create/place-create.component';
import { PlaceDetailsComponent } from './pages/place-details/place-details.component';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { PlacesComponent } from './pages/places/places.component';

import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { VerificationCodeComponent } from './pages/verification-code/verification-code.component';

import { UpdateProfileComponent } from './pages/update-profile/update-profile.component';
import { RequestsListComponent } from './pages/requests-list/requests-list.component';
import { RequestViewComponent } from './pages/request-view/request-view.component';
import { ProjectCreateComponent } from './pages/project-create/project-create.component';

import { TasksComponent } from './pages/tasks/tasks.component';
import { UsersComponent } from './pages/users/users.component';

import { UsersCreateComponent } from './pages/users-create/users-create.component';
import { TasksCreateComponent } from './pages/tasks-create/tasks-create.component';
import { UserViewComponent } from './pages/user-view/user-view.component';
import { UserUpdateComponent } from './pages/user-update/user-update.component';
import { TaskViewComponent } from './pages/task-view/task-view.component';
import { EmployeeComponent } from './pages/employee/employee.component';
import { CreateEmployeeComponent } from './pages/create-employee/create-employee.component';
import { ViewEmployeeComponent } from './pages/view-employee/view-employee.component';
import { CompanyJobsComponent } from './pages/company-jobs/company-jobs.component';
import { AddJobsComponent } from './pages/add-jobs/add-jobs.component';
import { ViewJobComponent } from './pages/view-job/view-job.component';
import { RequestTypesComponent } from './pages/request-types/request-types.component';
import { CreateRequestTypesComponent } from './pages/create-request-types/create-request-types.component';
import { ProductCreateComponent } from './pages/product-create/product-create.component';
import { ServiceCreateComponent } from './pages/service-create/service-create.component';
import { ServicesComponent } from './pages/services/services.component';
import { ServiceItemComponent } from './pages/service-item/service-item.component';
import { PlaceViewComponent } from './pages/place-view/place-view.component';
import { AuthGuard } from './Shared/Services/auth.guard';
import { CompanyGuard } from './Shared/Services/company.guard';
import { UserGuard } from './Shared/Services/user.guard';
import { RelatedContactComponent } from './pages/related-contact/related-contact.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { ChatComponent } from './pages/chat/chat.component';
import { AllProductsComponent } from './pages/all-products/all-products.component';
import { AllServicesComponent } from './pages/all-services/all-services.component';

import { ServicesIndividualComponent } from './pages/services-individual/services-individual.component';
import { ServicesCorporateComponent } from './pages/services-corporate/services-corporate.component';

import { LoginNewComponent } from './pages/login-new/login-new.component';
import { RegisterCorporateComponent } from './pages/register-corporate/register-corporate.component';
import { RegisterIndividualComponent } from './pages/register-individual/register-individual.component';
import { RegisterNewComponent } from './pages/register-new/register-new.component';
import { OtpVerificationComponent } from './pages/otp-verification/otp-verification.component';
import { ChatQuickComponent } from './pages/chat-quick/chat-quick.component';

export const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/home/:lang',
  //   pathMatch: 'full',
  //   data: { title: 'Qpedia', lang: 'en' },
  // },
  // {
  //   path: ':lang',
  //   redirectTo: '/home/:lang',
  //   pathMatch: 'full',
  //   data: { title: 'Qpedia', lang: 'en' },
  // },
  // { path: 'home/:lang', component: HomeComponent, data: { title: 'Qpedia' } },
  
  { path: '', component: HomeComponent, data: { title: 'Qpedia' } },
  { path: ':lang', component: HomeComponent, data: { title: 'Qpedia' } },
  { path: 'home/:lang', component: HomeComponent, data: { title: 'Qpedia' } },

  {
    path: 'about',
    loadChildren: () =>
      import('./pages/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'pricing/:lang',
    component: PricingComponent,
    data: { title: 'Pricing' },
  },
  {
    path: 'categories/:lang',
    component: CategoriesComponent,
    data: { title: 'Categories' },
  },
  {
    path: 'cities/categories/:lang',
    component: CategoriesComponent,
    data: { title: 'Categories' },
  },
  {
    path: 'cities/:lang',
    component: CitiesComponent,
    data: { title: 'Cities' },
  },
  {
    path: 'login-register/:lang',
    component: LoginComponent,
    data: { title: 'Login-Register' },
  },
  {
    path: 'place-new/:lang',
    component: PlaceCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'place-view/:lang',
    component: PlaceViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project-new/:lang',
    component: ProjectCreateComponent,
    canActivate: [AuthGuard],
  },
  { path: 'place-details/:lang', component: PlaceDetailsComponent },
  { path: 'categories/places/:lang', component: PlacesComponent },
  { path: 'places/:lang', component: PlacesComponent },
  { path: 'categories/place-details/:lang', component: PlaceDetailsComponent },
  { path: 'terms-of-use/:lang', component: TermsOfUseComponent },
  { path: 'privacy-policy/:lang', component: PrivacyPolicyComponent },
  { path: 'forgot-password/:lang', component: ForgotPasswordComponent },
  { path: 'forget-password/:lang', component: ForgetPasswordComponent },
  { path: 'verification-code/:lang', component: VerificationCodeComponent },
  {
    path: 'profile/:lang',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'updateprofile/:lang',
    component: UpdateProfileComponent,
    canActivate: [AuthGuard],
  },
 
  { path: 'requests/:lang', component: RequestsListComponent },
  { path: 'requests/view/:lang', component: RequestViewComponent },
  {
    path: 'services/:lang',
    component: ServicesComponent,
    canActivate: [AuthGuard, UserGuard],
  },
  { path: 'add-item/:lang', component: ServiceItemComponent },
  {
    path: 'notification/:lang',
    component: NotificationsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'tasks/:lang',
    component: TasksComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: 'users/:lang',
    component: UsersComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: 'tasks-create/:lang',
    component: TasksCreateComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },

  // { path: 'product-create/:lang', component: ProductCreateComponent, canActivate:[AuthGuard, CompanyGuard]},
  {
    path: 'product-create/:lang',
    component: ProductCreateComponent,
    canActivate: [AuthGuard],
  },
  // { path: 'service-create/:lang', component: ServiceCreateComponent , canActivate:[AuthGuard , CompanyGuard]},
  {
    path: 'service-create/:lang',
    component: ServiceCreateComponent,
    canActivate: [AuthGuard],
  },

  // { path: 'related-create/:lang', component: RelatedContactComponent, canActivate:[AuthGuard, CompanyGuard]},
  {
    path: 'related-create/:lang',
    component: RelatedContactComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'tasks-update/:id/:lang',
    component: TasksCreateComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: 'task-view/:lang',
    component: TaskViewComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: 'users-create/:lang',
    component: UsersCreateComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: 'view-employee/:lang',
    component: ViewEmployeeComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: 'view-user/:lang',
    component: UserViewComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: 'view-update/:lang',
    component: UserUpdateComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: 'employee/:lang',
    component: EmployeeComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: 'employee-create/:lang',
    component: CreateEmployeeComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: 'company-jobs/:lang',
    component: CompanyJobsComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: 'create-job/:lang',
    component: AddJobsComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: 'update-job/:id/:lang',
    component: AddJobsComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: 'view-job/:lang',
    component: ViewJobComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  { path: 'jobs/:lang', component: JobsComponent, canActivate: [AuthGuard] },
  {
    path: 'products/:lang',
    component: AllProductsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'all-services/:lang',
    component: AllServicesComponent,
    canActivate: [AuthGuard],
  },
  { path: 'request-types/:lang', component: RequestTypesComponent },
  {
    path: 'create-request-types/:lang',
    component: CreateRequestTypesComponent,
  },
  {
    path: 'update-request-types/:id/:lang',
    component: CreateRequestTypesComponent,
  },
  { path: 'chat/:lang', component: ChatComponent },
  { path: 'ask-question/:lang', component: ChatQuickComponent },

  { path: 'opt-verification/:lang', component: OtpVerificationComponent },
  {
    path: 'servicescorporate/:lang',
    component: ServicesCorporateComponent,
    data: { title: 'Services' },
  },
  {
    path: 'servicesindividual/:lang',
    component: ServicesIndividualComponent,
    data: { title: 'Services' },
  },

  { path: 'login/:lang', component: LoginNewComponent },
  { path: 'register-individual/:lang', component: RegisterIndividualComponent },
  { path: 'register-corporate/:lang', component: RegisterCorporateComponent },
  { path: 'register/:lang', component: RegisterNewComponent },
  {
    path: 'missing',
    loadChildren: () =>
      import('./components/missing/missing.module').then(
        (m) => m.MissingModule
      ),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
