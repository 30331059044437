import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AddingMetaService } from 'src/app/Shared/Services/adding-meta.service';
import { PagesService} from '../../Shared/Services/pages.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
   pivacytitle;
   privacycontent;
   seo:any[] = [];
  config:any[] = [];
  constructor(
    private pageservice: PagesService,
    private translateservice:TranslateService,
    private meta: AddingMetaService
    ) { }

  ngOnInit(): void {
    this.getPrivacyPolicyList();

  }

  getPrivacyPolicyList(){
    this.pageservice.GetPrivacyPolicy().subscribe((res:any)=>{
       this.pivacytitle = res.content[0].name;
       this.privacycontent = res.content[0].content;
       this.seo = res.content[0].seo;
       this.seo.forEach(element=>{
         if(element.title == "Title"){
           this.config.push(element.keyword);
         }
         if(element.title == "Description"){
          this.config.push(element.keyword);
         }

       });

      let seoConfig = {title: this.config[0], description: this.config[1], compTitle: this.config[0]};
      console.log('Config issss===>', seoConfig);
      this.meta.updateMeta(seoConfig);
    })
  }

}
