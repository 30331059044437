<div class="statistics">
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-sm-3 col-xs-12"></div>
    </div>
  </div>

  <div  class="container">
    <div  class="row norow">

      <div  class="col-lg-3 col-md-12 col-sm-12 col-xs-12 bottom-border border-r">
        <div class="row norow">
          <div
            class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right f-right"
          >
            <p  class="counter text-right">11</p>
          </div>
          <div
            class="col-lg-8 col-md-8 col-sm-8 col-xs-8 counter-box"
          >
            <p class="white-paragraph">Happy</p>
            <p  class="secondary-paragraph">Customers</p>
          </div>
          <div class="col-md-12 col-md-12 col-xs-12">
            <p class="gray-statistics-paragraph">This project is userly interface motivate sometimes ti gives good experience is userly interface motivate sometimes ti gives good experience</p>
          </div>

        </div>
      </div>
      <div  class="col-lg-3 col-md-12 col-sm-12 col-xs-12 bottom-border border-r">
        <div  class="row norow">
          <div
            class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right f-right"
          >
            <p  class="counter text-right">5</p>
          </div>
          <div
            class="col-lg-8 col-md-8 col-sm-8 col-xs-8 counter-box"
          >
            <p  class="white-paragraph">Fun</p>
            <p  class="secondary-paragraph">Products</p>
          </div>
          <div class="col-md-12 col-md-12 col-xs-12">
            <p class="gray-statistics-paragraph">This project is userly interface motivate sometimes ti gives good experience is userly interface motivate sometimes ti gives good experience</p>
          </div>
        </div>
      </div>
      <div  class="col-lg-3 col-md-12 col-sm-12 col-xs-12 bottom-border border-r">
        <div class="row norow">
          <div
            class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right f-right"
          >
            <p  class="counter text-right">1124</p>
          </div>
          <div
            class="col-lg-8 col-md-8 col-sm-8 col-xs-8 counter-box"
          >
            <p  class="white-paragraph">Special</p>
            <p  class="secondary-paragraph">Moments</p>
          </div>
          <div class="col-md-12 col-md-12 col-xs-12">
            <p class="gray-statistics-paragraph">This project is userly interface motivate sometimes ti gives good experience is userly interface motivate sometimes ti gives good experience</p>
          </div>
        </div>
      </div>
      <div  class="col-lg-3 col-md-12 col-sm-12 col-xs-128">
        <div class="row norow">
          <div
            class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right f-right"
          >
            <p  class="counter text-right">400</p>
          </div>
          <div
            class="col-lg-8 col-md-8 col-sm-8 col-xs-8 counter-box"
          >
            <p class="white-paragraph">Customer</p>
            <p  class="secondary-paragraph">Engagments</p>
          </div>
          <div class="col-md-12 col-md-12 col-xs-12">
            <p class="gray-statistics-paragraph">This project is userly interface motivate sometimes ti gives good experience is userly interface motivate sometimes ti gives good experience</p>
          </div>
        </div>
      </div>

    </div>
  </div>

  
</div>
