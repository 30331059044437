import {Component, NgZone, OnInit} from '@angular/core';
import firebase from "firebase";
import { ActivatedRoute, Params, Router } from '@angular/router';
import {UserService} from "../../Shared/Services/user.service";
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';
// import { RecaptchaComponent } from 'ng-recaptcha';


@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit {
  otpInputConfig: any;
  private verificationId: any;
  private otp: string;
  private otpreverse: string;

  private resend: boolean;

  lang;
  lang_param;

  user_id;

  constructor(private router: Router,
     private ngZone: NgZone,
     private userService: UserService,
     private toastrService: ToastrService,
     private translateservice:TranslateService,
     private activatedRoute: ActivatedRoute,
     private httpClientService: HttpClientService,
     private languageService: LanguageService,



     ) {
  }

  ngOnInit(): void {
   
  

    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    console.log(localStorage.getItem('verificationId'));
    this.verificationId = JSON.parse(localStorage.getItem('verificationId'));

    console.log(this.verificationId);
    this.activatedRoute.url.subscribe(url =>{
      this.lang_param = url[1].path;
      console.log("the router url  without any changes :-----",url);
      console.log("the router url language:-----",this.lang_param);
    });

  }

  onOtpChange(otp: string) {
    this.otp = otp;
    // this.otp = otp.split("").reverse().join("");
    // console.log("The verification code :---",otp)
  }

  verify() {
    // debugger
    console.log( "The verification code :---" ,this.otp);
    if (this.lang_param == 'ar'){
      this.otpreverse =  this.otp.split("").reverse().join("");
      console.log( "The verification code in arabic case  :---" ,this.otpreverse);
    }

    else {
      this.otpreverse =  this.otp;
      console.log( "The verification code in english case  :---" ,this.otpreverse);
    }

    const verId = localStorage.getItem('verificationId');
    const credential = firebase.auth.PhoneAuthProvider.credential(
      localStorage.getItem('verificationId').toString(),
      this.otpreverse
    );

    console.log("fire base credentials",credential);
    firebase.auth().signInWithCredential(credential)
      .then((response:any) => {
        this.user_id = localStorage.getItem('UserID');
        console.log("User ID :::::::::::::",this.user_id);

        // call verify api
        // this.userService.Verifycode(this.user_id).subscribe((res:any)=>{
        //   console.log("verify mobile api res: ", res)
        // })

        this.httpClientService.post('api/verify_mobile',{'user_id':this.user_id},{}).subscribe(
          res=>{
            console.log("verify mobile api res:  ", res);
          }
        );


        localStorage.setItem('user_data', JSON.stringify(response));

      
          this.router.navigate(['/login', this.lang]);
       
      })
      .catch((error) => {
        console.log(error);
        // alert(error.message);
        this.toastrService.error(error.message);

        if(error.code==='auth/code-expired'){
          this.resend=true;
        }
        // code: 'auth/code-expired', message: 'The SMS code has expired. Please re-send the verification code to try again.',
      });
  }

  resendCode() {
    debugger
    this.userService.getOtp(localStorage.getItem('phoneNumber'));
  }
}
