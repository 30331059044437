import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-place-square',
  templateUrl: './place-square.component.html',
  styleUrls: ['./place-square.component.scss']
})
export class PlaceSquareComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
