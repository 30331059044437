    <!-- Start Page Content -->
    <div class="container pg-content">
        <div class="pg-header-content">
            <h1 class="pg-title" *ngIf="!id">
              {{'add job'|translate}}
            </h1>
            <h1 class="pg-title" *ngIf="id">
              {{'edit job'|translate}}
            </h1>
          </div>
        
         <!--Start Contact Form -->
        <div class="col-sm-12 text-center">
         
          <form [formGroup]="JobForm" #myform="ngForm" (ngSubmit)="create(JobForm)" class="form-horizontal contact-form">
            <!-- Text input-->

            <div class="form-group">
              <div class="col-sm-6 inputGroupContainer"> 
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-user"></i></span>
                  <!-- <input formControlName="position"  name="name" id="name" placeholder="{{''|translate}}" class="form-control"  > -->
                  <ngx-select-dropdown
                                formControlName="position_id" 
                                [multiple]="false" 
                                [config]="config" 
                                [options]="positionList"
                                [required]="true"
                                [(ngModel)]="val"
                            ></ngx-select-dropdown>
                  <span *ngIf="JobForm.controls['position_id']?.errors?.required && myform.submitted" class="error-msg">
                      {{'positionReqMes'|translate}}
                  </span>
                </div>
              </div>
            </div>
            
            <div class="form-group">
              <div class="col-sm-6 inputGroupContainer"> 
              <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-user"></i></span>
              <input formControlName="title"  name="name" id="name" placeholder="{{'job name'|translate}}" class="form-control"  >
    
              <span *ngIf="JobForm.controls['title']?.errors?.required && myform.submitted" class="error-msg">
                  {{'job name' | translate}} {{'required'|translate}}
              </span>
              <!-- <span *ngIf="JobForm.controls['full_name'].touched && JobForm.controls['full_name']?.errors?.pattern" class="error-msg">
                  {{'nameMustBeCharc'|translate}}.
              </span> -->
            </div>
              </div>
            </div>
            <!-- <div class="form-group">
              <div class="col-sm-6 inputGroupContainer">
              <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-user"></i></span>
              <input formControlName="title_ar"  name="name_ar" id="name_ar" placeholder="{{'job name'|translate}} - {{'arabic'|translate}}" class="form-control"  >
    
              <span *ngIf="JobForm.controls['title_ar'].touched && JobForm.controls['title_ar']?.errors?.required" class="error-msg">
                  {{'required'|translate}}
              </span>
            </div>
              </div>
            </div> -->
          

            <!-- Text input-->
            <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="fas fa-file-alt"></i></span>
                    <textarea formControlName="description" placeholder="{{'description'|translate}}" class="form-control"></textarea>
                  
                    <span *ngIf="JobForm.controls['description']?.errors?.required && myform.submitted"
                      class="error-msg">
                      {{'description' | translate}} {{'required'|translate}}
                    </span>
                  
                  </div>
                </div>
              </div>
            <!-- <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                  <div class="input-group">
                    <span class="input-group-addon"><i class="fas fa-file-alt"></i></span>
                    <textarea formControlName="description_ar" placeholder="{{'description'|translate}} - {{'arabic'|translate}}" class="form-control"></textarea>                  
                  </div>
                </div>
              </div> -->
            <!-- Text input-->
            <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fas fa-dollar-sign"></i></span>
                        <input  formControlName="salary" name="salary" id="salary" placeholder="{{'salary'|translate}}" class="form-control" >
                        <span *ngIf="JobForm.controls['salary']?.errors?.required && myform.submitted"
                      class="error-msg">
                      {{'salary' | translate}} {{'required'|translate}}
                    </span>
                      </div>
                </div>
            </div>


            <!-- Text input-->
            <div class="form-group">
                <div class="col-sm-12 inputGroupContainer">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="far fa-sticky-note"></i></span>
                        <input  formControlName="notes"  name="note" id="note" placeholder="{{'notes'|translate}}" class="form-control"  >
                        <span *ngIf=" JobForm.controls['notes']?.errors?.required && myform.submitted" class="error-msg">
                            {{'notesReqMes'|translate}}
                        </span>
                         <!-- <span *ngIf="JobForm.controls['note'].touched && JobForm.controls['note']?.errors?.note" class="error-msg">
                  {{'notemustbe'|translate}}.
              </span> -->
                    </div>
                </div>
            </div>
            <!-- Text input-->
       
           
 
           
            <!-- Submit Button -->
            <div class="form-group sub">
              <div class="col-sm-12">
                <button  type="submit" name="name" class="btn btn-warning submit" >
                  <i *ngIf="isEnabled" class="fa fa-spinner fa-spin"></i> {{'Submit'|translate}}</button>
              </div>
            </div>
          </form>
        </div>
        <!--End Contact Form-->
      </div>
      <!-- End Page Content -->
    