import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/Shared/Services/home.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';

declare var $: any;

@Component({
  selector: 'app-add-jobs',
  templateUrl: './add-jobs.component.html',
  styleUrls: ['./add-jobs.component.scss'],
})
export class AddJobsComponent implements OnInit {
  JobForm: FormGroup;
  isEnabled: boolean = false;
  currentlang: string;
  lang: string;
  id: number;
  jobsList: any[] = [];
  job;
  config = {};
  positionList: any[] = [];
  val: any;
  contactId;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private translateservice: TranslateService,
    private homeService: HomeService,
    private languageService: LanguageService,
    private service: HttpClientService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    // this.jobsList=[
    //   {id:"1",name_ar:"Pentavalue Soft",name_en:"accountant",description_ar:"developer application",description_en:"developer application",salary:"2500",notes:"nnnnnnnnnn"},
    //   {id:"2",name_r:"Pentavalue Soft",name_en:"accountant 1",description_ar:"developer application 1",description_en:"developer application1",salary:"5000",notes:"nnnnnnnnnn1"},
    //   {id:"3",name_ar:"Pentavalue Soft",name_en:"accountant 2",description_ar:"developer application 2",description_en:"developer application2",salary:"4500",notes:"nnnnnnnnnn2"}
    // ];
    this.config = {
      placeholder: this.translateservice.instant('pos'),
      search: false,
      displayKey: 'name',
    };
    this.initForm();

    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      if (this.id) {
        this.getJob(this.id);
      } else {
      }
    });

    this.route.queryParams.subscribe((res) => {
      this.contactId = +res['placeId'];
    });

    this.currentlang = localStorage.getItem('lang');

    this.getPositions();
  }

  initForm() {
    this.JobForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      title_ar: new FormControl(''),
      salary: new FormControl('', [Validators.required]),
      notes: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      description_ar: new FormControl(''),
      position_id: new FormControl('', [Validators.required]),
    });
  }

  getJob(id: number) {
    console.log(id);
    let obj = { id: id };
    this.service.post('api/company_jobs/show', obj, []).subscribe((res) => {
      console.log('The Response', res);

      // this.jobForm.patchValue({
      //     name: res['content'][0]?.company.name,
      //     salary: res['content'][0]?.salary,
      //     title: res['content'][0]?.title,
      //     description: res['content'][0]?.description

      //   });
      this.JobForm.patchValue({
        title: res['content'][0]?.title,
        title_ar: res['content'][0]?.title,
        description_ar: res['content'][0]?.description,
        description: res['content'][0]?.description,
        salary: res['content'][0]?.salary,
        notes: res['content'][0]?.notes,
      });

      this.positionList.forEach((element) => {
        if (element.id == res['content'][0].position_id) {
          this.val = element;
        }
      });
    });
    console.log(this.JobForm);
  }

  create(formdata) {
    this.isEnabled = true;

    if (formdata.valid) {
      console.log('valid');
      if (!this.id) {
        console.log('create job :-', formdata);
        formdata.value.title_ar = formdata.value.title;
        formdata.value.description_ar = formdata.value.description;
        formdata.value.position_id = this.val.id;
        formdata.value.place_id = this.contactId;

        this.service
          .post('api/company_jobs/store', formdata.value, [])
          .subscribe(
            (res) => {
              this.isEnabled = false;
              console.log(res);
              if (this.lang == 'en') {
                this.toastService.success('Job Added Successfully');
              } else {
                this.toastService.success('تم إضافة الوظيفة بنجاح');
              }

              this.router.navigate(['company-jobs', this.lang]);
            },
            (error) => {
              this.isEnabled = false;
              console.log(error);
              let validation_errors = error.error.status.validation_errors;
              validation_errors.forEach((element) => {
                if (element.field == 'title') {
                  this.toastService.error(element.message);
                } else if (element.field == 'title_ar') {
                  this.toastService.error(element.message);
                } else if (element.field == 'salary') {
                  this.toastService.error(element.message);
                } else if (element.field == 'notes') {
                  this.toastService.error(element.message);
                } else if (element.field == 'position_id') {
                  this.toastService.error(element.message);
                } else if (element.field == 'description') {
                  this.toastService.error(element.message);
                } else if (element.field == 'description_ar') {
                  this.toastService.error(element.message);
                }
              });
              // if(this.lang == 'en'){
              //   this.toastService.error('Error Happened');
              // }else{
              //   this.toastService.error('حدث خطأ');
              // }
            }
          );
      } else {
        console.log('update job :-');
        formdata.value.id = this.id;
        formdata.value.position_id = this.val.id;
        formdata.value.place_id = this.contactId;
        console.log(formdata.value);
        this.service
          .post('api/company_jobs/update', formdata.value, [])
          .subscribe(
            (res) => {
              this.isEnabled = false;
              console.log(res);
              if (this.lang == 'en') {
                this.toastService.success('Job Update Successfully');
              } else {
                this.toastService.success('تم تعديل الوظيفة بنجاح');
              }
              this.router.navigate(['company-jobs', this.lang]);
            },
            (error) => {
              this.isEnabled = false;
              console.log(error);
              let validation_errors = error.error.status.validation_errors;
              validation_errors.forEach((element) => {
                if (element.field == 'title') {
                  this.toastService.error(element.message);
                } else if (element.field == 'title_ar') {
                  this.toastService.error(element.message);
                } else if (element.field == 'salary') {
                  this.toastService.error(element.message);
                } else if (element.field == 'notes') {
                  this.toastService.error(element.message);
                } else if (element.field == 'position_id') {
                  this.toastService.error(element.message);
                } else if (element.field == 'description') {
                  this.toastService.error(element.message);
                } else if (element.field == 'description_ar') {
                  this.toastService.error(element.message);
                }
              });
            }
          );
      }
    } else {
      console.log('not valid');
      this.isEnabled = false;
    }
  }

  getPositions() {
    let lang_id: any;
    if (this.lang == 'ar') {
      lang_id = 2;
    } else {
      lang_id = 1;
    }
    this.service
      .get('api/company_jobs/positions', { lang_id: lang_id }, {})
      .subscribe((res: any) => {
        this.positionList = res.content;
      });
  }
}
