import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AddingMetaService } from 'src/app/Shared/Services/adding-meta.service';
import { HomeService } from 'src/app/Shared/Services/home.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import Swal from 'sweetalert2';
import { HttpHeaders } from '@angular/common/http';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit {
  page_no: number = 1;
  p: number = 1;
  lang: string;
  jobs: any[] = [];
  displayResponsive: boolean = false;
  requestForm: FormGroup;
  type: any;
  fileName: any;
  file_url: any;
  id: number;
  hasCv: boolean = false;
  fileuploaded: boolean = false;

  searchedProduct: any[] = [];
  searchSubscription: any;
  showMenu: boolean = false;
  query: string;
  price: string;

  corporatelogincheck;

  FilterForm: FormGroup;
  token: string;

  header: HttpHeaders = new HttpHeaders();

  CV_File;

  constructor(
    private translateservice: TranslateService,
    private router: Router,
    private homeService: HomeService,
    private service: HttpClientService,
    private languageService: LanguageService,
    private meta: AddingMetaService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.hasCv = localStorage.getItem('hasCv') == '1' ? false : true;
    this.token = localStorage.getItem('token');

    this.CV_File = localStorage.getItem('cv');

    this.initForm();
    this.getAllJobs(1);

    this.meta.updateMeta({
      compTitle: 'Jobs',
    });

    this.corporatelogincheck = localStorage.getItem('Loggedin');

    this.FilterForm = new FormGroup({
      title: new FormControl(''),
      price: new FormControl(''),
    });
  }

  initForm() {
    this.requestForm = new FormGroup({
      attached_cv: new FormControl('', [Validators.required]),
    });
  }

  getAllJobs(page) {
    this.homeService.getAllJobs(page).subscribe((res: any) => {
      console.log('Response', res);
      this.jobs = res.content;
    });
  }

  open(id: any) {
    this.displayResponsive = true;
    this.id = id;
  }

  sendRequest(formData) {
    formData.value.intiator_type = 1;
    formData.value.job_id = this.id;

    if (this.CV_File == '') {
      formData.value.attached_cv = this.CV_File;
    } else {
      formData.value.attached_cv = this.file_url;
    }

    console.log(formData.value);

    let setHeaders = this.header.set('access-token', this.token);

    this.service
      .post('api/company_users/store', formData.value, [], setHeaders)
      .subscribe((res: any) => {
        console.log('RES', res);
        if (res.status.code == 200) {
          if (this.lang == 'en') {
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: 'Application sent successfully',
              timer: 1500,
            });
          } else if (this.lang == 'ar') {
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: 'تم ارسال الطلب بنجاح',
              timer: 1500,
            });
          }

          this.requestForm.reset();
          this.displayResponsive = false;
          localStorage.setItem('hasCv', '1');

          this.getAllJobs(1);
          window.location.reload();
        }
        this.getAllJobs(1);
      });
  }

  readImage(event) {
    var files = event.target.files[0];
    this.type = event.target.files[0].type;
    console.log(this.type);
    this.fileName = files.name;
    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.file_url = `data:${this.type};base64,` + btoa(binaryString);
    console.log(this.file_url);
    if (this.file_url) {
      this.fileuploaded = true;
    }
  }

  search(query) {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (query) {
      this.service
        .post('api/company_jobs/all', { name: query }, [])
        .subscribe((res) => {
          console.log('RES', res);
          this.searchedProduct = res['content'];
          this.jobs = res['content'];
        });
    } else {
      this.searchedProduct = [];
      this.getAllJobs(1);
      // return;
    }
  }

  filterByPrice(price) {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (price) {
      this.service
        .post('api/company_jobs/all', { price: price }, [])
        .subscribe((res) => {
          console.log('RES', res);
          this.searchedProduct = res['content'];
          this.jobs = res['content'];
        });
    } else {
      this.searchedProduct = [];
      this.getAllJobs(1);
      // return;
    }
  }

  FilterServices() {
    console.log('Form Values :::::::::::::', this.FilterForm.value);
    this.service
      .post(
        'api/company_jobs/all',
        {
          title: this.FilterForm.value.title,
          salary: this.FilterForm.value.price,
        },
        []
      )
      .subscribe((res) => {
        console.log('RES of filter api ::::::', res);
        this.jobs = res['content'];
      });
  }

  menuHide(event) {
    this.showMenu = false;
  }

  menuShow() {
    this.showMenu = true;
  }

  navigateplacedetails(item) {
    this.router.navigate(['../place-details', this.lang], {
      queryParams: {
        id: item.place_id,
      },
    });
  }
}
