import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ContactService } from '../../Shared/Services/contact.service';
import { AuthenticationService } from 'src/app/Shared/Services/authentication.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  address;
  phone;

  Facebook_link;
  Instagram_link;
  Twitter_Link;
  Paintrest_Link;
  linkedin_link;

  currentlang: string;
  lang;

  @ViewChild('navbarToggler') navbarToggler: ElementRef;

  constructor(
    public authService: AuthenticationService,
    private contactservice: ContactService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.currentlang = localStorage.getItem('lang');
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });

    this.getHomeSocialLinks();
    this.getAddress();
  }

  getAddress() {
    this.contactservice.GetContactInfo().subscribe((res: any) => {
      this.address = res.content[0].address;
      this.phone = res.content[0].phone;
    });
  }

  getHomeSocialLinks() {
    this.contactservice.GetSocialLinks().subscribe((res: any) => {
      this.Facebook_link = res.content[0].value;
      this.Twitter_Link = res.content[1].value;
      this.Instagram_link = res.content[2].value;
      this.Paintrest_Link = res.content[3].value;
      this.linkedin_link = res.content[4].value;
    });
  }

  navBarTogglerIsVisible() {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }

  collapseNav() {
    if (this.navBarTogglerIsVisible()) {
      this.navbarToggler.nativeElement.click();
    }
  }
}
