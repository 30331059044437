import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/Shared/Services/home.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';

declare var $: any;

@Component({
  selector: 'app-tasks-create',
  templateUrl: './tasks-create.component.html',
  styleUrls: ['./tasks-create.component.scss'],
})
export class TasksCreateComponent implements OnInit {
  taskForm: FormGroup;
  image_url: any;
  profile_img = '';
  types: any[] = [];
  priority: any[] = [];
  districts: any[] = [];
  items: any[] = [];
  related_task: any[] = [];
  currentlang: string;
  lang: string;
  id: number;
  task: any;
  relatedContacts: any[] = [];
  users: any[] = [];
  typeID: number;
  itemID: number;
  selectedUser: any[] = [];
  locationID: number;
  isEnabled: boolean = false;
  statuses: any[] = [];
  contactId: number;
  offset = 0;
  index = 1;

  selectedItems = [];
  dropdownSettings = {};
  place: any;
  searchRes: any[] = [];
  isVisible: boolean = false;
  value: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private translateservice: TranslateService,
    private languageService: LanguageService,
    private homeService: HomeService,
    private http: HttpClientService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.getStatuses();
    this.initForm();

    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      if (this.id) {
        this.getTask(this.id);
      }
    });

    this.route.queryParams.subscribe((res) => {
      this.contactId = +res.id;

      if (this.contactId) {
        this.http
          .post('api/cms/show', { id: this.contactId }, [])
          .subscribe((res) => {
            this.place = res['content'][0];

            this.onKey(this.place.title);
          });
        this.taskForm.get('item_id').setValue(this.contactId);
      }
    });

    this.currentlang = localStorage.getItem('lang');

    this.homeService.getItems(this.index).subscribe((res) => {
      // console.log("The items", res['content'])
      this.items = res['content'];
      console.log('The items from oninit:::::::::::::', this.items);

      // res['content'].forEach(ele=>{
      //   this.items.push(ele);
      // });
    });

    this.getTypes();
    this.getDistricts();
    this.getItems();
    this.getTaskStatuses();
    this.getRelatedRequest();
    this.getUser();
    // this.getNextBatch();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'full_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  onKey(event: string) {
    console.log('Eveeeeeent', event);
    console.log(event.search(' '));
    if (event) {
      if (event.length >= 3) {
        this.homeService.searchPlaces(event).subscribe((res: any) => {
          // this.items = res['content'];
          // console.log('Search Results=========>', this.items);
        });
      }
    } else {
      this.isVisible = true;
      this.getItems();

      return;
    }
  }

  initForm() {
    this.taskForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      // mobile: new FormControl('', [Validators.required]),
      date: new FormControl('', [Validators.required]),
      time: new FormControl('', [Validators.required]),
      // location: new FormControl('', [Validators.required]),
      users: new FormControl('', [Validators.required]),
      notes: new FormControl(''),
      description: new FormControl('', [Validators.required]),
      request_type_id: new FormControl('', [Validators.required]),
      district_id: new FormControl(''),
      item_id: new FormControl('', [Validators.required]),
      priority_level_id: new FormControl('', [Validators.required]),
      related_request_id: new FormControl(''),
      related_contact_id: new FormControl(''),
      otherAction: new FormControl(''),
      indoor: new FormControl(''),
      request_status_id: new FormControl(''),
      // city_id: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),

      // Twitter: new FormControl('',),
      // description: new FormControl('', [Validators.required]),
    });
  }

  readImage(event) {
    var files = event.target.files[0];
    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }

  getTaskStatuses() {
    this.homeService.getTaskStatuses().subscribe((res) => {
      console.log('The Task Statuses:- ', res['content']);
    });
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.image_url = 'data:image/jpeg;base64,' + btoa(binaryString);
    // console.log(this.image_url )
  }

  closebtn(index) {
    this.image_url = '';
    $('#image_url').val('');
  }

  openImg() {
    var win = window.open();
    win.document.write(
      '<iframe src="' +
        this.image_url +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    );
  }

  getTypes() {
    this.homeService.getTypes().subscribe((res) => {
      console.log('The types', res);
      this.types = res['content'];
    });
  }

  getUser() {
    this.homeService.getAllEmployee().subscribe((res) => {
      console.log('The users', res['content']);
      this.users = res['content'];
    });
  }
  getDistricts() {
    this.homeService.getDistricts().subscribe((res) => {
      console.log('The Districts', res['content']);
      this.districts = res['content'];
    });
  }
  getRelatedRequest() {
    this.homeService.getAllTasks({}).subscribe((res) => {
      console.log('The All task', res);
      this.related_task = res['content'];
    });
  }

  getItems() {
    // this.homeService.getItems(1).subscribe((res) => {
    //   console.log('The items', res['content']);
    //   this.items = res['content'];
    // });
  }

  onItemChange(id: any) {
    this.items.forEach((element) => {
      if (element.id == +id) {
        console.log(
          'The Related:- ',
          element.more[2].value[20].value.value_array
        );
        this.relatedContacts = element.more[2].value[20].value.value_array;
      }
    });
    this.itemID = +id;
    this.getRelatedContacts(id);

    let body = { cms_id: this.itemID };
    this.homeService.getPriority(body).subscribe((res) => {
      console.log('The priority', res['content']);
      this.priority = res['content'];
    });
    console.log('The id', this.itemID);
  }

  
  onTypeSelected(id: any) {
    this.typeID = +id;
    console.log('The selecte type', this.typeID);
    // this.getRelatedContacts(id);
    if (this.typeID == 1) {
      this.taskForm
        .get('related_contact_id')
        .setValidators(Validators.required); // 5.Set Required Validator
      this.taskForm.get('related_contact_id').updateValueAndValidity();
    } else if (this.typeID == 2) {
      this.taskForm.get('district_id').setValidators(Validators.required); // 5.Set Required Validator
      this.taskForm.get('district_id').updateValueAndValidity();
    } else if (this.typeID == 3) {
      this.taskForm.get('otherAction').setValidators(Validators.required); // 5.Set Required Validator
      this.taskForm.get('otherAction').updateValueAndValidity();
    }
  }

  getTask(id: number) {
    this.homeService.showTask(id).subscribe((res: any) => {
      this.task = res.content[0];
      this.typeID = this.task.request_type_id;
      this.onTypeSelected(this.typeID);
      console.log('this task :-', this.task);
      this.taskForm.patchValue({
        name: this.task.name,
        description: this.task.description,
        date: this.task.date,
        time: this.task.time,
        district_id: this.task.district_id,
        priority_level_id: this.task.priority_level_id,
        related_request_id: this.task.related_request_id,
        related_contact_id: this.task.related_contact_id,
        otherAction: this.task.otherAction,
        indoor: this.task.indoor,
        users: this.task.users,
        notes: this.task.notes,
        request_type_id: this.task.request_type_id,
        item_id: this.task.item_id,
        request_status_id: this.task.request_status_id,
      });

      //   console.log("users selected:",this.task.users);

      //  this.onTypeSelected(+this.task.request_type_id-1);
      this.onItemChange(this.task.item_id);

      //  this.getRelatedContacts(this.task.item_id);
    });
  }

  getStatuses() {
    this.homeService.getTaskStatuses().subscribe(
      (res) => {
        this.statuses = res['content'];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getRelatedContacts(id: number) {
    this.homeService.getRelatedContacts(id, 1).subscribe(
      (res) => {
        console.log('The Related Contacts of the cms is fff: ', res);
        this.relatedContacts = res['content'];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  submit() {
    this.isEnabled = true;
    if (this.taskForm.valid) {
      if (this.taskForm.value.indoor == true) {
        this.taskForm.value.indoor = 1;
      } else {
        this.taskForm.value.indoor = 2;
      }

      console.log('the from type is call', this.taskForm.value);
      if (!this.id) {
        let userList = [];
        this.taskForm.value.users.forEach((ele) => {
          userList.push(ele.id);
        });
        this.taskForm.value.users = userList;
        console.log('users :-', userList);
        this.taskForm.value.request_status_id = 1;

        this.homeService.createTask(this.taskForm.value).subscribe(
          (res: any) => {
            this.isEnabled = false;
            console.log('the response:- ', res);
            if (res.status.code == 200) {
              if (this.lang == 'en') {
                this.toastService.success('Task Created Successfully');
              } else {
                this.toastService.success(' تم انشاء التاسك بنجاح');
              }
              this.router.navigate(['tasks', this.lang]);
            }
          },
          (error) => {
            this.isEnabled = false;
            console.log(error);
          }
        );
      } else {
        let obj: any = this.taskForm.value;
        obj.id = this.id;
        obj.time = obj.time;
        console.log('The Object', obj);

        let userList = [];
        this.taskForm.value.users.forEach((ele) => {
          userList.push(ele.id);
        });
        this.taskForm.value.users = userList;
        this.homeService.updateTask(obj).subscribe(
          (res: any) => {
            this.isEnabled = false;
            console.log('the response:- ', res);
            if (res.status.code == 200) {
              if (this.lang == 'en') {
                this.toastService.success('Task Updated Successfully');
              } else {
                this.toastService.success(' تم تعديل التاسك بنجاح');
              }
              this.router.navigate(['tasks', this.lang]);
            } else if (res.status.code == 403) {
              this.toastService.error(
                res.status.validation_errors[0].message[0]
              );
            }
          },
          (error) => {
            this.isEnabled = false;
            console.log(error);
          }
        );
      }
    } else {
      this.isEnabled = false;
    }
  }

  getNextBatch() {
    this.index = this.index + 1;
    this.homeService.getItems(this.index).subscribe((res) => {
      console.log('The items', res['content']);
      this.items = res['content'];
      console.log('The items :::::::::::::', this.items);

      // res['content'].forEach(ele=>{
      //   this.items.push(ele);
      // });
    });
    // this.offset += this.limit;
  }

}
