import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';


@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private httpClientService: HttpClientService) { }

  GetCategoryList() {

    let body={}
    return this.httpClientService.post('api/category_values', body,  {});
  }
  
}
