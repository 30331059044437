    <!-- Start Page Content -->
    <div class="container pg-content">
        <div class="pg-header-content">
            <h1 class="pg-title" *ngIf="!serviceId">
              {{'AddNewService'|translate}}
            </h1>

            <h1 class="pg-title" *ngIf="serviceId">
              {{'updateService'|translate}}
            </h1>
          </div>
        
        <!--Start Contact Form -->
        <div class="col-sm-12 text-center">
          <!-- Success message -->
          <!-- <div class="alert alert-success" role="alert" id="success">
              <p>Your Project was sent succssfully! We will be in touch as soon as possible.</p>
          </div> -->
          <!-- Error message -->
         <!--  <div class="alert alert-error" role="alert" id="error">
              <p>Something went wrong, verify the fields or try refreshing the page.</p>
          </div> -->
          <form [formGroup]="serviceForm" #myform="ngForm" (ngSubmit)="onSubmit()" id="projectForm" name="projectForm" class="form-horizontal contact-form">
                <!-- ٍselect-->
                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="fa fa-map-marker"></i></span>
                            <!-- <select  name="contact_id" formControlName="contact_id"  id="places" class="form-control" required>
                                <option disabled selected value="">{{'Home.selectPlace' | translate}}</option>
                                <option  value={{item.id}} *ngFor="let item of contactlist"> {{item.title}} </option>
                            </select> -->

                            <ngx-select-dropdown
                                formControlName="contact_id"
                                [multiple]="false"
                                [config]="config"
                                [options]="contactlist"
                                [required]="true"
                                [(ngModel)]="val"
                            ></ngx-select-dropdown>
                        </div>
                        <p *ngIf="contactlist?.length == 0" class="text-danger">
                          {{'noPlace' | translate}}
                          <button (click)="gotoPlace()" class="btn btn-view">
                              <i class="fa fa-plus"></i>
                          </button>
                        </p>
                        <span *ngIf="!val && myform.submitted"
                            class="error-msg">
                            {{'place'|translate}} {{'required'|translate}}
                        </span>
                    </div>
                </div>

                <!-- Text input-->
                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="glyphicon glyphicon-pencil"></i></span>
                            <input formControlName="price"  name="price" id="price" placeholder="{{'price'|translate}}" class="form-control"  required>
                        </div>
                        <span *ngIf="serviceForm.controls['price']?.errors?.required && myform.submitted"
                            class="error-msg">
                            {{'price'|translate}} {{'required'|translate}}
                        </span>
                    </div>
                </div>

                <!-- Text input-->
                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="glyphicon glyphicon-pencil"></i></span>
                            <input formControlName="name" [(ngModel)]="query" (ngModelChange)="search(query)" name="name" id="title" placeholder="{{'serviceNameEn'|translate}}" class="form-control"  required>
                        </div>

                        <span *ngIf="serviceForm.controls['name']?.errors?.required && myform.submitted"
                            class="error-msg">
                            {{'serviceNameEn'|translate}} {{'required'|translate}}
                        </span>
                    </div>
                </div>

                <div *ngIf="result.length > 0 && show == true">
                  <ul>
                      <li *ngFor="let res of result" (click)="setName(res.name)">
                          {{res.name}}
                      </li>
                  </ul>
                </div>

                <!-- Text input-->
                <div class="form-group" *ngIf="showText">
                  <div class="col-sm-12 inputGroupContainer">
                      <div class="input-group">
                          <span class="input-group-addon"><i class="glyphicon glyphicon-pencil"></i></span>
                          <input formControlName="name_ar" name="name_ar" id="name_ar" placeholder="{{'serviceNameAr'|translate}}" class="form-control"  required>
                      </div>
                      <span *ngIf="serviceForm.controls['name_ar']?.errors?.required && myform.submitted"
                            class="error-msg">
                            {{'serviceNameAr'|translate}} {{'required'|translate}}
                        </span>
                  </div>
                </div>

                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                      <div class="input-group">
                        <span class="input-group-addon">
                          <i class="fas fa-user"></i>
                        </span>
                        <!-- <select formControlName="users" name="users" id="users" class="form-control">
                          <option [ngValue]="user.id" *ngFor="let user of users">{{user.full_name}}</option>
                        </select> -->
                        <div>
                          <!-- <p-multiSelect 
                            [options]="CitiesData"
                            id="users"
                            defaultLabel="Select Cities"
                            formControlName="cities"
                            name="cities"
                            optionLabel="name"
                            [style]="{'width':'100%','text-align': 'start','font-size':'16px'}"
                            [panelStyle]="{'minWidth':'100%','font-size':'16px'}"
                            [filter]="false"
                         ></p-multiSelect> -->

                         <ng-multiselect-dropdown
                          placeholder="{{'Select Cities' | translate}}"
                          [settings]="dropdownSettings"
                          [data]="CitiesData"
                          formControlName="cities"
                          [(ngModel)]="selectedItems"
                          (onSelect)="onItemSelect($event)"
                          (onSelectAll)="onSelectAll($event)"
                        >
                        </ng-multiselect-dropdown>
                     </div> 
                       
                        <span *ngIf="serviceForm.controls['cities']?.errors?.required && myform.submitted"
                          class="error-msg">
                          {{'Select Cities' | translate}} {{'required'|translate}}
                        </span>
                        
                      </div>
                    </div>
                  </div>

                <!-- Image upload -->
                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="glyphicon glyphicon-cloud-upload"></i></span>
                            <input style="display: none;" formControlName="image" name="image" accept="image/*" (change)="readImage($event)" type="file" class="form-control" placeholder="{{'UploadProjectImage'|translate}}" id="project_img">
                            <label *ngIf="!photo" class="form-control" style="text-align: start; cursor: pointer;"  for="project_img">
                                <i class="fa fa-cloud-upload"></i>{{'UploadServiceImage' | translate}}
                                <span class="imageName">{{imageName}}</span>
                              </label>

                              <div *ngIf="photo" class="js-uploader__file-list uploader__file-list">
                                <div class="js-uploader__file-list ">
                                    <div class="">
                                        <div class="" style="width: 90px;">
                                           <span class="uploader__file-list__thumbnail">
                                            <img id="profileImg" class="thumbnail" id="img_" src="{{photo}}" style="margin-bottom: 0px;">    
                                          </span>  
                                        </div>
                                        <div>
                                          <span class="close">
                                            <a id="close" (click)="closebtn()" class="uploader__icon-button fa fa-times cursor--pointer">
                                            </a>
                                          </span>  
                                       </div>
                                    </div>
                                </div>
                              </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                  <div class="col-sm-12 inputGroupContainer">
                    <div class="input-group">
                      <span class="input-group-addon"><i class="glyphicon glyphicon-th-list"></i></span>
                      <select formControlName="category_id" name="category_id" id="category_id" class="form-control">
                        <option *ngFor="let item of categorylist" value={{item.id}}>{{item.title}}</option>
                      </select>
                      <span *ngIf="serviceForm.controls['category_id']?.errors?.required && myform.submitted"
                            class="error-msg">
                            {{'Category'|translate}} {{'required'|translate}}
                      </span>

                    </div>
                    </div>
                </div>

                <!-- desc textarea-->
                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                        <div class="input-group">
                            <textarea  name="description" formControlName="description" id="desc" class="form-control"
                            placeholder="{{'description'|translate}}" required></textarea>
                        </div>
                        <span *ngIf="serviceForm.controls['description']?.errors?.required && myform.submitted"
                            class="error-msg">
                            {{'description'|translate}} {{'required'|translate}}
                        </span>
                    </div>
                </div>
        
                <!-- Submit Button -->
                <div class="form-group sub">
                <div class="col-sm-12">
                    <button type="submit" name="name" class="btn btn-warning submit" >
                        <i *ngIf="isEnabled" class="fa fa-spinner fa-spin"></i> {{'Submit'|translate}}</button>
                </div>
                </div>
          </form>
        </div>
        <!--End Contact Form-->
      </div>
      <!-- End Page Content -->
    