import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../Shared/Services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {FormGroup, FormControl, FormBuilder, Validators, FormArray} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../Shared/Services/authentication.service';
import {CitiesService} from '../../Shared/Services/cities.service';
import {HomeService} from '../../Shared/Services/home.service';
import {SearchCountryField, CountryISO, PhoneNumberFormat} from 'ngx-intl-tel-input';
import {HttpClientService} from 'src/app/Shared/Services/http-client.service';
import {PlacesService} from '../../Shared/Services/places.service';
import {AgmMap, MapsAPILoader, MouseEvent} from '@agm/core';
import { LanguageService } from 'src/app/Shared/Services/language.service';

declare var $: any;
declare var google;

@Component({
  selector: 'app-register-corporate',
  templateUrl: './register-corporate.component.html',
  styleUrls: ['./register-corporate.component.scss']
})


export class RegisterCorporateComponent implements OnInit {

  registerForm1: FormGroup;
  registerForm2: FormGroup;
  registerForm3: FormGroup;


  errors: any[] = [];
  errorList;

  lang_id;
  lang
  currentlang: string;

  fileName: string = '';
  type;
  image_url: any;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Egypt, CountryISO.SaudiArabia];

  codelist;


  fileName2: string = '';
  type2;
  image_url2: any;

  fileName3: string = '';
  type3;
  image_url3: any;


  containers = [];
  sarray: FormArray;


  socialarray: FormArray;
  socialcontainers = [];

  services;
  products;

  // title = 'My first AGM project';
  // lat = 51.678418;
  // lng = 7.809007;

  geocoder: any;
  public location: Location = {
    lat: 30.043408537674175,
    lng: 31.30309544531249,
    marker: {
      lat: 30.043408537674175,
      lng: 31.30309544531249,
      draggable: true
    },
    zoom: 5
  };
  address: string = '';
  latitude: any;
  longitude: any;

  @ViewChild(AgmMap, {static: true}) map: AgmMap;
  @ViewChild('searchElementRef')
  public searchElementRef: ElementRef;

  // title = 'rou';
  //Local Variable defined
  formattedaddress = " ";
  options = {
    componentRestrictions: {
      country: ["EG"]
    }
  }


  constructor(private userservice: UserService,
              private translateservice: TranslateService,
              private toastrService: ToastrService,
              private authservice: AuthenticationService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public translate: TranslateService,
              private cityservices: CitiesService,
              private homeservices: HomeService,
              private httpClientService: HttpClientService,
              private fb: FormBuilder,
              private placeservices: PlacesService,
              public mapsApiLoader: MapsAPILoader,
              private languageService: LanguageService,
              private ngZone: NgZone) {


    this.getCountryTelephoneCode();

    // intiate the 3rd form
    this.registerForm3 = fb.group({
      students: fb.array([this.createFields()]),
      socials: fb.array([this.CreateFieldsSocial()])

    })

    // google maps
    this.mapsApiLoader = mapsApiLoader;
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.location.lat = place.geometry.location.lat();
          this.latitude = place.geometry.location.lat();
          this.location.lng = place.geometry.location.lng();
          this.longitude = place.geometry.location.lng();
          this.location.zoom = 12;
        });
      });
    });


  }

  ngOnInit(): void {

    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    if (this.lang == 'en') {
      this.lang_id = 1;
    } else {
      this.lang_id = 2;
    }

    this.getCountryTelephoneCode();
    this.currentlang = localStorage.getItem('lang');

    // intiate the 1st form
    this.registerForm1 = new FormGroup({

      logo: new FormControl(''),
      full_name: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      email: new FormControl('', [Validators.required, Validators.email]),

    })

    // intiate the 2nd form
    this.registerForm2 = new FormGroup({


      services: new FormControl(''),
      products: new FormControl(''),
      company_description: new FormControl(''),
      tax_card: new FormControl('', [Validators.required]),
      commercial_record: new FormControl('', [Validators.required]),

    })


    this.cityservices.getServices(1, this.lang).subscribe((res: any) => {
      this.services = res.content;
      console.log("The Service Api :-------", this.services)
    })

    this.homeservices.getAllProducts(1).subscribe((res: any) => {
      this.products = res.content
    })


    document.getElementById('form2').classList.add('hide');
    document.getElementById('form3').classList.add('hide');
    document.getElementById('form4').classList.add('hide');

    document.getElementById('service_dropdown').classList.add('hide');


    // google maps
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder;
    });


  }


  public AddressChange(address: any) {
    //setting address from API to local variable
    this.formattedaddress = address.formatted_address
  }


  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  // mapClicked($event: MouseEvent) {
  //   this.markers.push({
  //     lat: $event.coords.lat,
  //     lng: $event.coords.lng,
  //     draggable: true
  //   });
  // }

  // markerDragEnd(m: marker, $event: MouseEvent) {
  //   console.log('dragEnd', m, $event);
  // }

  // markers: marker[] = [
  //   {
  // 	  lat: 51.673858,
  // 	  lng: 7.815982,
  // 	  label: 'A',
  // 	  draggable: true
  //   },
  //   {
  // 	  lat: 51.373858,
  // 	  lng: 7.215982,
  // 	  label: 'B',
  // 	  draggable: false
  //   },
  //   {
  // 	  lat: 51.723858,
  // 	  lng: 7.895982,
  // 	  label: 'C',
  // 	  draggable: true
  //   }
  // ]


  markerDragEnd($event: MouseEvent) {
    console.log('dragEnd', $event);
    this.location.lat = $event.coords.lat;
    this.location.lng = $event.coords.lng;
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.location.lat, this.location.lng);
  }

  getAddress(latitude, longitude) {
    this.geocoder.geocode({'location': {lat: latitude, lng: longitude}}, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.location.zoom = 12;
          this.address = results[0].formatted_address;
          // this.getAddresses(this.address);
          console.log('Address is:- ', this.address);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });


  }

  detectLocation() {
    window.navigator.geolocation.getCurrentPosition(pos => {
      console.log("Latitude ", pos.coords.latitude);
      console.log("Longitude ", pos.coords.longitude);

      this.location.lat = pos.coords.latitude;
      this.location.lng = pos.coords.longitude;
      this.latitude = pos.coords.latitude;
      this.longitude = pos.coords.longitude;
      this.getAddress(this.location.lat, this.location.lng);
    })
  }


  createFields() {
    return this.fb.group({
      address: new FormControl(''),
    })
  }

  add() {
    this.sarray = this.registerForm3.get('students') as FormArray;
    this.sarray.push(this.createFields());
    this.containers.push(this.containers.length);
  }

  removeProgram(StudentIndex) {
    this.sarray = this.registerForm3.get('students') as FormArray;
    this.sarray.removeAt(StudentIndex);
    this.containers.splice(0, 1)

  }


  CreateFieldsSocial() {
    return this.fb.group({
      value: new FormControl(''),
      social_media_type: new FormControl(''),
    })

  }

  addSocialinks() {
    this.socialarray = this.registerForm3.get('socials') as FormArray;
    this.socialarray.push(this.CreateFieldsSocial());
    this.socialcontainers.push(this.socialcontainers.length);
  }

  removeSocial(SocialIndex) {
    this.socialarray = this.registerForm3.get('socials') as FormArray;
    this.socialarray.removeAt(SocialIndex);
    this.socialcontainers.splice(0, 1)

  }


  readImage(event) {
    var files = event.target.files[0];
    this.type = event.target.files[0].type;
    console.log(this.type);
    this.fileName = files.name;
    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }


  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.image_url = `data:${this.type};base64,` + btoa(binaryString);
    // console.log("Corporate Image :::::::::::::", this.image_url )

  }

  getCountryTelephoneCode() {
    this.cityservices.GetCountryCodeList().subscribe((res: any) => {
      this.codelist = res.content;
      console.log(this.codelist)
    })
  }


  readImage2(event) {
    var files2 = event.target.files[0];
    this.type2 = event.target.files[0].type;
    console.log("Tax file type:-----", this.type2);
    this.fileName2 = files2.name;
    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded2.bind(this);
    reader.readAsBinaryString(files2);
    console.log("Tax file image:-----", this.fileName2)
  }


  handleReaderLoaded2(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.image_url2 = `data:${this.type2};base64,` + btoa(binaryString);
    console.log(this.image_url2)

  }

  readImage3(event) {
    var files3 = event.target.files[0];
    this.type3 = event.target.files[0].type;
    console.log("Commerial Image type:----", this.type3);
    this.fileName3 = files3.name;
    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded3.bind(this);
    reader.readAsBinaryString(files3);
    console.log("Commerial Image:----", this.fileName3)
  }


  handleReaderLoaded3(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.image_url3 = `data:${this.type3};base64,` + btoa(binaryString);
    console.log(this.image_url3)

  }

  SubmitForm1(value: any) {
    // Submit the 1st form here

    if (value.invalid && value.pristine) {
      value.markAllAsTouched();
    }

    else {

      let submitobj = {
        "logo": this.image_url,
        "full_name": this.registerForm1.get('full_name').value,
        "mobile": this.registerForm1.value.mobile.number,
        "tele_code": this.registerForm1.value.mobile.dialCode,
        "email": this.registerForm1.get('email').value,
        "password": this.registerForm1.get('password').value,
        "lang_id": this.lang_id,
        // "user_type":3

      }

      this.userservice.validateuser(submitobj).subscribe((res:any) => {

        console.log("The validate form 1 API Response:----", res)
        console.log("rrrrrrrrrrrrrrrrrr :-----",res)

        if (res.status.code == 200) {


          document.getElementById('form1').classList.add('hide');
          document.getElementById('form2').classList.remove('hide');
          document.getElementById('stepcircle2').classList.add('step-selected');

          console.log("Next Button of Step 1 :----", this.registerForm1.value)
        } else if (res.status.code == 401) {
          console.log("error code is :-----------",res.status.code)
          let validation_error = res.status.validation_errors;
          console.log(res.status.validation_errors)
          validation_error.forEach(element => {

            if (element.field == 'full_name') {
              this.toastrService.error(element.message);
            } else if (element.field == 'mobile') {
              this.toastrService.error(element.message);
              console.log("validation error :::::::",element.message )
            } else if (element.field == 'password') {
              this.toastrService.error(element.message);
            } else if (element.field == 'tele_code') {
              this.toastrService.error(element.message);
            } else if (element.field == 'email') {
              this.toastrService.error(element.message);
            } else if (element.field == 'lang_id') {
              this.toastrService.error(element.message);
            }


          })

        } else if (res.status.code == 403) {
          this.toastrService.error(res.status.error_details);
          console.log(res.status.validation_errors)
        }


      }, error=>{
        let validation_error = error.error.status.validation_errors;
        console.log(error.error.status.validation_errors)
        validation_error.forEach(element => {

          if (element.field == 'full_name') {
            this.toastrService.error(element.message);
          } else if (element.field == 'mobile') {
            this.toastrService.error(element.message);
            console.log("validation error :::::::",element.message )
          } else if (element.field == 'password') {
            this.toastrService.error(element.message);
          } else if (element.field == 'tele_code') {
            this.toastrService.error(element.message);
          } else if (element.field == 'email') {
            this.toastrService.error(element.message);
          } else if (element.field == 'lang_id') {
            this.toastrService.error(element.message);
          }


        })
      })


    }


    // document.getElementById('form1').classList.add('hide');
    // document.getElementById('form2').classList.remove('hide');
    // document.getElementById('stepcircle2').classList.add('step-selected');

  }

  SubmitForm2(value: any) {
    // Submit the 2nd form here

    if (value.invalid && value.pristine) {
      value.markAllAsTouched()
    } else if (value.invalid && value.dirty) {
      Object.keys(value.controls).forEach(field => {
        const control = value.get(field);
        control.markAsTouched({onlySelf: true});
      });

      if (this.lang == 'en') {
        this.toastrService.error('Please enter the rest of the data');
        console.log("form is still invalid")
      } else {
        this.toastrService.error('من فضلك ادخل باقي البيانات');
        console.log("form is still invalid")
      }

    } else if (value.valid == true) {


      document.getElementById('form2').classList.add('hide');
      document.getElementById('form3').classList.remove('hide');
      document.getElementById('stepcircle3').classList.add('step-selected');

    }


  }


  SubmitForm3(value: any) {
    console.log("form 3 address :-----", this.registerForm3.value.students[0].address)
    console.log("form 3 social media :-----", this.registerForm3.value.socials)

    // Submit The whole object
    // Call The Api

    let submitobj = {
      "logo": this.image_url,
      "full_name": this.registerForm1.get('full_name').value,
      "mobile": this.registerForm1.value.mobile.number,
      "tele_code": this.registerForm1.value.mobile.dialCode,
      "email": this.registerForm1.get('email').value,
      "password": this.registerForm1.get('password').value,
      "lang_id": this.lang_id,

      "products": this.registerForm2.get('products').value,
      "services": this.registerForm2.get('services').value,
      "company_description": this.registerForm2.get('company_description').value,
      "commercial_record": this.registerForm2.get('commercial_record').value,
      "tax_card": this.registerForm2.get('tax_card').value,


      "latitude": this.latitude,
      "longitude": this.longitude,
      "address": this.registerForm3.value.students[0].address,
      "socilaMedia": this.registerForm3.value.socials,
      "user_type": 3,
      "rule_id": 3
    }

    console.log("The Submitted Object to the API :-----", submitobj)

    this.userservice.registeruser(submitobj).subscribe((res: any) => {

      console.log("The Corporate Register API Response:----", res);

      if (res.status.code === 200) {
        // this.userservice.getOtp(this.registerForm1.value.mobile.e164Number);
          localStorage.setItem('phoneNumber',this.registerForm1.value.mobile.e164Number.toString());
        // localStorage.setItem('RuleID',"corporate")
        document.getElementById('form3').classList.add('hide');
        document.getElementById('form4').classList.remove('hide');
        document.getElementById('stepcircle4').classList.add('step-selected');
        this.router.navigate(['login', this.lang]);
      } else if (res.status.code === 401) {
        let validation_error = res.status.validation_errors;
        console.log(res.status.validation_errors);
        validation_error.forEach(element => {
          if (element.field == 'full_name') {
            this.toastrService.error(element.message);
          } else if (element.field == 'mobile') {
            this.toastrService.error(element.message);
          } else if (element.field == 'password') {
            this.toastrService.error(element.message);
          } else if (element.field == 'tele_code') {
            this.toastrService.error(element.message);
          } else if (element.field == 'email') {
            this.toastrService.error(element.message);
          } else if (element.field == 'lang_id') {
            this.toastrService.error(element.message);
          } else if (element.field == 'commercial_record') {
            this.toastrService.error(element.message);
          } else if (element.field == 'tax_card') {
            this.toastrService.error(element.message);
          }

        })

      } else if (res.status.code === 403) {
        this.toastrService.error(res.status.error_details);
        console.log(res.status.validation_errors)
      } else if (res.status.code === 500) {
        this.toastrService.error(res.status.error_details);
        console.log("The error is :------", res.status.error_details)
        window.location.reload()
      }


    })


  }

  NavigateToHome() {
    this.router.navigate(['../../home', this.lang]);
  }


  showproductdropdown() {
    document.getElementById('productt_dropdown').classList.remove('hide');
    document.getElementById('service_dropdown').classList.add('hide');

  }

  showservicedropdown() {
    document.getElementById('productt_dropdown').classList.add('hide');
    document.getElementById('service_dropdown').classList.remove('hide');
  }


}

interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

interface Location {
  lat: number;
  lng: number;
  viewport?: Object;
  zoom: number;
  address_level_1?: string;
  address_level_2?: string;
  address_country?: string;
  address_zip?: string;
  address_state?: string;
  marker?: Marker;
}


