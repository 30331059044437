import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe, formatDate } from '@angular/common';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';

import Swal from 'sweetalert2';
import { CitiesService } from 'src/app/Shared/Services/cities.service';

declare var $: any;



@Component({
  selector: 'app-users-create',
  templateUrl: './users-create.component.html',
  styleUrls: ['./users-create.component.scss']
})
export class UsersCreateComponent implements OnInit {

  userForm: FormGroup;
  type;
  image_url: any;
  profile_img = '';
  date14: Date;
  codelist;
  fileName: string = '';
  currentlang: string;
  isEnabled: boolean = false;

  constructor(private router: Router,
    private toastr: ToastrService,
    private cityservices: CitiesService,
    private translateservice: TranslateService, private service: HttpClientService) {
    this.getCountryTelephoneCode();

  }

  ngOnInit(): void {
    this.currentlang = localStorage.getItem('lang');

    this.userForm = new FormGroup({
      full_name: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
      tele_code: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      photo: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(9)]),


      job_title: new FormControl(''),
      is_active: new FormControl(''),

      birthdate: new FormControl('', [Validators.required]),
      rule_id: new FormControl('4'),

      address: new FormControl(''),
      gender_id: new FormControl('', [Validators.required]),
      user_key: new FormControl('', [Validators.required]),



    })

    this.currentlang = localStorage.getItem('lang');

  }
  getCountryTelephoneCode() {
    this.cityservices.GetCountryCodeList().subscribe((res: any) => {
      this.codelist = res.content;
      console.log(this.codelist)
    })
  }
  readImage(event) {
    var files = event.target.files[0];
    this.type = event.target.files[0].type;
    console.log(files);

    this.fileName = files.name;

    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }


  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.image_url = `data:${this.type};base64,` + btoa(binaryString);
    // console.log(this.image_url )


  }

  closebtn(index) {
    this.image_url = '';
    $("#image_url").val('')

  }

  openImg() {
    var win = window.open();
    win.document.write('<iframe src="' + this.image_url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  }
  create(formdata) {
    this.isEnabled = true;
    let value = this.userForm;
    if (value.invalid && value.pristine) {
      value.markAllAsTouched();
      if ((this.currentlang == 'en')) {
        this.toastr.error('please fill the information ');
        console.log("form data ================= ", formdata);

      }
      else if ((this.currentlang == 'ar')) {
        console.log("form data ================= ", formdata);

        this.toastr.error('من فضلك ادخل  البيانات');

      }
    } else if (value.invalid && value.dirty) {
      Object.keys(value.controls).forEach(field => {
        const control = value.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      if ((this.currentlang == 'en')) {
        this.toastr.error('Please enter the rest of the information');
        console.log("form data ================= ", formdata);


      }
      else if ((this.currentlang == 'ar')) {
        this.toastr.error('من فضلك ادخل باقي البيانات');
        console.log("form data ================= ", formdata);


      }
    }
    else if (value.valid) {

      if (formdata.photo.length > 0)
        formdata.photo = this.image_url;
      else {
        formdata.photo = '';

      }
      // if(formdata.birthdate)
      // {
      //   let pipe = new DatePipe('en');
      //   console.log(formdata.birthdate);
      //   formdata.birthdate = this.userForm.value['birthdate'].year.toString()+ '-' + this.userForm.value['birthdate'].month.toString() + '-' + this.userForm.value['birthdate'].day.toString();

      //   formdata.birthdate=pipe.transform(formdata.birthdate, 'yyyy-MM-dd');
      // }
      if (formdata.is_active.length == 0) {
        formdata.is_active = '0'
      }
      else if (formdata.is_active == true) {
        formdata.is_active = '1'

      }
      console.log("form data", formdata);
      this.service.post('api/employees/store', formdata, {}).subscribe((res: any) => {
        console.log("create =>", res);
        if (res.status.code == 200) {
          this.isEnabled = false;
          if ((this.currentlang == 'en')) {
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: 'user created successfully',
            })

          }
          else if ((this.currentlang == 'ar')) {
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: 'تم اضافه مستخدم',
            })


          }
          this.userForm.reset();
        }
        else {
          this.isEnabled = false;
          this.toastr.error(res.status.validation_errors[0].message[0]);

        }

      })

    }

  }








}
