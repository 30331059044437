import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { HttpHeaders,HttpRequest,HttpParams  } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class ChatService {

  header:HttpHeaders = new HttpHeaders();


  constructor(private httpClientService: HttpClientService) { }

  GetMyRequestsList(obj){

    let body={
      'contacts':[obj],
      'lang_id':1
    }
    return this.httpClientService.post('api/contacts_question/list', body,  {});

  }

  GetCompanyRequestsList(obj){

    let body={
      'created_by':obj,
      'lang_id':1
    }
    return this.httpClientService.post('api/contacts_question/list', body,  {});

  }

  showCompanyRequest(Company_Request_ID){


    let body={
      'id':Company_Request_ID,
      'lang_id':1
    }
    return this.httpClientService.post('api/contacts_question/show', body,  {});

  }

  AddCompanyQuestion(item,UserID,Place_ID,Company_Contact_Question_ID){
    let body={
      'contact_question_id': Company_Contact_Question_ID,
      'answer': item.question,
      'contact_id':Place_ID,
      'created_by':UserID,
      'lang_id':1
    }
    return this.httpClientService.post('api/contacts_question/answers/store', body,  {});
  }


  GetGeneralRequestList(){
     return this.httpClientService.get('api/negotiations/list', {},{});
  }


  ShowGeneralRequest(RequestID){
    let body={
      'id':RequestID,
      'lang_id':1
    }
    return this.httpClientService.post('api/negotiations/show', body,  {});
  }


  SendGeneralRequest(ItemID  , comment){
    let body={
      'comment': comment,
      'negotiation_id':ItemID,
      'lang_id':1
    }
    return this.httpClientService.post('api/negotiations/store', body,  {});
  }




  SendQuickChat(UserID,Place_ID,Question){
    let body={
      'question': Question,
      'contact_id':Place_ID,
      'created_by':UserID,
      'lang_id':1
    }
    return this.httpClientService.post('api/contacts_question/store', body,  {});
  }



}
