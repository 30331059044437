import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CitiesService } from 'src/app/Shared/Services/cities.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-service-item',
  templateUrl: './service-item.component.html',
  styleUrls: ['./service-item.component.scss']
})
export class ServiceItemComponent implements OnInit {
  itemForm: FormGroup;
  currentlang: string;
  isEnabled: boolean = false;
  categoryId: number;
  query: string;
  result: any[] = [];
  show: boolean = true;
  dropdownSettings = {};
  selectedItems = [];
  selected:any[] = [];
  citieslist:any[] = [];
  lang_id:any;
  allSelected: boolean = false;

  service_name;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate:TranslateService,
    private service:HttpClientService,
    private cityservice:CitiesService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      res=>{
        this.categoryId = +res['id'];
        console.log("IDDD0", this.categoryId);
        this.service_name = res.name;
        console.log("service name :::::::::", this.service_name);

      }
    );
    this.currentlang = localStorage.getItem('lang');
    this.itemForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      category_id: new FormControl(''),
      cities: new FormControl(''),
      all_cities_included: new FormControl(false)
    })

    this.currentlang = localStorage.getItem('lang');
    if(this.currentlang == 'en'){
      this.lang_id = 1;
    }else{
      this.lang_id = 2;
    }
    this.translate.use(this.currentlang);

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.getcitieslist();
  }

  create(value) {
    this.isEnabled = true;
    if(this.itemForm.valid){
      value.category_id = this.categoryId;

    value.cities = this.selected;
    value.all_cities_included = this.allSelected;
    console.log(value);
    this.service.post('api/items/store', value, []).subscribe(
      (res: any)=>{
        this.isEnabled = false;
        console.log(res);
        if(res.status.code == 400){
          if(this.currentlang == 'en'){
            // this.toastr.error('Please Login and try again')

            Swal.fire({
              icon: 'error',
              title: ' ',
              text: 'Please Login and try again',
            })
          }else{
            // this.toastr.error('من فضلك قم بتسجيل الدخول وحاول مرة أخرى')
            Swal.fire({
              icon: 'error',
              title: ' ',
              text: 'من فضلك قم بتسجيل الدخول وحاول مرة أخرى',
            })
          }
        }else if(res.status.code == 200){
          this.router.navigate(['home', this.currentlang]);
          if(this.currentlang == 'en'){
            // this.toastr.success('Request Created Successfully')
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: 'The request was  created successfully',
            })
          }else{
            // this.toastr.success('تم انشاء الطلب بنجاح')
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: 'تم الطلب بنجاح' ,
            })
          }
        }
        else if (res.status.code == 401){
          // this.toastr.error(res.status.error_details)

          let validation_errors = res.status.validation_errors;
          validation_errors.forEach(element => {
            if(element.field == 'cities')
             {this.toastr.error(element.message)}
            else if(element.field == 'all_cities_included')
             {this.toastr.error(element.message)}
            else if(element.field == 'category_id')
             {this.toastr.error(element.message)}
            else if(element.field == 'description')
             {this.toastr.error(element.message)}
            else if(element.field == 'title')
             {this.toastr.error(element.message)}
            else if(element.field == 'name')
             {this.toastr.error(element.message)}

          })
        }
        else if (res.status.code == 403){
          let validation_errors = res.status.validation_errors;
          validation_errors.forEach(element => {
            if(element.field == 'cities')
             {this.toastr.error(element.message)}
            else if(element.field == 'all_cities_included')
             {this.toastr.error(element.message)}
            else if(element.field == 'category_id')
             {this.toastr.error(element.message)}
            else if(element.field == 'description')
             {this.toastr.error(element.message)}
            else if(element.field == 'title')
             {this.toastr.error(element.message)}
            else if(element.field == 'name')
             {this.toastr.error(element.message)}

          })
        }

      },
      error=>{
        console.log(error);
        let validation_errors = error.error.status.validation_errors;
        validation_errors.forEach(element => {
          if(element.field == 'cities')
           {this.toastr.error(element.message)}
          else if(element.field == 'all_cities_included')
           {this.toastr.error(element.message)}
          else if(element.field == 'category_id')
           {this.toastr.error(element.message)}
          else if(element.field == 'description')
           {this.toastr.error(element.message)}
          else if(element.field == 'title')
           {this.toastr.error(element.message)}
          else if(element.field == 'name')
           {this.toastr.error(element.message)}

        })
        this.isEnabled = false;
      }
    );
    }else{
      this.isEnabled = false;
    }
  }

  search(query: string){
    if(query == ''){
      this.show = true;
      this.query = '';
    }else{
      if(query?.length >= 3){
        this.service.get('api/items',{'name':query, lang_id: this.lang_id}, {}).subscribe(
          res=>{
            console.log("The Response:- ", res);
            this.result = res['content'];
          }
        );
      }
    }
  }

  setName(name: string){
    this.show = false;
    this.query = name;
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems)
    let arr:any[] = [];
    this.selectedItems.forEach(ele=>{
      this.citieslist.forEach(element => {
        if(element.id == ele.id){
          // this.selected.push(element);
          // console.log('match', element);
          arr.push(element.id);
        }
      });
    })


    this.selected = arr;
    console.log('selected', this.selected)
  }

  onSelectAll(items: any) {
    console.log(items);
    this.allSelected = true;
  }

  getcitieslist(){
    this.cityservice.GetCityList().subscribe((res:any)=>{
        this.citieslist = res.content;
        console.log(this.citieslist);

    })
  }

}
