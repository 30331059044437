import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  pageSize: number;
  totalPages: number;
  p: number = 1;
  totalapi: number;
  notifications: any[] = [];

  lang: string;
  userType: number;
  hasTasks: number;

  constructor(
    private service: HttpClientService,
    private router: Router,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.userType = +localStorage.getItem('userType');
    this.hasTasks = +localStorage.getItem('hasTasks');
    this.getNotifications();
  }

  getNotifications(page?) {
    let parameters = new HttpParams();
    let pagination = { pagination: 10 };

    if (page) {
      parameters = parameters.set('page', page + '');
    }
    this.service
      .post('api/notifications', pagination, parameters)
      .subscribe((res: any) => {
        console.log('user =>', res);
        this.notifications = res.content;
        this.totalapi = res.pagination?.total;
        this.pageSize = res.pagination?.per_page;
        this.p = res.pagination?.current_page;
      });
  }

  onPageChange(event) {
    this.p = event;
    this.getNotifications(this.p + '');
  }

  Navigate(item) {
    if (item.entity_name == 'items' || item.entity_name == 'العناصر') {
      if (item.notification_text.includes('accepted')) {
        this.router.navigate(['requests', this.lang]);
      } else {
      }
    } else if (
      item.entity_name == 'company_jobs' ||
      item.entity_name == 'وظائف الشركة'
    ) {
      this.router.navigate(['view-job', this.lang], {
        queryParams: { id: item.item_id },
      });
    } else if (
      (item.entity_name == 'Contacts' || item.entity_name == 'الأماكن') &&
      this.userType == 3
    ) {
      this.router.navigate(['my-profile', this.lang]);
    } else if (
      (item.entity_name == 'Contacts projects' ||
        item.entity_name == 'مشاريع الاتصالات') &&
      this.userType == 3
    ) {
      this.service
        .post('api/contacts_projects/show', { id: item.item_id }, [])
        .subscribe((res) => {
          let ID = res['content'].list[0].cms_id;
          this.router.navigate(['place-view', this.lang], {
            queryParams: { id: ID },
          });
        });
    } else if (
      (item.entity_name == 'Company users' ||
        item.entity_name == 'موظفي الشركة') &&
      this.userType == 3
    ) {
      this.router.navigate(['company-jobs', this.lang]);
    } else if (
      item.entity_name == 'Negotiations' ||
      item.entity_name == 'التفاوض'
    ) {
      this.router.navigate(['requests', this.lang]);
    } else if (
      item.entity_name == 'contact_question_answers' ||
      item.entity_name == 'contact_questions'
    ) {
      this.router.navigate(['chat', this.lang]);
    }
  }
}
