
<!-- Start Page Header -->
<div class="container-fluid profile-user pg-header">
    <div class="container ">
        <!-- <div class="user-detail">
        <button class="profile-btn" routerLink="../Update-Profile" >
            <i class="fa fa-pencil" aria-hidden="true"></i>

            {{'updateprofile'|translate}}
        </button>
    </div> -->


        <div class="row user-detail">

            <div class="col-md-3">
                <div class="user-image">
                    <!-- <img src="./assets/images/usr.jpg"  *ngIf="image == null || image == '0' " > -->
                    <img src="{{image}}"  *ngIf="image != null || image != '0' " >

                </div>

                <button class="profile-btn" [routerLink]="['/updateprofile', lang]"  >
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                    {{'updateprofile'|translate}}
                </button>

                <button *ngIf="userType != 1 " (click)="openEmployee()" class="profile-btn" style="margin-top: 20px;">
                  <i class="fa fa-link" aria-hidden="true"></i>
                  {{'add employee'|translate}}
                </button>

                <button *ngIf="userType != 1 "  (click)="openEmployees()" class="profile-btn" style="margin-top: 20px;">
                  <a  style="color: white;">
                    <i class="fa fa-users" aria-hidden="true"></i>
                    {{'Employees'|translate}}
                  </a>

                </button>



            </div>


            <div class="col-md-9 user-data">

              <div class="personal-items">
                <h2>{{username}}
                    <span *ngIf="usergender">({{usergender}})</span>
                </h2>
                <!-- <span class="post-label">Admin</span> -->
                <!-- <p><i class="fa fa-suitcase" aria-hidden="true"></i>  Rule of the user</p> -->
                <p *ngIf="usercode != ''"><i class="fa fa-phone" aria-hidden="true"></i>
                    <span *ngIf="lang == 'en'">
                        ({{usercode}}) - {{userphone}}
                    </span>
                    <span *ngIf="lang == 'ar'">
                        {{userphone}} - ({{arabic_usercode}})
                    </span>
                </p>
                <p *ngIf="useremail != ''"><i class="fa fa-envelope" aria-hidden="true"></i>
                    <span>

                        {{useremail}}
                    </span>
                </p>
                <p *ngIf="userType != 3"><i class="fa fa-birthday-cake"></i>
                    <!-- <b> {{'Birthdate'|translate}} </b> -->
                    <span>

                        {{userbirthdate}}
                    </span>
                </p>
                <p *ngIf="useraddress != ''"><i class="fa fa-map-marker" aria-hidden="true"></i>
                    <span>

                        {{useraddress}}
                    </span>
                </p>

                <p *ngIf="attachedCV != '' && userType == 1 "><i class="fa fa-file" aria-hidden="true"></i>
                    <a href="{{attachedCV}}" target="_blank">{{'CV' | translate}} <i class="fa fa-eye"></i></a>

                    <span *ngIf="update_at">{{'last' | translate}} {{update_at}}</span>
                </p>

                <p *ngIf="userCategory != '' && userType == 3"><i class="fa fa-cogs" aria-hidden="true"></i>
                    <!-- <b> {{'Category'|translate}} </b> -->
                    <span>

                        {{userCategory}}
                    </span>
                    </p>
              </div>

              <div class="social-icons">
                       <a *ngIf="userfacebook != ''" href={{userfacebook}} target="_blank" > <i class="fa fa-facebook" ></i></a>
                       <a *ngIf="usertwitter  != '' " href={{usertwitter}}  target="_blank"> <i  class="fa fa-twitter"></i></a>
                       <a *ngIf="userlinkedin != ''"   href={{userlinkedin}} target="_blank"> <i class="fa fa-linkedin"></i></a>
                       <a *ngIf="userinstagram != '' " href={{userinstagram}} target="_blank">  <i class="fa fa-instagram"></i></a>
              </div>

            </div>

        </div>



        <div class="row"  *ngIf="userType != 1">
            <div class="col-md-3 centered-btns"></div>
            <div class="col-md-9 centered-btns">
                <div class="panel-heading">
                    <!-- <button (click)="openPlace()" class="search-btn">
                        <i class="fa fa-link" aria-hidden="true"></i>
                        {{'Home.addnewplace'|translate}}
                    </button> -->

                    <!-- <button (click)="openProject()" class="search-btn">
                        <i class="fa fa-link" aria-hidden="true"></i>
                        {{'Home.addproject'|translate}}</button>
                    <button (click)="openProduct()" class="search-btn">
                        <i class="fa fa-link" aria-hidden="true"></i>
                        {{'Home.addproduct'|translate}}</button>
                    <button (click)="openService()" class="search-btn">
                        <i class="fa fa-link" aria-hidden="true"></i>

                        {{'Home.addservice'|translate}}</button>
                    <button (click)="openRelated()" class="search-btn">
                        <i class="fa fa-link" aria-hidden="true"></i>

                        {{'Home.addRelated'|translate}}</button> -->
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 25px;">

          <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="statistics">
            <div class="statistc-card" style="background: #19406b;">
              {{'No. of services'|translate}} : {{ statistics.contacts_services}}
            </div>
          </div>

          <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="statistics">
            <div class="statistc-card" style="background: #5dc9a1;">
              {{'No. of items'|translate}} : {{ statistics.items}}
            </div>
          </div>

          <div class="col-md-4 col-sm-4 col-xs-12" *ngIf="statistics">
            <div class="statistc-card" style="background: #d83c03;">
              {{'No. of visits'|translate}} : {{ statistics.visits}}
            </div>
          </div>

        </div>

        <!--Start-->
        <div class="row" *ngIf="userType != 1" style="margin-top: 35px; ">
            <div class="map-contact">
                <div class="container page_bg pt-50 pb-50 pr-50 pl-50 ">
                    <div class="row">
                        <div class="col-md-12" *ngFor="let place of places ">


                            <div class="row">
                              <div class="col-md-3 centered-btns">
                                <div class="table-btns" >
                                  <button (click)="openEdit(place?.id)" class="edit-btn" alt="تعديل" title="تعديل"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                                  <button *ngIf="hasTasks" (click)="addTask(place?.id)" class="view-btn" alt="إضافة" title="إضافة"><i class="fa fa-plus"></i></button>
                                  <!-- <button (click)="addQuestion(place?.id)" class="view-btn" alt="سؤال" title="سؤال"><i class="fa fa-question"></i></button> -->
                                  <button (click)="deleteContact(place?.id)" class="delete-btn" alt="حذف" title="حذف"><i class="fa fa-trash"></i></button>
                                </div>
                              </div>
                                <div class="col-md-9 centered-btns">
                                    <div class="panel-heading">
                                        <button (click)="openProject()" class="search-btn">
                                        <i class="fa fa-link" aria-hidden="true"></i>
                                        {{'Home.addproject'|translate}}</button>
                                        <button (click)="openProduct()" class="search-btn">
                                        <i class="fa fa-link" aria-hidden="true"></i>
                                        {{'Home.addproduct'|translate}}</button>
                                        <button (click)="openService()" class="search-btn">
                                        <i class="fa fa-link" aria-hidden="true"></i>

                                        {{'Home.addservice'|translate}}</button>
                                        <button (click)="openRelated()" class="search-btn">
                                        <i class="fa fa-link" aria-hidden="true"></i>
                                            {{'Home.addRelated'|translate}}</button>

                                        <button (click)="openJob(place.id)" class="search-btn">
                                            <i class="fa fa-link" aria-hidden="true"></i>
                                                {{'add job'|translate}}
                                        </button>

                                    </div>
                                </div>
                            </div>


                              <tabset [justified]="true">

                                <tab heading="{{'Place Info'|translate}}">
                                  <!--Start Contact Form -->
                                  <div class="col-sm-12">
                                    <form [formGroup]="placeForm" id="placeForm" name="placeForm" class="form-horizontal contact-form">
                                      <!-- Text input-->
                                      <div class="row">
                                        <div class="col-md-4 col-sm-6">
                                          <div class="form-group">
                                            <div class="inputGroupContainer">
                                              <label>{{'Home.place_name'|translate}}</label>
                                              <div class="input-group">
                                                <span class="input-group-addon"><i class="glyphicon glyphicon-pencil"></i></span>
                                                <input formControlName="title" disabled name="title" id="title"
                                                  placeholder="{{'Home.place_name'|translate}}" class="form-control" required>

                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <!-- Text input-->
                                        <div class="col-md-4 col-sm-6">
                                          <div class="form-group">
                                            <div class="inputGroupContainer">
                                              <label>{{'phoneno'|translate}}</label>
                                              <div class="input-group">
                                                <span class="input-group-addon"><i class="glyphicon glyphicon-phone-alt"></i></span>
                                                <input formControlName="mobile_number" disabled name="phone" id="phone"
                                                  placeholder="{{'phoneno'|translate}}" class="form-control">
                                              </div>
                                            </div>
                                          </div>
                                        </div>


                                        <div class="col-md-4 col-sm-6">

                                          <div class="form-group">
                                            <div class=" inputGroupContainer">
                                              <label>{{'Home.city_name'|translate}}</label>
                                              <div class="input-group">
                                                <span class="input-group-addon">
                                                  <i class="glyphicon glyphicon-map-marker"></i>
                                                </span>
                                                <!-- <select disabled formControlName="city_id" name="city_id" id="city_id" class="form-control">
                                              <option selected disabled value="">{{ 'Home.city_name' | translate}}</option>
                                              <option [ngValue]="city.id" *ngFor="let city of citieslist">{{city.name}}</option>
                                            </select> -->

                                                <input type="text" class="form-control" [(ngModel)]="city" [ngModelOptions]="{standalone: true}" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="col-md-4 col-sm-6">
                                          <div class="form-group">
                                            <div class=" inputGroupContainer">
                                              <label>{{'Home.categories'|translate}}</label>
                                              <div class="input-group">
                                                <span class="input-group-addon"><i class="glyphicon glyphicon-th-list"></i></span>

                                                <!-- <ng-select #MyNgSelect class="selector" bindLabel="name" appendTo="body" multiple="true"
                                              [searchable]="true" [clearable]="true" [closeOnSelect]="false" disabled [(ngModel)]="selected"
                                              (change)="getValues()"
                                              [placeholder]="selected.length == 0 ?(currentlang == 'en'?'Category':'القسم') : ''"
                                              [ngModelOptions]="{standalone: true}">
                                              <ng-option [value]="cat.id" *ngFor="let cat of Categorieslist">
                                                {{cat.title}}
                                              </ng-option>
                                            </ng-select> -->

                                                <ng-multiselect-dropdown [settings]="dropdownSettings" formControlName="category_values_id"
                                                  [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                                                  [disabled]="true">
                                                </ng-multiselect-dropdown>

                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <!-- Text input-->
                                        <div class="col-md-4 col-sm-6">

                                          <div class="form-group">
                                            <div class=" inputGroupContainer">
                                              <label>{{'Tax card'|translate}}</label>
                                              <div class="input-group">
                                                <span class="input-group-addon"><i class="glyphicon glyphicon-pencil"></i></span>
                                                <input formControlName="tax_card" disabled name="tax" id="tax"
                                                  placeholder="{{'Tax card'|translate}}" class="form-control" required>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <!-- file upload -->
                                        <div class="col-md-4 col-sm-6">

                                          <div class="form-group">
                                            <div class=" inputGroupContainer">
                                              <label>{{'taxfile'|translate}}</label>
                                              <div class="input-group">
                                                <span class="input-group-addon"><i class="glyphicon glyphicon-upload"></i></span>

                                                <div class="js-uploader__file-list uploader__file-list">
                                                  <div class="js-uploader__file-list ">
                                                    <div class="row">
                                                      <div class="col-md-4">
                                                        <span class="uploader__file-list__thumbnail" style="width: 90px;">
                                                          <a class="thumbnail" href="{{taxsFile}}" style="margin-bottom: 0px;"
                                                            target="_blank">{{'taxfile' | translate}}</a>
                                                        </span>
                                                      </div>
                                                      <div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <!-- Text input-->
                                        <div class="col-md-4 col-sm-6">

                                          <div class="form-group">
                                            <div class=" inputGroupContainer">
                                              <label>{{'Commercial Register'|translate}}</label>
                                              <div class="input-group">
                                                <span class="input-group-addon"><i class="glyphicon glyphicon-pencil"></i></span>
                                                <input formControlName="commercial_register" disabled name="reg" id="reg"
                                                  placeholder="{{'Commercial Register'|translate}}" class="form-control" required>

                                              </div>
                                            </div>
                                          </div>
                                        </div>



                                        <!-- Text input-->
                                        <div class="col-md-4 col-sm-6">

                                          <div class="form-group">
                                            <div class=" inputGroupContainer">
                                              <label>{{'Place Address'|translate}}</label>
                                              <div class="input-group">
                                                <span class="input-group-addon"><i class="glyphicon glyphicon-map-marker"></i></span>
                                                <input formControlName="address" disabled name="address" id="address"
                                                  placeholder="{{'Place Address'|translate}}" class="form-control" required>

                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="col-md-4 col-sm-6">

                                          <div class="form-group">
                                            <label>{{'Facebook Url'|translate}}</label>
                                            <div class="input-group">
                                              <!-- <span class="input-group-addon"><i class="glyphicon glyphicon-thumbs-up"></i></span> -->
                                              <span class="input-group-addon"> <i class="fa fa-facebook" style="padding: 0px 3px;"></i></span>
                                              <input formControlName="Facebook" disabled name="Facebook" id="Facebook"
                                                placeholder="https://www.facebook.com/" class="form-control" required>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="col-md-4 col-sm-6">


                                          <div class="form-group">
                                            <label>{{'Twitter Url'|translate}}</label>
                                            <div class="input-group">
                                              <span class="input-group-addon"><i class="fa fa-twitter"></i></span>
                                              <input formControlName="Twitter" disabled name="Twitter" id="Twitter"
                                                placeholder="https://www.Twitter.com/" class="form-control" required>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4 col-sm-6">
                                          <label>{{'Instagram Url'|translate}}</label>
                                          <div class="input-group">
                                            <span class="input-group-addon"> <i class="fa fa-instagram"></i></span>
                                            <input formControlName="Instagram" disabled name="Instagram" id="Instagram"
                                              placeholder="https://www.Instagram.com/" class="form-control" required>
                                          </div>
                                        </div>

                                        <div class="col-md-4 col-sm-6">
                                          <label>{{'Linkedin Url'|translate}}</label>
                                          <div class="form-group">
                                            <div class="input-group">
                                              <span class="input-group-addon"><i class="fa fa-linkedin"></i></span>
                                              <input formControlName="Linkedin" disabled name="Linkedin" id="Linkedin"
                                                placeholder="https://www.Linkedin.com/" class="form-control" required>
                                            </div>
                                          </div>
                                        </div>




                                        <!-- Text input-->
                                        <div class="col-md-4 col-sm-12">
                                          <div class="form-group">
                                            <div class=" inputGroupContainer">
                                              <label>{{'Image'|translate}}</label>
                                              <div class="input-group">
                                                <span class="input-group-addon"><i class="glyphicon glyphicon-cloud-upload"></i></span>

                                                <div class="js-uploader__file-list uploader__file-list">
                                                  <div class="js-uploader__file-list ">
                                                    <div class="row">
                                                      <div class="col-md-4">
                                                        <span class="uploader__file-list__thumbnail" style="width: 90px;">
                                                          <img id="profileImg" class="thumbnail" id="img_" src="{{photo}}"
                                                            style="margin-bottom: 0px;">
                                                        </span>
                                                      </div>
                                                      <div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <!-- file upload -->
                                        <div class="col-md-4 col-sm-12">

                                          <div class="form-group">
                                            <div class=" inputGroupContainer">
                                              <label>{{'commercialfile' | translate}}</label>
                                              <div class="input-group">
                                                <span class="input-group-addon"><i class="glyphicon glyphicon-upload"></i></span>

                                                <div class="js-uploader__file-list uploader__file-list">
                                                  <div class="js-uploader__file-list ">
                                                    <div class="row">
                                                      <div class="col-md-4">
                                                        <span class="uploader__file-list__thumbnail" style="width: 90px;">
                                                          <a class="thumbnail" href="{{commercialFile}}" style="margin-bottom: 0px;"
                                                            target="_blank">{{'commercialfile' | translate}}</a>
                                                        </span>
                                                      </div>
                                                      <div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <!-- Text input-->
                                        <div class="col-md-4 col-sm-12">
                                          <label>{{'Website Link'|translate}}</label>
                                          <div class="form-group">
                                            <div class="input-group">
                                              <span class="input-group-addon"><i class="fa fa-link"></i></span>
                                              <input formControlName="link" disabled name="link" id="link"
                                                placeholder="https://www.WebsiteLink.com/" class="form-control" required>
                                            </div>
                                          </div>
                                        </div>

                                        <!-- Text input-->
                                        <div class="col-md-12 col-sm-12">

                                          <div class="form-group">
                                            <div class=" inputGroupContainer">
                                              <label>{{'locationOnMap'|translate}}</label>
                                              <div class="input-group">
                                                <span class="input-group-addon"><i class="glyphicon glyphicon-map-marker"></i></span>
                                                <!-- <input formControlName="link" disabled name="location" id="location"
                                              placeholder="{{'location'|translate}}" class="form-control" required> -->
                                                <agm-map #map [latitude]="location.lat" [longitude]="location.lng" style="height: 300px">
                                                  <agm-marker [latitude]="location.lat" [longitude]="location.lng"></agm-marker>
                                                </agm-map>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <!-- Text input-->

                                        <div class="col-md-12 col-sm-12">

                                          <div class="form-group">
                                            <div class="inputGroupContainer">
                                              <label>{{'Place Description'|translate}}</label>
                                              <div class="input-group">
                                                <textarea name="desc" disabled formControlName="description" id="desc" class="form-control"
                                                  placeholder="{{'Place Description'|translate}}" required></textarea>

                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </form>
                                  </div>
                                  <!--End Contact Form-->
                                </tab>

                                <tab heading="{{'Home.projects'|translate}}">
                                    <!--Start-->
                                    <div class="row">
                                      <div class="map-contact">
                                        <div class="container page_bg pt-50 pb-50 pr-50 pl-50 container-margin">
                                          <div class="row">
                                            <div class="col-md-12">
                                              <div class="panel panel-form">
                                                <!-- <div class="panel-heading">
                                                                  <h2>{{'Home.projects' | translate}}</h2>
                                                              </div> -->
                                                <div class="panel-body">
                                                  <div class="tab-content" id="myTabContent">
                                                    <div class="tab-pane fade active in" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                      <table class="table" cellspacing="0" *ngIf="place.projects?.length > 0">
                                                        <thead>
                                                          <tr>
                                                            <th>ID</th>
                                                            <th class="wide">{{'title'|translate}}</th>
                                                            <th>{{'Image'|translate}}</th>
                                                            <th>{{'Status' | translate}}</th>
                                                            <th class="wide">{{'description'|translate}}</th>
                                                            <th>{{'Actions' | translate}}</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr
                                                            *ngFor="let user of place.projects | paginate: { itemsPerPage: pageSize, currentPage: p2, totalItems: totalProjects }">
                                                            <td>{{user?.id}}</td>
                                                            <td>{{user?.name}}</td>
                                                            <td>
                                                              <img *ngIf="!user?.image" class="img-width" src="assets/images/user_profile.png">
                                                              <img *ngIf="user?.image" class="img-width" [src]="user?.image">

                                                            <td *ngIf="user?.is_approved == 0">
                                                              {{'Pending approval' | translate}}
                                                            </td>

                                                            <td *ngIf="user?.is_approved == 1">
                                                              {{'Accepted' | translate}}
                                                            </td>

                                                            <td *ngIf="user?.is_approved == 2">
                                                              {{'Rejected' | translate}}
                                                            </td>
                                                            <td>
                                                              {{user?.description}}
                                                            </td>
                                                            <td>
                                                              <div class="d-flex btns-cell-td">
                                                                <!-- <button (click)="navigateView(user)" class="view-btn"><i class="fa fa-eye"></i></button> -->
                                                                <button (click)="navigateProject(user)" class="edit-btn"><i
                                                                    class="fa fa-edit"></i></button>
                                                                <button (click)="deleteProject(user)" class="delete-btn"><i
                                                                    class="fa fa-trash"></i></button>
                                                              </div>
                                                            </td>

                                                          </tr>

                                                        </tbody>
                                                      </table>
                                                      <div class="col-md-12 col-sm-12 text-center" *ngIf="place.projects?.length>0">
                                                        <pagination-controls (pageChange)="onProjectsChange($event)"
                                                          previousLabel="{{'previous'|translate}}" nextLabel="{{'next'|translate}}">
                                                        </pagination-controls>

                                                      </div>
                                                      <h2 *ngIf="place.projects?.length==0" style="color: #333;">{{'no result'|translate}}</h2>
                                                      <a (click)="createProject(place.id)" class="edit-btn"><i class="fa fa-plus"></i>{{'add' |
                                                        translate}}</a>
                                                      <div class="col-md-12 col-sm-12 text-center">

                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                    <!--End-->
                                </tab>

                                <tab heading="{{'Home.products'|translate}}">
                                    <!--Start-->
                                    <div class="row">
                                      <div class="map-contact">
                                        <div class="container page_bg pt-50 pb-50 pr-50 pl-50 container-margin">
                                          <div class="row">
                                            <div class="col-md-12">
                                              <div class="panel panel-form">
                                                <!-- <div class="panel-heading">
                                                                  <h2>{{'Home.products' | translate}}</h2>
                                                              </div> -->
                                                <div class="panel-body">
                                                  <div class="tab-content" id="myTabContent">
                                                    <div class="tab-pane fade active in" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                      <table class="table" cellspacing="0" *ngIf="place.products?.length > 0">
                                                        <thead>
                                                          <tr>
                                                            <th>ID</th>
                                                            <th class="wide">{{'title'|translate}}</th>
                                                            <th>{{'Image'|translate}}</th>
                                                            <th class="wide">{{'description'|translate}}</th>
                                                            <th>{{'price' | translate}}</th>
                                                            <th>{{'Actions' | translate}}</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr
                                                            *ngFor="let user of place.products | paginate: { itemsPerPage: pageSize, currentPage: p3, totalItems: totalProducts, id:'pagination3' }">
                                                            <td>{{user?.id}}</td>
                                                            <td>{{user?.name}}</td>
                                                            <td>
                                                              <img *ngIf="!user?.image" class="img-width" src="assets/images/user_profile.png">
                                                              <img *ngIf="user?.image" class="img-width" [src]="user?.image">

                                                            <td>
                                                              {{user?.description}}
                                                            </td>
                                                            <td>
                                                              {{user?.price}}
                                                            </td>
                                                            <td>
                                                              <div class="d-flex btns-cell-td">
                                                                <!-- <button (click)="navigateView(user)" class="view-btn"><i class="fa fa-eye"></i></button> -->
                                                                <button (click)="navigateProduct(user)" class="edit-btn"><i
                                                                    class="fa fa-edit"></i></button>
                                                                <button (click)="deleteProduct(user)" class="delete-btn"><i
                                                                    class="fa fa-trash"></i></button>
                                                              </div>
                                                            </td>

                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                      <div class="col-md-12 col-sm-12 text-center" *ngIf="place.products?.length>0">
                                                        <pagination-controls id="pagination3" (pageChange)="onProductsChange($event)"
                                                          previousLabel="{{'previous'|translate}}" nextLabel="{{'next'|translate}}">
                                                        </pagination-controls>

                                                      </div>
                                                      <h2 *ngIf="place.products?.length==0" style="color: #333;">{{'no result'|translate}}</h2>
                                                      <a (click)="createProduct(place.id)" class="edit-btn"><i class="fa fa-plus"></i>{{'add' |
                                                        translate}}</a>
                                                      <div class="col-md-12 col-sm-12 text-center">

                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <!--End-->
                                </tab>

                                <tab heading="{{'Corporates Requests'|translate}}">
                                    <!--Start-->
                                    <div class="row">
                                      <div class="map-contact">
                                        <div class="container page_bg pt-50 pb-50 pr-50 pl-50 container-margin">
                                          <div class="row">
                                            <div class="col-md-12">
                                              <div class="panel panel-form">
                                                <!-- <div class="panel-heading">
                                                                  <h2>{{'Home.services' | translate}}</h2>
                                                              </div> -->
                                                <div class="panel-body">
                                                  <div class="tab-content" id="myTabContent">
                                                    <div class="tab-pane fade active in" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                      <table class="table" cellspacing="0" *ngIf="place.services?.length > 0">
                                                        <thead>
                                                          <tr>
                                                            <th>ID</th>
                                                            <th class="wide">{{'title'|translate}}</th>
                                                            <th>{{'Image'|translate}}</th>
                                                            <th class="wide">{{'description'|translate}}</th>
                                                            <th>{{'price' | translate}}</th>
                                                            <th>{{'cities' | translate}}</th>
                                                            <th>{{'Actions' | translate}}</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr
                                                            *ngFor="let user of place.services | paginate: { itemsPerPage: pageSize, currentPage: p4, totalItems: totalServices, id:'pagination4' }">
                                                            <td>{{user?.id}}</td>
                                                            <td>{{user?.name}}</td>
                                                            <td>
                                                              <img *ngIf="!user?.image" class="img-width" src="assets/images/user_profile.png">
                                                              <img *ngIf="user?.image" class="img-width" [src]="user?.image">

                                                            <td>
                                                              {{user?.description}}
                                                            </td>
                                                            <td>
                                                              {{user?.price}}
                                                            </td>
                                                            <td>
                                                              <!-- {{user?.cities[0]?.name}} -->
                                                            </td>
                                                            <td>
                                                              <div class="d-flex btns-cell-td">
                                                                <!-- <button (click)="navigateView(user)" class="view-btn"><i class="fa fa-eye"></i></button> -->
                                                                <button (click)="navigateUpdate(user)" class="edit-btn"><i
                                                                    class="fa fa-edit"></i></button>
                                                                <button (click)="deleteService(user)" class="delete-btn"><i
                                                                    class="fa fa-trash"></i></button>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                      <div class="col-md-12 col-sm-12 text-center" *ngIf="place.services?.length>0">
                                                        <pagination-controls id="pagination4" (pageChange)="onServicesChange($event)"
                                                          previousLabel="{{'previous'|translate}}" nextLabel="{{'next'|translate}}">
                                                        </pagination-controls>

                                                      </div>
                                                      <h2 *ngIf="place.services?.length==0" style="color: #333;">{{'no result'|translate}}</h2>
                                                      <a (click)="createService(place.id)" class="edit-btn"><i class="fa fa-plus"></i>{{'add' |
                                                        translate}}</a>
                                                      <div class="col-md-12 col-sm-12 text-center">
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!--End-->
                                </tab>

                                <tab heading="{{'Individual Requests'|translate}}">
                                    <!--Start-->
                                    <div class="row">
                                      <div class="map-contact">
                                        <div class="container page_bg pt-50 pb-50 pr-50 pl-50 container-margin">
                                          <div class="row">
                                            <div class="col-md-12">
                                              <div class="panel panel-form">
                                                <!-- <div class="panel-heading">
                                                                  <h2>{{'Home.services' | translate}}</h2>
                                                              </div> -->
                                                <div class="panel-body">
                                                  <div class="tab-content" id="myTabContent">
                                                    <div class="tab-pane fade active in" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                      <table class="table" cellspacing="0" *ngIf="IndividualRequests?.length > 0">
                                                        <thead>
                                                          <tr>
                                                            <th>ID</th>
                                                            <th class="wide">{{'title'|translate}}</th>
                                                            <th class="wide">{{'name'|translate}}</th>
                                                            <th class="wide">{{'description'|translate}}</th>
                                                            <!-- <th>{{'cities' | translate}}</th> -->
                                                            <th>{{'Actions' | translate}}</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr
                                                            *ngFor="let user of IndividualRequests | paginate: { itemsPerPage: pageSize, currentPage: p5, totalItems: totalIndividualRequests, id:'pagination5' }">
                                                            <td>{{user?.id}}</td>
                                                            <td>{{user?.title}}</td>
                                                            <td>{{user?.name}}</td>
                                                            <td>{{user?.description}}</td>
                                                            <!-- <td>{{user?.cities[0]?.name}}</td> -->
                                                            <td>
                                                              <div class="d-flex btns-cell-td">
                                                                <!-- <button (click)="navigateView(user)" class="view-btn"><i class="fa fa-eye"></i></button> -->
                                                                <button (click)="navigateUpdate(user)" class="edit-btn"><i
                                                                    class="fa fa-edit"></i></button>
                                                                <button (click)="deleteService(user)" class="delete-btn"><i
                                                                    class="fa fa-trash"></i></button>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                      <div class="col-md-12 col-sm-12 text-center" *ngIf="IndividualRequests?.length>0">
                                                        <pagination-controls id="pagination5" (pageChange)="onIndividualRequestsChange($event)"
                                                          previousLabel="{{'previous'|translate}}" nextLabel="{{'next'|translate}}">
                                                        </pagination-controls>

                                                      </div>
                                                      <h2 *ngIf="IndividualRequests?.length==0" style="color: #333;">{{'no result'|translate}}</h2>

                                                      <div class="col-md-12 col-sm-12 text-center">
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!--End-->
                                </tab>

                                <tab heading="{{'Offers Requests'|translate}}">
                                    <!--Start-->
                                    <div class="row">
                                      <div class="map-contact">
                                        <div class="container page_bg pt-50 pb-50 pr-50 pl-50 container-margin">
                                          <div class="row">
                                            <div class="col-md-12">
                                              <div class="panel panel-form">
                                                <!-- <div class="panel-heading">
                                                                  <h2>{{'Home.services' | translate}}</h2>
                                                              </div> -->
                                                <div class="panel-body">
                                                  <div class="tab-content" id="myTabContent">
                                                    <div class="tab-pane fade active in" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                      <table class="table" cellspacing="0" *ngIf="OffersRequests?.length > 0">
                                                        <thead>
                                                          <tr>
                                                            <th>ID</th>
                                                            <th class="wide">{{'title'|translate}}</th>

                                                            <th>{{'Actions' | translate}}</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr
                                                            *ngFor="let user of OffersRequests | paginate: { itemsPerPage: pageSize, currentPage: p6, totalItems: totalOffersRequests, id:'pagination6' }">
                                                            <td>{{user?.id}}</td>
                                                            <td>{{user?.question}}</td>

                                                            <td>
                                                              <div class="d-flex btns-cell-td">
                                                                <!-- <button (click)="navigateView(user)" class="view-btn"><i class="fa fa-eye"></i></button> -->
                                                                <button (click)="navigateUpdate(user)" class="edit-btn"><i
                                                                    class="fa fa-edit"></i></button>
                                                                <button (click)="deleteService(user)" class="delete-btn"><i
                                                                    class="fa fa-trash"></i></button>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                      <div class="col-md-12 col-sm-12 text-center" *ngIf="OffersRequests?.length>0">
                                                        <pagination-controls id="pagination6" (pageChange)="onOfferRequestChange($event)"
                                                          previousLabel="{{'previous'|translate}}" nextLabel="{{'next'|translate}}">
                                                        </pagination-controls>

                                                      </div>
                                                      <h2 *ngIf="OffersRequests?.length==0" style="color: #333;">{{'no result'|translate}}</h2>

                                                      <div class="col-md-12 col-sm-12 text-center">
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!--End-->
                                </tab>



                              </tabset>












                        </div>
                    </div>
                </div>
            </div>
       </div>
        <!--End-->


    </div>
</div>
