import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AddingMetaService } from 'src/app/Shared/Services/adding-meta.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { PlacesService } from 'src/app/Shared/Services/places.service';
import { UserService } from 'src/app/Shared/Services/user.service';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HomeService } from 'src/app/Shared/Services/home.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';




@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  username;
  usercode;
  arabic_usercode;
  userphone;
  useremail;
  userbirthdate;
  useraddress;
  usergender;
  userfacebook;
  userinstagram;
  userlinkedin;
  usertwitter;
  image;
  userCategory:string = '';
  companyId: number;
  places: any[] =[];
  attachedCV: any;
  update_at:any;

  userType: number;
  hasTasks: boolean = false;
  lang: string;

  placeForm: FormGroup;
  photo;
  commercialFile: any;
  taxsFile: any;
  cityId: number;
  city: string = '';
  selectedItems = [];
  selected = [];
  relatedContacts: any[] = [];
  IndividualRequests: any[] = [];
  OffersRequests: any[] = [];

  Contact_ID;


  geocoder: any;
  public location: Location = {
    lat: 30.043408537674175,
    lng: 31.30309544531249,
    marker: {
      lat: 30.043408537674175,
      lng: 31.30309544531249,
      draggable: false
    },
    zoom: 5
  };

  dropdownSettings = {};

  pageSize: number = 10;
  p1: number = 1;
  p2: number = 1;
  p3: number = 1;
  p4: number = 1;
  p5: number = 1;
  p6: number = 1;

  totalRelated: number;
  totalProjects: number;
  totalProducts: number;
  totalServices: number;
  totalIndividualRequests: number;
  totalOffersRequests: number;

  page: number = 1;

  currentlang;

  statistics;


  constructor(
    private userservice:UserService,
    private service:HttpClientService,
    private http: HttpClientService,
    private translateservice:TranslateService,
    private router:Router,
    private placeService: PlacesService,
    private toaster: ToastrService,
    private meta: AddingMetaService,
    private homeService: HomeService,
    private toastService: ToastrService,
    private languageService: LanguageService,
    private placesService: PlacesService,
  ) { }

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.currentlang = localStorage.getItem('lang');

    this.userType = +localStorage.getItem('userType');
    this.hasTasks = (localStorage.getItem('hasTasks') == '0') ? false : true;

    this.initForm();

    this.ViewProfileInfo();





    this.meta.updateMeta({
      compTitle: 'Profile'
    });

    this.dropdownSettings = {
      singleSelection: true,
      closeDropDownOnSelection:true,
      disabled:true,
      idField: 'id',
      textField: 'value',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };


  }

  ViewProfileInfo(){
    this.userservice.viewprofile().subscribe((res:any)=>{
      this.image = res.content.photo;
      console.log("my profile image ==========> ",this.image)


      this.places = res.content.contacts;
      this.companyId = res.content.id;
      this.username = res.content.full_name;
      this.usercode = res.content.tele_code;
      this.userphone = res.content.mobile;
      this.useremail = res.content.email;
      this.userbirthdate = res.content.birthdate;
      this.useraddress = res.content.address;
      this.usergender = res.content.gender;
      this.userfacebook = res.content.facebook;
      this.userinstagram = res.content.instagram;
      this.userlinkedin = res.content.linkedin;
      this.usertwitter = res.content.twitter;

      console.log("place info ::::::::::: ", this.places)

      this.updateForm(this.places[0]);

      this.GetStatistics();

      this.places.forEach(element => {
        this.updateForm(element);

        this.homeService.getRelatedContacts(element.id, this.page).subscribe(
          (res: any)=>{
            this.relatedContacts = res['content'];
            this.totalRelated = res.pagination?.total;
            this.p1 = res.pagination?.current_page;
          }
        );


        this.placesService.getIndividualRequests(element.id ,this.page).subscribe(
          (res: any)=>{
            this.IndividualRequests = res.content.data;
            console.log("The Individual Requests:----", this.IndividualRequests)
            this.totalIndividualRequests = res.pagination?.total;
            this.p5 = res.pagination?.current_page;
          }
        );


        this.placesService.getOffersRequests(element.id ,this.page).subscribe(
          (res: any)=>{
            this.OffersRequests = res['content'];
            this.totalOffersRequests = res.pagination?.total;
            this.p6 = res.pagination?.current_page;
            console.log("The Asked Questions to this place:----",this.OffersRequests)
          }
        );




      });

      if(res.content.contacts){
        this.Contact_ID = res.content.contacts[0]?.id

      }


      this.update_at = res.content.cv_updated_at;
      this.attachedCV = res.content.attached_cv;
      this.image = res.content.photo;



      if(res.content.company){
        res.content.company.categories.forEach(ele=>{
          this.userCategory += ele.name + ', ';
        });
      }

      // this.updateForm(this.places);

      this.userCategory = this.userCategory.slice(0, -2);
      console.log("Telecode ::::::::::::::::::", this.usercode)
      this.arabic_usercode =  this.usercode.split("").reverse().join("");
    })
  }

  updateprofile(){
    this.router.navigate(['update-profile', this.lang]);
  }

  openEmployees(){
    this.router.navigate(['employee', this.lang]);
  }

  openEmployee(){
    this.router.navigate(['employee-create', this.lang]);
  }

  openProduct(){
    this.router.navigate(['product-create', this.lang]);
  }

  openPlace(){
    this.router.navigate(['place-new', this.lang]);
  }

  openProject(){
    this.router.navigate(['project-new', this.lang]);
  }

  openService() {
    this.router.navigate(['service-create', this.lang]);
  }

  openRelated(){
    this.router.navigate(['related-create', this.lang]);
  }

  gotoDetails(id){
    this.router.navigate(['place-view', this.lang], { queryParams: { 'id': id } });
  }

  openEdit(id){
    this.router.navigate(['place-new', this.lang], { queryParams: { 'id': id } });
  }

  deleteContact(id) {
    Swal.fire({
      title: this.translateservice.instant('delete_contact'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translateservice.instant('delete'),
      cancelButtonText: this.translateservice.instant('cancel')
    }).then((result) => {
      if (result.value) {
        this.service.post("api/cms/destroy", { id: id }, {}).subscribe((res: any) => {
          console.log(res);
          if (res.status.code == 200) {
            if ((this.lang == 'en')) {
              Swal.fire({
                icon: 'success',
                title: ' ',
                text: 'Contact deleted successfully',
              })
            } else if ((this.lang == 'ar')) {
              Swal.fire({
                icon: 'success',
                title: ' ',
                text: 'تم ازاله المكان',
              })

            }
            this.ViewProfileInfo();
          }
          else {
            this.toaster.error(res.status.message);
          }

        })
      } else if (result.dismiss === Swal.DismissReason.cancel) { }
    })
  }

  addTask(id){
    this.router.navigate(['tasks-create', this.lang], { queryParams: { 'id': id } });
  }

  addQuestion(id){
    this.router.navigate(['chat', this.lang]);
  }

  openJob(contactId){
    this.router.navigate(['create-job', this.lang], {
      queryParams: { placeId: contactId },
    });

  }

  // navigateContact(data){
  //   this.router.navigate(['related-create', this.currentlang], {queryParams:{id: data.id, paceId: this.contactId}});
  // }


  initForm() {
    this.placeForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      mobile_number: new FormControl('', []),
      image_url: new FormControl(''),
      city_id : new FormControl('', [Validators.required]),
      category_values_id : new FormControl('', [Validators.required]),
      tax_card: new FormControl('', [Validators.required]),
      commercial_register: new FormControl('', [Validators.required]),
      commercial_registry_file: new FormControl(''),
      tax_card_file: new FormControl(''),
      link: new FormControl(''),
      address: new FormControl('', [Validators.required]),
      // latitude : new FormControl('', [Validators.required]),
      // longitude : new FormControl('', [Validators.required]),
      Facebook : new FormControl('',),
      Twitter : new FormControl('',),
      Instagram : new FormControl('',),
      Linkedin : new FormControl('',),
    })

    // this.placeForm.get('title').setValue(this.places[0].title);
    // this.placeForm.get('description').setValue(this.places[0].description);

    // this.placeForm.get('mobile_number').setValue(this.places[0].mobile_number);
    // this.placeForm.get('address').setValue(this.places[0].address);
    // this.placeForm.get('link').setValue(this.places[0].link);
    // this.placeForm.get('tax_card').setValue(this.places[0].tax_card);
    // this.placeForm.get('commercial_register').setValue(this.places[0].commercial_register);
    // this.placeForm.get('category_values_id').setValue(this.places[0].category_values_id);
    // this.placeForm.get('city_id').setValue(this.places[0].city_id);

    // this.placeForm.get('Facebook').setValue(this.places[0].title);
    // this.placeForm.get('Twitter').setValue(this.places[0].title);
    // this.placeForm.get('Instagram').setValue(this.places[0].title);

    // // this.photo = data.image;
    // // this.commercialFile = data.commercial_registry_file;
    // // this.taxsFile = data.tax_card_file;
    // // this.selected = data.categories
    // // this.selectedItems = data.categories;
    // // this.location.lat = +data.latitude;
    // // this.location.lng = +data.longitude;
    // // this.city = data.city;

    // console.log("title of form ::::::", this.placeForm.get('title') )

  }

  updateForm(data) {

    this.placeForm.get('title').setValue(data.title);
    this.placeForm.get('description').setValue(data.description);
    // this.placeForm.get('latitude').setValue(data.latitude);
    // this.placeForm.get('longitude').setValue(data.longitude);
    this.placeForm.get('mobile_number').setValue(data.mobile_number);
    this.placeForm.get('address').setValue(data.address);
    this.placeForm.get('link').setValue(data.link);
    this.placeForm.get('tax_card').setValue(data.tax_card);
    this.placeForm.get('commercial_register').setValue(data.commercial_register);
    this.placeForm.get('category_values_id').setValue(data.categories);
    this.placeForm.get('city_id').setValue(data.city.id);
    this.photo = data.image;
    this.commercialFile = data.commercial_registry_file;
    this.taxsFile = data.tax_card_file;
    this.selected = data.categories
    this.selectedItems = data.categories;
    this.location.lat = +data.latitude;
    this.location.lng = +data.longitude;
    this.city = data.city;
    this.placeForm.get('Facebook').setValue(data.facebook);
    this.placeForm.get('Twitter').setValue(data.twitter);
    this.placeForm.get('Instagram').setValue(data.instagram);
    // this.placeForm.get('Linkedin').setValue(data.social_media[3]?.pivot.link);

    console.log("title of form ::::::", this.placeForm.get('city_id') )

  }

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  navigateProduct(data){
    this.router.navigate(['product-create', this.currentlang], {queryParams:{id: data.id}});
  }

  navigateProject(data){
    this.router.navigate(['project-new', this.currentlang], { queryParams: { id: data.id } });
  }

  deleteService(data){
    Swal.fire({
      title: this.translateservice.instant('delete_service'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translateservice.instant('delete'),
      cancelButtonText: this.translateservice.instant('cancel')
    }).then((result) => {
      if (result.value) {
        this.http.post("api/contacts_services/destroy", { id: data.id }, {}).subscribe((res: any) => {
          console.log(res);
          if (res.status.code == 200) {
            if ((this.currentlang == 'en')) {
              Swal.fire({
                icon: 'success',
                title: ' ',
                text: 'Service deleted successfully',
              })
            } else if ((this.currentlang == 'ar')) {
              Swal.fire({
                icon: 'success',
                title: ' ',
                text: 'تمت ازاله الخدمة',
              })

            }
            // this.getPlaceServices();
          }
          else {
            this.toastService.error(res.status.message);
          }

        })
      } else if (result.dismiss === Swal.DismissReason.cancel) { }
    })
  }

  deleteProduct(data){
    Swal.fire({
      title: this.translateservice.instant('delete_product'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translateservice.instant('delete'),
      cancelButtonText: this.translateservice.instant('cancel')
    }).then((result) => {
      if (result.value) {
        this.http.post("api/contacts_products/destroy", { id: data.id }, {}).subscribe((res: any) => {
          console.log(res);
          if (res.status.code == 200) {
            if ((this.currentlang == 'en')) {
              Swal.fire({
                icon: 'success',
                title: ' ',
                text: 'Product deleted successfully',
              })
            } else if ((this.currentlang == 'ar')) {
              Swal.fire({
                icon: 'success',
                title: ' ',
                text: 'تمت ازاله المنتج',
              })

            }
            // this.getPlaceProducts();
          }
          else {
            this.toastService.error(res.status.message);
          }

        })
      } else if (result.dismiss === Swal.DismissReason.cancel) { }
    })
  }

  deleteProject(data){
    Swal.fire({
      title: this.translateservice.instant('delete_project'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translateservice.instant('delete'),
      cancelButtonText: this.translateservice.instant('cancel')
    }).then((result) => {
      if (result.value) {
        this.http.post("api/contacts_projects/destroy", { id: data.id }, {}).subscribe((res: any) => {
          console.log(res);
          if (res.status.code == 200) {
            if ((this.currentlang == 'en')) {
              Swal.fire({
                icon: 'success',
                title: ' ',
                text: 'Project deleted successfully',
              })
            } else if ((this.currentlang == 'ar')) {
              Swal.fire({
                icon: 'success',
                title: ' ',
                text: 'تمت ازاله المشروع',
              })

            }
            // this.getPlaceProjects();
          }
          else {
            this.toastService.error(res.status.message);
          }

        })
      } else if (result.dismiss === Swal.DismissReason.cancel) { }
    })
  }

  onProjectsChange(event){
    this.p2=event;
    // this.getPlaceProjects(this.p2+"");
  }

  onProductsChange(event){
    this.p3=event;
    // this.getPlaceProducts(this.p3+"");
  }

  onServicesChange(event){
    this.p4=event;
    // this.getPlaceServices(this.p4+"");
  }

  onIndividualRequestsChange(event){
    this.p5=event;
    // this.getPlaceIndividualRequests(this.p5+"");
  }

  onOfferRequestChange(event){
    this.p6=event;
    // this.getPlaceOffersRequests(this.p6+"");
  }

  createProject(contactId){
    this.router.navigate(['project-new', this.currentlang], { queryParams: { placeId: contactId } })
  }

  createProduct(contactId){
    this.router.navigate(['product-create', this.currentlang], {queryParams:{placeId:contactId}})
  }

  createService(contactId){
    this.router.navigate(['service-create', this.currentlang], {queryParams:{placeId: contactId}})
  }

  navigateUpdate(data){
    console.log(data)
    this.router.navigate(['service-create', this.currentlang],{queryParams:{id: data.id}})
  }

  GetStatistics(){
    this.placesService.GetStatistics(this.Contact_ID).subscribe((res: any) => {
         this.statistics = res.content
         console.log('statistics Res ::::', res.content);
    })

  }


}

interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

interface Location {
  lat: number;
  lng: number;
  viewport?: Object;
  zoom: number;
  address_level_1?: string;
  address_level_2?: string;
  address_country?: string;
  address_zip?: string;
  address_state?: string;
  marker?: Marker;
}
