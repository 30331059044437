<div class="container-fluid pg-header" style="background-image: url(./assets/images/16.jpg);">
    <div class="pg-header-content">
      <h1 class="pg-title">
        {{'company jobs'|translate}}
      </h1>
      <h4 class="pg-subtitle">
         
      </h4>
    </div>
    <!-- <div class="pg-header-icon icon-bubbles"></div> -->
</div>
  <!-- End Page Header -->
  <!-- Start Contact Map -->
<div class="container-fluid map-contact">
    <div class="container page_bg pt-50 pb-50 pr-50 pl-50 container-margin">
        <div class="row">
            <div class="col-md-12">
                <div class="panel panel-form">
                    <div class="panel-heading">
                        <button class="search-btn" [routerLink]="['/create-job', currentlang]">{{'add job'|translate}}</button>
                    </div>
                    <div class="panel-body">
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade active in " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" >
                                <div class="table-responsive" style="width:100%">

                                
                                <table class="table" cellspacing="0" *ngIf="jobsList?.length>0">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th class="wide">{{'Company name'|translate}}</th>
                                            <th>{{'job name'|translate}}</th>
                                            <!-- <th class="wide">{{'Email'|translate}}</th> -->
                                            <th>{{'description'|translate}}</th>
                                            <!-- <th class="wide">{{'Job'|translate}}</th> -->
                                            <th>{{'salary'|translate}}</th>
                                            <!-- <th  class="wide">{{'Condition'|translate}}</th> -->
                                            <th>{{'Actions'|translate}}</th>

                                        </tr>
                                    </thead> 
                                    <tbody>
                                        <tr *ngFor="let job of jobsList | paginate: { itemsPerPage: 10, currentPage: p }">
                                            <td>
                                                <input  type="checkbox" value="0" (change)="addToDelete(job.id)"  class="" name="active" id="active">
                                            </td>
                                            <td >{{job?.company?.name}}</td>
                                            <td >{{job?.title}}</td>
                                          

                                            <!-- <td >
                                               {{job?.title}}
                                            </td> -->
                                            <td>
                                                {{job?.description}}
                                            </td>
                                            <td class="date">
                                                {{job?.salary}}
                                            </td>
                                           
                                           
                                            <td>
                                                <div class="btns-cell-td">
                                                <button (click)="navigateView(job)" class="view-btn"><i class="fa fa-eye"></i></button>
                                                <button (click)="editTask(job.id)" class="edit-btn"><i class="fa fa-edit"></i></button>
                                                <button (click)="deleteJob(job.id)" class="delete-btn"><i class="fa fa-trash"></i></button>
                                            </div>
                                            </td>
                                        </tr>

                               
                                    </tbody>
                                </table>
                            <button *ngIf="jobsList?.length>0" class="delete-selected-btn" (click)="deleteSelected()">{{'delete_selected'|translate}}</button>

                            </div>
                                <h2 *ngIf="jobsList?.length==0">{{'no result'|translate}}</h2>
                                <div class="col-md-12 col-sm-12 text-center" *ngIf="jobsList?.length>0">
                                    <pagination-controls (pageChange)="p = $event" previousLabel="{{'previous'|translate}}"
                                    nextLabel="{{'next'|translate}}">
                                  </pagination-controls>
                            
                                  </div>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
     
           
        
    </div>
   
</div>



