import { Component, OnInit } from '@angular/core';

import { HomeService } from '../../Shared/Services/home.service';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-home-category',
  templateUrl: './home-category.component.html',
  styleUrls: ['./home-category.component.scss'],
})
export class HomeCategoryComponent implements OnInit {
  CategoryData: any[] = [];
  TotalCategoryNO;
  total;
  lang;

  constructor(
    private languageService: LanguageService,
    private homeservice: HomeService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });

    this.getCategoriesList();
    this.othercategorieslist();
  }

  getCategoriesList() {
    this.homeservice.GetCategoryList().subscribe((res: any) => {
      console.log('categories = > ', res);
      this.CategoryData = res.content;
      // this.TotalCategoryNO = res.pagination.total
    });
  }

  navigateplaces(item) {
    this.router.navigate(['categories/places', this.lang], {
      queryParams: {
        category_value_id: item.id,
      },
    });
  }

  othercategorieslist() {
    this.homeservice.GetallplacesCount().subscribe((res: any) => {
      this.TotalCategoryNO = res.pagination.total;
    });
  }
}
