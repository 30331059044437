    <!-- Start Page Content --> 
    <div class="container pg-content">
        <div class="pg-header-content">
            <h1 class="pg-title" *ngIf="!projectId">
              {{'AddNewProject'|translate}}
            </h1>
            <h1 class="pg-title" *ngIf="projectId">
                {{'EditProject'|translate}}
              </h1>
          </div>
        
        <!--Start Contact Form -->
        <div class="col-sm-12 text-center">
          <!-- Success message -->
          <!-- <div class="alert alert-success" role="alert" id="success">
              <p>Your Project was sent succssfully! We will be in touch as soon as possible.</p>
          </div> -->
          <!-- Error message -->
         <!--  <div class="alert alert-error" role="alert" id="error">
              <p>Something went wrong, verify the fields or try refreshing the page.</p>
          </div> -->
          <form [formGroup]="placeForm" #myform="ngForm"  id="projectForm" name="projectForm" class="form-horizontal contact-form">
                <!-- ٍselect-->
                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="fa fa-map-marker"></i></span>
                            <!-- <select  name="places" formControlName="cms_id"  id="places" class="form-control">
                                <option value="" disabled>{{'Home.selectPlace' | translate}}</option>
                                <option  [ngValue]="item.id" *ngFor="let item of contactlist"> {{item.title}} </option>
                            </select> -->

                            <ngx-select-dropdown
                                formControlName="cms_id" 
                                [multiple]="false" 
                                [config]="config" 
                                [options]="contactlist"
                                [required]="true"
                                [(ngModel)]="val"
                            ></ngx-select-dropdown>
                        </div>
                        <p *ngIf="contactlist?.length == 0" class="text-danger">
                            {{'noPlace' | translate}}
                            <button (click)="gotoPlace()" class="btn btn-view">
                                <i class="fa fa-plus"></i>
                            </button>
                        </p>
                        <span *ngIf="!val && myform.submitted"
                            class="error-msg">
                            {{'Home.selectPlace' | translate}} {{'required'|translate}}
                        </span>
                    </div>
                </div>
                <!-- Text input-->
                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="glyphicon glyphicon-pencil"></i></span>
                            <input formControlName="title"  name="title" id="title" placeholder="{{'Home.project_name'|translate}}" class="form-control"  required>
                        </div>
                        <span *ngIf="placeForm.controls['title']?.errors?.required && myform.submitted"
                            class="error-msg">
                            {{'Home.project_name'|translate}} {{'required'|translate}}
                        </span>
                    </div>
                </div>
                <!-- Image upload -->
                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="glyphicon glyphicon-cloud-upload"></i></span>
                            <input style="display: none;" formControlName="image_url" accept="image/*" (change)="readImage($event)" type="file" class="form-control" placeholder="{{'UploadProjectImage'|translate}}" id="project_img">
                            <label *ngIf="!photo" class="form-control" style="text-align: start; cursor: pointer;"  for="project_img">
                                <i class="fa fa-cloud-upload"></i>{{'UploadProjectImage' | translate}}
                                <span class="imageName">{{imageName}}</span>
                              </label>

                              <div *ngIf="photo" class="js-uploader__file-list uploader__file-list">
                                <div class="js-uploader__file-list ">
                                   <div>
                                           <span class="uploader__file-list__thumbnail" style="width: 90px;">
                                            <img id="profileImg" class="thumbnail" id="img_" src="{{photo}}" style="margin-bottom: 0px;">    
                                          </span>  
                                     
                                          <span class="close">
                                            <a id="close" (click)="closebtn()" class="uploader__icon-button fa fa-times cursor--pointer">
                                            </a>
                                          </span>  
                                        </div>
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
                <!-- file upload -->
                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="glyphicon glyphicon-upload"></i></span>
                            <input style="display: none;" formControlName="file_url"  accept="application/pdf" (change)="readFile($event)" type="file" class="form-control" placeholder="{{'UploadProjectImage'|translate}}" id="project_file">
                            <label *ngIf="!file" class="form-control" style="text-align: start; cursor: pointer;"  for="project_file">
                                <i class="fa fa-cloud-upload"></i>{{'UploadProjectFile' | translate}}
                                <span class="imageName">{{fileName}}</span>
                              </label>

                              <div *ngIf="file" class="js-uploader__file-list uploader__file-list">
                                <div class="js-uploader__file-list ">
                                    <div>
                                           <span class="uploader__file-list__thumbnail" style="width: 90px;">
                                            <a [href]="file">{{'file' | translate}}</a>  
                                          </span>  
                                      
                                          <span class="close">
                                            <a id="close" (click)="closeFilebtn()" class="uploader__icon-button fa fa-times cursor--pointer">
                                            </a>
                                          </span>  
                                        </div>
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
                <!-- desc textarea-->
                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                        <div class="input-group">
                            <textarea  name="desc" formControlName="description" id="desc" class="form-control"
                            placeholder="{{'ProjectDesc'|translate}}" required></textarea>

                            <span *ngIf="placeForm.controls['description']?.errors?.required && myform.submitted"
                                class="error-msg">
                                {{'ProjectDesc'|translate}} {{'required'|translate}}
                            </span>
                        </div>
                    </div>
                </div>
        
                <!-- Submit Button -->
                <div class="form-group sub">
                <div class="col-sm-12">
                    <button (click)="submitPlace()" type="submit" name="name" class="btn btn-warning submit" >
                        <i *ngIf="isEnabled" class="fa fa-spinner fa-spin"></i> {{'Submit'|translate}}</button>
                </div>
                </div>
          </form>
        </div>
        <!--End Contact Form-->
      </div>
      <!-- End Page Content -->
    