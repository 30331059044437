import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HomeService } from 'src/app/Shared/Services/home.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';
import { ProjectsService } from 'src/app/Shared/Services/projects.service';

@Component({
  selector: 'app-related-contact',
  templateUrl: './related-contact.component.html',
  styleUrls: ['./related-contact.component.scss'],
})
export class RelatedContactComponent implements OnInit {
  contactForm: FormGroup;
  typeImage: any;
  imageName: string = '';
  image_url: any;
  file_base64: any;
  type: any;
  contactlist: any[] = [];
  isEnabled: boolean = false;
  lang: string;
  productId: any;
  placeId: any;
  photo: any;
  contactId: any;
  val: any;
  id: number;

  config: any = {};

  constructor(
    private projectservice: ProjectsService,
    private httpClientService: HttpClientService,
    private toaster: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService,
    private translate: TranslateService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.initForm();

    this.config = {
      placeholder: this.translate.instant('selectPlace'),
      search: false,
      displayKey: 'title',
    };

    this.route.queryParams.subscribe((res) => {
      this.productId = +res['id'];
      this.placeId = +res['placeId'];
      this.id = +res['paceId'];

      this.projectservice.GetcontactsList().subscribe((res: any) => {
        this.contactlist = res.content;
        let place;
        this.contactlist.forEach((ele) => {
          if (ele.id == this.placeId) {
            place = ele;
            this.val = ele;
          }
        });
        console.log('The Place is:-', place);
        console.log('contact list:-', this.contactlist);
      });

      this.contactForm.get('cms_id').setValue(this.placeId);
      if (this.productId) {
        let body = { id: this.productId };
        // this.projectservice.getProduct(body).subscribe(
        //   res=>{
        //     this.setForm(res['content']);
        //   }
        // );

        this.homeService.getRelatedContacts(this.id, 1).subscribe((res) => {
          console.log('Related Contacts ', res['content']);
          res['content'].forEach((element) => {
            if (element.id == this.productId) {
              this.setForm(element);
            }
          });
        });
      }
    });
  }

  initForm() {
    this.contactForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      position: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      cms_id: new FormControl('', [Validators.required]),
    });
  }

  closebtn() {
    this.photo = '';
    // this.img =false;
  }
  setForm(data) {
    console.log('Data', data);
    this.contactForm.patchValue({
      name: data?.name,
      position: data?.position,
      cms_id: data?.cms_id,
      mobile: data?.mobile,
    });
    this.contactId = data?.cms_id;
    this.placeId = this.contactId;
    this.val = data;
    // this.show = false;
  }

  onSubmit() {
    this.isEnabled = true;
    if (this.contactForm.valid) {
      this.contactForm.value.image = this.image_url;
      console.log('Result', this.contactForm.value);
      if (this.productId) {
        let mobileArray: any[] = [];
        mobileArray.push(this.contactForm.value.mobile);
        this.contactForm.value.mobile = mobileArray;
        let obj = this.contactForm.value;
        obj.id = this.productId;
        obj.cms_id = this.val.id;
        this.httpClientService
          .post('api/cms/edit_related_contact', obj, [])
          .subscribe(
            (res: any) => {
              this.isEnabled = false;
              console.log(res);
              if (res.status.code == 400) {
                this.toaster.error(res.status.error_details);
              } else if (res.status.code == 200) {
                if (this.lang == 'ar') {
                  this.toaster.success('تم تعديل جهة الاتصال المتعلقة بنجاح');
                } else {
                  this.toaster.success('Related Contact Updated Successfully');
                }
                this.router.navigate(['place-view', this.lang], {
                  queryParams: { id: obj.cms_id },
                });
              }
            },
            (error) => {
              this.isEnabled = false;
              console.log(error);
              let validation_errors = error.error.status.validation_errors;
              validation_errors.forEach((element) => {
                if (element.field == 'name') {
                  this.toaster.error(element.message);
                } else if (element.field == 'position') {
                  this.toaster.error(element.message);
                } else if (element.field == 'mobile') {
                  this.toaster.error(element.message);
                } else if (element.field == 'cms_id') {
                  this.toaster.error(element.message);
                }
              });
              // if(this.lang == 'ar'){
              //   this.toaster.error('حدث خطأ ما');
              // }else{
              //   this.toaster.error('Error happened')
              // }
            }
          );
      } else {
        let mobileArray: any[] = [];
        mobileArray.push(this.contactForm.value.mobile);
        this.contactForm.value.mobile = mobileArray;

        let obj = this.contactForm.value;
        obj.cms_id = this.val.id;
        this.httpClientService
          .post('api/cms/add_related_contact', obj, [])
          .subscribe(
            (res: any) => {
              this.isEnabled = false;
              console.log(res);
              if (res.status.code == 400) {
                this.toaster.error(res.status.error_details);
              } else if (res.status.code == 200) {
                if (this.lang == 'ar') {
                  this.toaster.success('تم إنشاء جهة الاتصال المتعلقة بنجاح');
                } else {
                  this.toaster.success('Related Contact Created Successfully');
                }

                this.router.navigate(['place-view', this.lang], {
                  queryParams: { id: obj.cms_id },
                });
              }
            },
            (error) => {
              this.isEnabled = false;
              console.log(error);
              let validation_errors = error.error.status.validation_errors;
              validation_errors.forEach((element) => {
                if (element.field == 'name') {
                  this.toaster.error(element.message);
                } else if (element.field == 'position') {
                  this.toaster.error(element.message);
                } else if (element.field == 'mobile') {
                  this.toaster.error(element.message);
                } else if (element.field == 'cms_id') {
                  this.toaster.error(element.message);
                }
              });
            }
          );
      }
    } else {
      this.isEnabled = false;
    }
  }

  gotoPlace() {
    this.router.navigate(['place-new', this.lang]);
  }
}
