import { Component, OnInit } from '@angular/core';
import {UserService} from '../../Shared/Services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl,FormBuilder,Validators } from '@angular/forms';
import {Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {AuthenticationService} from '../../Shared/Services/authentication.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  forgetpasswordForm: FormGroup;
  email;
  code;
  currentlang;


  constructor(private userservice:UserService, 
    private translateservice:TranslateService,
    private toastrService: ToastrService,
    private authservice:AuthenticationService,
    private router: Router) { }

  ngOnInit(): void {
     this.initForm();
     this.email = this.authservice.getEmail();
     this.code = localStorage.getItem('verification-code');

     console.log("code ::::::::", this.code)
     this.currentlang = localStorage.getItem('lang');



  }

  initForm(){

    this.forgetpasswordForm = new FormGroup({
      new_password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirm_new_password: new FormControl('', [Validators.required, Validators.minLength(8)])
    })
  }

  ForgetPassword(){
    let submitObject = {
      "email" : this.email,
      "email_verification_code" : this.code,
      "new_password":this.forgetpasswordForm.value['new_password'],
      "confirm_new_password":this.forgetpasswordForm.value['confirm_new_password'],

      

    }
    this.userservice.ForgetPassword(submitObject).subscribe((res:any)=>{
      if(res.status.code === 200){

        this.toastrService.success("sent"); // toasr services
        this.router.navigate(['/login', this.currentlang]);
      }
      else if (res.status.code === 401){
        let validation_errors = res.status.validation_errors;  
        validation_errors.forEach(element => {
          if(element.field == 'new_password')
           {this.toastrService.error(element.message)}
          else if (element.field == 'confirm_new_password')
           {this.toastrService.error(element.message)}

        
        })

      }
      else {
        this.toastrService.error(res.status.error_details); // toasr services
          }

      // this.toastrService.success("sent"); // toasr services
      // this.router.navigate(['/Login/Register']);
      
    },error => {
      console.log(error.error);
      if (error.error.status.code == 401){
        let validation_errors = error.error.status.validation_errors;  
        validation_errors.forEach(element => {
          if(element.field == 'new_password')
           {this.toastrService.error(element.message)}
          else if (element.field == 'confirm_new_password')
           {this.toastrService.error(element.message)}

        })
      }
      // if(error.validation_errors.field == 'email'){
      //   this.toastrService.error(error); // toasr services
      // }

    }
    )
  }

}
