<div class="container-fluid pg-header" style="background-image: url(./assets/images/16.jpg);">
    <div class="pg-header-content">
        <h1 class="pg-title">
            {{'Requests.Requests'|translate}}
        </h1>
        <h4 class="pg-subtitle">

        </h4>
    </div>
    <!-- <div class="pg-header-icon icon-bubbles"></div> -->
</div>
<!-- End Page Header -->
<!-- Start Contact Map -->
<div class="container-fluid map-contact">
    <div class="container page_bg pt-50 pb-50 pr-50 pl-50">

        <div class="row" *ngIf="isUser">
            <div class="col-md-3">
                <select class="form-control" (change)="CityFilter($event.target.value)">
                    <option disabled selected>{{'Choose_City' | translate}}</option>
                    <option *ngFor="let city of cities" [value]="city.id">{{city.name}}</option>
                </select>
            </div>

            <div class="col-md-3">
                <select class="form-control" (change)="CategoryFilter($event.target.value)">
                    <option disabled selected>{{'Choose_Category' | translate}}</option>
                    <option *ngFor="let item of CategoriesData" [value]="item.id">{{item.title}}</option>
                </select>
            </div>

            <div class="col-md-3">
                <select class="form-control" (change)="ContactFilter($event.target.value)">
                    <option disabled selected>{{'Choose_Contact' | translate}}</option>
                    <option *ngFor="let place of places" [value]="place.id">{{place.title}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="panel panel-form">
                    <!-- <div class="panel-heading">
                        <div class="row nav nav-tabs" id="myTab" role="tablist" style="display: flex;">
                            <div class="col-xs-4 nav-item">
                                <a class="nav-item nav-link active" id="nav-all-tab" data-toggle="tab" href="#nav-all"
                                    role="tab" aria-controls="nav-all-tab" >
                                    {{'Requests.allRequests'|translate}}
                                </a>
                            </div>
                            <div class="col-xs-4 nav-item">
                                <a class="nav-item nav-link" id="nav-home-tab" data-toggle="tab" href="#nav-home"
                                    role="tab" aria-controls="nav-home-tab" >
                                    {{'Requests.inprogress'|translate}}
                                </a>
                            </div>
                            <div class="col-xs-4 nav-item">
                                <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile"
                                    role="tab" aria-controls="nav-profile-tab" >
                                    {{'Requests.CurrentRequests'|translate}}

                                </a>
                            </div>
                            <div class="col-xs-4 nav-item">
                                <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact"
                                    role="tab" aria-controls="nav-contact-tab">
                                    {{'Requests.ClosedRequests'|translate}}

                                </a>
                            </div>
                        </div>
                        <hr>
                    </div> -->
                    <!-- <div class="panel-body">
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade in active" id="nav-all" role="tabpanel"
                                aria-labelledby="nav-all-tab">
                                <table class="table" cellspacing="0" *ngIf="requestList.length>0">
                                    <thead>
                                        <tr>
                                            <th>{{'Requests.IDRequests'|translate}}</th>
                                            <th>{{'Requests.TypeRequests'|translate}}</th>
                                            <th>{{'Requests.NameRequests'|translate}}</th>
                                            <th>{{'Requests.TitleRequests'|translate}}</th>
                                            <th width="250">{{'Requests.DetailRequests'|translate}}</th>
                                            <th>{{'Requests.PriceRequests'|translate}}</th>
                                            <th>{{'Requests.DateRequests'|translate}}</th>
                                            <th>{{'Requests.ActionRequests'|translate}}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let request of requestList">
                                            <td> {{request?.item_id}}</td>
                                            <td> {{request?.item?.category?.name}}</td>
                                            <td> {{request?.item.name}}</td>
                                            <td> {{request?.item.title}}</td>
                                            <td class="desc">
                                                {{request?.item.description}}
                                            </td>
                                            <td> {{request?.last_offer}}</td>
                                            <td class="date">
                                                <span> <i class="fa fa-calendar"></i>{{request?.item.created_at |
                                                    date:'short'}}</span>
                                            </td>
                                            <td>
                                                <button [disabled]="user_id==request?.updated_by" class="button details"
                                                    (click)="closeRequest(request,1)"> {{'Requests.accept'|translate}}
                                                </button>
                                                <button [disabled]="user_id==request?.updated_by" class="button cancel "
                                                    (click)="closeRequest(request,0)">
                                                    {{'Requests.reject'|translate}}</button>
                                                <button [disabled]="user_id==request?.updated_by" class="button edit"
                                                    (click)="showDialog(request)">
                                                    {{'Requests.edit'|translate}}</button>
                                            </td>
                                        </tr>



                                    </tbody>
                                </table>
                                <h2 class="tab-pane fade in active" *ngIf="newRequest.length == 0"> {{'Requests.NoRequest'|translate}} </h2>

                            </div>

                            <div class="tab-pane fade in" id="nav-home" role="tabpanel"
                                aria-labelledby="nav-home-tab">
                                <table class="table" cellspacing="0" *ngIf="newRequest.length>0">
                                    <thead>
                                        <tr>
                                            <th>{{'Requests.IDRequests'|translate}}</th>
                                            <th>{{'Requests.TypeRequests'|translate}}</th>
                                            <th>{{'Requests.NameRequests'|translate}}</th>
                                            <th>{{'Requests.TitleRequests'|translate}}</th>
                                            <th width="250">{{'Requests.DetailRequests'|translate}}</th>
                                            <th>{{'Requests.PriceRequests'|translate}}</th>
                                            <th>{{'Requests.DateRequests'|translate}}</th>
                                            <th>{{'Requests.ActionRequests'|translate}}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let request of newRequest">
                                            <td> {{request?.item_id}}</td>
                                            <td> {{request?.item?.category?.name}}</td>
                                            <td> {{request?.item.name}}</td>
                                            <td> {{request?.item.title}}</td>
                                            <td class="desc">
                                                {{request?.item.description}}
                                            </td>
                                            <td> {{request?.last_offer}}</td>
                                            <td class="date">
                                                <span> <i class="fa fa-calendar"></i>{{request?.item.created_at |
                                                    date:'short'}}</span>
                                            </td>
                                            <td>
                                                <button [disabled]="user_id==request?.updated_by" class="button details"
                                                    (click)="closeRequest(request,1)"> {{'Requests.accept'|translate}}
                                                </button>
                                                <button [disabled]="user_id==request?.updated_by" class="button cancel "
                                                    (click)="closeRequest(request,0)">
                                                    {{'Requests.reject'|translate}}</button>
                                                <button [disabled]="user_id==request?.updated_by" class="button edit"
                                                    (click)="showDialog(request)">
                                                    {{'Requests.edit'|translate}}</button>
                                            </td>
                                        </tr>



                                    </tbody>
                                </table>
                                <h2 class="tab-pane fade in active" *ngIf="newRequest.length == 0"> {{'Requests.NoRequest'|translate}} </h2>

                            </div>
                            <div class="tab-pane fade" id="nav-profile" role="tabpanel"
                                aria-labelledby="nav-profile-tab">
                                <table class="table" cellspacing="0" *ngIf="approvedRequest.length>0">
                                    <thead>
                                        <tr>
                                            <th>{{'Requests.IDRequests'|translate}}</th>
                                            <th>{{'Requests.TypeRequests'|translate}}</th>
                                            <th>{{'Requests.NameRequests'|translate}}</th>
                                            <th>{{'Requests.TitleRequests'|translate}}</th>
                                            <th width="250">{{'Requests.DetailRequests'|translate}}</th>
                                            <th>{{'Requests.PriceRequests'|translate}}</th>
                                            <th>{{'Requests.DateRequests'|translate}}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let request of approvedRequest">
                                            <td> {{request?.item_id}}</td>
                                            <td> {{request?.item?.category?.name}}</td>
                                            <td> {{request?.item.name}}</td>
                                            <td> {{request?.item.title}}</td>
                                            <td class="desc">
                                                {{request?.item.description}}
                                            </td>
                                            <td> {{request?.last_offer}}</td>
                                            <td class="date">
                                                <span> <i class="fa fa-calendar"></i>{{request?.item.created_at |
                                                    date:'short'}}</span>
                                            </td>

                                        </tr>



                                    </tbody>
                                </table>
                                <h2 class="tab-pane fade" *ngIf="approvedRequest.length == 0"> {{'Requests.NoRequest'|translate}} </h2>

                            </div>
                            <div class="tab-pane fade" id="nav-contact" role="tabpanel"
                                aria-labelledby="nav-contact-tab">
                                <table class="table" cellspacing="0" *ngIf="declinedRequest.length>0">
                                    <thead>
                                        <tr>
                                            <th>{{'Requests.IDRequests'|translate}}</th>
                                            <th>{{'Requests.TypeRequests'|translate}}</th>
                                            <th>{{'Requests.NameRequests'|translate}}</th>
                                            <th>{{'Requests.TitleRequests'|translate}}</th>
                                            <th width="250">{{'Requests.DetailRequests'|translate}}</th>
                                            <th>{{'Requests.PriceRequests'|translate}}</th>
                                            <th>{{'Requests.DateRequests'|translate}}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let request of declinedRequest">
                                            <td> {{request?.item_id}}</td>
                                            <td> {{request?.item?.category?.name}}</td>
                                            <td> {{request?.item.name}}</td>
                                            <td> {{request?.item.title}}</td>
                                            <td class="desc">
                                                {{request?.item.description}}
                                            </td>
                                            <td> {{request?.last_offer}}</td>
                                            <td class="date">
                                                <span> <i class="fa fa-calendar"></i>{{request?.item.created_at |
                                                    date:'short'}}</span>
                                            </td>

                                        </tr>



                                    </tbody>
                                </table>
                                <h2 class="tab-pane fade" *ngIf="declinedRequest.length == 0"> {{'Requests.NoRequest'|translate}} </h2>
                            </div>
                        </div>
                    </div> -->
                    
<div class="tabset-section">
                    <tabset [justified]="true">
                        <tab heading="{{'Requests.Requests'|translate}}" *ngIf="isUser">
                            <table class="table" cellspacing="0" *ngIf="newRequest.length>0">
                                <thead>
                                    <tr>
                                        <!-- <th>{{'Requests.IDRequests'|translate}}</th> -->
                                        <th>{{'Requests.TypeRequests'|translate}}</th>
                                        <th>{{'Requests.NameRequests'|translate}}</th>
                                        <th>{{'Requests.TitleRequests'|translate}}</th>
                                        <th>{{'Requests.DetailRequests'|translate}}</th>
                                        <!-- <th>{{'Requests.PriceRequests'|translate}}</th> -->
                                        <th>{{'Requests.DateRequests'|translate}}</th>
                                        <th>{{'Actions' | translate}}</th>
                                        <!-- <th>{{'Requests.ActionRequests'|translate}}</th> -->

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let request of newRequest">
                                        <!-- <td> {{request?.id}}</td> -->
                                        <td> {{request?.category?.name}}</td>
                                        <td> {{request?.name}}</td>
                                        <td> {{request?.title}}</td>
                                        <td class="desc">
                                            {{request?.description}}
                                        </td>
                                        <!-- <td><span class="status cancelled">Cancelled</span></td> -->
                                        <td class="date">
                                            <span> <i class="fa fa-calendar"></i>{{request?.created_at |
                                                date:'short'}}</span>
                                            <!-- <span> <i class="fa fa-time"></i> 12:00 pm</span> -->
                                        </td>
                                        <td>
                                            <button class="btn btn-danger" (click)="cancelRequest(request?.id)"><i class="fa fa-trash"></i></button>
                                        </td>
                                        <!-- <td>
                                            <button [disabled]="user_id==request?.updated_by" class="button details"
                                                (click)="closeRequest(request,1)"> {{'Requests.accept'|translate}}
                                            </button>
                                            <button [disabled]="user_id==request?.updated_by" class="button cancel "
                                                (click)="closeRequest(request,0)">
                                                {{'Requests.reject'|translate}}</button>
                                            <button [disabled]="user_id==request?.updated_by" class="button edit"
                                                (click)="showDialog(request)">
                                                {{'Requests.edit'|translate}}</button>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                            <h2 class="tab-pane fade in active" *ngIf="newRequest.length == 0"> {{'Requests.NoRequest'|translate}} </h2>
                        </tab>
                        <tab heading="{{'Requests.inprogress'|translate}}" *ngIf="isUser || !isUser">
                            <table class="table" cellspacing="0" *ngIf="inProgress.length>0">
                                <thead>
                                    <tr>
                                        <!-- <th>{{'Requests.IDRequests'|translate}}</th> -->
                                        <th>{{'Requests.TypeRequests'|translate}}</th>
                                        <th>{{'Requests.NameRequests'|translate}}</th>
                                        <th>{{'Requests.TitleRequests'|translate}}</th>
                                        <th width="250">{{'Requests.DetailRequests'|translate}}</th>
                                        <th *ngIf="isUser">{{'Requests.information' | translate}}</th>
                                        <!-- <th *ngIf="!isUser">{{'Requests.information' | translate}}</th> -->
                                        <th>{{'Requests.messages'|translate}}</th>
                                        <th>{{'Requests.PriceRequests'|translate}}</th>
                                        <th>{{'Requests.DateRequests'|translate}}</th>
                                        <th>{{'Requests.ActionRequests'|translate}}</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let request of inProgress">
                                        <!-- <td> {{request?.item_id}}</td> -->
                                        <td> {{request?.item?.category?.name}}</td>
                                        <td> {{request?.item.name}}</td>
                                        <td> {{request?.item.title}}</td>
                                        <td class="desc">
                                            {{request?.item.description}}
                                        </td>
                                        <td *ngIf="isUser">
                                            <button class="comapny" (click)="showCompanyInfo(request)">{{'show' | translate}}</button>
                                        </td>

                                        <td>
                                            <button class="comapny" (click)="showMessages(request)">{{'show' | translate}}</button>
                                        </td>
                                        <!-- <td *ngIf="!isUser">
                                            <button class="comapny" (click)="showUserInfo(request)">{{'show' | translate}}</button>
                                        </td> -->
                                        <td> {{request?.last_offer}}</td>
                                        
                                        <!-- <td><span class="status cancelled">Cancelled</span></td> -->
                                        <td class="date">
                                            <span> <i class="fa fa-calendar"></i>{{request?.item.created_at |
                                                date:'short'}}</span>
                                            <!-- <span> <i class="fa fa-time"></i> 12:00 pm</span> -->
                                        </td>
                                        <td *ngIf="user_id!=request?.updated_by">
                                            <button [disabled]="user_id==request?.updated_by" class="button details"
                                                (click)="closeRequest(request,1)" [ngClass]="(user_id==request?.updated_by)?'disabled':''"> {{'Requests.accept'|translate}}
                                            </button>
                                            <button [disabled]="user_id==request?.updated_by" class="button cancel "
                                                (click)="closeRequest(request,0)" [ngClass]="(user_id==request?.updated_by)?'disabled':''">
                                                {{'Requests.reject'|translate}}</button>
                                            <button [disabled]="user_id==request?.updated_by" class="button edit"
                                                (click)="showDialog(request)" [ngClass]="(user_id==request?.updated_by)?'disabled':''">
                                                {{'Requests.edit'|translate}}</button>
                                        </td>
                                        <td *ngIf="user_id==request?.updated_by">
                                            <!-- {{'waitingResponse' | translate}} -->
                                            <button class="btn btn-danger" (click)="cancelRequest(request?.id)"><i class="fa fa-trash"></i></button>
                                        </td>
                                    </tr>



                                </tbody>
                            </table>
                            <h2 class="tab-pane fade in active" *ngIf="inProgress.length == 0"> {{'Requests.NoRequest'|translate}} </h2>
                        </tab>
                        <tab heading="{{'Requests.CurrentRequests'|translate}}" *ngIf="isUser || !isUser">
                            <table class="table" cellspacing="0" *ngIf="approvedRequest.length>0">
                                <thead>
                                    <tr>
                                        <!-- <th>{{'Requests.IDRequests'|translate}}</th> -->
                                        <th>{{'Requests.TypeRequests'|translate}}</th>
                                        <th>{{'Requests.NameRequests'|translate}}</th>
                                        <th>{{'Requests.TitleRequests'|translate}}</th>
                                        <th width="250">{{'Requests.DetailRequests'|translate}}</th>
                                        <th *ngIf="isUser">{{'Requests.information' | translate}}</th>
                                        <th *ngIf="!isUser">{{'Requests.information' | translate}}</th>
                                        <th>{{'Requests.PriceRequests'|translate}}</th>
                                        <th>{{'Requests.DateRequests'|translate}}</th>
                                        <!-- <th>{{'Requests.ActionRequests'|translate}}</th> -->

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let request of approvedRequest">
                                        <!-- <td> {{request?.item_id}}</td> -->
                                        <td> {{request?.item?.category?.name}}</td>
                                        <td> {{request?.item.name}}</td>
                                        <td> {{request?.item.title}}</td>
                                        <td class="desc">
                                            {{request?.item.description}}
                                        </td>

                                        <td *ngIf="isUser">
                                            <button class="comapny" (click)="showCompanyInfo(request)">{{'show' | translate}}</button>
                                        </td>
                                        <td *ngIf="!isUser">
                                            <button class="comapny" (click)="showUserInfo(request)">{{'show' | translate}}</button>
                                        </td>

                                        <td> {{request?.last_offer}}</td>
                                        <!-- <td><span class="status cancelled">Cancelled</span></td> -->
                                        <td class="date">
                                            <span> <i class="fa fa-calendar"></i>{{request?.item.created_at |
                                                date:'short'}}</span>
                                            <!-- <span> <i class="fa fa-time"></i> 12:00 pm</span> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h2 class="tab-pane fade in active" *ngIf="approvedRequest.length == 0"> {{'Requests.NoRequest'|translate}} </h2>
                        </tab>
                        <tab heading="{{'Requests.ClosedRequests'|translate}}"*ngIf="isUser || !isUser">
                            <table class="table" cellspacing="0" *ngIf="declinedRequest.length>0">
                                <thead>
                                    <tr>
                                        <!-- <th>{{'Requests.IDRequests'|translate}}</th> -->
                                        <th>{{'Requests.TypeRequests'|translate}}</th>
                                        <th>{{'Requests.NameRequests'|translate}}</th>
                                        <th>{{'Requests.TitleRequests'|translate}}</th>
                                        <th width="250">{{'Requests.DetailRequests'|translate}}</th>
                                        <th *ngIf="isUser">{{'Requests.information' | translate}}</th>
                                        <th *ngIf="!isUser">{{'Requests.information' | translate}}</th>
                                        <th>{{'Requests.PriceRequests'|translate}}</th>
                                        <th>{{'Requests.DateRequests'|translate}}</th>
                                        <!-- <th>{{'Requests.ActionRequests'|translate}}</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let request of declinedRequest">
                                        <!-- <td> {{request?.item_id}}</td> -->
                                        <td> {{request?.item?.category?.name}}</td>
                                        <td> {{request?.item.name}}</td>
                                        <td> {{request?.item.title}}</td>
                                        <td class="desc">
                                            {{request?.item.description}}
                                        </td>
                                        <td *ngIf="isUser">
                                            <button class="comapny" (click)="showCompanyInfo(request)">{{'show' | translate}}</button>
                                        </td>
                                        <td *ngIf="!isUser">
                                            <button class="comapny" (click)="showUserInfo(request)">{{'show' | translate}}</button>
                                        </td>
                                        <td> {{request?.last_offer}}</td>
                                        <!-- <td><span class="status cancelled">Cancelled</span></td> -->
                                        <td class="date">
                                            <span> <i class="fa fa-calendar"></i>{{request?.item.created_at |
                                                date:'short'}}</span>
                                            <!-- <span> <i class="fa fa-time"></i> 12:00 pm</span> -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h2 class="tab-pane fade in active" *ngIf="declinedRequest.length == 0"> {{'Requests.NoRequest'|translate}} </h2>
                        </tab>
                      </tabset>
                    </div>
                    </div>
            </div>
        </div>

        
    </div>
</div>
<p-dialog [(visible)]="display" [baseZIndex]="10000" [closable]="true" [modal]="true">
    <p-header>
        {{'title' | translate}}: {{negotiationTilte}}
    </p-header>
    <p><span>{{'description' | translate}}:</span> {{negotiationDesc}}</p>
    <form class="form-horizontal request-form" [formGroup]="requestPrice" (ngSubmit)="sendPrice(requestPrice.value)">
        <!-- Text input-->
        <div class="form-group">
            <div class="col-sm-12 inputGroupContainer">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-money"></i></span>
                    <input name="price" id="price" formControlName="price" placeholder="{{'price'|translate}}"
                        class="form-control" required>

                </div>
                <div *ngIf="requestPrice.controls.price.touched && requestPrice.controls.price.errors?.required"
                    class="text text-danger">
                    {{'price'|translate}} {{'required'|translate}}
                </div>
                <div *ngIf="priceNotValid&&requestPrice.get('price').dirty" class="text text-danger">
                    {{'VALIDATORS.price'|translate}}
                </div>

            </div>

            <div class="col-sm-12 inputGroupContainer mt-2" style="margin-top: 10px;">
                <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
                    <textarea id="message" formControlName="comment" placeholder="{{'Your Message...........'|translate}}"
                        class="form-control" rows="5"></textarea>

                </div>
            </div>
        </div>

        <p-footer></p-footer>
        <div class="form-group sub">
            <div class="col-sm-12">
                <button [disabled]="!requestPrice.valid" class="btn btn-warning submit">{{'Submit'| translate}}</button>
            </div>
        </div>

    </form>

</p-dialog>

<p-dialog [(visible)]="display2" [baseZIndex]="10000">
    <p-header>
        {{'Requests.information' | translate}}
    </p-header>
    <div class="">
        <div class="">
            <div >
                <p>{{'name' | translate}} : {{companyName}}</p>
            </div>
            <div>
                <p>
                    {{'email' | translate}} : {{companyEmail}}
                </p>
            </div>

            <div >
                <p>
                    {{'phoneno' | translate}} : {{companyPhone}}
                </p>
            </div>

        </div>
    </div>
   
</p-dialog>

<p-dialog [(visible)]="display3" [baseZIndex]="10000" [style]="{width: '75vw'}">
    <p-header>
        {{'Requests.messages' | translate}}
    </p-header>
    <!-- <div class="">
        <div class="" *ngFor="let message of messages">
            <div *ngIf="message.created_by == user_id">
                <p>{{'myMessage' | translate}} : {{message.comment}}</p>
            </div>
            <div *ngIf="message.created_by != user_id">
                <p>
                    {{'otherMessage' | translate}} : {{message.comment}}
                </p>
            </div>
        </div>
    </div> -->

    <table class="table" cellspacing="0" *ngIf="messages.length>0">
        <thead>
            <tr>
                <th><h3>{{'sender'|translate}}</h3></th>
                <th><h3>{{'Requests.PriceRequests'|translate}}</h3></th>
                <th><h3>{{'Requests.message'|translate}}</h3></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let message of messages">
                <td *ngIf="message.created_by == user_id"><h5> {{'You' | translate}}</h5></td>
                <td *ngIf="message.created_by != user_id"><h5> {{'Other' | translate}}</h5></td>
                <td><h5> {{message?.offer}}</h5></td>
                <td><h5> {{message?.comment}}</h5></td>
            </tr>
        </tbody>
    </table>
    <h2 class="tab-pane fade in active" *ngIf="messages.length == 0"> {{'Requests.nomessages'|translate}} </h2>
   
</p-dialog>