
<!-- Start Page Content -->
<div class="container-fluid no-padding bg-login">
    <div class="row">
          <div class="col-sm-12 text-center">
              <div class="panel panel-form">

                <div class="panel-heading">
                    <div class="row nav nav-tabs" id="myTab" role="tablist">
                        <div class="col-xs-12 nav-item" >
                            <a class="nav-link active" id="login-tab" data-toggle="tab" href="#login" role="tab" aria-controls="login" aria-selected="true">{{'updateprofile'|translate}}</a>
                        </div>

                    </div>
                    <hr>
                </div>

                  <div class="panel-body">
                    <div class="tab-content" id="myTabContent">




                        <div  class="tab-pane fade active in" id="login"  role="tabpanel" >
                            <form [formGroup]="updateprofileform" #myform="ngForm">
                                <legend>{{'Basic Information'| translate}}</legend>
                                <div class="row">
                                    <div class="col-md-12 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <input *ngIf="currentlang === 'ar'" type="text" formControlName="full_name" name="fullname" id="register-fullname" tabindex="1" class="form-control" placeholder="Full Name"    value={{username}}>
                                            <input *ngIf="currentlang === 'en'" type="text" formControlName="full_name" name="fullname" id="register-fullname" tabindex="1" class="form-control" placeholder="الاسم الكامل" value={{username}}>

                                            <span *ngIf="updateprofileform.controls['full_name']?.errors?.required && myform.submitted" class="error-msg">
                                                {{'nameReqMes'|translate}}
                                            </span>
                                            <span *ngIf="updateprofileform.controls['full_name']?.errors?.pattern && myform.submitted" class="error-msg">
                                                {{'nameMustBeCharc'|translate}}.
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <input *ngIf="currentlang === 'ar'" type="email" name="email" formControlName="email" id="register-email" tabindex="3" class="form-control" placeholder="Email Address"      value={{useremail}}>
                                            <input *ngIf="currentlang === 'en'" type="email" name="email" formControlName="email" id="register-email" tabindex="3" class="form-control" placeholder="البريد الالكترونى" value={{useremail}}>

                                            <span *ngIf="updateprofileform.controls['email']?.errors?.required && myform.submitted" class="error-msg">
                                                {{'emailReqMes'|translate}}
                                            </span>
                                            <span *ngIf="updateprofileform.controls['email']?.errors?.email && myform.submitted" class="error-msg">
                                                {{'emailmustbe'|translate}}.
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div class="row">

                                    <div class="col-md-3 col-sm-3 col-xs-3">
                                        <div class="form-group">
                                            <select  formControlName="tele_code" name="code" id="register-phone-code" tabindex="4" class="form-control" >
                                                <option value="{{code.tele_code}}" *ngFor="let code of codelist">{{code.tele_code}}</option>
                                              </select>
                                            <span *ngIf="updateprofileform.controls['tele_code']?.errors?.required && myform.submitted" class="error-msg">
                                                {{'codeReqMes'|translate}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-9 col-sm-9 col-xs-9">
                                        <div class="form-group">
                                            <input *ngIf="currentlang === 'ar'" type="number" formControlName="mobile" name="phone" id="register-phone" tabindex="5" class="form-control" placeholder="Phone Number" value={{userphone}}>
                                            <input *ngIf="currentlang === 'en'" type="number" formControlName="mobile" name="phone" id="register-phone" tabindex="5" class="form-control" placeholder="رقم الهاتف"  value={{userphone}}>

                                            <span *ngIf="updateprofileform.controls['mobile']?.errors?.required && myform.submitted" class="error-msg">
                                                {{'phoneReqMes'|translate}}
                                            </span>
                                            <span *ngIf="updateprofileform.controls['mobile']?.errors?.pattern && myform.submitted" class="error-msg">
                                                {{'phonePatMes'|translate}}.
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">

                                            <!-- <input formControlName="birthdate" placeholder="{{userbirthdate}}"  id="register-birthdate"  name="birthdate" tabindex="6" class="form-control"    type = "date"> -->

                                            <div class="input-group" style="display:flex">
                                                    <!-- <input class="form-control" placeholder="{{userbirthdate}}" name="dp" formControlName="birthdate" ngbDatepicker #d="ngbDatepicker"> -->
                                                    <span class="old-date">{{userbirthdate}}</span>
                                                    <input formControlName="birthdate" type="date" name="dp" [ngClass]="currentlang == 'ar'?'text-right':''" placeholder="{{userbirthdate}}" class="form-control" (click)="ClearOldDate()">
                                                           
                                                    
                                            </div>
                                            <span *ngIf="updateprofileform.controls['birthdate']?.errors?.required && myform.submitted" class="error-msg">
                                                {{'birthdateReqMes'|translate}}
                                            </span> 
                                                
                                              
                                              <!-- <hr/>
                                              <pre>Model: {{ model | json }}</pre>
                                              
                                              <ngb-alert class="mt-3 mb-0" type="info" [dismissible]="false">
                                                You must provide the icon for the button. This allows you
                                                to choose an icon that matches your application's style.
                                                In this example, the icon is set via a CSS class.
                                              </ngb-alert> -->


                                            <!-- <span *ngIf="updateprofileform.controls['birthdate'].touched && updateprofileform.controls['birthdate']?.errors?.required" class="error-msg">
                                                {{'birthdateReqMes'|translate}}
                                            </span> -->
                                            <!-- <span *ngIf="updateprofileform.controls['birthdate'].touched && updateprofileform.controls['birthdate']?.errors?.pattern" class="error-msg">
                                                {{'birthdateMes'|translate}}.
                                            </span> -->
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group text-left">
                                            <input type="radio"   [value]="1"  formControlName="gender_id" id="male"  name="gender_id"  tabindex="7"> &nbsp;
                                            <label for="male">{{'Male'|translate}}</label> &nbsp; &nbsp;
                                            <input type="radio"  [value]="2"   formControlName="gender_id" id="female"  name="gender_id"  tabindex="8"> &nbsp;
                                            <label for="female">{{'Female'|translate}}</label>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col-md-6 col-sm-12 col-xs-12" *ngIf="userType != '1'">

                                        <div class="form-group">
                                            <div *ngIf="uploaded_image3" class="js-uploader__file-list uploader__file-list">
                                                <div class="js-uploader__file-list ">
                                                    <div>
                                                        <span class="uploader__file-list__thumbnail" >
                                                            <span class="thumbnail style" style="margin-bottom: 0px;">{{'Tax File' | translate}}</span>  
                                                            <span *ngIf="update_at">{{'last' | translate}}{{update_at}}</span>
                                                        </span>
                                            
                                                        <span class="close" style="margin:5px 25px">
                                                            <a id="close" (click)="closebtn3()" class="uploader__icon-button fa fa-times cursor--pointer">
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <input type="file" accept="image/png, image/gif, image/jpeg , image/jpg , application/pdf, .doc , .docx , .xml , application/msword , application/vnd.openxmlformats-officedocument.wordprocessingml.document"(change)="readImage3($event)" style="visibility: hidden;" name="tax_image" formControlName="tax_image" id="tax_image" tabindex="9" class="form-control">
                                            <label for="tax_image" class="form-control" *ngIf="!uploaded_image3">{{'Upload Tax File' | translate}}</label>
                                        </div>
                                        
                                    </div>

                                    <div class="col-md-6 col-sm-12 col-xs-12" *ngIf="userType != '1'">

                                        <div class="form-group">
                                            <div *ngIf="uploaded_image4" class="js-uploader__file-list uploader__file-list">
                                                <div class="js-uploader__file-list ">
                                                    <div>
                                                        <span class="uploader__file-list__thumbnail" >
                                                            <span class="thumbnail style" style="margin-bottom: 0px;">{{'Commercial File' | translate}}</span>  
                                                            <span *ngIf="update_at">{{'last' | translate}}{{update_at}}</span>
                                                        </span>
                                            
                                                        <span class="close" style="margin:5px 25px">
                                                            <a id="close" (click)="closebtn4()" class="uploader__icon-button fa fa-times cursor--pointer">
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <input type="file" accept="image/png, image/gif, image/jpeg , image/jpg , application/pdf, .doc , .docx , .xml , application/msword , application/vnd.openxmlformats-officedocument.wordprocessingml.document"(change)="readImage4($event)" style="visibility: hidden;" name="commercial_image" formControlName="commercial_image" id="commercial_image" tabindex="10" class="form-control">
                                            <label for="commercial_image" class="form-control" *ngIf="!uploaded_image4">{{'Upload Commercial File' | translate}}</label>
                                        </div>
                                        
                                    </div>

                                </div>

                                <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="userType == '1'">
                                    <div class="form-group">
                                        <div *ngIf="attached_cv" class="js-uploader__file-list uploader__file-list">
                                            <div class="js-uploader__file-list ">
                                                <div>
                                                    <span class="uploader__file-list__thumbnail" >
                                                        <span class="thumbnail style" style="margin-bottom: 0px;">{{'CV' | translate}}</span>  
                                                        <span *ngIf="update_at">{{'last' | translate}}{{update_at}}</span>
                                                    </span>
                                        
                                                    <span class="close">
                                                        <a id="close" (click)="closebtn()" class="uploader__icon-button fa fa-times cursor--pointer">
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="file" accept="application/pdf" (change)="readImage($event)" style="visibility: hidden;" name="cv" formControlName="attached_cv" id="cv" tabindex="3" class="form-control">
                                        <label for="cv" class="form-control" *ngIf="!attached_cv">{{'Attach CV' | translate}}</label>
                                    </div>
                                </div>

                                <div class="col-md-12 col-sm-12 col-xs-12" style="padding:0px">
                                    <div class="form-group">

                                        <div *ngIf="uploaded_image" class="js-uploader__file-list uploader__file-list">
                                            <div class="js-uploader__file-list ">
                                                <div>
                                                    <span class="uploader__file-list__thumbnail" >
                                                        <span class="thumbnail style" style="margin-bottom: 0px;">{{'Image' | translate}}</span>  
                                                        <span *ngIf="update_at">{{'last' | translate}}{{update_at}}</span>
                                                    </span>
                                        
                                                    <span class="close" style="margin:5px 25px">
                                                        <a id="close" (click)="closebtn2()" class="uploader__icon-button fa fa-times cursor--pointer">
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <input type="file"  (change)="readImage2($event)" style="visibility: hidden;" name="image" formControlName="uploaded_image" id="image" tabindex="6" class="form-control">
                                        <label for="image" class="form-control" *ngIf="!uploaded_image">{{'Upload Image' | translate}}</label>
                                        <!-- <label class="form-control" *ngIf="attached_cv">{{fileName}}</label> -->
                                    </div>
                                </div>

                                <!-- soical media links -->
                                <legend *ngIf="userType != '1'">{{'Social Media Links'|translate}}</legend>
                                <div class="row" *ngIf="userType != '1'">
                                    <div class="col-md-6 col-sm-6">
                                      <div class="socialMedia d-flex social-icons form-group">
                                        <div class="kb-icon"><i class="fa fa-facebook"></i></div>
                                        <input type="text" class="form-control" formControlName="facebook" placeholder="https://wwww.facebook.com/" value={{userfacebook}}>
                                      </div>
                                      <div class="socialMedia d-flex social-icons form-group">
                                        <div class="kb-icon"><i class="fa fa-twitter"></i></div>
                                        <input type="text" class="form-control " formControlName="twitter" placeholder="https://wwww.twitter.com/" value={{usertwitter}}>
                                      </div>
                                    </div>

                                    <div class="col-md-6 col-sm-6">
                                      <div class="socialMedia d-flex social-icons form-group">
                                        <div class="kb-icon"><i class="fa fa-linkedin"></i></div>
                                        <input type="text" class="form-control" formControlName="linkedin" placeholder="https://wwww.linkedin.com/" value={{userlinkedin}}>
                                      </div>
                                      <div class="socialMedia d-flex social-icons form-group">
                                        <div class="kb-icon"><i class="fa fa-instagram"></i></div>
                                        <input type="text" class="form-control" formControlName="instagram" placeholder="https://wwww.instagram.com/" value={{userinstagram}}>
                                      </div>

                                    </div>


                                </div>


                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <button  (click)="updateprofile()" class="form-control btn btn-submit">{{'updateprofile'|translate}}</button>

                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>


                    </div>
                  </div>


              </div>

          </div>
      </div>
  </div>
<!-- End Page Content -->
