import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../Shared/Services/home.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-feat-post',
  templateUrl: './feat-post.component.html',
  styleUrls: ['./feat-post.component.scss'],
})
export class FeatPostComponent implements OnInit {
  TopRatedData;

  lang;

  constructor(
    private homeservice: HomeService,
    private languageService: LanguageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.getTopRatedPlaces();
  }

  getTopRatedPlaces() {
    this.homeservice.GetTopRated().subscribe((res: any) => {
      this.TopRatedData = res.content;
    });
  }

  navigateplace(item) {
    this.router.navigate(['/place-details', this.lang], {
      queryParams: {
        id: item.id,
      },
    });
  }
}
