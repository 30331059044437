<div class="container-fluid pg-header" style="background-image: url(./assets/images/16.jpg);">
    <div class="pg-header-content">
        <h1 class="pg-title">
            {{'Home.services'|translate}}
        </h1>
        <h4 class="pg-subtitle">
            {{'Request your service from here and contact the company directly'|translate}}
        </h4>

    </div>
    <!-- <div class="pg-header-icon icon-bubbles"></div> -->
</div>

<div class="container mt-3" style="margin-top: 30px;">

    <div class="search">
        <div class="row">
            <div class="col-md-12">
                <input type="text" class="form-control" [(ngModel)]="query"
                (ngModelChange)="search($event)"
                on-focusout="menuHide($event)" on-focus="menuShow()" placeholder="{{'searchService' | translate}}" />

                <ul *ngIf="(searchedService.length > 0 || searchedService.length != null) && showMenu"
                    id="search_id2" class="list-group">
                    <a *ngFor="let product of searchedService"
                        class="list-group-item text-left"
                        (mousedown)="gotoAddService(product?.id , product?.name)">
                        <img width="40px" height="40px"
                            [src]="product?.image" [alt]="product?.name">
                        <span class="search-text">
                            {{ product.name }}
                        </span>
                    </a>
                </ul>
            </div>

            
        </div>
    </div>

    <div class="row">

        <div class="col-md-3 col-sm-3 col-xs-12" *ngFor="let service of services | paginate : config">
            <a class="card" (click)="openService(content , service , service.id  )">
                <div class="card-img img-responsive">
                    <img [alt]="service.name" [src]="service.image">
                </div>
                <h5 class="card-title">
                    {{service.name}}
                </h5>
            </a>
        </div>

        <div class="col-sm-12 text-center">
            <pagination-controls
                id='placesID'
                (pageChange)="pageChanged($event)"
                (pageBoundsCorrection)="pageChanged($event)"
                class="list-pagination"
                directionLinks="true"
                autoHide="true"
                responsive="true"
                previousLabel="{{'previous'|translate}}"
                nextLabel="{{'next'|translate}}"
                screenReaderPaginationLabel="Pagination"
                screenReaderPageLabel="page"
                screenReaderCurrentLabel="You're on page">
             </pagination-controls>
        </div> 

    </div>

</div>

<div class="col-lg-12">

    <ng-template #content let-modal  >

        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"> {{'From Qpedia'|translate}} {{service_array.name}} </h4>
            <button type="button" style="opacity: 1;" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true" class="cancel-btn">&times;</span>
            </button>
        </div>

        <div class="modal-body">

            <form [formGroup]="itemForm" #myform="ngForm" (ngSubmit)="create(itemForm.value)" class="form-horizontal contact-form">
                <!-- Text input-->
    
                <div class="form-group">
                    <div class="col-sm-6 inputGroupContainer">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="fa fa-user"></i></span>
                            <input formControlName="name" [(ngModel)]="query" (ngModelChange)="search2(query)" name="name"
                                id="name" placeholder="{{'name'|translate}}" class="form-control">
    
                            <span *ngIf="itemForm.controls['name']?.errors?.required && myform.submitted"
                                class="error-msg">
                                {{'name'|translate}} {{'required'|translate}}
                            </span>
    
                        </div>
                    </div>
                </div>
    
                <div *ngIf="result.length > 0 && show == true">
                    <ul>
                        <li *ngFor="let res of result" (click)="setName(res.name)">
                            {{res.name}}
                        </li>
                    </ul>
                </div>
    
                <!-- Text input-->
                <div class="form-group">
                    <div class="col-sm-6 inputGroupContainer">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="fa fa-bars"></i></span>
                            <input formControlName="title" name="title" id="title"
                                placeholder="{{'Requests.TitleRequests'|translate}}" class="form-control">
    
                            <span *ngIf="itemForm.controls['title']?.errors?.required && myform.submitted"
                                class="error-msg">
                                {{'Requests.TitleRequests'|translate}} {{'required'|translate}}
                            </span>
                        </div>
                    </div>
                </div>
    
                <div class="form-group">
                    <div class="col-sm-12 inputGroupContainer">
                      <div class="input-group">
                        <span class="input-group-addon"><i class="glyphicon glyphicon-th-list"></i></span>
                        
                        <ng-multiselect-dropdown
                              [settings]="dropdownSettings"
                              [data]="citieslist"
                              formControlName="cities"
                              [(ngModel)]="selectedItems"
                              (onSelect)="onItemSelect($event)"
                              (onSelectAll)="onSelectAll($event)"
                            >
                        </ng-multiselect-dropdown>
            
            
            
                      </div>
                      <span *ngIf="itemForm.controls['cities']?.errors?.required && myform.submitted"
                        class="error-msg">
                        {{ 'categoryReqMes' | translate}}
                      </span>
                      
                    </div>
                  </div>
                <!-- Text input-->
                <div class="form-group">
                    <div class="col-sm-6 inputGroupContainer">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="fa fa-briefcase"></i></span>
                            <textarea formControlName="description" name="description" id="description"
                                placeholder="{{'description'|translate}}" class="form-control"></textarea>
    
                            <span *ngIf="itemForm.controls['description']?.errors?.required && myform.submitted"
                                class="error-msg">
                                {{'description'|translate}} {{'required'|translate}}
                            </span>
                        </div>
                    </div>
                </div>
    
                <!-- <div class="form-group">
                    <div class="col-sm-6 inputGroupContainer">
                        <div class="input-group">
                            <input type="checkbox" formControlName="all_cities_included">
                            <label>{{'All Citites'}}</label>
                        </div>
                    </div>
                </div> -->
    
    
                <!-- Submit Button -->
                <div class="form-group sub">
                    <div class="col-sm-12">
                        <button type="submit" name="name"
                            class="btn btn-warning submit"><i *ngIf="isEnabled" class="fa fa-spinner fa-spin"></i>
                            {{'Submit'|translate}}</button>
                    </div>
                </div>
            </form>

        </div>

    </ng-template>
 
    
</div>