<div class="container pg-content">
    <div class="pg-header-content">
      <h1 class="pg-title">
        {{'allnotification'|translate}}
      </h1>
    </div>

    <div class="col-sm-12 text-center">
        <div class="panel panel-form">
            <!-- <div class="panel-heading">
                <button class="search-btn" *ngIf="userList?.length>0" (click)="open(content)">{{'Advanced Filter'|translate}}</button>
                <button class="search-btn" routerLink="/create-job">{{'add job'|translate}}</button>
                <button class="search-btn" routerLink="/company-jobs">{{'company jobs'|translate}}</button>
            </div> -->
            <div class="panel-body">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade active in " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" >
                        <div class="table-responsive" style="width:100%">

                        
                        <table class="table" cellspacing="0" *ngIf="notifications?.length>0">
                            <thead>
                                <tr>
                                    <th class="text-center">{{'Name'|translate}}</th>
                                    <th class="wide text-center">{{'NotificationText'|translate}}</th>
                                    <th class="text-center">{{'NotificationDate'|translate}}</th>
                                </tr>
                            </thead> 
                            <tbody>
                                <tr *ngFor="let notification of notifications | paginate: { itemsPerPage: pageSize, currentPage: p, totalItems: totalapi }" style="cursor: pointer;" (click)="Navigate(notification)">
                                    <td>
                                        {{notification?.entity_name}}
                                    </td>
                                    <td>
                                        {{notification?.notification_text}}
                                    </td>
                                    <td>
                                       {{notification?.created_at}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                        <h2 *ngIf="notifications?.length==0">{{'no result'|translate}}</h2>
                        <div class="col-md-12 col-sm-12 text-center" *ngIf="notifications?.length>0">
                            <pagination-controls 
                                       (pageChange)="onPageChange($event)"
                                       previousLabel="{{'previous'|translate}}"
                                       nextLabel="{{'next'|translate}}">
                            </pagination-controls>
                    
                          </div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>
</div>