import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HomeService } from 'src/app/Shared/Services/home.service';
import { UsersComponent } from '../users/users.component';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss'],
})
export class TaskViewComponent implements OnInit {
  taskForm: FormGroup;
  taskId: any;
  task: any;
  users = '';
  priority: any[] = [];
  priorityName: any;
  typeID;
  indoor: boolean = true;
  lang: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private translateservice: TranslateService,
    private languageService: LanguageService,
    private homeService: HomeService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.route.queryParams.subscribe((res: any) => {
      console.log('hhh', res);
      this.taskId = +res.task_id;
      console.log('hhh', this.taskId);
      if (this.taskId) {
        this.getTask(this.taskId);
      }
    });
    this.taskForm = new FormGroup({
      name: new FormControl(''),
      date: new FormControl(''),
      time: new FormControl(''),
      description: new FormControl(''),
      priority_level_id: new FormControl(''),
      notes: new FormControl(''),
      request_type_id: new FormControl(''),
      district_id: new FormControl(''),
      item_id: new FormControl(''),
      related_request_id: new FormControl(''),
      related_contact_name: new FormControl(''),
      otherAction: new FormControl(''),
      indoor: new FormControl(''),
      users: new FormControl(''),
    });
  }

  openMaps(location) {
    window.open('https://www.google.com/maps/place/' + location, '_blank');
  }

  getTask(id: number) {
    this.homeService.showTask(id).subscribe((res: any) => {
      this.task = res.content[0];
      console.log('The Task is:- ', this.task);
      this.typeID = this.task.request_type_id;

      let body = { cms_id: this.task.item_id };
      this.homeService.getPriority(body).subscribe((res) => {
        console.log('The priority', res['content']);
        this.priority = res['content'];
        this.priority.forEach((element) => {
          if (element.id == this.task.priority_level_id) {
            this.priorityName = element.name;
            console.log('priority :=', this.priorityName);
          }
        });
      });
      this.taskForm.patchValue({
        name: this.task.name,
        date: this.task.date,
        time: this.task.time,

        notes: this.task.notes,
        request_type_id: this.task.request_type_value,
        district_id: this.task.district_name,
        item_id: this.task.item_name,
        description: this.task.description,

        priority_level_id: this.task.priority_level_id,
        related_request_id: this.task.related_request_id,
        related_contact_name: this.task.related_contact_name,
        otherAction: this.task.otherAction,
        indoor: this.task.indoor,
        users: this.task.users,
      });

      this.task.users.forEach((element) => {
        this.users += element.full_name + '   ';
      });

      console.log(this.users);
      if (this.task.indoor == 2) {
        this.indoor = false;
      }
    });
  }
}
