<section>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-6 col-xs-6 pull-left">
        <h2 class="services-title">
          {{ "Recently Added Places" | translate }}
        </h2>
        <h3 class="services-desc">
          {{ "Find out the closest to you" | translate }}
        </h3>
        <!-- <p class="services-desc2">{{'Are you searching for vacancies & career opportunities ? Qpedia will help you'|translate}}</p>  -->
      </div>
      <div class="col-md-6 col-sm-6 col-xs-6 pull-right">
        <!-- <a class="red-more-btn">{{'Read More'|translate}}</a> -->
      </div>
    </div>
    <div class="row">
      <div
        class="col-lg-2 col-md-2 col-sm-12 col-xs-12 pull-left"
        style="margin: 0px"
        *ngFor="let item of TrendingData | slice : 0 : 5"
      >
        <div class="card" style="cursor: pointer" (click)="navigateplace(item)">
          <img class="places-img" src="{{ item.image }}" />
          <div class="card-desc">
            <h3>{{ item.title }}</h3>
          </div>
        </div>
      </div>

      <div
        class="col-md-12 col-sm-12 col-xs-12"
        style="text-align: center"
        *ngIf="!TrendingData?.length"
      >
        <img class="no-data-img" src="assets/images/empty-chat2.png" />
        <h2 style="padding-top: 15px">
          {{ "There is no places" | translate }}
        </h2>
      </div>
    </div>
  </div>
</section>
