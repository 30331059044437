import { Injectable } from '@angular/core';
import { HttpClientService } from '../Services/http-client.service';


@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(private httpClientService: HttpClientService) { }

  GetFAQ(){
    let body={}
    return this.httpClientService.post('api/question_types', body,  {});
  }

  GetAbout(){
    let body={
      "page_name":"about_us"

    }
    return this.httpClientService.post('api/pages', body,  {});
  }

  GetVision(){
    let body={
      "page_name":"our_vision"

    }
    return this.httpClientService.post('api/pages', body,  {});
  }

  GetMission(){
    let body={
      "page_name":"our_mission"

    }
    return this.httpClientService.post('api/pages', body,  {});
  }

  GetTerms(){
    let body={
      "page_name":"terms"

    }
    return this.httpClientService.post('api/pages', body,  {});
  }

  GetPrivacyPolicy(){
    let body={
      "page_name":"privacy_policy"

    }
    return this.httpClientService.post('api/pages', body,  {});
  }

  GetPricing(){
    let body={}
    return this.httpClientService.post('api/buckets', body,  {});
  }


}
