<!-- Start Page Content -->
<div class="container pg-content">
  <div class="pg-header-content">
    <h1 class="pg-title" *ngIf="!id">
      {{'Add New Task'|translate}}
    </h1>
    <h1 class="pg-title" *ngIf="id">
      {{'Edit Task'|translate}}
    </h1>
  </div>

  <!--Start Contact Form -->
  <div class="col-sm-12 text-center">

    <form [formGroup]="taskForm" #myform="ngForm" class="form-horizontal contact-form" (ngSubmit)="submit()">
      <!-- Text input-->

      <div class="form-group">
        <div class="col-sm-6 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-file" aria-hidden="true"></i>
            </span>
            <input formControlName="name" name="name" id="title" placeholder="{{'Requests.TitleRequests' | translate}}"
              class="form-control" required>

            <span *ngIf="taskForm.controls['name']?.errors?.required && myform.submitted"
              class="error-msg">
              {{'titleReqMes'|translate}}
            </span>
            <!-- <span *ngIf="taskForm.controls['name'].touched && taskForm.controls['name']?.errors?.pattern" class="error-msg">
                  {{'nameMustBeCharc'|translate}}.
              </span> -->
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-6 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-file-text" aria-hidden="true"></i></span>
            <textarea formControlName="description" placeholder="{{'description'|translate}}" class="form-control"></textarea>
          
            <span *ngIf="taskForm.controls['description']?.errors?.required && myform.submitted"
              class="error-msg">
              {{'description' | translate}} {{'required'|translate}}
            </span>
          
          </div>
        </div>
      </div>


      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </span>
            <select formControlName="request_type_id" name="request" id="request" (change)="onTypeSelected($event.target.value[0])" class="form-control">
              <!-- <option  value="" disabled selected> {{'contactname'|translate}} </option> -->
              <option   value=""  disabled>{{'Requests.TypeRequests'|translate}}</option>
            
              <option [ngValue]="type.id" *ngFor="let type of types">{{type.name}}</option>
            </select>
            <span *ngIf="taskForm.controls['request_type_id']?.errors?.required && myform.submitted"
              class="error-msg">
              {{'Requests.TypeRequests' | translate}} {{'required'|translate}}
            </span>
          </div>
        </div>
      </div>
    
      <div class="form-group" *ngIf="typeID==2">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </span>
            <select  formControlName="district_id" name="district" id="district" class="form-control">
              <!-- <option  value="" disabled selected> {{'contactname'|translate}} </option>  -->
              <option disabled value="">{{'Home.selectPlace' | translate}}</option>
              <option   [ngValue]="district.id" *ngFor="let district of districts">{{district.name}}</option>
            </select>
            <span *ngIf="taskForm.controls['district_id']?.errors?.required && myform.submitted"
              class="error-msg">
              {{'required'|translate}}
            </span>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="typeID==1">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fa fa-users" aria-hidden="true"></i>  
            </span>
            <select formControlName="related_contact_id" name="related" id="related" class="form-control">
              <!-- <option  value="" disabled selected> {{'relatedContact'|translate}} </option> -->
              <option disabled value="">{{'Related Contact' | translate}}</option>
              <option [ngValue]="contact.id" *ngFor="let contact of relatedContacts">{{contact.name}}</option>
            </select>
            <span *ngIf="taskForm.controls['related_contact_id']?.errors?.required && myform.submitted"
            class="error-msg">
            {{'required'|translate}}
          </span>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="typeID==3">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fa fa-plus-square" aria-hidden="true"></i>
            </span>
            <input type="text" formControlName="otherAction" placeholder="{{'otherAction'|translate}}" class="form-control" />
            <span *ngIf="taskForm.controls['otherAction']?.errors?.required && myform.submitted"
            class="error-msg">
            {{'required'|translate}}
          </span>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fa fa-address-book" aria-hidden="true"></i>
            </span>
            <select 
              formControlName="item_id" 
              name="item" 
              id="item" 
              class="form-control" 
              (change)="onItemChange($event.target.value[0])">
              <option  value="" disabled> {{'contactname'|translate}} </option> 
              <option [ngValue]="item.id" #val *ngFor="let item of items">{{item.title}}</option>
            </select>

            <!-- <mat-form-field appearance="outline">
              <mat-label>{{'contactname'|translate}}</mat-label>
              <mat-select 
                (infiniteScroll)="getNextBatch()" 
                [complete]="offset === items.length" 
                msInfiniteScroll 
                formControlName="item_id"
                (selectionChange)="onItemChange($event.value)">
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="{{ 'searchContact' | translate}}" (keyup)="onKey(value)" [(ngModel)]="value" [ngModelOptions]="{standalone: true}">
                    <mat-icon ngxMatSelectSearchClear>X</mat-icon>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let item of items" [value]="item.id">{{item.title}}</mat-option>
              </mat-select>
          </mat-form-field> -->

          <span *ngIf="taskForm.controls['item_id']?.errors?.required && myform.submitted"
              class="error-msg">
              {{'contactname' | translate}} {{'required'|translate}}
            </span>
          
          </div>
        </div>
      </div>

      <!-- <p-dropdown 
        [options]="items"
        optionLabel="title"
        optionValue="id"
        placeholder="Select Item" 
        [virtualScroll]="true" 
        [itemSize]="5" 
        [filter]="false"
      ></p-dropdown> -->


      <!-- Text input-->
      <div class="form-group">
        <div class="col-sm-6 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-calendar" aria-hidden="true"></i></span>
            <input formControlName="date" type="date" [ngClass]="currentlang == 'ar'?'text-right':''" placeholder="{{'Date'|translate}}" class="form-control">

            <span *ngIf="taskForm.controls['date']?.errors?.required && myform.submitted"
              class="error-msg">
              {{'dateReqMes'|translate}}
            </span>
            <!-- <span *ngIf="taskForm.controls['date'].touched && taskForm.controls['date']?.errors?.pattern" class="error-msg">
                      {{'phonePatMes'|translate}}.
                  </span> -->
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group">
        <div class="col-sm-6 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-clock-o" aria-hidden="true"></i></span>
            <input formControlName="time" type="time" [ngClass]="currentlang == 'ar'?'text-right':''" placeholder="{{'Time'|translate}}" class="form-control" required>
            <span *ngIf="taskForm.controls['time']?.errors?.required && myform.submitted"
            class="error-msg">
            {{'timeReqMes'|translate}}
          </span>
          </div>
        </div>
      </div>


      <!-- Text input-->
      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fa fa-user" aria-hidden="true"></i>
            </span>
            <!-- <select formControlName="users" name="users" id="users" class="form-control">
              <option [ngValue]="user.id" *ngFor="let user of users">{{user.full_name}}</option>
            </select> -->
            <div>
              <!-- <p-multiSelect 
                [options]="users"
                id="users"
                defaultLabel="{{'Select Employee' | translate}}"
                formControlName="users"
                optionLabel="full_name"
                [style]="{'width':'100%','text-align': 'start','font-size':'16px'}"
                [panelStyle]="{'minWidth':'100%','font-size':'16px'}"
                [filter]="false"
             ></p-multiSelect> -->

             <ng-multiselect-dropdown
              placeholder="{{'Select Employee' | translate}}"
              [settings]="dropdownSettings"
              [data]="users"
              formControlName="users"
              [(ngModel)]="selectedItems"
              (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)"
            >
            </ng-multiselect-dropdown>
         </div> 
           
            <span *ngIf="taskForm.controls['users']?.errors?.required && myform.submitted"
              class="error-msg">
              {{'Select Employee' | translate}} {{'required'|translate}}
            </span>
            
          </div>
        </div>
      </div>
      <!-- Text input-->
      <div class="form-group" *ngIf="id">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fa fa-bars" aria-hidden="true"></i>
            </span>
            <select formControlName="request_status_id" name="related" id="related" class="form-control">
              <option  value="" disabled selected> {{'Requests.StatusRequests'|translate}} </option>
              <option [ngValue]="status.id" *ngFor="let status of statuses">{{status.name}}</option>
            </select>
          </div>

          <span *ngIf="taskForm.controls['request_status_id']?.errors?.required && myform.submitted"
              class="error-msg">
              {{'required'|translate}}
            </span>
        </div>
      </div>



            <div class="form-group">
              <div class="col-sm-12 inputGroupContainer">
                <div class="input-group">
                  <span class="input-group-addon">
                    <i class="fa fa-bar-chart" aria-hidden="true"></i>
                  </span>
                  <select formControlName="priority_level_id" name="priority_level_id" id="priority_level_id" class="form-control" >
                    <!-- <option  value="" disabled selected> {{'contactname'|translate}} </option> -->
                    <option disabled value="">{{ 'Priority Level' | translate}}</option>
                    <!-- <option  value="1">{{ 'important' | translate}}</option> -->
                    <option [ngValue]="item.id" #val *ngFor="let item of priority">{{item.name}}</option>
                  </select>
                </div>
                <span *ngIf="taskForm.controls['priority_level_id']?.errors?.required && myform.submitted"
              class="error-msg">
              {{'Priority Level' | translate}} {{'required'|translate}}
            </span>
              </div>
            </div>

      <div class="form-group">
        <div class="col-sm-12 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fa fa-tasks" aria-hidden="true"></i>
            </span>
            <select formControlName="related_request_id" name="related_request_id" id="related_request_id"
              class="form-control">
              <option disabled value="">{{'Related Tasks' | translate}}</option>
              <option [ngValue]="item.id" #val *ngFor="let item of related_task">{{item.name}}</option>
      
            </select>
          </div>
        </div>
      </div>
           

      <div class="form-group">
        <div class="col-sm-6 inputGroupContainer">
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-comment" aria-hidden="true"></i></span>
            <textarea formControlName="notes" placeholder="{{'notes'|translate}}" class="form-control"></textarea>
            <span *ngIf="taskForm.controls['notes']?.errors?.required && myform.submitted"
              class="error-msg">
              {{'notesReqMes'|translate}}
            </span>
          </div>
        </div>
      </div>
      


      <div class="form-group" >
        <div class="col-md-12">
            <div class="input-group active">
                <input [checked]="1" type="checkbox" value="1" formControlName="indoor" tabindex="3" class="" name="indoor" id="indoor">
                <label for="indoor"  class="active_label">{{'indoor'|translate}}</label>     
           </div>
          </div>
    </div>
      <!-- Submit Button -->
      <div class="form-group sub">
        <div class="col-sm-12">
          <button type="submit" name="name"
            class="btn btn-warning submit">
            <i *ngIf="isEnabled" class="fa fa-spinner fa-spin"></i> {{'Submit'|translate}}</button>
        </div>
      </div>
    </form>
  </div>
  <!--End Contact Form-->
</div>
<!-- End Page Content -->