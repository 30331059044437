
<!-- Start Page Header -->
<div class="container-fluid pg-header" style="background-image: url(./assets/images/polygons.jpg);">
    <div class="pg-header-content">
      <h1 class="pg-title">
        {{'Home.pricing' | translate}}
      </h1>
      <h4 class="pg-subtitle">
        {{'pricing_content'| translate}}
      </h4>
    </div>
    <!-- <div class="pg-header-icon">
      <i class="fas fa-coins"></i>
    </div> -->
  </div>
  <!-- End Page Header -->
  <!-- Start Page Intro -->
  <div class="container page-intro">
    {{'pricing_content'| translate}}

  </div>
  <!-- End Page Intro -->
  <!-- Start Page Content -->
  <div class="container pg-content">
    <!-- Start pricing table -->
    <div class="row pricing">


      <div class="col-sm-3 price" *ngFor="let item of PricingData">
        <div class="content">
          <div class="title">
            {{item.name}}
          </div>
          <div class="subtitle">
          </div>
          <div class="circle">
            <div class="the-price">
              ${{item.price}}
            </div>
          </div>
          <div class="included">
            <div class="item">
              <!-- 01 Listing -->
            </div>
            <div class="item">
              <!-- No Featured -->
            </div>
            <div class="item">
              <!-- No Support -->
            </div>
            <div class="item strong">
              {{'Expiration'|translate}} {{item.duration}} {{'Days'|translate}}
            </div>
            <div class="sign-in-button">
              <!-- <a href="#" class="sign-in">Sign In</a> -->
              <a>{{'Purchase'|translate}}</a>

            </div>
          </div>
        </div>
      </div>


      <!-- <div class="col-sm-3 price feat">
        <div class="content">
          <div class="title">
            Standard
          </div>
          <div class="subtitle">
            Starting PRO
          </div>
          <div class="circle">
            <div class="the-price">
              $20
            </div>
            <div class="hot-label">
              Popular
            </div>
          </div>
          <div class="included">
            <div class="item">
              03 Listings
            </div>
            <div class="item">
              02 Featured
            </div>
            <div class="item">
              Awesome Support
            </div>
            <div class="item strong">
              Expiration: 90 Days
            </div>
            <div class="sign-in-button">
              <a href="#">Purchase</a>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-sm-3 price">
        <div class="content">
          <div class="title">
            Premium
          </div>
          <div class="subtitle">
            For Real Business
          </div>
          <div class="circle">
            <div class="the-price">
              $40
            </div>
          </div>
          <div class="included">
            <div class="item">
              10 Listing
            </div>
            <div class="item">
              05 Featured
            </div>
            <div class="item">
              Awesome Support
            </div>
            <div class="item strong">
              Expiration: 180 Days
            </div>
            <div class="sign-in-button">
              <a href="#">Purchase</a>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-sm-3 price">
        <div class="content">
          <div class="title">
            Advanced
          </div>
          <div class="subtitle">
            For Top Sellers
          </div>
          <div class="circle">
            <div class="the-price">
              $70
            </div>
          </div>
          <div class="included">
            <div class="item">
              25 Listing
            </div>
            <div class="item">
              15 Featured
            </div>
            <div class="item">
              Awesome Support
            </div>
            <div class="item strong">
              Expiration: 365 Days
            </div>
            <div class="sign-in-button">
              <a href="#">Purchase</a>
            </div>
          </div>
        </div>
      </div> -->

    </div>
    <!-- End pricing table items -->
  </div>
  <!-- End Page Content -->
