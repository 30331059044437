import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe, formatDate } from '@angular/common';

import Swal from 'sweetalert2';
import { CitiesService } from 'src/app/Shared/Services/cities.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
declare var $: any;

@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.scss']
})
export class CreateEmployeeComponent implements OnInit {


  userForm: FormGroup;
  type;
  image_url: any;
  profile_img = '';
  date14: Date;
  fileName: string = '';
  currentlang: string;
  errors: any[] = [];
  errorList;
  codelist;
  isEnabled: boolean = false;
  isValid: boolean = false;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Egypt, CountryISO.SaudiArabia];

  constructor(private router: Router,
    private toastr: ToastrService,
    private cityservices: CitiesService,
    private translateservice: TranslateService, private service: HttpClientService) { }

  ngOnInit(): void {
    this.getCountryTelephoneCode();

    this.currentlang = localStorage.getItem('lang');

    this.userForm = new FormGroup({
      full_name: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
      tele_code: new FormControl(''),
      mobile: new FormControl('', [Validators.required]),
      photo: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),


      job_title: new FormControl(''),
      is_active: new FormControl(''),

      birthdate: new FormControl('', [Validators.required]),
      rule_id: new FormControl('4'),

      address: new FormControl(''),
      gender_id: new FormControl('', [Validators.required])



    })

    this.currentlang = localStorage.getItem('lang');

  }
  getCountryTelephoneCode() {
    this.cityservices.GetCountryCodeList().subscribe((res: any) => {
      this.codelist = res.content;
      console.log(this.codelist)
    })
  }
  readImage(event) {
    var files = event.target.files[0];
    this.type = event.target.files[0].type;
    console.log(this.type);
    this.fileName = files.name;
    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }


  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.image_url = `data:${this.type};base64,` + btoa(binaryString);
    // console.log(this.image_url )


  }

  closebtn(index) {
    this.image_url = '';
    $("#image_url").val('')

  }

  openImg() {
    var win = window.open();
    win.document.write('<iframe src="' + this.image_url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  }
  create(formdata) {
    console.log('Form Data', formdata)
    this.isEnabled = true;
    this.isValid = this.validateMobile(formdata.value.mobile);
    if (formdata.valid) {
      if (formdata.value.photo.length > 0)
        formdata.value.photo = this.image_url;
      else {
        formdata.value.photo = '';

      }

      if (formdata.value.is_active.length == 0) {
        formdata.value.is_active = '0'
      }
      else if (formdata.value.is_active == true) {
        formdata.value.is_active = '1'
      }
      formdata.value.tele_code = formdata.value.mobile.dialCode
      formdata.value.mobile = formdata.value.mobile.number

      console.log('Data ', formdata.value)
      this.service.post('api/employees/store', formdata.value, {}).subscribe((res: any) => {
        this.isEnabled = false;
        console.log("create =>", res);
        if (res.status.code == 200) {
          if ((this.currentlang == 'en')) {
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: 'Employee created successfully',
            })

          }
          else if ((this.currentlang == 'ar')) {
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: 'تم اضافه موظف',
            })


          }
          this.userForm.reset();
          this.router.navigate(['employee', this.currentlang]);
        }
        else {
          this.isEnabled = false;
          // this.toastr.error(res.status.message);
          let validation_errors = res.status.validation_errors;
          validation_errors.forEach(element => {
            if (element.field == 'email') { this.toastr.error(element.message) }
            else if (element.field == 'password') { this.toastr.error(element.message) }
            else if (element.field == "mobile") { this.toastr.error(element.message) }
            else if (element.field == 'full_name') { this.toastr.error(element.message) }
            else if (element.field == "username") { this.toastr.error(element.message) }
            else if (element.field == 'job_title') { this.toastr.error(element.message) }
            else if (element.field == "is_active") { this.toastr.error(element.message) }
            else if (element.field == 'birthdate') { this.toastr.error(element.message) }
            else if (element.field == "address") { this.toastr.error(element.message) }
            else if (element.field == 'gender_id') { this.toastr.error(element.message) }
            else if (element.field == "rule_id") { this.toastr.error(element.message) }


          })

          this.errors = res.status.validation_errors;
          this.errorList = {};
          this.errors.forEach(element => {
            this.errorList[element.field] = element.message;
            this.toastr.error(element.message);
          });
          console.log("error msg :- ", this.errors);
          console.log("error msg :- ", this.errorList);


        }

      },
        error => {
          this.isEnabled = false;
          let validation_errors = error.error.status.validation_errors;
          validation_errors.forEach(element => {
            if (element.field == 'email') { this.toastr.error(element.message) }
            else if (element.field == 'password') { this.toastr.error(element.message) }
            else if (element.field == "mobile") { this.toastr.error(element.message) }
            else if (element.field == 'full_name') { this.toastr.error(element.message) }
            else if (element.field == "username") { this.toastr.error(element.message) }
            else if (element.field == 'job_title') { this.toastr.error(element.message) }
            else if (element.field == "is_active") { this.toastr.error(element.message) }
            else if (element.field == 'birthdate') { this.toastr.error(element.message) }
            else if (element.field == "address") { this.toastr.error(element.message) }
            else if (element.field == 'gender_id') { this.toastr.error(element.message) }
            else if (element.field == "rule_id") { this.toastr.error(element.message) }


          })

        })
    } else {
      this.isEnabled = false;
    }



  }

  getEvent(event) {
    console.log('Event is:- ', event.checked);
    let isChecked: number;
    if (event.checked == true) {
      isChecked = 1;
      this.userForm.get('is_active').setValue('1');
    } else {
      isChecked = 0;
    }

  }

  validateMobile(phone) {
    let EGYPT_REGEX = /^01[0-2,5]\d{1,8}$/; // Regular Expression 1
    let KSA_REGEX = /^[0-9]\d{1,7}$/;         // Regular Expression 2

    return ((EGYPT_REGEX.test(phone?.nationalNumber) && phone.dialCode == "+20") || (KSA_REGEX.test(phone?.nationalNumber) && phone.dialCode == "+966")) ? true : false;
  }






}
