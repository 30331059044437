<!-- Start Hero Header -->
<div class="container-fluid hero-header">
    <!-- <div class="dot-overlay"></div><div class="overlay" style="display: block;"></div><div class="hero-image"></div> -->
    <!-- Start Header Centralizer -->
    <div class="header-centralizer">
      <div class="content-centralized">
        <!-- Start Hero Search -->
        <div class="hero-search">
          <form>
            <fieldset>
              <!-- <input type="text"  class="form-control" data-placeholder="Explore and Enjoy..."> -->
              <input  [(ngModel)]="searchQuery" (ngModelChange)="search($event)"
							class="form-control" placeholder="{{'What are you looking for?'|translate}}" type="text"
              aria-label="Search" id="menu1" [ngModelOptions]="{standalone: true}">

              <!-- <input *ngIf="currentlang === 'en'" [(ngModel)]="searchQuery" (ngModelChange)="search($event)"
							class="form-control" placeholder="عما تبحث؟" type="text"
              aria-label="Search" id="menu1" [ngModelOptions]="{standalone: true}"> -->


            </fieldset>

            <!-- End Search Cities -->
            <div class="search-submit">
              <!-- <input type="submit" value=" "> -->
              <i class="hero-search-icon"></i>
            </div>

          </form>
          <ul  *ngIf="searchRes?.length>0" id="search_id2" class="list-group ">
            <li *ngIf="showSpin" style="background-color: white;">
              <i class="fa fa-spinner fa-spin" style="color: #2196F3; font-size: 24px;"></i>
            </li>
            <div *ngIf="!showSpin">
              <li *ngFor="let itemsearch of searchRes">
                <a  (click)="navigateplace(itemsearch)" class="list-group-item" (click)="MenuHide()">
                  <!-- <img width="40px" height="40px" [alt]="itemsearch.title" [src]="itemsearch.image"> -->
                  {{itemsearch.title}}
                </a>
              </li>
            </div>

          </ul>
          <ul  *ngIf="showSpin" class="list-group1 ">
            <li style="background-color: white;">
              <i class="fa fa-spinner fa-spin" style="color: #2196F3; font-size: 24px; line-height: 95px;"></i>
            </li>
          </ul>

          <ul *ngIf="searchRes?.length == 0 && isVisible" id="search_id2" [ngClass]="isVisible?'hide_div':''" class="list-group1">
            <li>
              {{'no result' | translate}}
            </li>
          </ul>



        </div>
        <!-- End Hero Search -->
        <!-- Start Search Categories -->
        <div class="search-categories">
          <div class="categories"  *ngFor="let item of CategoryData | slice:0:14">
            <!-- <a class="icon-platter child" routerLink="Categories" href="#">{{'Home.restaurant' | translate}}</a>
            <a class="icon-teacup child" routerLink="Categories" href="#">{{'Home.cafe' | translate}}</a>
            <a class="icon-moon" routerLink="Categories" href="#">{{'Home.nightlife' | translate}}</a>
            <a class="icon-landscape" routerLink="Categories" href="#">{{'Home.outdoor' | translate}}</a>
            <a class="icon-hanger child" routerLink="Categories" href="#">{{'Home.clothing' | translate}}</a>
            <a class="icon-bench-press child" routerLink="Categories" href="#">{{'Home.gym' | translate}}</a>
            <a class="icon-saw child" routerLink="Categories" href="#">{{'Home.service' | translate}}</a>
            <a class="icon-laundry child" routerLink="Categories" href="#">{{'Home.laundry' | translate}}</a>
            <a class="icon-bag-dollar child" routerLink="Categories" href="#">{{'Home.bank' | translate}}</a>
            <a class="icon-palette child" routerLink="Categories" href="#">{{'Home.gallery' | translate}}</a>
            <a class="icon-library child" routerLink="Categories" href="#">{{'Home.bookstore' | translate}}</a>
            <a class="icon-city" routerLink="Categories" href="#">{{'Home.more' | translate}}</a> -->
            <div class="custom">
              <img [alt]="item.title" [src]="item.image" class="cat_img">
              <a (click)="navigateplaces(item)">
                <p>{{item.title}}</p>
              </a>
            </div>
          </div>
        </div>
        <!-- End Search Categories -->
        <!-- Start Feat Posts -->
       <div class="fixed-section">
        <button class="show-section" (click)="clickEvent()" >
          <i class="fa fa-star" aria-hidden="true"></i>
        </button>
        <div class="feat-posts" [ngClass]="status ? 'success' : 'danger'">
          <div class="helper">

            <div class="feat-posts-list">
              <div class="feat-posts-title">
                {{'Home.toprated' | translate}}
              </div>
              <app-feat-post></app-feat-post>



              <a  [routerLink]="['categories', currentlang]" href="#" class="more-map-listing icon-plus" title="More Listings" data-toggle="tooltip" data-placement="top"></a>
            </div>
          </div>
        </div>
      </div>
        <!-- End Feat Posts -->
      </div>
    </div>
  </div>
  <!-- Ends Hero Header -->
