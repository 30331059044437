<div class="feat-post" *ngFor="let item of TopRatedData">
    <a  (click)="navigateplace(item)">

      <div class="feat-post-icon icon-dinner"></div>
      <div class="ribbon">{{'Home.stratingfrom' | translate}} {{item?.price}}</div>
      <div class="listing-item-rating">{{item?.avg_rate}}</div>
      <div class="feat-post-pic">
        <!-- <div style="background-image:url('assets/images/feat-posts/3.png')"></div> -->
        <div [style.backgroundImage]="'url(' + item.image + ')'"></div>
      </div>
      <div class="feat-post-data">
        <div class="feat-post-title">
            {{item?.title}}
        </div>
        <div class="feat-post-category padd-r">
          {{item?.categories[0]?.value}} 

            <!-- {{'Home.category_name' | translate}} -->
        </div>
        <div class="feat-post-city border-l">
            <!-- | {{'Home.city_name' | translate}} -->
            {{ item?.city}}
        </div>
      </div>
    </a>
  </div>