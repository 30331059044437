import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AddingMetaService } from 'src/app/Shared/Services/adding-meta.service';
import { HomeService } from 'src/app/Shared/Services/home.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-all-products',
  templateUrl: './all-products.component.html',
  styleUrls: ['./all-products.component.scss'],
})
export class AllProductsComponent implements OnInit {
  lang: string;
  p: number = 1;
  products: any[] = [];
  searchedProduct: any[] = [];
  searchSubscription: any;
  showMenu: boolean = false;
  query: string;
  price: string;

  constructor(
    private homeService: HomeService,
    private languageService: LanguageService,
    private meta: AddingMetaService,
    private service: HttpClientService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.getAllProducts(1);
    this.meta.updateMeta({
      compTitle: 'Products',
    });
  }

  getAllProducts(page) {
    this.homeService.getAllProducts(page).subscribe((res: any) => {
      console.log('Response', res);
      this.products = res.content;
    });
  }

  search(query) {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (query) {
      this.service
        .post('api/contacts_products', { name: query }, [])
        .subscribe((res) => {
          console.log('RES', res);
          this.searchedProduct = res['content'];
          this.products = res['content'];
        });
    } else {
      this.searchedProduct = [];
      this.getAllProducts(1);
      // return;
    }
  }

  filterByPrice(price) {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (price) {
      this.service
        .post('api/contacts_products', { price: price }, [])
        .subscribe((res) => {
          console.log('RES', res);
          this.searchedProduct = res['content'];
          this.products = res['content'];
        });
    } else {
      this.searchedProduct = [];
      this.getAllProducts(1);
      // return;
    }
  }

  menuHide(event) {
    this.showMenu = false;
  }

  menuShow() {
    this.showMenu = true;
  }

  navigateplace(item) {
    this.router.navigate(['../place-details', this.lang], {
      queryParams: {
        id: item.contact_id,
      },
    });
  }
}
