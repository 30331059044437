<section>
    <div class="row">
        <div class="col-md-2 col-sm-2 col-xs-4" *ngFor="let item of CategoryData | slice:0:12">
            <div class="card" style="cursor:pointer"  (click)="navigateplaces(item)">
                 <img class="category-img" src="{{item.image}}">
                 <a >
                    <h3 class="category-title">{{item.title}}</h3>
                 </a>
            </div>
        </div>
    </div>
</section>