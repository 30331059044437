import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HomeService } from 'src/app/Shared/Services/home.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { UsersComponent } from '../users/users.component';
import { LanguageService } from 'src/app/Shared/Services/language.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-view-job',
  templateUrl: './view-job.component.html',
  styleUrls: ['./view-job.component.scss'],
})
export class ViewJobComponent implements OnInit {
  jobForm: FormGroup;
  jobId: any;
  job: any;
  jobsList: any[] = [];
  pageSize: number;
  totalPages: number;
  p: number = 1;
  totalapi: number;
  userList: any[] = [];
  appliedUsers: any[] = [];
  users: any[] = [];
  lang;
  positionList: any[] = [];
  position: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private languageService: LanguageService,
    private service: HttpClientService
  ) {}

  ngOnInit(): void {
    // this.jobsList=[
    //   {id:1,name:"Pentavalue Soft",title:"accountant",description:"developer application",salary:"2500"},
    //   {id:2,name:"Pentavalue Soft",title:"accountant 1",description:"developer application 1",salary:"5000"},
    //   {id:3,name:"Pentavalue Soft",title:"accountant 2",description:"developer application 2",salary:"4500"}
    // ];
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.jobForm = new FormGroup({
      name: new FormControl(''),
      salary: new FormControl(''),
      title: new FormControl(''),
      description: new FormControl(''),
      position_id: new FormControl(''),
    });

    this.route.queryParams.subscribe((res: any) => {
      this.jobId = +res.id;
      if (this.jobId) {
        this.getjob(this.jobId);
      }
    });

    this.getUser();
  }
  getUser(page?) {
    let parameters = new HttpParams();
    let pagination = { pagination: 10 };

    if (page) {
      parameters = parameters.set('page', page + '');
    }
    this.service
      .post('api/employees', pagination, parameters)
      .subscribe((res: any) => {
        console.log('user =>', res);
        this.userList = res.content;
        this.totalapi = res.pagination.total;
        this.pageSize = res.pagination.per_page;
        this.p = res.pagination.current_page;
      });
  }

  navigateView(item) {
    this.router.navigate(['./view-employee', this.lang], {
      queryParams: {
        id: item.id,
      },
    });
  }

  getjob(id: number) {
    let obj = { id: id };
    this.service.post('api/company_jobs/show', obj, []).subscribe((res) => {
      console.log('The Response', res);
      this.appliedUsers = res['content'][0].applied_users;
      this.position = res['content'][0].position_id;
      this.getPositions();
      console.log('Applied Users ', this.appliedUsers);
      this.appliedUsers.forEach((ele) => {
        if (ele.created_by == ele.user_id) {
          this.service
            .post('api/users/show', { id: ele.user_id }, [])
            .subscribe((response) => {
              console.log('responseeeeee', response);
              this.users.push(response['content'][0]);
            });
        } else {
          this.service
            .post('api/users/show', { id: ele.user_id }, [])
            .subscribe((response) => {
              console.log('responseeeeee', response);
              if (ele.is_approved == 0 && ele.intiator_type == 2) {
                response['content'][0].approved = 0;
              } else if (ele.is_approved == 1 && ele.intiator_type == 2) {
                response['content'][0].approved = 1;
              } else if (ele.is_approved == 2 && ele.intiator_type == 2) {
                response['content'][0].approved = 2;
              }
              response['content'][0].approve = 1;
              this.users.push(response['content'][0]);
              console.log('uuuuusers', this.users);
            });
        }
      });

      this.jobForm.patchValue({
        name: res['content'][0]?.company.name,
        salary: res['content'][0]?.salary,
        title: res['content'][0]?.title,
        description: res['content'][0]?.description,
      });
    });
  }
  onPageChange(event) {
    this.p = event;
    this.getUser(this.p + '');
  }

  confirmApplication(id: number) {
    let body = {
      user_id: id,
      job_id: this.jobId,
    };
    this.service.post('api/company_jobs/confirm_request', body, []).subscribe(
      (res) => {
        if (res.status.code === 403) {
          
          if (this.lang == 'en') {
            Swal.fire({
              icon: 'error',
              title: ' ',
              text: 'Job application rejected can not be accepted',
              timer: 2000,
            });
          } else if (this.lang == 'ar') {
            Swal.fire({
              icon: 'error',
              title: ' ',
              text: 'لا يمكن قبول طلب الوظيفة المرفوض   ',
              timer: 2000,
            });
          }
          // this.toastService.error(res.status.error_details);
          console.log("confirm job api message::::::::::", res.status)
        } else if (res.status.code === 200) {

          if (this.lang == 'en') {
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: 'job accepted successfully',
              timer: 2000,
            });
          } else if (this.lang == 'ar') {
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: 'تم قبول الوظيفة بنجاح',
              timer: 2000,
            });
          }
            // this.toastService.success(res.status.message);
            console.log("confirm job api message::::::::::", res.status.message)
            // window.location.reload();
        
        }
      },
      (error) => {
        // console.log("error from job",error);
        if (error.error.status.code === 403) {
          
          if (this.lang == 'en') {
            Swal.fire({
              icon: 'error',
              title: ' ',
              text: 'Job application rejected can not be accepted',
              timer: 2000,
            });
          } else if (this.lang == 'ar') {
            Swal.fire({
              icon: 'error',
              title: ' ',
              text: 'لا يمكن قبول طلب الوظيفة المرفوض   ',
              timer: 2000,
            });
          }
          // this.toastService.error(res.status.error_details);
          // console.log("confirm job api message::::::::::", res.status)
        } else if (error.error.status.code === 200) {

          if (this.lang == 'en') {
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: 'job accepted successfully',
              timer: 2000,
            });
          } else if (this.lang == 'ar') {
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: 'تم قبول الوظيفة بنجاح',
              timer: 2000,
            });
          }
            // this.toastService.success(res.status.message);
            // console.log("confirm job api message::::::::::", res.status.message)
            // window.location.reload();
        
        }
      }
    );

  }

  rejectApplication(id: number) {
    let body = {
      user_id: id,
      job_id: this.jobId,
    };
    this.service.post('api/company_jobs/reject_request', body, []).subscribe(
      (res) => {
        // console.log(res);
        if (res.status.code === 403) {
          // this.toastService.error(res.status.error_details);
          if (this.lang == 'en') {
            Swal.fire({
              icon: 'error',
              title: ' ',
              text: 'Job application accepted can not be rejected',
              timer: 2000,
            });
          } else if (this.lang == 'ar') {
            Swal.fire({
              icon: 'error',
              title: ' ',
              text: 'لا يمكن رفض طلب الوظيفة المقبول    ',
              timer: 2000,
            });
          }
        } else if (res.status.code === 200) {
          // this.toastService.success(res['status'].message);
          // window.location.reload();
          if (this.lang == 'en') {
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: 'job declined successfully',
              timer: 2000,
            });
          } else if (this.lang == 'ar') {
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: ' تم  رفض  الوظيفة المقبول  ',
              timer: 2000,
            });
          }

        }
      },
      (error) => {
        // console.log(error);
        if (error.error.status.code === 403) {
          // this.toastService.error(res.status.error_details);
          if (this.lang == 'en') {
            Swal.fire({
              icon: 'error',
              title: ' ',
              text: 'Job application accepted can not be rejected',
              timer: 2000,
            });
          } else if (this.lang == 'ar') {
            Swal.fire({
              icon: 'error',
              title: ' ',
              text: 'لا يمكن رفض طلب الوظيفة المقبول    ',
              timer: 2000,
            });
          }
        } 
        
      }
    );
  }

  getPositions() {
    let lang_id: any;
    if (this.lang == 'ar') {
      lang_id = 2;
    } else {
      lang_id = 1;
    }
    this.service
      .get('api/company_jobs/positions', { lang_id: lang_id }, {})
      .subscribe((res: any) => {
        this.positionList = res.content;
        this.positionList.forEach((element) => {
          if (element.id == this.position) {
            this.jobForm.patchValue({
              position_id: element.name,
            });
          }
        });
      });
  }
}

