<div class="container pg-content">
    <div class="pg-header-content">
        <h1 class="pg-title" >
          {{'view job'|translate}}
        </h1>
       
      </div>
    
     <!--Start Contact Form -->
    <div class="col-sm-12">
     
      <form [formGroup]="jobForm" class="form-horizontal contact-form">
        <!-- Text input-->
        <div class="row">
        <div class="col-md-4 col-sm-6">
        <div class="form-group">
          <label>{{'Company name'|translate}}</label>
          <div class="input-group">
          <span class="input-group-addon"><i class="fa fa-user"></i></span>
          <input readonly formControlName="name"  name="name" id="name" placeholder="{{'Company name'|translate}}" class="form-control"  >

         
        </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-6">
          <div class="form-group">
            <label>{{'position'|translate}}</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-user"></i></span>
              <input readonly formControlName="position_id" name="position" id="position" placeholder="{{'position'|translate}}"
                class="form-control">
        
            </div>
        
          </div>
        </div>
     
          <div class="col-md-4 col-sm-6">
            <div class="form-group">
              <label>{{'job name'|translate}}</label>
              <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-user"></i></span>
                <input readonly formControlName="title" name="title" id="title" placeholder="{{'job name'|translate}}"
                  class="form-control">
          
              </div>
          
            </div>
          </div>
     
      

        <!-- Text input-->

            <div class="col-md-4 col-sm-6">
        <div class="form-group">
          <label>{{'salary'|translate}}</label>
                <div class="input-group">
                    <span class="input-group-addon"><i class="fas fa-dollar-sign"></i></span>
                    <input  readonly formControlName="salary" name="salary" id="salary" placeholder="{{'salary'|translate}}" class="form-control" >

                  </div>
            </div>
          </div>
        

        <!-- Text input-->
       
        <div class="col-md-4 col-sm-6">
        <div class="form-group">
          <div class="inputGroupContainer">
            <label>{{'description'|translate}}</label>
              <div class="input-group">
                <span class="input-group-addon"><i class="fas fa-file-alt"></i></span>
                <input readonly formControlName="description" placeholder="{{'description'|translate}} " class="form-control">
             
              </div>
              </div>
            </div>
          </div>

        <!-- Text input-->
      
        <!-- Text input-->
   
       

      </div>
       
      </form>
    </div>
    <!--End Contact Form-->
    <div class="container-fluid map-contact">
        <div class="container page_bg pt-50 pb-50 pr-50 pl-50 container-margin">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel panel-form">
                       
                        <div class="panel-body">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade active in" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                    <table class="table" cellspacing="0" *ngIf="users?.length>0">
                                        <thead>
                                            <tr>
                                                <th class="wide">{{'Name'|translate}}</th>
                                                <th class="wide">{{'Email'|translate}}</th>
                                                <th class="wide">{{'CV' | translate}}</th>
                                                <th class="wide">{{'Job'|translate}}</th>
                                                <th>{{'Actions'|translate}}</th>
    
                                            </tr>
                                        </thead> 
                                        <tbody>
                                            <tr *ngFor="let user of users | paginate: { itemsPerPage: pageSize, currentPage: p, totalItems: totalapi }">
                                                <td >{{user?.full_name}}</td>
                                               <td>
                                                   {{user?.email}}
                                                </td>
                                                <td>
                                                  <a *ngIf="user?.attached_cv" href={{user?.attached_cv}} target="_blank">{{'CV' | translate}} <i class="fa fa-eye"></i></a>
                                                </td>
                                              
                                                <td class="date">
                                                    {{user?.job_title}}
                                                </td>
                                              
                                                <td>
                                                <div class="btns-cell-td">
                                                  
                                                    <button (click)="navigateView(user)" class="view-btn"><i class="fa fa-eye"></i></button>
                                                    <button (click)="confirmApplication(user.id)" *ngIf="!user?.approve" class="view-btn success btn-success"><i class="fa fa-check"></i></button>
                                                    <button (click)="rejectApplication(user.id)" *ngIf="!user?.approve" class="view-btn error btn-danger"><i class="fa fa-times"></i></button>
                                                    <span *ngIf="user?.approved == 0">{{'waitingResponse' | translate}}</span>
                                                    <span *ngIf="user?.approved == 1">{{'acceptedResponse' | translate}}</span>
                                                    <span *ngIf="user?.approved == 2">{{'rejectedResponse' | translate}}</span>
                                             </div>
                                                  </td>
                                            </tr>
    
                                   
                                        </tbody>
                                    </table>
                                    <h2 *ngIf="users?.length==0">{{'There is no employees in this job'|translate}}</h2>
                                    <div class="col-md-12 col-sm-12 text-center" *ngIf="users?.length > 0">
                                        <pagination-controls 
                                                   (pageChange)="onPageChange($event)"
                                                   previousLabel="{{'previous'|translate}}"
                                                   nextLabel="{{'next'|translate}}">
                                        </pagination-controls>
                                
                                      </div>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
         
               
            
        </div>
       
    </div>
  </div>