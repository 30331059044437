import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';


@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private httpClientService: HttpClientService) { }

  GetContactInfo(){
    let body={}
    return this.httpClientService.post('api/office', body,  {});
  }
  
  contactus(contactdata){
    let body={}
    return this.httpClientService.post('api/contact_us', body,contactdata);

  }

  GetSocialLinks(){
    return this.httpClientService.get('api/social_media',  {}, {});
  }

  

}
