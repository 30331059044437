import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AddingMetaService } from 'src/app/Shared/Services/adding-meta.service';
import { PagesService} from '../../Shared/Services/pages.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  termstitle;
  termscontent;
  seo:any[] = [];
  config:any[] = [];

 constructor(
   private pageservice: PagesService,
   private translateservice:TranslateService,
   private meta: AddingMetaService) { }

  ngOnInit(): void {
    this.gettermsofuse();
  }

  gettermsofuse(){
    this.pageservice.GetTerms().subscribe((res:any)=>{
      console.log('terms', res)
      this.termstitle = res.content[0].name;
      this.termscontent = res.content[0].content;
      this.seo = res.content[0].seo;
      this.seo.forEach(element=>{
        if(element.title == "Title"){
          this.config.push(element.keyword);
        }
        if(element.title == "Description"){
         this.config.push(element.keyword);
        }

      });

     let seoConfig = {title: this.config[0], description: this.config[1], compTitle: this.config[0]};
     console.log('Config issss===>', seoConfig);
     this.meta.updateMeta(seoConfig);
    })
  }

}
