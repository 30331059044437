import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe, formatDate } from '@angular/common';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';

import Swal from 'sweetalert2';
import { CitiesService } from 'src/app/Shared/Services/cities.service';

declare var $: any;



@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.scss']
})
export class UserUpdateComponent implements OnInit {

  userForm: FormGroup;
  type;
  image_url: any;
  profile_img = '';
  date14: Date;
  user;

  currentlang: string;
  setDob = [];
  photo;
  codelist;

  img = true;
  constructor(private router: Router,
    private cityservices: CitiesService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private translateservice: TranslateService, private service: HttpClientService) {
    this.userForm = new FormGroup({
      full_name: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
      tele_code: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      photo: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
    // password: new FormControl('', [Validators.required, Validators.minLength(9)]),


      job_title: new FormControl(''),
      is_active: new FormControl(''),

      birthdate: new FormControl('', [Validators.required]),
      rule_id: new FormControl('4'),

      address: new FormControl(''),
      gender_id: new FormControl('', [Validators.required])



    });
    this.getCountryTelephoneCode();

  }

  ngOnInit(): void {
    this.currentlang = localStorage.getItem('lang');

    this.showUser();

  }
  getCountryTelephoneCode() {
    this.cityservices.GetCountryCodeList().subscribe((res: any) => {
      this.codelist = res.content;
      console.log(this.codelist)
    })
  }
  showUser() {
    let body = {
      id: this.activatedRoute.snapshot.queryParams['id']
    }
    this.service.post('api/users/show', body, {}).subscribe((res: any) => {
      this.user = res.content[0];
      console.log("user =>", this.user);

      this.setDob = this.user.birthdate.split(' ');
      this.photo = this.user.photo;
      this.userForm.get('full_name').setValue(this.user.full_name);
      this.userForm.get('address').setValue(this.user.address);
      this.userForm.get('email').setValue(this.user.email);
      this.userForm.get('gender_id').setValue(this.user.gender_id);
      this.userForm.get('is_active').setValue(this.user.is_active);
      this.userForm.get('mobile').setValue(this.user.mobile);
      // this.userForm.get('photo').setValue(this.user.photo);
      this.userForm.get('username').setValue(this.user.username);
      this.userForm.get('tele_code').setValue(this.user.tele_code);
      this.userForm.get('birthdate').setValue(this.user.birthdate);
      this.userForm.get('job_title').setValue(this.user.job_title);


    });
  }

  readImage(event) {
    var files = event.target.files[0];
    this.type = event.target.files[0].type;
    console.log(this.type);

    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }


  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.img = true;
    this.image_url = `data:${this.type};base64,` + btoa(binaryString); 4
    // console.log(this.image_url )


  }

  closebtn() {
    this.photo = '';
    this.img = false;
    $("#image_url").val('');

  }


  create(formdata) {
    let value = this.userForm;
    if (value.invalid && value.pristine) {
      value.markAllAsTouched();
      if ((this.currentlang == 'en')) {
        this.toastr.error('please fill the information ');
        console.log("form data ================= ", formdata);

      }
      else if ((this.currentlang == 'ar')) {
        console.log("form data ================= ", formdata);

        this.toastr.error('من فضلك ادخل  البيانات');

      }
    } else if (value.invalid && value.dirty) {
      Object.keys(value.controls).forEach(field => {
        const control = value.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      if ((this.currentlang == 'en')) {
        this.toastr.error('Please enter the rest of the information');
        console.log("form data ================= ", formdata);


      }
      else if ((this.currentlang == 'ar')) {
        this.toastr.error('من فضلك ادخل باقي البيانات');
        console.log("form data ================= ", formdata);


      }
    }
    else if (value.valid) {
      console.log(formdata.photo);
      formdata.id = this.activatedRoute.snapshot.queryParams['id'];
      if (this.img == false) {
        formdata.photo = ''
      }
      else if (formdata.photo.length > 0)
        formdata.photo = this.image_url;
      else if (formdata.photo.length == 0) {
        formdata.photo = this.user.photo;
      }

      if (formdata.is_active == false) {
        formdata.is_active = '0'
      }
      else if (formdata.is_active == true) {
        formdata.is_active = '1'

      }
      console.log("form data", formdata);
      this.service.post('api/users/update', formdata, {}).subscribe((res: any) => {
        console.log("create =>", res);
        if (res.status.code == 200) {
          if ((this.currentlang == 'en')) {
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: 'user updated successfully',
            })

          }
          else if ((this.currentlang == 'ar')) {
            // this.toastr.success('تم تعديل مستخدم ');
            Swal.fire({
              icon: 'success',
              title: ' ',
              text: 'تم تعديل مستخدم ',
            })


          }
          this.showUser();
        }
        else if (res.status.code == 403) {

          let validation_errors = res.status.validation_errors;
          validation_errors.forEach(element => {

            if (element.field == 'username') { this.toastr.error(element.message) }
            else if (element.field == 'full_name') { this.toastr.error(element.message) }

            else if (element.field == 'tele_code') { this.toastr.error(element.message) }
            else if (element.field == 'mobile') { this.toastr.error(element.message) }

            else if (element.field == 'photo') { this.toastr.error(element.message) }
            else if (element.field == 'email') { this.toastr.error(element.message) }
            else if (element.field == 'job_title') { this.toastr.error(element.message) }
            else if (element.field == 'is_active') { this.toastr.error(element.message) }
            else if (element.field == 'birthdate') { this.toastr.error(element.message) }
            else if (element.field == 'rule_id') { this.toastr.error(element.message) }
            else if (element.field == 'address') { this.toastr.error(element.message) }
            else if (element.field == 'gender_id') { this.toastr.error(element.message) }



          })

        }
        else {
          this.toastr.error(res.status.message);

        }

      },
        (error: any) => {
          if (error.error.status.code == 403) {

            let validation_errors = error.error.status.validation_errors;
            validation_errors.forEach(element => {

              if (element.field == 'username') { this.toastr.error(element.message) }
              else if (element.field == 'full_name') { this.toastr.error(element.message) }

              else if (element.field == 'tele_code') { this.toastr.error(element.message) }
              else if (element.field == 'mobile') { this.toastr.error(element.message) }

              else if (element.field == 'photo') { this.toastr.error(element.message) }
              else if (element.field == 'email') { this.toastr.error(element.message) }
              else if (element.field == 'job_title') { this.toastr.error(element.message) }
              else if (element.field == 'is_active') { this.toastr.error(element.message) }
              else if (element.field == 'birthdate') { this.toastr.error(element.message) }
              else if (element.field == 'rule_id') { this.toastr.error(element.message) }
              else if (element.field == 'address') { this.toastr.error(element.message) }
              else if (element.field == 'gender_id') { this.toastr.error(element.message) }



            })

          }
        })

    }



  }


  getEvent(event) {
    console.log('Event is:- ', event.checked);
    let isChecked: number;
    if (event.checked == true) {
      isChecked = 1;
      this.userForm.get('is_active').setValue('1');
    } else {
      isChecked = 0;
    }

  }





}
