
<div class="container">
    <div class="row">
        <div class="col-md-7 col-sm-12 col-xs-12">
            <h1 class="main-title2">{{'WHO WE ARE'|translate}}</h1>
            <p class="white-paragraph">{{'paragraph1'|translate}}</p>
            <p class="black-paragraph">{{'paragraph2'|translate}}</p>
            
            <div class="values-cards">
                <h1>
                    {{'Our Values'|translate}}
                </h1>


                <div class="row">
                    <div class="col-md-4">
                        <div id="card-container">



                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <h1> {{'Value1'|translate}} </h1>
                                    </div>
                                    <div class="flip-card-back">
                                        <p>
                                            {{'value1parg'|translate}}
                                        </p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>


                    <div class="col-md-4">
                        <div id="card-container">

                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <h1> {{'Value2'|translate}} </h1>
                                    </div>
                                    <div class="flip-card-back">
                                        <p>
                                            {{'value2parg'|translate}}
                                        </p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div class="col-md-4">
                        <div id="card-container">


                            <div class="flip-card">
                                <div class="flip-card-inner">
                                    <div class="flip-card-front">
                                        <h1> {{'Value3'|translate}} </h1>
                                    </div>
                                    <div class="flip-card-back">
                                        <p>
                                            {{'value3parg'|translate}}
                                        </p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>

        </div>
    
        <div class="col-md-5 col-sm-12 col-xs-12">
            <img src="https://pentavalue.com/public/frontend/img/marketing/2480553.png" alt="">
        </div>

    </div>
</div>
