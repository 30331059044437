<div class="container">
    <div class="tabset-section">
        <tabset [justified]="true">
            <tab heading="{{'Home.projects' | translate}}" *ngIf="projects?.length > 0">
              <div class="row listing white squared w-100 w-100">
                <div class="col-md-2 col-sm-12"
                  *ngFor="let item of projects">
                  <div class="listing-item">
                    <a class="listing-item-link">
                      <!-- <div class="listing-item-title-centralizer">
                        <div class="listing-item-title">
                          {{item.name}}
                        </div>
                      </div> -->
                      <img [alt]="item.name" [src]="item.image?item.image:'assets/images/logo.png'" />
                    </a>
                    <div class="listing-item-data">
                        <div class="listing-item-excerpt">
                            {{'title' | translate}}: {{item.name}}
                        </div>
                        <div class="listing-item-excerpt">
                            {{'description' | translate}}: {{item.description}}
                        </div>
                    </div>
                  </div>
                </div>
                <h2 *ngIf="projects.length == 0"> {{'Requests.NoRequest'|translate}} </h2>
              </div>
            </tab>
            <tab heading="{{'Home.products' | translate}}" *ngIf="products?.length > 0">
              <div class="row listing white squared w-100">
          
                <div class="col-md-2 col-sm-12"
                  *ngFor="let item of products">
                  <div class="listing-item">
                    <a class="listing-item-link">
                      <div class="listing-item-title-centralizer">
                        <!-- <div class="listing-item-title">
                          {{item.name}}
                        </div> -->
                      </div>
                      <img [alt]="item.name" [src]="item.image?item.image:'assets/images/logo.png'" />
                    </a>
                    <div class="listing-item-data">
                      <div class="listing-item-excerpt">
                        {{'title' | translate}}: {{item.name}}
                      </div>
                      <div class="listing-item-excerpt">
                        {{'description' | translate}}: {{item.description}}
                      </div>
                      <div class="listing-item-excerpt">
                        {{'price' | translate}}: {{item.price}} {{'LE' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
                <h2 *ngIf="products.length == 0"> {{'Requests.NoRequest'|translate}} </h2>
              </div>
            </tab>
            <tab heading="{{'Home.servicess' | translate}}" *ngIf="services?.length > 0">
              <div class="row listing white squared w-100">
          
                <div class="col-md-2 col-sm-12"
                  *ngFor="let item of services">
                  <div class="listing-item">
                    <a class="listing-item-link">
                      <div class="listing-item-title-centralizer">
                      </div>
                      <img [alt]="item.name" [src]="item.image?item.image:'assets/images/logo.png'" />
                    </a>
                    <div class="listing-item-data">
                      <div class="listing-item-excerpt">
                        {{'title' | translate}}: {{item.name}}
                      </div>
                      <div class="listing-item-excerpt">
                        {{'description' | translate}}: {{item.description}}
                      </div>
                      <div class="listing-item-excerpt">
                        {{'Home.cities' | translate}}: 
                        <span *ngFor="let city of item.cities">{{city.name}}, </span>
                        <span *ngIf="item?.cities?.length == 0">{{'noCities' | translate}}</span>
                      </div>
                      <div class="listing-item-excerpt">
                        {{'price' | translate}}: {{item.price}} {{'LE' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
                <h2 *ngIf="services.length == 0"> {{'Requests.NoRequest'|translate}} </h2>
              </div>
            </tab>
           
            <tab heading="{{'Jobs' | translate}}" *ngIf="jobs?.length > 0">
                <div class="row listing white squared w-100">
            
                  <div class="col-md-2 col-sm-12"
                    *ngFor="let item of jobs">
                    <div class="listing-item">
                      <a class="listing-item-link">
                        <div class="listing-item-title-centralizer">
                          <!-- <div class="listing-item-title">
                            {{item.name}}
                          </div> -->
                        </div>
                        <img [alt]="item.title" [src]="item.company.logo?item.company.logo:'assets/images/logo.png'" />
                      </a>
                      <div class="listing-item-data">
                        <div class="listing-item-excerpt">
                          {{'title' | translate}}: {{item.title}}
                        </div>
                        <div class="listing-item-excerpt">
                          {{'description' | translate}}: {{item.description}}
                        </div>
                        <div class="listing-item-excerpt">
                          {{'salary' | translate}}: {{item.salary}} {{'LE' | translate}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 *ngIf="jobs.length == 0"> {{'Requests.NoRequest'|translate}} </h2>
                </div>
            </tab>
        </tabset>
    </div>
</div>