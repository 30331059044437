<!-- Start Page Content -->
<div class="container pg-content">
    <div class="pg-header-content">
        <h1 class="pg-title">
            {{'Show Task'|translate}}
        </h1>
    </div>

    <!--Start Contact Form -->
    <div class="col-sm-12">

        <form [formGroup]="taskForm" class="form-horizontal contact-form">
            <!-- Text input-->

            <div class="row ">
                <div class="col-md-4 col-sm-6 ">
                    <div class="form-group">
                        <div class="inputGroupContainer">
                            <label>{{'Requests.TitleRequests' | translate}}</label>
                            <div class="input-group">
                                <span class="input-group-addon"><i class="fas fa-file-signature"></i></span>
                                <input formControlName="name" readonly name="name" id="title"
                                    placeholder="{{'Requests.TitleRequests' | translate}}" class="form-control"
                                    required>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 ">

                    <div class="form-group">
                        <div class="inputGroupContainer">
                            <label>{{'description' | translate}}</label>
                            <div class="input-group">
                                <span class="input-group-addon">
                                    <i class="fas fa-bars"></i>
                                </span>
                                <input type="text" readonly formControlName="description"
                                    placeholder="{{'description'|translate}}" class="form-control" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6 ">

                    <div class="form-group">
                        <div class="inputGroupContainer">
                            <label>{{'Requests.TypeRequests' | translate}}</label>
                            <div class="input-group">
                                <span class="input-group-addon">
                                    <i class="fas fa-ellipsis-v"></i>

                                </span>
                                <input type="text" readonly formControlName="request_type_id" name="request"
                                    id="request" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6 ">

                    <div class="form-group">
                        <div class="inputGroupContainer">
                            <label>{{'Home.place_name' | translate}}</label>
                            <div class="input-group">
                                <span class="input-group-addon">
                                    <i class="fas fa-address-book"></i>
                                </span>
                                <input type="text" readonly formControlName="item_id" name="item" id="item"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6 " *ngIf="typeID==2">

                    <div class="form-group">
                        <div class="inputGroupContainer">
                            <label>{{'city_name' | translate}}</label>
                            <div class="input-group">
                                <span class="input-group-addon">
                                    <i class="fas fa-map-marker-alt"></i>
                                </span>
                                <input type="text" readonly formControlName="district_id" name="district" id="district"
                                    (click)="openMaps(task.district_name)" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6 clear" *ngIf="typeID==1">

                    <!-- <div class="form-group">
                        <div class="inputGroupContainer">
                            <div class="input-group">
                                <span class="input-group-addon">
                                    <i class="fas fa-user-friends"></i> -->


            <!-- Text input-->
            <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                    <label>{{'Date' | translate}}</label>
                <div class="input-group">
                    <span class="input-group-addon"><i class="fas fa-calendar-alt"></i></span>
                    <input readonly formControlName="date" type="date" placeholder="{{'Date'|translate}}" class="form-control">
                                <!-- </span>
                                <input type="text" readonly formControlName="related_contact_name" name="related"
                                    id="related" class="form-control"> -->
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-md-4 col-sm-6 ">
                    <div class="form-group">
                        <div class="inputGroupContainer">
                            <label>{{'Date' | translate}}</label>
                            <div class="input-group date-input">
                                <span class="input-group-addon"><i class="fas fa-calendar-alt"></i></span>
                                <input readonly formControlName="date" type="date" placeholder="{{'Date'|translate}}"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="col-md-4 col-sm-6 ">

                    <!-- Text input-->
                    <div class="form-group">
                        <div class="inputGroupContainer">
                            <label>{{'Time' | translate}}</label>
                            <div class="input-group time-input">
                                <span class="input-group-addon"><i class="fas fa-business-time"></i></span>
                                <input readonly formControlName="time" type="time" placeholder="{{'Time'|translate}}"
                                    class="form-control" required>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6" *ngIf="typeID==3">

                    <!-- Text input-->
                    <div class="form-group">
                        <div class="inputGroupContainer">
                            <label>{{'otherAction' | translate}}</label>
                            <div class="input-group">
                                <span class="input-group-addon">
                                    <i class="fas fa-plus-circle"></i>


                                </span>
                                <input readonly formControlName="otherAction" placeholder="{{'otherAction'|translate}}"
                                    class="form-control" required>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6 ">

                    <div class="form-group">
                        <div class="inputGroupContainer">
                            <label>{{'Priority Level' | translate}}</label>
                            <div class="input-group">
                                <span class="input-group-addon">
                                    <i class="fas fa-chart-bar"></i>


                                </span>
                                <input readonly value="{{priorityName}}" class="form-control" required>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6 ">

                    <!-- Text input-->
                    <div class="form-group">
                        <div class="inputGroupContainer">
                            <label>{{'Home.users' | translate}}</label>
                            <div class="input-group">
                                <span class="input-group-addon">
                                    <i class="fas fa-user"></i>

                                </span>
                                <input readonly name="users" id="users" value="{{users}}" class="form-control" required>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">

                    <div class="form-group">
                        <!-- <label>{{'indoor' | translate}}</label> -->
                            <div class="input-group active">
                                <input [checked]="indoor" disabled type="checkbox" value="indoor"
                                    formControlName="indoor" tabindex="3" class="" name="indoor" id="indoor">
                                <label for="indoor" class="active_label">{{'indoor'|translate}}</label>
                            </div>
                    </div>
                </div>
                <div class="col-md-12">

                    <div class="form-group">
                        <div class="inputGroupContainer">
                            <label>{{'notes' | translate}}</label>
                            <div class="input-group">
                                <span class="input-group-addon"><i class="fas fa-file-alt"></i></span>
                                <textarea readonly formControlName="notes" placeholder="{{'notes'|translate}}"
                                    class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

               
            </div>
        </form>
    </div>
    <!--End Contact Form-->
</div>
<!-- End Page Content -->