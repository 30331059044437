import { Component, OnInit, Injectable } from '@angular/core';
import {UserService} from '../../Shared/Services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl,FormBuilder,Validators } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {AuthenticationService} from '../../Shared/Services/authentication.service';
import {CitiesService} from '../../Shared/Services/cities.service';

import {NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { AddingMetaService } from 'src/app/Shared/Services/adding-meta.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}



@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})


export class UpdateProfileComponent implements OnInit {

  updateprofileform: FormGroup;

  codelist;
  lang
  currentlang: string;
  username: any;
  usercode: any;
  userphone: any;
  useremail: any;
  userbirthdate: any;
  useraddress: any;
  usergender: any;
  userfacebook: any;
  userinstagram: any;
  userlinkedin: any;
  usertwitter: any;
  usergenderm: any;
  usergenderf: any;
  gender_id;
  userType:any;

  model: NgbDateStruct;

  type: any;
  fileName: any;
  file_url: any;
  attached_cv: any;
  update_at: any;

  type2: any;
  fileName2: any;
  file_url2: any;
  uploaded_image: any;
  Uploaded_profile_image: any;

  type3: any;
  fileName3: any;
  file_url3: any;
  uploaded_image3:any;
  Uploaded_tax_image: any;

  type4: any;
  fileName4: any;
  file_url4: any;
  uploaded_image4:any;
  Uploaded_commercial_image: any ;

  constructor(private userservice:UserService,
    private translateservice:TranslateService,
    private toastrService: ToastrService,
    private authservice:AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private cityservices:CitiesService,
    private datePipe: DatePipe,
    private meta: AddingMetaService,
    private languageService: LanguageService,

    ) { }

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.currentlang = localStorage.getItem('lang');
    this.userType = localStorage.getItem('userType');
    this.initForm();
    this.getCountryTelephoneCode();
    this.ViewProfileInfo();

    this.meta.updateMeta({
      compTitle: 'Update Profile'
    });

  }

  getCountryTelephoneCode(){
    this.cityservices.GetCountryCodeList().subscribe((res:any)=>{
        this.codelist = res.content;
        // console.log(this.codelist)
    })
  }

  initForm(){

    this.updateprofileform = new FormGroup({
      full_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),

      tele_code: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),

      birthdate: new FormControl(''),
      gender_id: new FormControl(''),


      password: new FormControl(''),
      confirm_password: new FormControl(''),

      facebook: new FormControl(''),
      twitter: new FormControl(''),
      instagram: new FormControl(''),
      linkedin: new FormControl(''),
      attached_cv: new FormControl(''),

      uploaded_image: new FormControl(''),
      tax_image: new FormControl(''),
      commercial_image: new FormControl(''),

    })
  }



  ViewProfileInfo(){
    this.userservice.viewprofile().subscribe((res:any)=>{
      console.log("Profile View ====> ",res)
      // console.log("telecode from profile view =====> ",this.usercode)

      this.username = res.content.full_name;
      this.usercode = res.content.tele_code;
      this.userphone = res.content.mobile;
      this.useremail = res.content.email;
      this.update_at = res.content.cv_updated_at;
      this.updateprofileform.controls['tele_code'].setValue( this.usercode)
      this.userbirthdate = this.datePipe.transform(res.content.birthdate, 'yyyy-MM-dd')
      //  res.content.birthdate;
      this.useraddress = res.content.address;
      this.gender_id = res.content.gender_id;
      if(this.gender_id) {
        console.log("GENDER ID", this.gender_id)
        this.updateprofileform.patchValue({
          gender_id: this.gender_id.toString()
        })
      }

      this.attached_cv = res.content.attached_cv;
      this.uploaded_image = res.content.photo;
      console.log("The image from profile :::::::::::::-",this.uploaded_image )


      // console.log(this.gender_id,'gender')

      // console.log ("User Birthdate :",this.userbirthdate)




      this.userfacebook = res.content.facebook;
      this.userinstagram = res.content.instagram;
      this.userlinkedin = res.content.linkedin;
      this.usertwitter = res.content.twitter;

      // this.updateprofileform.patchValue({ full_name: this.username });
      // this.updateprofileform.patchValue({ tele_code: this.usercode });
      // this.updateprofileform.patchValue({ mobile: this.userphone });
      // this.updateprofileform.patchValue({ email: this.useremail });

      // this.updateprofileform.patchValue({ birthdate: this.userbirthdate });
      // // this.updateprofileform.patchValue({ full_name: this.useraddress });
      this.updateprofileform.patchValue({ gender_id: this.gender_id });


      // this.updateprofileform.patchValue({ facebook: this.userfacebook });
      // this.updateprofileform.patchValue({ instagram: this.userinstagram });
      // this.updateprofileform.patchValue({ linkedin: this.userlinkedin });
      // this.updateprofileform.patchValue({ twitter: this.usertwitter });
      // this.updateprofileform.patchValue({ photo:  this.uploaded_image });

    this.updateprofileform.controls['full_name'].setValue(this.username)
    this.updateprofileform.controls['tele_code'].setValue(this.usercode)
    this.updateprofileform.controls['mobile'].setValue(this.userphone)
    this.updateprofileform.controls['email'].setValue(this.useremail)

    this.updateprofileform.controls['birthdate'].setValue(this.userbirthdate)

    this.updateprofileform.controls['facebook'].setValue( this.userfacebook)
    this.updateprofileform.controls['instagram'].setValue( this.userinstagram)
    this.updateprofileform.controls['linkedin'].setValue( this.userlinkedin)
    this.updateprofileform.controls['twitter'].setValue( this.usertwitter)



    })
    console.log("the set values ::", this.updateprofileform.value)
    console.log("The image from profile :::::::::::::-",this.uploaded_image )
  }



  readImage(event) {
    var files = event.target.files[0];
    this.type = event.target.files[0].type;
    console.log("CV Type ::::::::::::",this.type);
    this.fileName = files.name;
    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.file_url = `data:${this.type};base64,` + btoa(binaryString);
    this.attached_cv = this.file_url;
    console.log("CV full url :::::::::::::::: ",this.file_url)


  }

  closebtn() {
    this.attached_cv = '';
    this.file_url  = '' ;
    console.log('CV Deleted')
  }

  readImage2(event) {
    var files2 = event.target.files[0];
    this.type2 = event.target.files[0].type;
    console.log("image type :::::::::::::::",this.type2);
    this.fileName2 = files2.name;
    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded2.bind(this);
    reader.readAsBinaryString(files2);
  }

  handleReaderLoaded2(readerEvt) {
    var binaryString2 = readerEvt.target.result;
    this.file_url2 = `data:${this.type2};base64,` + btoa(binaryString2);
    this.uploaded_image = this.file_url2;
    console.log("image full url :::::::::::::::: ",this.file_url2)


  }

  closebtn2() {
    this.file_url2 = '';
    this.uploaded_image = '';
    console.log('Profile Image Deleted')
  }


  readImage3(event) {
    var files3 = event.target.files[0];
    this.type3 = event.target.files[0].type;
    console.log("Tax file type  ::::::::::::::: ",this.type3);
    this.fileName3 = files3.name;
    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded3.bind(this);
    reader.readAsBinaryString(files3);
  }

  handleReaderLoaded3(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.file_url3 = `data:${this.type3};base64,` + btoa(binaryString);
    this.uploaded_image3 = this.file_url3;
    console.log("Tax file full url ::::::::::::::: ",this.file_url3)


  }

  closebtn3() {
    this.Uploaded_tax_image = '';
    this.file_url3 = '';
    this.uploaded_image3 = '';
    console.log('tax Image Deleted')
  }

  readImage4(event) {
    var files4 = event.target.files[0];
    this.type4 = event.target.files[0].type;
    console.log(this.type4);
    this.fileName4 = files4.name;
    var reader = new FileReader();
    reader.onload = this.handleReaderLoade4.bind(this);
    reader.readAsBinaryString(files4);
  }

  handleReaderLoade4(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.file_url4 = `data:${this.type4};base64,` + btoa(binaryString);
    this.uploaded_image4 = this.file_url4;
    console.log("Commercial register file full url :::::::::::",this.file_url4)


  }

  closebtn4() {
    this.Uploaded_commercial_image = '';
    this.file_url4 = '';
    this.uploaded_image4 = '';
    console.log('Commercial Image Deleted')
  }

  updateprofile(){
    console.log("change => ",this.gender_id)
    console.log(this.updateprofileform);


   if(this.updateprofileform.valid){

    let submitobj ={
      "full_name":this.updateprofileform.value['full_name'],
      "email":this.updateprofileform.value['email'],

      "tele_code":this.updateprofileform.value['tele_code'],
      "mobile":this.updateprofileform.value['mobile'],

      "birthdate":this.datePipe.transform(this.updateprofileform.value['birthdate'], "dd/MM/yyyy"),
      "gender_id":+this.updateprofileform.value['gender_id'],
      "photo" : this.file_url2 ,

      "facebook":this.updateprofileform.value['facebook'],
      "twitter":this.updateprofileform.value['twitter'],
      "instagram":this.updateprofileform.value['instagram'],
      "linkedin":this.updateprofileform.value['linkedin'],
      "attached_cv" : this.file_url,

      "commercial_record":this.file_url4,
      "tax_card":this.file_url3


     }
      console.log("The Form Value ============> ",submitobj)
      console.log("The CV ============> ",this.file_url)
      console.log("The photo ============> ",this.file_url2)
      console.log("The Commercial register File ============> ",this.file_url4)
      console.log("The Tax File  ============> ",this.file_url3)


      // console.log("TeleCode formValue=> ",this.updateprofileform.value['tele_code'])
      localStorage.setItem('telecode',this.updateprofileform.value['tele_code'])

      this.userservice.UpdateProfile(submitobj).subscribe((res:any)=>{
        if(res.status.code === 200){
          this.toastrService.success("You Have Updated Your Profile Successfully !")
          this.router.navigate(['../profile', this.lang])

        }
        else if (res.status.code === 403){
          let validation_errors = res.status.validation_errors;
          validation_errors.forEach(element => {
            if(element.field == 'email')
             {this.toastrService.error(element.message)}
            else if (element.field == 'full_name')
             {this.toastrService.error(element.message)}

            else if (element.field == 'gender_id')
             {this.toastrService.error(element.message)}
            else if (element.field == 'birthdate')
             {this.toastrService.error(element.message)}

            else if (element.field == 'tele_code')
             {this.toastrService.error(element.message)}
            else if (element.field == 'mobile')
             {this.toastrService.error(element.message)}

            else if (element.field == 'photo')
             {this.toastrService.error(element.message)}

            else if (element.field == 'facebook')
             {this.toastrService.error(element.message)}
            else if (element.field == 'twitter')
             {this.toastrService.error(element.message)}
            else if (element.field == 'linkedin')
             {this.toastrService.error(element.message)}
            else if (element.field == 'instagram')
             {this.toastrService.error(element.message)}

          })

        }
        else {
          this.toastrService.error(res.status.error_details)
        }
        // console.log("Update profile API response====>",res)

      }, error=>{
        let validation_errors = error.error.status.validation_errors;
          validation_errors.forEach(element => {
            if(element.field == 'email')
             {this.toastrService.error(element.message)}
            else if (element.field == 'full_name')
             {this.toastrService.error(element.message)}

            else if (element.field == 'gender_id')
             {this.toastrService.error(element.message)}
            else if (element.field == 'birthdate')
             {this.toastrService.error(element.message)}

            else if (element.field == 'tele_code')
             {this.toastrService.error(element.message)}
            else if (element.field == 'mobile')
             {this.toastrService.error(element.message)}

            else if (element.field == 'photo')
             {this.toastrService.error(element.message)}

            else if (element.field == 'facebook')
             {this.toastrService.error(element.message)}
            else if (element.field == 'twitter')
             {this.toastrService.error(element.message)}
            else if (element.field == 'linkedin')
             {this.toastrService.error(element.message)}
            else if (element.field == 'instagram')
             {this.toastrService.error(element.message)}

          })
      })
   }
  }


  ClearOldDate(){
    this.userbirthdate = '';
  }

}
