
<section class="our-clients">
    <div class="container">
        <h1 class="main-title" data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-out-cubic"
            data-aos-duration="​1800">{{'TAKE IT FROM THEM'|translate}}</h1>



        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                    <div class="item">
                    <p>
                      {{'slider parag1'|translate}}
                    </p>
                    <div class="" style="display: flex;">
                        <img src="assets/images/logo1.png" alt="">

                        <div class="name" style="width: 90%;">{{'slider title1'|translate}}</div>
                    </div>
                    

                    <!-- <div class="position">VP Marketing, <span>3d Signals</span></div> -->
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="item">
                    <p>
                        {{'slider parag2'|translate}}</p>
                        <div class="" style="display: flex;">
                            <img src="assets/images/logo1.png" alt="">

                            <div class="name" style="width: 90%;">{{'slider title2'|translate}}</div>
                        </div>

                     

               </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="item">
                    
                    <p>
                        {{'slider parag3'|translate}}</p>
                        <div class="" style="display: flex;">
                            <img src="assets/images/logo1.png" alt="">

                            <div class="name" style="width: 90%;">{{'slider title3'|translate}}</div>
                        </div>

                </div>
            </ng-template>
           
            


           



        </owl-carousel-o>


    </div>
</section>