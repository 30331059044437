<div class="container-fluid pg-header" style="background-image: url(./assets/images/16.jpg);">
    <div class="pg-header-content">
      <h1 class="pg-title">
        {{'Requests.TypeRequests'|translate}}
      </h1>
      <h4 class="pg-subtitle">
         
      </h4>
    </div>
    <!-- <div class="pg-header-icon icon-bubbles"></div> -->
</div>
  <!-- End Page Header -->
  <!-- Start Contact Map -->
<div class="container-fluid map-contact">
    <div class="container page_bg pt-50 pb-50 pr-50 pl-50 container-margin">
        <div class="row">
            <div class="col-md-12">
                <div class="panel panel-form">
                  
                        <!-- <div class="panel-heading">
                            <button class="search-btn" routerLink="/create-request-types">{{'Requests.add_request_type'|translate}}</button>
                        </div> -->
                    <div class="panel-body">
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade active in " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" >
                                <div class="table-responsive" style="width:100%">

                                
                                <table class="table" cellspacing="0" *ngIf="requestsList?.length>0">
                                    <thead>
                                        <tr>
                                            <!-- <th ></th> -->
                                            <th class="wide">{{'Requests.TypeRequests'|translate}}</th>
                                           
                                            <!-- <th>{{'Actions'|translate}}</th> -->

                                        </tr>
                                    </thead> 
                                    <tbody>
                                        <tr *ngFor="let request of requestsList | paginate: { itemsPerPage: pageSize, currentPage: p, totalItems: totalapi }">
                                            <!-- <td>
                                                <input  type="checkbox" value="0"  class="" name="active" id="active">
                                            </td> -->
                                            <td >{{request?.name}}</td>
                                          

                                            
                                            <!-- <td>
                                                <button (click)="editTask(request.id)" class="edit-btn"><i class="fa fa-edit"></i></button>
                                                <button  class="delete-btn"><i class="fa fa-trash"></i></button>
                                            </td> -->
                                        </tr>

                               
                                    </tbody>
                                </table>
                        <!-- <button class="delete-selected-btn" (click)="deleteSelected()">{{'delete_selected'|translate}}</button> -->

                            </div>
                                <h2 *ngIf="requestsList?.length==0">{{'no result'|translate}}</h2>
                                <div class="col-md-12 col-sm-12 text-center" *ngIf="requestsList?.length==0">
                                    <pagination-controls 
                                               (pageChange)="onPageChange($event)"
                                               previousLabel="{{'previous'|translate}}"
                                               nextLabel="{{'next'|translate}}">
                                    </pagination-controls>
                            
                                  </div>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
     
           
        
    </div>
   
</div>



