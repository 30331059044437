import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/Shared/Services/home.service';
import { HttpParams } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
  closeResult = '';
  pageSize: number;
  totalPages: number;
  p: number = 1;
  totalapi: number;
  searchForm: FormGroup;
  statuses: any[] = [];
  approvedList = [];
  tasks: any[] = [];
  types: any;
  typeID: number;
  items: any[] = [];
  currentlang: string;
  index = 1;
  offset = 0;
  isVisible: boolean = false;
  value: any;
  constructor(private modalService: NgbModal,
    private service: HttpClientService,
    private homeService: HomeService,
    private router: Router, 
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.initForm();

  }
  ngOnInit(): void {
    this.currentlang = localStorage.getItem('lang');
    this.getStatuses();
    
    this.getTypes();
    this.getItems();
    this.getNextBatch();
    
  }
  initForm() {
    this.searchForm = new FormGroup({
      date: new FormControl(''),
      name: new FormControl(''),
      status_id: new FormControl(''),
      request_type_id: new FormControl(''),
      item_id: new FormControl('')



    })
  }
  getTypes() {
    this.homeService.getTypes().subscribe(
      res => {
        console.log('The types', res);
        this.types = res['content'];
      }
    );
  }
  getStatuses() {
    this.homeService.getTaskStatuses().subscribe(
      res=>{
        this.statuses = res['content'];
        console.log('Statuses:--', this.statuses)
        this.approvedList = this.statuses;
        this.getAllTasks();
      },
      error=>{
        console.log(error);
      }
    );
  }
  getItems() {
    this.homeService.getItems(1).subscribe(
      res => {
        console.log("The items", res['content'])
        this.items = res['content'];
      }
    );
  }
  
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  search(formdata) {

    console.log(formdata);
    this.homeService.filterTasks(formdata).subscribe(
      res => {
        this.modalService.dismissAll();
        console.log("user =>", res);
        this.tasks = res['content'];
      });

  }


  getAllTasks(page?) {
    let parameters = new HttpParams();
    let pagination = { 'pagination': 10 };

    if (page) {
      parameters = parameters.set('page', page + "");

    }
    this.homeService.getAllTasks(pagination, parameters).subscribe(
      (res: any) => {
        console.log('the res', res);
        this.tasks = res['content'];
        this.tasks.forEach(ele=>{
          this.statuses.forEach(element=>{
            if(ele.request_status_id == element.id){
              ele.request_status_id = element.name
            }
          })
        });

        this.totalapi = res.pagination.total;
        this.pageSize = res.pagination.per_page;
        this.p = res.pagination.current_page
      }
    );
  }

  viewTask(id: number) {
    this.router.navigate(['task-view', this.currentlang], { queryParams: { 'task_id': id } });
  }

  editTask(id: number) {
    this.router.navigate(['tasks-update', id]);
  }
  onPageChange(event) {
    this.p = event;
    this.getAllTasks(this.p + "");
  }
  delete(id) {

    Swal.fire({
      title: this.translate.instant('delete_task'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('delete'),
      cancelButtonText: this.translate.instant('cancel')
    }).then((result) => {
      if (result.value) {
        this.service.post("api/requests/destroy", { id: id }, {}).subscribe((res: any) => {
          console.log(res);
          if (res.status.code == 200) {
            if ((this.currentlang == 'en')) {
              Swal.fire({
                icon: 'success',
                title: ' ',
                text: 'Request deleted successfully',
              })
            }
            else if ((this.currentlang == 'ar')) {
              Swal.fire({
                icon: 'success',
                title: ' ',
                text: 'تم ازاله الطلب',
              })
            }
            this.getAllTasks();
          }
          else {
            this.toastr.error(res.status.message);
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) { }
    })


  }

  getNextBatch() {
    this.index = this.index + 1;
    this.homeService.getItems(this.index).subscribe(
      res => {
        console.log("The items", res['content'])
        res['content'].forEach(ele=>{
          this.items.push(ele);
        });
      }
    );
    // this.offset += this.limit;
}

onKey(event: string){
  console.log('Eveeeeeent',event);
  console.log(event.search(' '));
  if(event) {
    
    this.homeService.searchPlaces(event).subscribe((res:any) => {
      this.items = res['content'];
      console.log("Search Results=========>",this.items)
    });

  }
   else {
     this.isVisible = true;
    this.getItems();

    return;
  }
}
}
