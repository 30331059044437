<div class="container b-margin">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <h1 class="main-title">Our Testimonials</h1>
        </div>

    </div>
    
    <div class="row" style="margin: 25px 0px;">
        <div class="col-md-3 col-sm-6 col-xs-12">
             <div class="card">
                 <img class="testimonilas-image" src="assets/images/user.jpg">
                 <h3 class="card-title">Ahmed Salem</h3>
                 <p class="card-description">This project is userly interface motivate sometimes ti gives good experience </p>
                 <p class="card-company">Pentavalue Company</p>
                 <p class="card-position">Project Manager</p>
             </div>
        </div>

        <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="card">
                <img class="testimonilas-image" src="assets/images/user.jpg">
                <h3 class="card-title">Ahmed Salem</h3>
                <p class="card-description">This project is userly interface motivate sometimes ti gives good experience </p>
                <p class="card-company">Pentavalue Company</p>
                <p class="card-position">Project Manager</p>
            </div>
        </div>

       <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="card">
                <img class="testimonilas-image" src="assets/images/user.jpg">
                <h3 class="card-title">Ahmed Salem</h3>
                <p class="card-description">This project is userly interface motivate sometimes ti gives good experience </p>
                <p class="card-company">Pentavalue Company</p>
                <p class="card-position">Project Manager</p>
            </div>
       </div>

       <div class="col-md-3 col-sm-6 col-xs-12">
        <div class="card">
            <img class="testimonilas-image" src="assets/images/user.jpg">
            <h3 class="card-title">Ahmed Salem</h3>
            <p class="card-description">This project is userly interface motivate sometimes ti gives good experience </p>
            <p class="card-company">Pentavalue Company</p>
            <p class="card-position">Project Manager</p>
        </div>
       </div>



    </div>
</div>