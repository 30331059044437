import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';
import { ProjectsService } from 'src/app/Shared/Services/projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss'],
})
export class ProductCreateComponent implements OnInit {
  productForm: FormGroup;
  typeImage: any;
  imageName: string = '';
  image_url: any;
  file_base64: any;
  type: any;
  contactlist: any[] = [];
  isEnabled: boolean = false;
  lang: string;
  productId: any;
  photo: any;
  placeId: any;
  val: any;
  config: any = {};

  constructor(
    private projectservice: ProjectsService,
    private httpClientService: HttpClientService,
    private toaster: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.initForm();
    this.config = {
      placeholder: this.translate.instant('selectPlace'),
      search: false,
      displayKey: 'title',
    };
    this.projectservice.GetcontactsList().subscribe((res: any) => {
      this.contactlist = res.content;
      this.contactlist.forEach((ele) => {
        if (ele.id == this.placeId) {
          this.val = ele;
        }
      });
      console.log('contact list:-', this.contactlist);
    });

    this.route.queryParams.subscribe((res) => {
      this.productId = +res['id'];
      this.placeId = +res['placeId'];
      this.productForm.get('contact_id').setValue(this.placeId);
      if (this.productId) {
        let body = { id: this.productId };
        this.projectservice.getProduct(body).subscribe((res) => {
          this.setForm(res['content']);
          this.placeId = res['content'].list[0].contact_id;
        });
      }
    });
  }

  initForm() {
    this.productForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      price: new FormControl('', [Validators.required]),
      image: new FormControl(''),
      contact_id: new FormControl('', [Validators.required]),
    });
  }

  closebtn() {
    this.photo = '';
    // this.img =false;
  }
  setForm(data) {
    this.productForm.patchValue({
      name: data.list[0].name,
      description: data.list[0].description,
      contact_id: data.list[0].contact_id,
      price: data.list[0].price,
    });

    this.photo = data.list[0].image;
    this.val = data.list[0].cms;
    // this.show = false;
  }

  readImage(event) {
    var files = event.target.files[0];
    this.typeImage = files.type;
    // console.log(this.typeImage);

    this.imageName = files.name;
    var reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(files);
  }

  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.image_url = 'data:' + this.typeImage + ';base64,' + btoa(binaryString);
  }

  onSubmit() {
    this.isEnabled = true;
    if (this.productForm.valid) {
      this.productForm.value.image = this.image_url;
      console.log('Result', this.productForm.value);
      if (this.productId) {
        let obj = this.productForm.value;
        obj.id = this.productId;
        obj.contact_id = this.val.id;
        this.httpClientService
          .post('api/contacts_products/update', obj, [])
          .subscribe(
            (res: any) => {
              this.isEnabled = false;
              console.log(res);
              if (res.status.code == 400) {
                this.toaster.error(res.status.error_details);
              } else if (res.status.code == 200) {
                if (this.lang == 'ar') {
                  this.toaster.success('تم تعديل المنتج بنجاح');
                } else {
                  this.toaster.success('Product Updated Successfully');
                }

                this.router.navigate(['place-view', this.lang], {
                  queryParams: { id: obj.contact_id },
                });
              } else if (res.status.code == 403) {
                if (res.status.validation_errors[0].field == 'description') {
                  this.toaster.error(
                    res.status.validation_errors[0].message[0]
                  );
                }
              }
            },
            (error) => {
              this.isEnabled = false;
              console.log(error);
              let validation_errors = error.error.status.validation_errors;
              validation_errors.forEach((element) => {
                if (element.field == 'name') {
                  this.toaster.error(element.message);
                } else if (element.field == 'description') {
                  this.toaster.error(element.message);
                } else if (element.field == 'contact_id') {
                  this.toaster.error(element.message);
                } else if (element.field == 'image') {
                  this.toaster.error(element.message);
                } else if (element.field == 'price') {
                  this.toaster.error(element.message);
                }
              });
              // if(this.lang == 'ar'){
              //   this.toaster.error('حدث خطأ ما');
              // }else{
              //   this.toaster.error('Error happened')
              // }
            }
          );
      } else {
        let obj = this.productForm.value;
        obj.id = this.productId;
        obj.contact_id = this.val.id;
        this.httpClientService
          .post('api/contacts_products/store', obj, [])
          .subscribe(
            (res: any) => {
              this.isEnabled = false;
              console.log(res);
              if (res.status.code == 400) {
                this.toaster.error(res.status.error_details);
              } else if (res.status.code == 200) {
                if (this.lang == 'ar') {
                  this.toaster.success('تم إنشاء المنتج بنجاح');
                } else {
                  this.toaster.success('Product Created Successfully');
                }
                this.router.navigate(['place-view', this.lang], {
                  queryParams: { id: obj.contact_id },
                });
              }
            },
            (error) => {
              this.isEnabled = false;
              console.log(error);
              let validation_errors = error.error.status.validation_errors;
              validation_errors.forEach((element) => {
                if (element.field == 'name') {
                  this.toaster.error(element.message);
                } else if (element.field == 'description') {
                  this.toaster.error(element.message);
                } else if (element.field == 'contact_id') {
                  this.toaster.error(element.message);
                } else if (element.field == 'image') {
                  this.toaster.error(element.message);
                } else if (element.field == 'price') {
                  this.toaster.error(element.message);
                }
              });
            }
          );
      }
    } else {
      this.isEnabled = false;
    }
  }

  gotoPlace() {
    this.router.navigate(['place-new', this.lang]);
  }
}
