import { Component, OnInit } from '@angular/core';
import { CitiesService } from '../../Shared/Services/cities.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AddingMetaService } from 'src/app/Shared/Services/adding-meta.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss'],
})
export class CitiesComponent implements OnInit {
  CitiesData;
  p: number = 1;
  lang;

  constructor(
    private cityservice: CitiesService,
    private translateservice: TranslateService,
    private router: Router,
    private meta: AddingMetaService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.GetCitiesList();

    this.meta.updateMeta({
      compTitle: 'Cities',
    });
  }

  GetCitiesList() {
    this.cityservice.GetCityList().subscribe((res: any) => {
      this.CitiesData = res.content;
    });
  }

  navigateplaces(item) {
    this.router.navigate(['../places', this.lang], {
      queryParams: {
        city_id: item.id,
      },
    });
  }
}
