// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   baseConfig: {
     apiKey: 'AIzaSyCycZq1olLfQGGa9rr_P8zoeu9-Ps--DHw',
     authDomain: 'qpedia-co.firebaseapp.com',
     databaseURL: 'https://qpedia-co.firebaseio.com',
     projectId: 'qpedia-co',
     storageBucket: 'qpedia-co.appspot.com',
     messagingSenderId: '797542914282',
     appId: '1:797542914282:web:7d4a38404574fd3b0f9f93',
     measurementId: 'G-2DD70VK070'
   },
  firebaseConfig:{
    apiKey: "AIzaSyBLG9yIWdPr3JqEkeVuOpMQu_Q2je0ojgI",
    authDomain: "qpedia-8a3e3.firebaseapp.com",
    projectId: "qpedia-8a3e3",
    storageBucket: "qpedia-8a3e3.appspot.com",
    messagingSenderId: "55095221706",
    appId: "1:55095221706:web:6814de188d7e90320e0333"
  },

  // base_url:"https://cors-anywhere.herokuapp.com/https://sales-sky.com/sales_apis/public/"
  // base_url:"https://cors-anywhere.herokuapp.com/https://api.qpedia.com/public",
  base_url:"https://api.qpedia.com/public"


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
