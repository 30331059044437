import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';



@Injectable({
  providedIn: 'root'
})
export class PlacesService {

  constructor(private httpClientService: HttpClientService) { }

  GetPlacesList(id,currentPage) {

    let body={
      "is_public":1,
    }
    return this.httpClientService.post('api/cms', body,{category_value_id: id, page:currentPage});
  }

  getAllPlaces(){
    let body={
      "is_public":1,
    }
    return this.httpClientService.post('api/cms', body,{});
  }

  GetPlace(id){
    let body={}
    return this.httpClientService.post('api/cms/show', body,{id: id});
  }

  GetKeywordList(){
    let body={}
    return this.httpClientService.post('api/contact_keywords', body,{});
  }

  GetPlacesListByCity(id,cat_id,currentPage){
    let body={
      "is_public":1,
      "category_value_id":cat_id,
      "city_id": id
    }
    return this.httpClientService.post('api/cms', body,{  page:currentPage});
  }

  GetPlacesbyCity(id){
    let body={
      "is_public":1,
    }
    return this.httpClientService.post('api/cms', body,{city_id: id});
  }

  GetPlacesListByTag(currentPage,listArray,cat_id){
    let body={
      "is_public":1,
      "category_value_id":cat_id,
      "keywords":listArray
    }
    console.log("body is :-",body);
    
    return this.httpClientService.post('api/cms', body,{page:currentPage});
  }


  createPlace(placeData) {
    let body={
    }
    return this.httpClientService.post('api/cms/store', placeData, body)
  }

  updatePlace(placeData) {
    return this.httpClientService.post('api/cms/update', placeData,[]);
  }

  getPlaceProjects(id: number, page){
    let body = {
      "cms_id": id
    }
    return this.httpClientService.post('api/contacts_projects', body, {'page': page});
  }

  getPlaceProducts(id: number, page){
    let body = {
      "contact_id": id
    }
    return this.httpClientService.post('api/contacts_products', body, {'page': page});
  }

  getPlaceServices(id: number, page){
    let body = {
      "contact_id": id
    }
    return this.httpClientService.post('api/contacts_services', body, {'page': page});
  }

  getIndividualRequests(id: number,page){
    let body = {
      "contact_id": id
    }
    return this.httpClientService.post('api/items', body, {'page': page});
  }

  getOffersRequests(id: number,page){
    let body = {
      "contact_id": id
    }
    return this.httpClientService.post('api/contacts_question', body, {'page': page});
  }

  getVerifiedPlaces(currentPage,verified,cat_id){
    let body={
      "is_public":1,
      "category_value_id":cat_id,
      "verified":verified
    }

    return this.httpClientService.post('api/cms', body,{page:currentPage});
  }

 getLatestProjects(){
  let body = {
    "pagination": 12
  }
  return this.httpClientService.post('api/contacts_projects', body, {});
 }

 getLatestProducts(){
  let body = {
    "pagination": 12
  }
  return this.httpClientService.post('api/contacts_products', body, {});
 }

 getLatestServices(){
  let body = {
    "pagination": 12
  }
  return this.httpClientService.post('api/contacts_services', body, {});
 }
 
 getLatestJobs(){
  let body = {
    "pagination": 12
  }
  return this.httpClientService.post('api/company_jobs/all', body, {});
 }


 SendNumberOfClicks(PlaceID){
  return this.httpClientService.post('api/cms/count_views', PlaceID, {})
 }


 GetStatistics(PlaceID){
  let body = {
    "contact_id": PlaceID
  }
  return this.httpClientService.post('api/cms/statistics', body, {})
 }



}
