
<!-- Start Page Header -->
<div class="container-fluid pg-header" style="background-image: url(./assets/images/polygons.jpg);">
    <div class="pg-header-content">
      <h1 class="pg-title">
       {{pivacytitle}}
      </h1>
      <h4 class="pg-subtitle">
      <!-- Privacy Policy Subtitle -->
      </h4>
    </div>
    <!-- <div class="pg-header-icon icon-bag-dollar"></div> -->
  </div>
  <!-- End Page Header -->
  <!-- Start Page Intro -->
  <div class="container page-intro">
      {{'privacy_description'| translate}}
  </div>
  <!-- End Page Intro -->
  <!-- Start Page Content -->
  <div class="container pg-content">
    <!-- Start pricing table -->
    <div class="row ">
      <p>{{privacycontent}}
     </p>
    </div>
    <!-- End pricing table items -->
  </div>
  <!-- End Page Content -->

