<div class="container-fluid pg-header" style="background-image: url(././assets/images/11.jpg);">
    <div class="pg-header-content">
      <h1 class="pg-title">
        {{'Home.servicess' | translate}}
      </h1>
      <h3 class="services-desc">{{'Find our services near you'|translate}}</h3> 
      <p class="services-desc2">{{'Qpedia provides a variety of services to individuals and companies'|translate}}</p>  
    </div>
    <!-- <div class="pg-header-icon">
      <i class="fa fa-briefcase"></i>
    </div> -->
  </div>

  <!-- Start Listings Container  -->
<div class="container-fluid listing-block listings-has-sidebar">

    <div class="search">
      <form [formGroup]="FilterForm">

        <div class="row">

          <div class="col-md-6 col-sm-12 col-xs-12 pull-left" style=" margin-bottom: 20px !important; ">
              <input type="text" class="form-control"  formControlName="title" placeholder="{{'Search for Service'|translate}}"/>

              <!-- <ul *ngIf="(searchedProduct.length > 0 || searchedProduct.length != null) && showMenu"
                  id="search_id2" class="list-group">
                  <a *ngFor="let product of searchedProduct"
                      class="list-group-item text-left">
                      <img width="40px" height="40px"
                          [src]="product?.image" [alt]="product?.name">
                      <span class="search-text">
                          {{ product.name }}
                      </span>
                  </a>
              </ul> -->
          </div>

          <div class="col-md-4 col-sm-12 col-xs-12 pull-left" style=" margin-bottom: 20px !important; ">
              <!-- <input type="text" class="form-control" [(ngModel)]="price"
              (ngModelChange)="filterByPrice($event)"
              on-focusout="menuHide($event)" on-focus="menuShow()" placeholder="{{'Service Price'|translate}}"/> -->

              <select class="form-control" formControlName="price">
                <option *ngFor="let item of services" value="{{item.price}}"> {{item.price}} {{"L.E"|translate}}</option>
              </select>

          </div>


          <div class="col-md-2 col-sm-12 col-xs-12 pull-left" style=" margin-bottom: 20px !important; ">
            <button class="search-btn" (click)="FilterServices()">{{'search'|translate}}</button>

          </div>

      </div>

      </form>
        
    </div>

    <div class=" listing grid2 no-addr-on-mobile white">
  
  
      <!-- Start Listing -->
    
  
  
        <!-- Start Listing Item Col -->
        <div class="row">
          <div class="col-md-3 col-sm-12 col-xs-12 pull-left" style="margin-bottom: 20px !important" *ngFor="let item of services | paginate: { itemsPerPage: 10, currentPage: p }">

            <div class="card" style="cursor:pointer;" (click)="navigateplacedetails(item)">

              <img [alt]="item.name" [src]="item.image" loading="lazy" />
              <h2>{{item.name}}</h2>
              <p>{{item.description}}</p>
              <p class="blue-paragraph">{{'price' | translate}}: {{item.price}}  {{"L.E"|translate}}</p>

            </div>

              <!-- <div class="listing-item" style="cursor:pointer; width:100%">
                
      
                <div class="listing-item-link">
                  <img [alt]="item.name" [src]="item.image" loading="lazy" />
                  <div class="listing-item-title-centralizer">
                    <div class="listing-item-title">
                      {{item.name}}
                    </div>
                    
                    <div class="listing-item-data">  
                      
                      <div class="listing-item-excerpt">
                          
                        <p>{{'description' | translate}}: {{item.description}}</p>
                        <p>{{'price' | translate}}: {{item.price}}</p>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

               <!-- <p *ngIf="item.salary">{{'salary' | translate}}: {{item.salary}} {{'currency' | translate}}</p>
                        <p *ngIf="item.notes">{{'notes' | translate}}: {{item.notes}}</p> -->


          </div>
       
         <div *ngIf="services.length == 0" class="col-md-12 text-center">{{'NoServices' | translate}}</div>

        </div>
        <!-- End Listing Item Col -->
        <div class="row text-center" *ngIf="services.length > 0">
          <div class="col-sm-12">
          <pagination-controls (pageChange)="p = $event" previousLabel="{{'previous'|translate}}"
            nextLabel="{{'next'|translate}}">
          </pagination-controls>
        </div>
      </div>
      <!-- End Listing -->
      <div class="col-md-1"></div>
    </div>
  </div>
  <!-- End Listings Container  -->