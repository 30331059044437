<section>
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-sm-2 col-xs-1"></div>
      <div class="col-md-6 col-sm-8 col-xs-10">
        <div class="card">
          <form
            id="loginForm"
            #myform="ngForm"
            action="#"
            method="post"
            [formGroup]="loginForm"
          >
            <!-- <div class="row">
                                <div class="col-md-6">
                                <div class="form-check">
                                    <input class="form-check-input" value="1" (change)="onLoginItemChange($event.target.value)" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        {{'User Login' | translate}}
                                    </label>
                                </div>
                                </div>
                                <div class="col-md-6">

                                <div class="form-check">
                                    <input class="form-check-input" value="3" (change)="onLoginItemChange($event.target.value)" type="radio" name="flexRadioDefault" id="flexRadioDefault22" checked>
                                    <label class="form-check-label" for="flexRadioDefault22">
                                        {{'Company Login' | translate}}
                                    </label>
                                </div>
                                </div>
                            </div> -->
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <h2 class="login-titl">{{ "Login" | translate }}</h2>
                <p class="register-label">
                  {{ "New in Qpedia ?" | translate
                  }}<span
                    ><a
                      class="register-link"
                      [routerLink]="['../../register', lang]"
                      >{{ "Register new account" | translate }}</a
                    ></span
                  >
                </p>
              </div>
            </div>

            <div class="row" style="margin-bottom: 20px">
              <div class="col-md-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    value="1"
                    (change)="onLoginItemChange($event.target.value)"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                  />
                  <label
                    class="form-check-label"
                    for="flexRadioDefault2"
                    style="margin: 0px 5px"
                  >
                    {{ "User Login" | translate }}
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    value="3"
                    (change)="onLoginItemChange($event.target.value)"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault22"
                    checked
                  />
                  <label
                    class="form-check-label"
                    for="flexRadioDefault22"
                    style="margin: 0px 5px"
                  >
                    {{ "Company Login" | translate }}
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <!-- <div class="wrapper"> -->
                <label>{{ "Mobile" | translate }}</label
                ><br />
                <ngx-intl-tel-input
                  [cssClass]="'col-md-12 form-control'"
                  [preferredCountries]="preferredCountries"
                  [searchCountryFlag]="true"
                  [customPlaceholder]="
                    currentlang == 'en'
                      ? 'Please Enter Your Phone Number'
                      : 'من فضلك قم بادخال رقم الهاتف'
                  "
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.Egypt"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  [separateDialCode]="separateDialCode"
                  [numberFormat]="PhoneNumberFormat.International"
                  name="phone"
                  formControlName="mobile"
                >
                </ngx-intl-tel-input>
                <!-- </div> -->
                <!-- <pre>{{ myform.form.controls['mobile'].errors | json }}</pre> -->
                <span
                  *ngIf="
                    loginForm.controls['mobile']?.errors?.required &&
                    myform.submitted
                  "
                  class="error-msg"
                >
                  {{ "phoneReqMes" | translate }}
                </span>
                <span
                  *ngIf="
                    (loginForm.controls['mobile']?.value?.number?.length < 10 ||
                      loginForm.controls['mobile']?.value?.number?.length >
                        11) &&
                    loginForm.controls['mobile']?.value?.dialCode == '+20' &&
                    myform.submitted
                  "
                  class="error-msg"
                >
                  {{ "phonePatMes" | translate }}.
                </span>
                <span
                  *ngIf="
                    loginForm.controls['mobile']?.value?.number?.length != 8 &&
                    loginForm.controls['mobile']?.value?.dialCode == '+966' &&
                    myform.submitted
                  "
                  class="error-msg"
                >
                  {{ "phonePatMes" | translate }}.
                </span>
              </div>
            </div>

            <div class="form-group">
              <label class="password-label">{{ "Password" | translate }}</label>
              <input
                type="password"
                name="password"
                formControlName="password"
                id="login-password"
                tabindex="2"
                class="form-control"
                placeholder="{{ 'Password' | translate }}"
              />

              <span
                *ngIf="
                  loginForm.controls['password']?.errors?.required &&
                  myform.submitted
                "
                class="error-msg"
              >
                {{ "passwordReqMes" | translate }}
              </span>
              <span
                *ngIf="
                  loginForm.controls['password']?.errors?.minlength  ||
                  loginForm.controls['password']?.errors?.maxlength  &&
                  myform.submitted
                "
                class="error-msg"
              >
                {{ "passwordMinLengMes" | translate }}.
              </span>
            </div>

            <div style="display: flex; margin: 30px 0px 0px 0px">
              <div class="form-group" style="width: 50%">
                <input
                  type="checkbox"
                  tabindex="3"
                  class=""
                  name="remember"
                  id="remember"
                  (click)="RememberMe($event)"
                />
                <label for="remember" class="remember_label">{{
                  "RememberMe" | translate
                }}</label>
              </div>

              <div class="form-group" style="width: 50%">
                <a
                  [routerLink]="['../../forgot-password', currentlang]"
                  tabindex="5"
                  class="login-forgot-password"
                  >{{ "ForgotPassword" | translate }}</a
                >
              </div>
            </div>

            <div class="form-group">
              <!-- <input type="submit" name="login-submit" id="login-submit" tabindex="4" class="form-control btn btn-submit" value="Log In"> -->
              <button
                (click)="login()"
                class="form-control btn btn-submit login-btn"
              >
                <i *ngIf="isEnabled" class="fa fa-spinner fa-spin"></i>
                {{ "Home.login" | translate }}
              </button>
            </div>

            <!-- <div class="form-group">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="text-center">
                                            <a [routerLink]="['../../Forgot-Password', currentlang]" tabindex="5" class="login-forgot-password">{{'ForgotPassword'|translate}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
          </form>
        </div>
      </div>
      <div class="col-md-3 col-sm-2 col-xs-1"></div>
    </div>
  </div>

  <div id="captcha"></div>
</section>
