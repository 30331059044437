<!-- Start Trending -->
<div class="container-fluid trending">
    <div class="row row-title">
      <h1>Trending Right Now</h1>
      <h4>The most quoted recently</h4>
    </div>
    <div class="row listing squared">
      <!-- Start Trending Item Col -->
      <div class="col-sm-3">
        <div class="listing-item">
          <a href="#" class="category-icon icon-teacup"></a>
          <div class="listing-item-rating">4.2</div>
          <a href="#" class="listing-item-link">
            <div class="listing-item-title-centralizer">
              <div class="listing-item-title">
                Sightglass Coffee
                <div class="ribbon">~ $20-$50</div>
              </div>
            </div>
            <img alt="" src="assets/images/trending/2.jpg" />
          </a>
          <div class="listing-item-data">
            <a class="listing-item-address" href="#">
              3014 20th St San Francisco
            </a>
            <div class="listing-item-excerpt">
              The love of coffee shines all the woody ambience
            </div>
          </div>
          <div class="listing-category-name">
            <a href="#">Cafeteria</a>
          </div>
        </div>
      </div>
      <!-- End Trending Item Col -->
      <!-- Start Trending Item Col -->
      <div class="col-sm-3">
        <div class="listing-item">
          <a href="#" class="category-icon icon-library2"></a>
          <div class="listing-item-rating">4.9</div>
          <a href="#" class="listing-item-link">
            <div class="listing-item-title-centralizer">
              <div class="listing-item-title">
                N.O.M.A.
                <div class="ribbon">Admission $12</div>
              </div>
            </div>
            <img alt="" src="assets/images/trending/3.jpg" />
          </a>
          <div class="listing-item-data">
            <a class="listing-item-address" href="#">
              City Park, New Orleans, Louisiana 70124
            </a>
            <div class="listing-item-excerpt">
              The New Orleans Museum of Art
            </div>
          </div>
          <div class="listing-category-name">
            <a href="#">Museum</a>
          </div>
        </div>
      </div>
      <!-- End Trending Item Col -->
      <!-- Start Trending Item Col -->
      <div class="col-sm-3">
        <div class="listing-item">
          <a href="#" class="category-icon icon-lotus"></a>
          <div class="listing-item-rating">4.7</div>
          <a href="#" class="listing-item-link">
            <div class="listing-item-title-centralizer">
              <div class="listing-item-title">
                Mead Botanical Garden
                <div class="ribbon">Free Entrance</div>
              </div>
            </div>
            <img alt="" src="assets/images/trending/1.jpg" />
          </a>
          <div class="listing-item-data">
            <a class="listing-item-address" href="#">
              1500 S. Denning Drive Winter Park, FL 32789
            </a>
            <div class="listing-item-excerpt">
              48-acre urban oasis located on Orange County
            </div>
          </div>
          <div class="listing-category-name">
            <a href="#">Park and Garden</a>
          </div>
        </div>
      </div>
      <!-- End Trending Item Col -->
      <!-- Start Trending Item Col -->
      <div class="col-sm-3">
        <div class="listing-item">
          <a href="#" class="category-icon icon-glass"></a>
          <div class="listing-item-rating">3.9</div>
          <a href="#" class="listing-item-link">
            <div class="listing-item-title-centralizer">
              <div class="listing-item-title">
                Cave B Estate
                <div class="ribbon">Starting from $49</div>
              </div>
            </div>
            <img alt="" src="assets/images/trending/4.jpg" />
          </a>
          <div class="listing-item-data">
            <a class="listing-item-address" href="#">
              348 Silica Rd NW Quincy, WA 98848
            </a>
            <div class="listing-item-excerpt">
              Fine Wines, Crafted with Artistry and Passion
            </div>
          </div>
          <div class="listing-category-name">
            <a href="#">Winery</a>
          </div>
        </div>
      </div>
      <!-- End Trending Item Col -->
    </div>
  </div>
  <!-- End Trending -->
