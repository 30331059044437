import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-home-services',
  templateUrl: './home-services.component.html',
  styleUrls: ['./home-services.component.scss'],
})
export class HomeServicesComponent implements OnInit {
  lang: string;

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
  }
}
