import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {

  closeResult = '';
  pageSize: number;
  totalPages: number;
  p: number = 1;
  totalapi: number;
  userList: any[] = [];
  currentlang;
  searchForm: FormGroup;
  approvedList = [
    { "id": "0", "name": "موظفين تم ارسال اليهم طلب الانضمام للشركة " },
    { "id": "1", "name": "موظفين تابعين للشركة " },
    { "id": "2", "name": "موظفيين لم يتم الموافقة على طلب الشركة " },
    { "id": "3", "name": "موظفين غير تابعين الشركة " },
  ];
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private service: HttpClientService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentlang = localStorage.getItem('lang');

    this.getUser();
    this.initForm();
  }
  initForm() {
    this.searchForm = new FormGroup({
      full_name: new FormControl(''),
      username: new FormControl(''),
      tele_code: new FormControl(''),
      mobile: new FormControl(''),
      is_approved: new FormControl(''),
      email: new FormControl('')

    }
    )
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {



      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  getUser(page?) {
    let parameters = new HttpParams();
    let pagination = { 'pagination': 10 };

    if (page) {
      parameters = parameters.set('page', page + "");

    }
    this.service.post('api/employees', pagination, parameters).subscribe((res: any) => {
      console.log("user =>", res);
      this.userList = res.content;
      this.totalapi = res.pagination.total;
      this.pageSize = res.pagination.per_page;
      this.p = res.pagination.current_page

    })
  }

  navigateView(item) {
    this.router.navigate(['../view-employee', this.currentlang], {
      queryParams: {
        id: item.id
      }
    })

  }
  navigateUpdate(item) {
    this.router.navigate(['../view-update', this.currentlang], {
      queryParams: {
        id: item.id
      }
    })

  }
  delete(item) {

    Swal.fire({
      title: this.translate.instant('delete_employee'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('delete'),
      cancelButtonText: this.translate.instant('cancel')
    }).then((result) => {
      if (result.value) {
        this.service.post("api/users/destroy", { id: item.id }, {}).subscribe((res: any) => {
          console.log(res);
          if (res.status.code == 200) {
            if ((this.currentlang == 'en')) {
              Swal.fire({
                icon: 'success',
                title: ' ',
                text: 'employee deleted successfully',
              })
            } else if ((this.currentlang == 'ar')) {
              Swal.fire({
                icon: 'success',
                title: ' ',
                text: 'تم ازاله موظف',
              })

            }
            this.getUser();
          }
          else {
            this.toastr.error(res.status.message);
          }

        })
      } else if (result.dismiss === Swal.DismissReason.cancel) { }
    })


  }
  search(formdata) {
    let body = {
      full_name: '',
      username: '',
      tele_code: '',
      mobile: '',
      is_approved: '',
      email: '',



    };
    console.log(formdata);
    this.service.post('api/employees', formdata, {}).subscribe((res: any) => {
      this.modalService.dismissAll();
      console.log("user =>", res);
      this.userList = res.content;


    })

  }
  onPageChange(event) {
    this.p = event;
    this.getUser(this.p + "");
  }
}