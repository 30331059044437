import { Injectable, NgZone } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../Services/authentication.service';
import { Router } from '@angular/router';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  token;
  header: HttpHeaders = new HttpHeaders();
  lang;

  constructor(
    private httpClientService: HttpClientService,
    private Authservice: AuthenticationService,
    private router: Router,
    private httpClient: HttpClient,
    private zone: NgZone,
    private languageService: LanguageService
  ) {
    this.token = localStorage.getItem('token');
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    // console.log("access token service ",this.token);
  }

  handleResponse(response) {
    this.Authservice.setToken(response.token);
    // console.log("handle Response")
    this.router.navigate(['', this.lang]);
  }

  login(userData) {
    let body = {};
    return this.httpClientService.post('api/login_with_email', userData, []);
  }

  viewprofile() {
    let body = {};
    return this.httpClientService.post('api/statistics/profile', body, {});
  }

  registeruser(UserData) {
    return this.httpClientService.post('api/register', UserData, {});
  }

  UpdateProfile(UserData) {
    let body = {
      tele_code: localStorage.getItem('telecode'),
    };
    return this.httpClientService.post('api/profile/update', UserData, {});
  }

  ForgetPassword(UserData) {
    let body = {};
    return this.httpClientService.post(
      'api/forgot_password_email',
      body,
      UserData
    );
  }

  VerifyMobile(UserData) {
    return this.httpClientService.post('api/verify_code', UserData, []);
  }

  Verifycode(UserData) {
    let body = {
      user_id: UserData,
    };
    return this.httpClientService.post('api/verify_mobile', body, []);
  }

  sendMobileVerificationcode(data) {
    return this.httpClientService.post(
      'api/resend_verification_code',
      data,
      []
    );
  }

  SendVerificationCode(UserData) {
    let body = {};
    return this.httpClientService.post(
      'api/send_verification_code_email',
      body,
      UserData
    );
  }

  validateuser(UserData) {
    let body = {};
    return this.httpClientService.post('api/validation', UserData, {});
  }

  getOtp(phoneNumber: string) {
   
   
    
    const recaptchVerifier = new firebase.auth.RecaptchaVerifier('captcha', {
      size: 'invisible',
    });
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, recaptchVerifier)
      .then(
        (result) => {
          console.log('verification Id', result.verificationId);
          localStorage.setItem(
            'verificationId',
            result.verificationId.toString()
          );
          recaptchVerifier.clear();

          this.zone.run(() => {
            this.router.navigate(['../opt-verification/',this.lang]);
            console.log('verification Id 3', result.verificationId);
          });
          // this.router.navigate(['../chat/' + localStorage.getItem('lang')]);
          //  console.log("enter service")
        },
        (error) => {
          recaptchVerifier.clear();
        }
      );
  }
}
