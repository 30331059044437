
<!-- Start Page Content -->
<div class="container-fluid no-padding bg-reset-pass">
    <div class="row">
          <div class="col-sm-12 text-center">
              <div class="panel panel-form">
                  <div class="panel-heading">
                      <div class="row">
                          <div class="col-xs-12">
                              <h4>{{'Password Reset'|translate}}</h4>
                          </div>
                      </div>
                      <hr>
                  </div>
                  <div class="panel-body">
                      <div class="row">
                          <div class="col-lg-12">
                              <form [formGroup]="forgetpasswordForm">
                                    <!-- <div class="form-group">
                                      <input type="text"  formControlName="email" tabindex="1" class="form-control" placeholder=" Email">
                                        <span *ngIf="forgetpasswordForm.controls['email'].touched && forgetpasswordForm.controls['email']?.errors?.required" class="error-msg">
                                            {{'emailReqMes'|translate}}
                                        </span>
                                        <span *ngIf="forgetpasswordForm.controls['email'].touched && forgetpasswordForm.controls['email']?.errors?.email" class="error-msg">
                                            {{'emailmustbe'|translate}}.
                                        </span>
                                    </div> -->
                                    <!-- <div class="form-group">
                                        <input type="number"  formControlName="email_verification_code" tabindex="2" class="form-control" placeholder="Email Verification Code">
                                        <span *ngIf="forgetpasswordForm.controls['email_verification_code'].touched && forgetpasswordForm.controls['email_verification_code']?.errors?.required" class="error-msg">
                                            {{'emailcodeReqMes'|translate}}
                                        </span>
                                    </div> -->
                                    <div class="form-group">
                                        <input type="password" formControlName="new_password"   tabindex="3" class="form-control"  placeholder="{{'Password'|translate}}">
                                        <span *ngIf="forgetpasswordForm.controls['new_password'].touched && forgetpasswordForm.controls['new_password']?.errors?.required" class="error-msg">
                                            {{'passwordReqMes'|translate}}
                                        </span>
                                        <span *ngIf="forgetpasswordForm.controls['new_password'].touched && forgetpasswordForm.controls['new_password']?.errors?.minlength" class="error-msg">
                                            {{'passwordMinLengMes'|translate}}.
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <input type="password"  formControlName="confirm_new_password" tabindex="4" class="form-control"  placeholder="{{'repeatPassword'|translate}}">
                                        <span *ngIf="forgetpasswordForm.controls['confirm_new_password'].touched && forgetpasswordForm.controls['confirm_new_password']?.errors?.required"
                                            class="error-msg">
                                            {{'repeatPasswordReqMes'|translate}}
                                        </span>
                                        <span *ngIf="forgetpasswordForm.controls['confirm_new_password'].touched && forgetpasswordForm.controls['confirm_new_password']?.errors?.minlength"
                                            class="error-msg">
                                            {{'repeatPasswordMinLengMes'|translate}}.
                                        </span>
                                    </div>
                                  <div class="form-group">
                                      <!-- <input type="submit" name="password-submit" id="password-submit" tabindex="5" class="form-control btn btn-submit" > -->
                                      <button  (click)="ForgetPassword()"
                                       class="form-control btn btn-submit">{{'Submit'|translate}}</button>
                                    </div>
                                  <!-- <div class="form-group">
                                      <div class="row">
                                          <div class="col-lg-12">
                                              <div class="text-center">
                                                  <a routerLink="../Login/Register" tabindex="5">{{'Home.login'| translate}}</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div> -->
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
<!-- End Page Content -->
