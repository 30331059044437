<div class="col-sm-12">
    <div class="listing-item">
      <a href="#" class="category-icon icon-dinner"></a>
      <a href="#" class="listing-item-link">
        <img alt="" src="assets/images/listings/1.jpg" />
        <div class="listing-item-rating">4.9</div>
        <div class="listing-item-title-centralizer">
          <div class="listing-item-title">
            Area Four
          </div>
          <div class="feat-data">
            <div class="ribbon">~ $18 - $90</div>
          </div>
          <div class="listing-item-data">
            <div class="listing-item-address" href="#">
              Troy South End, 264 E. Berkley St, Boston
            </div>
            <div class="listing-item-excerpt">
              Excellent wood-fired pizzas, salads, and croissants
            </div>
            <div class="amenities">
              <span class="icon-credit-card">Accepts Credit Cards</span>
              <span class="icon-pipe">Smoking Allowed</span>
              <span class="icon-parking">Parking</span>
              <span class="icon-bicycle">Bike Parking</span>
              <span class="icon-accessibility">Special</span>
              <span class="icon-alarm-ringing">Balcony</span>
            </div>
          </div>
        </div>
        <div class="listing-category-name">
          Restaurant
        </div>
      </a>
    </div>
  </div>