<div class="container-fluid pg-header" style="background-image: url(././assets/images/2.jpg);">
    <div class="pg-header-content">
      <div class="row">
        <div class="col-md-1 col-sm-1 col-xs-12"></div>
        <div class="col-md-10 col-sm-10 col-xs-12">

          <h1 class="fun">
            {{'Services' | translate}}
          </h1>
          <h4 class="fun2">
            {{'Corporate Services'|translate}}
          </h4>

        </div>
        <div class="col-md-1 col-sm-1 col-xs-12"></div>
      </div>

    </div>
    <!-- <div class="pg-header-icon">
        <i class="fas fa-tools"></i>
    </div> -->
</div>

<div class="container-fluid" style="margin-top:30px">
    <app-flipcards4></app-flipcards4>
</div>

<div class="container-fluid" style="margin-top:70px">
  <app-slider-paragraph></app-slider-paragraph>
</div>

<div class="container-fluid" style="margin-top:50px">
     <app-clients-slider></app-clients-slider>
</div>

<div class="container-fluid" style="margin-top:50px;background-color: rgb(234 232 246);padding: 60px;">
       <app-flipcards3></app-flipcards3>
</div>
<div class="container-fluid" *ngIf="!authService.getToken()">
  <app-corporate-login></app-corporate-login>
</div>