import { Component, OnInit } from '@angular/core';
import { PlacesService } from 'src/app/Shared/Services/places.service';

@Component({
  selector: 'app-recents',
  templateUrl: './recents.component.html',
  styleUrls: ['./recents.component.scss']
})
export class RecentsComponent implements OnInit {

  projects:any[] = [];
  products:any[] = [];
  services:any[] = [];
  jobs:any[] = [];
  constructor(
    private placeservice:PlacesService,
  ) { }

  ngOnInit(): void {
    this.getPlaceProducts();
    this.getPlaceProjects();
    this.getPlaceServices();
    this.getLatestJobs();
  }

  getPlaceProjects(){
    this.placeservice.getLatestProjects().subscribe(
      res=>{
        this.projects = res['content'];
      }
    );
  }

  getPlaceProducts(){
    this.placeservice.getLatestProducts().subscribe(
      res=>{
        this.products = res['content'];
      }
    );
  }

  getPlaceServices(){
    this.placeservice.getLatestServices().subscribe(
      res=>{
        this.services = res['content'];
      }
    );
  }

  getLatestJobs(){
    this.placeservice.getLatestJobs().subscribe(
      res=>{
        this.jobs = res['content']
      }
    );
  }

}
