import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PagesService} from '../../Shared/Services/pages.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
   PricingData;

  constructor(private pageservice:PagesService, private translateservice:TranslateService) { }

  ngOnInit(): void {
    this.getPricingList();

  }

  getPricingList(){
    this.pageservice.GetPricing().subscribe((res:any)=>{
      this.PricingData = res.content;
    })
  }

}
