import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CitiesService } from 'src/app/Shared/Services/cities.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {
  lang: string;
  services: any[] = [];
  page = 1;
  nextPage: number;
  config;
  totalCount: number;
  perPage: number;
  p: number = 1;
  lang_id: any;
  searchedService: any[] = [];
  query: string;
  searchSubscription: any;
  showMenu: boolean = false;

  closeResult = '';
  service_array;

  itemForm: FormGroup;
  isEnabled: boolean = false;

  displayResponsive: boolean = false;
  allSelected: boolean = false;
  selectedItems = [];
  selected: any[] = [];

  categoryId: number;
  dropdownSettings = {};
  citieslist: any[] = [];

  show: boolean = true;
  result: any[] = [];

  content;

  constructor(
    private cityservices: CitiesService,
    private router: Router,
    private translateservice: TranslateService,
    private service: HttpClientService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private cityservice: CitiesService,
    private languageService: LanguageService,
    private ngModel: NgbModal
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });

    if (this.lang == 'en') {
      this.lang_id = 1;
      this.getServices(1, 1);
    } else {
      this.lang_id = 2;
      this.getServices(1, 2);
    }

    this.config = {
      id: 'placesID',
      itemsPerPage: this.perPage,
      currentPage: this.page,
      totalItems: this.totalCount,
    };

    this.itemForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      category_id: new FormControl(''),
      cities: new FormControl(''),
      all_cities_included: new FormControl(false),
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.getcitieslist();
  }

  getServices(page: number, lang_id) {
    this.cityservices.getServices(page, lang_id).subscribe(
      (res: any) => {
        this.services = res['content'];
        this.totalCount = res['pagination'].total;
        this.perPage = res['pagination'].per_page;
        this.p = res['pagination'].current_page;
        this.updateConfig();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  gotoAddService(id: number, servicename) {
    this.router.navigate(['add-item', this.lang], {
      queryParams: { id: id, name: servicename },
    });
  }

  openService(content, service, id: number) {
    // this.displayResponsive = true;

    this.categoryId = id;
    this.service_array = service;

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  pageChanged(pageeventnumber: any): void {
    this.page = pageeventnumber;
    console.log('pagination event', pageeventnumber);
    this.nextPage = pageeventnumber;
    this.getServices(this.page, this.lang_id);
  }

  updateConfig() {
    this.config = {
      id: 'placesID',
      itemsPerPage: this.perPage,
      currentPage: this.page,
      totalItems: this.totalCount,
    };
  }

  search(query) {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (query) {
      this.service.get('api/services', { name: query }, {}).subscribe((res) => {
        console.log('RES', res);
        this.searchedService = res['content'];
      });
    } else {
      this.searchedService = [];
      // return;
    }
  }

  menuHide(event) {
    this.showMenu = false;
  }

  menuShow() {
    this.showMenu = true;
  }

  getcitieslist() {
    this.cityservice.GetCityList().subscribe((res: any) => {
      this.citieslist = res.content;
      console.log('city list :::::::-', this.citieslist);
    });
  }

  search2(query: string) {
    if (query == '') {
      this.show = true;
      this.query = '';
    } else {
      if (query?.length >= 3) {
        this.service
          .get('api/items', { name: query, lang_id: this.lang_id }, {})
          .subscribe((res) => {
            console.log('The Response:- ', res);
            this.result = res['content'];
          });
      }
    }
  }

  setName(name: string) {
    this.show = false;
    this.query = name;
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
    let arr: any[] = [];
    this.selectedItems.forEach((ele) => {
      this.citieslist.forEach((element) => {
        if (element.id == ele.id) {
          // this.selected.push(element);
          // console.log('match', element);
          arr.push(element.id);
        }
      });
    });

    this.selected = arr;
    console.log('selected', this.selected);
  }

  onSelectAll(items: any) {
    console.log(items);
    this.allSelected = true;
  }

  create(value) {
    this.isEnabled = true;
    if (this.itemForm.valid) {
      value.category_id = this.categoryId;

      value.cities = this.selected;
      value.all_cities_included = this.allSelected;
      console.log(value);
      this.service.post('api/items/store', value, []).subscribe(
        (res: any) => {
          this.isEnabled = false;
          console.log(res);
          if (res.status.code == 400) {
            if (this.lang == 'en') {
              // this.toastr.error('Please Login and try again')

              Swal.fire({
                icon: 'error',
                title: ' ',
                text: 'Please Login and try again',
              });
            } else {
              // this.toastr.error('من فضلك قم بتسجيل الدخول وحاول مرة أخرى')
              Swal.fire({
                icon: 'error',
                title: ' ',
                text: 'من فضلك قم بتسجيل الدخول وحاول مرة أخرى',
              });
            }
          } else if (res.status.code == 200) {
            if (this.lang == 'en') {
              // this.toastr.success('Request Created Successfully')
              Swal.fire({
                icon: 'success',
                title: ' ',
                text: 'The request was  created successfully',
              });
            } else {
              // this.toastr.success('تم انشاء الطلب بنجاح')
              Swal.fire({
                icon: 'success',
                title: ' ',
                text: 'تم الطلب بنجاح',
              });
            }

            this.ngModel.dismissAll();
            this.router.navigateByUrl('');
          } else if (res.status.code == 401) {
            // this.toastr.error(res.status.error_details)

            let validation_errors = res.status.validation_errors;
            validation_errors.forEach((element) => {
              if (element.field == 'cities') {
                this.toastr.error(element.message);
              } else if (element.field == 'all_cities_included') {
                this.toastr.error(element.message);
              } else if (element.field == 'category_id') {
                this.toastr.error(element.message);
              } else if (element.field == 'description') {
                this.toastr.error(element.message);
              } else if (element.field == 'title') {
                this.toastr.error(element.message);
              } else if (element.field == 'name') {
                this.toastr.error(element.message);
              }
            });
          } else if (
            res.status.code == 401 &&
            res.status.validation_errors.length > 0
          ) {
            this.toastr.error(res.status.error_details);
          } else if (res.status.code == 403) {
            let validation_errors = res.status.validation_errors;
            validation_errors.forEach((element) => {
              if (element.field == 'cities') {
                this.toastr.error(element.message);
              } else if (element.field == 'all_cities_included') {
                this.toastr.error(element.message);
              } else if (element.field == 'category_id') {
                this.toastr.error(element.message);
              } else if (element.field == 'description') {
                this.toastr.error(element.message);
              } else if (element.field == 'title') {
                this.toastr.error(element.message);
              } else if (element.field == 'name') {
                this.toastr.error(element.message);
              }
            });
          }
        },
        (error) => {
          console.log(error);
          let validation_errors = error.error.status.validation_errors;
          validation_errors.forEach((element) => {
            if (element.field == 'cities') {
              this.toastr.error(element.message);
            } else if (element.field == 'all_cities_included') {
              this.toastr.error(element.message);
            } else if (element.field == 'category_id') {
              this.toastr.error(element.message);
            } else if (element.field == 'description') {
              this.toastr.error(element.message);
            } else if (element.field == 'title') {
              this.toastr.error(element.message);
            } else if (element.field == 'name') {
              this.toastr.error(element.message);
            }
          });
          this.isEnabled = false;
        }
      );
    } else {
      this.isEnabled = false;
    }
  }
}
