import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from '../../Shared/Services/home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from 'src/app/Shared/Services/language.service';



@Component({
  selector: 'app-home-recent-places',
  templateUrl: './home-recent-places.component.html',
  styleUrls: ['./home-recent-places.component.scss']
})
export class HomeRecentPlacesComponent implements OnInit {

  TrendingData;
  // @Input() trendings: trendings;
  lang;


  constructor(
    private homeservice: HomeService,
    private translate: TranslateService,
    private router: Router,
    private languageService: LanguageService,
  ) { }

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });

    this.homeservice.GetTrending().subscribe((res: any) => {
      this.TrendingData = res.content;
    })

  }

  navigateplace(item) {
    this.router.navigate(['../place-details', this.lang], {
      queryParams: {
        id: item.id,
      },
    });

  }

}
