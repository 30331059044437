import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-company-jobs',
  templateUrl: './company-jobs.component.html',
  styleUrls: ['./company-jobs.component.scss']
})
export class CompanyJobsComponent implements OnInit {


  // pageSize: number;
  // totalPages: number;
  totalapi: number;
  jobsList:any[]=[];
  currentlang;
  arrDelete: any[] = [];

  page_no : number=1;
  p: number = 1;

  constructor(
    private toastr:ToastrService,
    private router:Router,
    private modalService: NgbModal,
    private service:HttpClientService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentlang = localStorage.getItem('lang');

    this.getJobs();

  }

  getJobs(page?)
  {
    let parameters = new HttpParams();
    let pagination={'pagination':10};

      if(page){
        parameters= parameters.set('page',page+"");

      }
      // this.jobsList=[
      //   {id:1,name:"Pentavalue Soft",title:"accountant",desc:"developer application",salary:"2500"},
      //   {id:2,name:"Pentavalue Soft",title:"accountant 1",desc:"developer application 1",salary:"5000"},
      //   {id:3,name:"Pentavalue Soft",title:"accountant 2",desc:"developer application 2",salary:"4500"}
      // ]
    this.service.post('api/company_jobs',pagination,parameters).subscribe((res:any)=>
    {
      console.log("Jobs =>",res);
      this.jobsList=res.content;
      // this.totalapi = res.pagination?.total;
      // this.pageSize = res.pagination?.per_page;
      // this.p = res.pagination?.current_page

    })
  }

  navigateView(item) {
    this.router.navigate(['../view-job', this.currentlang], {
      queryParams: {
        id: item.id
      }
    })

  }
  editTask(id: number){
    this.router.navigate(['update-job', id, this.currentlang]);
  }

  //   onPageChange(event){
  //     this.p=event;
  //     this.getJobs(this.p+"");
  // }

  deleteSelected()
  {
    Swal.fire({
      title: this.translate.instant('delete_job'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('delete'),
      cancelButtonText: this.translate.instant('cancel')
    }).then((result) => {

      if (result.value) {
        let obj = {ids:this.arrDelete};
        console.log('Objj', obj)
        this.service.post('api/company_jobs/destroyall', obj, []).subscribe(
          res=>{
            this.getJobs();
            Swal.fire({
              icon: 'success',
              title: this.translate.instant('The jobs deleted successfully !'),
              showConfirmButton: false,
              timer: 1500

            });
          }
        );


      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })
  }

  deleteJob(id: number) {
    let obj = {"id" : id};

    Swal.fire({
      title: this.translate.instant('delete_job'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('delete'),
      cancelButtonText: this.translate.instant('cancel')
    }).then((result) => {
      if (result.value) {
        this.service.post('api/company_jobs/destroy', obj, []).subscribe(
          res=>{
            this.getJobs();

            Swal.fire({
              icon: 'success',
              title: this.translate.instant('The jobs deleted successfully !'),
              showConfirmButton: false,
              timer: 1500

            });
          },
          error=>{
            console.log(error);
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {}
    })

  }

  addToDelete(id: number) {
    let index = this.arrDelete.indexOf(id);
    console.log(index)
    if(index > -1){
      this.arrDelete.splice(index, 1);
    }else{
      this.arrDelete.push(id);
    }

    console.log(this.arrDelete);
  }
}




