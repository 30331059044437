<div class="container-fluid pg-header" style="background-image: url(././assets/images/11.jpg);">
  <div class="pg-header-content">
    <h1 class="pg-title">
      {{'Home.categories' | translate}}
    </h1>
    <h4 class="pg-subtitle">
      {{'cat_title' | translate}}
    </h4>
  </div>
  <!-- <div class="pg-header-icon">
    <i class="fas fa-globe-asia"></i>
  </div> -->
</div>

<!-- Start Listings Container  -->
<div class="container-fluid listing-block listings-has-sidebar">
  <div class=" listing grid2 no-addr-on-mobile white">


    <!-- Start Listing -->
  


      <!-- Start Listing Item Col -->
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-4 col-xs-12" *ngFor="let item of CategoriesData | paginate: { itemsPerPage: 10, currentPage: p }">
              <!-- <div class="listing-item">
                <i class="category-icon">
                  {{item.contacts_count}}
                </i>
      
                <a class="listing-item-link" (click)="navigateplaces(item)">
                  <img [alt]="item.title" [src]="item.image" loading="lazy" />
                  <div class="listing-item-title-centralizer">
                    <div class="listing-item-title">
                      {{item.title}}
                    </div>
                    
                    <div class="listing-item-data">
                      
                      <div class="listing-item-excerpt">
                        {{item.content}}
                        {{item.description}}
                      </div>
                    </div>
                  </div>
                  
                </a>
              </div> -->
    
              <div  class="card" style="cursor:pointer" (click)="navigateplaces(item)">
                <img [alt]="item.title" [src]="item.image" loading="lazy" />
                

                <div class="card-desc">
                  <h2>{{item.title}}</h2>
                </div>

                <!-- <p>
                   {{item.content}}
                   {{item.description}} 
                  <span>( {{item.contacts_count}} ) </span>
                </p> -->
    
              </div>
    
    
    
          </div>
         
    
          </div>
      </div>
      
      <!-- End Listing Item Col -->

      <div class="row text-center">
        <div class="col-sm-12">
        <pagination-controls (pageChange)="p = $event" previousLabel="{{'previous'|translate}}"
          nextLabel="{{'next'|translate}}">
        </pagination-controls>
      </div>

    </div>
    <!-- End Listing -->

    <div class="col-md-1"></div>


  </div>
  <!-- <div class="more-listing icon-plus" title="More Listings" data-toggle="tooltip" data-placement="top"></div> -->
</div>
<!-- End Listings Container  -->