import { Component, OnInit } from '@angular/core';




@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
   
  }

  // search(query: string) {
  //   if(query) {
  //     if(query.length>=3){
  //       if(this.searchSubscription) {
  //         this.searchSubscription.unsubscribe();
  //       }
  //       this.homeservice.searchPlaces(query).subscribe((res:any) => {
  //         this.searchRes = res['content'];
  //         console.log("Search Results=========>",this.searchRes);
  //         if(this.searchRes.length == 0){
  //           this.isVisible = false;
  //         }
  //       });
  //     }else{
  //       this.isVisible = true;
  //     }

  //   }
  //    else {
  //      this.isVisible = true;
  //   }
  // }

  // hide(){
  //   this.isVisible = true;
  // }

  // MenuHide(){
  //   document.getElementById("search_id2").classList.toggle("hide_div");
  // }

  // navigateplaces(item){
  //   this.router.navigate(['Categories/Places', this.currentlang], {
  //     queryParams: {
  //       category_value_id: item.id
  //     }
  //   })

  // }

  // navigateplace(item){
  //   this.router.navigate(['../Place-Details', this.currentlang], {
  //     queryParams: {
  //       id: item.id
  //     }
  //   })

  // }
  // showSection(){
  //   this.display = true;
  // }

  // status: boolean = false;
  // clickEvent(){
  //     this.status = !this.status;       
  // }

}
