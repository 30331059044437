<section>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-6 col-xs-6 pull-left">
        <h2 class="services-title">{{ "Jobs" | translate }}</h2>
        <h3 class="services-desc">
          {{ "Find the Best Jobs For You" | translate }}
        </h3>
        <p class="services-desc2">
          {{
            "Are you searching for vacancies & career opportunities ? Qpedia will help you"
              | translate
          }}
        </p>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-6 pull-right">
        <a class="red-more-btn" [routerLink]="['../jobs', lang]">{{
          "Read More" | translate
        }}</a>
      </div>
    </div>
    <div class="row">
      <div
        class="col-lg-2 col-md-2 col-sm-12 col-xs-12 pull-left"
        style="margin: 0px"
        *ngFor="let item of jobs | slice : 0 : 5"
      >
        <div class="card" style="cursor: pointer" (click)="navigateplace(item)">
          <img class="places-img" src="{{ item.company.logo }}" />
          <div class="card-desc">
            <h3>{{ item.title }}</h3>
            <h5>{{ item.position.name }}</h5>
            <h5 *ngIf="item.company.address != null">
              {{ "the palce" | translate }} : {{ item.place.address }}
            </h5>
          </div>
        </div>
      </div>

      <div
        class="col-md-12 col-sm-12 col-xs-12"
        style="text-align: center"
        *ngIf="!jobs?.length"
      >
        <img class="no-data-img" src="assets/images/empty-chat2.png" />
        <h2 style="padding-top: 15px">{{ "There is no jobs" | translate }}</h2>
      </div>
    </div>
  </div>
</section>
