import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {

  constructor(private activatedRoute:ActivatedRoute,private service:HttpClientService) { }
  user;
  setDob=[];
  ngOnInit(): void {
    let body={
      id:this.activatedRoute.snapshot.queryParams['id']
    }
    this.service.post('api/users/show',body,{}).subscribe((res:any)=>
      {
      this.user=res.content[0];
        console.log("user =>",this.user);
      this.setDob=this.user.birthdate.split(' ');
      console.log(this.setDob);
      
      });
  }

}
