import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CitiesService } from '../../Shared/Services/cities.service';
import { AuthenticationService } from 'src/app/Shared/Services/authentication.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isNumeric } from 'rxjs/internal-compatibility';
import { HttpParams } from '@angular/common/http';
import { LanguageService } from 'src/app/Shared/Services/language.service';
import { UserService } from 'src/app/Shared/Services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  CitiesData;
  notificationList: any[] = [];
  display: boolean = false;
  display2: boolean = false;
  requestPrice: FormGroup;
  questionaswerform: FormGroup;
  priceNotValid: boolean = false;
  negotiationTilte;
  negotiationDesc;
  item_id;
  counter = 0;
  notification_id;
  isArabic: boolean = false;
  lang: string;
  userType: number;
  hasTasks: number;
  @ViewChild('navbarToggler') navbarToggler: ElementRef;

  question_text;
  question_text_last;
  contact_ID;
  Question_ID;
  Questions;
  Latest_Question: boolean;

  Questions_All: any[] = [];
  Questions_User: any[] = [];
  answers: any[] = [];
  Questions_All_inner: any[] = [];
  all_questions: any[] = [];

  complete_profile: boolean = false;

  constructor(
    public translate: TranslateService,
    public authService: AuthenticationService,
    private cityservice: CitiesService,
    private router: Router,
    private service: HttpClientService,
    private languageService: LanguageService,
    private userservice: UserService
  ) {}

  ngOnInit(): void {
    this.getNotificationsList();

    this.Latest_Question = false;
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.userType = +localStorage.getItem('userType');
    this.hasTasks = +localStorage.getItem('hasTasks');
    this.languageService.changedUser.subscribe((res) => {
      this.userType = +res;
    });

    this.initForm();
    this.initForm2();

    this.getCitiesList();

    this.userservice.viewprofile().subscribe((res: any) => {
      if (res.content.contacts == '') {
        console.log('company places :::::::: ', res.content.contacts);
        this.complete_profile = true;
        console.log('company places chec :::::::: ', this.complete_profile);
      }
    });
  }

  initForm() {
    this.requestPrice = new FormGroup({
      price: new FormControl('', [
        Validators.required,
        this.validatePrice.bind(this),
      ]),
      comment: new FormControl(''),
    });
  }
  initForm2() {
    this.questionaswerform = new FormGroup({
      answer: new FormControl(''),
    });
  }

  getCitiesList() {
    this.cityservice.GetCityList().subscribe((res: any) => {
      this.CitiesData = res.content;
    });
  }

  getNotificationsList() {
    let body = {};
    let param = new HttpParams();
    param = param.set('per_page', '1000');
    this.service
      .post('api/notifications', body, { pramas: param })
      .subscribe((res: any) => {
        this.notificationList = res.content;
        this.counter = res.pagination.counter;
      });
  }

  // ==================================== Start Notifiction Navigation ====================================

  showDialog(item) {
    if (this.userType == 1 || this.userType == 5) {
      this.router.navigate(['chat', this.lang]);
      if (item.read_at == null) {
        this.service
          .post('api/notifications/read', { id: item.id }, {})
          .subscribe((res) => {
            this.getNotificationsList();
          });
      } else {
      }
    } else {
      this.item_id = item.item_id;
      this.notification_id = item.id;
      if (item.read_at == null) {
        this.service
          .post('api/notifications/read', { id: this.notification_id }, {})
          .subscribe((res) => {
            this.getNotificationsList();
            if (item.entity_name == 'items' || item.entity_name == 'العناصر') {
              let body = { id: this.item_id };
              if (item.notification_text.includes('accepted')) {
                this.router.navigate(['requests', this.lang]);
              } else {
                this.service
                  .post('api/items/show', body, {})
                  .subscribe((res: any) => {
                    this.display = true;
                    this.negotiationTilte = res.content.title;
                    this.negotiationDesc = res.content.description;
                  });
              }
            } else if (
              (item.entity_name == 'company_jobs' ||
                item.entity_name == 'وظائف الشركة') &&
              this.hasTasks == 1 &&
              this.userType == 3
            ) {
              this.router.navigate(['view-job', this.lang], {
                queryParams: { id: item.item_id },
              });
            } else if (
              (item.entity_name == 'Contacts' ||
                item.entity_name == 'الأماكن') &&
              this.userType == 3
            ) {
              this.router.navigate(['my-profile', this.lang]);
            } else if (
              (item.entity_name == 'Contacts projects' ||
                item.entity_name == 'مشاريع الاتصالات') &&
              this.userType == 3
            ) {
              this.service
                .post('api/contacts_projects/show', { id: item.item_id }, [])
                .subscribe((res) => {
                  let ID = res['content'].list[0].cms_id;
                  this.router.navigate(['place-view', this.lang], {
                    queryParams: { id: ID },
                  });
                });
            } else if (
              (item.entity_name == 'Company users' ||
              item.entity_name == 'company_jobs' ||
                item.entity_name == 'موظفي الشركة') &&
              this.userType == 3
             ){
                this.router.navigate(['view-job', this.lang], {
                  queryParams: { id: item.item_id },
                });            
              } 
              else if (
              item.entity_name == 'Negotiations' ||
              item.entity_name == 'التفاوض'
            ) {
              this.router.navigate(['requests', this.lang]);
            } else if (
              item.entity_name == 'contact_question_answers' ||
              item.entity_name == 'contact_questions'
            ) {
              this.display2 = true;

              let body = {
                contact_id: item.contact_id,
                created_by: item.from_user_id,
              };

              this.service
                .post('api/contacts_question', body, {})
                .subscribe((res: any) => {
                  this.Questions = res.content[0];
                  this.Questions_All = res.content;
                  // this.answers = res.content[0].answers;

                  this.Questions_All.forEach((obj) => {
                    this.Questions_All_inner.push(obj);

                    this.all_questions = this.Questions_All_inner;

                    this.Questions_All_inner.forEach((answer) => {
                      if (answer.id == item.item_id) {
                        this.answers = answer;
                      } else {
                        this.answers = res.content[0].answers;
                      }
                    });
                  });

                  this.Question_ID = res.content[0].id;

                  if (this.Question_ID == item.item_id) {
                    this.question_text_last = '';
                    this.question_text_last = res.content[0].question;
                  } else {
                    this.question_text_last = '';

                    this.answers.forEach((element) => {
                      if (element.id == item.item_id) {
                        this.question_text_last = element.answer;
                      }
                    });
                  }
                });
            }
          });
      } else {
        if (item.entity_name == 'items' || item.entity_name == 'العناصر') {
          let body = { id: this.item_id };

          if (item.notification_text.includes('accepted')) {
            this.router.navigate(['requests', this.lang]);
          } else {
            this.service
              .post('api/items/show', body, {})
              .subscribe((res: any) => {
                this.display = true;
                this.negotiationTilte = res.content.title;
                this.negotiationDesc = res.content.description;
              });
          }
        } else if (
          (item.entity_name == 'company_jobs' ||
            item.entity_name == 'وظائف الشركة') &&
          this.hasTasks == 1 &&
          this.userType == 3
        ) {
          this.router.navigate(['view-job', this.lang], {
            queryParams: { id: item.item_id },
          });
        } else if (
          (item.entity_name == 'Contacts' || item.entity_name == 'الأماكن') &&
          this.userType == 3
        ) {
          this.router.navigate(['my-profile', this.lang]);
        } else if (
          (item.entity_name == 'Contacts projects' ||
            item.entity_name == 'مشاريع الاتصالات') &&
          this.userType == 3
        ) {
          this.service
            .post('api/contacts_projects/show', { id: item.item_id }, [])
            .subscribe((res) => {
              let ID = res['content'].list[0].cms_id;
              this.router.navigate(['place-view', this.lang], {
                queryParams: { id: ID },
              });
            });
        } else if (
          (item.entity_name == 'Company users' || item.entity_name == 'موظفي الشركة' || item.entity_name == 'company_jobs') &&
           this.userType == 3
        ) {
          this.router.navigate(['view-job', this.lang], { queryParams: { id: item.item_id },});
        } else if (
          item.entity_name == 'Negotiations' ||
          item.entity_name == 'التفاوض'
        ) {
          this.router.navigate(['requests', this.lang]);
        } else if (
          item.entity_name == 'contact_question_answers' ||
          item.entity_name == 'contact_questions'
        ) {
          this.display2 = true;

          let body = {
            contact_id: item.contact_id,
            created_by: item.from_user_id,
          };

          this.service
            .post('api/contacts_question', body, {})
            .subscribe((res: any) => {
              this.Questions = res.content[0];
              // this.answers = res.content[0].answers;
              this.Questions_All = res.content;

              this.Questions_All.forEach((obj) => {
                this.Questions_All_inner.push(obj);

                this.all_questions = this.Questions_All_inner;

                this.Questions_All_inner.forEach((answer) => {
                  if (answer.id == item.item_id) {
                    this.answers = answer;
                  } else {
                    this.answers = res.content[0].answers;
                  }
                });
              });

              this.Question_ID = res.content[0].id;

              if (this.Question_ID == item.item_id) {
                this.question_text_last = '';
                this.question_text_last = res.content[0].question;
              } else {
                this.question_text_last = '';
                this.answers.forEach((element) => {
                  if (element.id == item.item_id) {
                    this.question_text_last = element.answer;
                  }
                });
              }
            });
        }
      }
    }
  }

  // ==================================== End Notifiction Navigation ====================================

  validatePrice(controle: FormControl): { [p: string]: boolean } {
    this.priceNotValid = false;
    let m = controle.value;
    if (m.length > 0) {
      if (isNumeric(m) == true) {
        return null;
      } else if (m !== '') {
        this.priceNotValid = true;
        return { invalid_number: true };
      }
    }

    return null;
  }

  sendPrice(formdata) {
    let body = {
      item_id: this.item_id,
      offer: formdata.price,
      comment: formdata.comment,
    };
    this.service.post('api/negotiations', body, {}).subscribe((res: any) => {
      if (res.status.code == 200) {
        this.display = false;
        this.display2 = false;

        if (this.lang == 'en') {
          Swal.fire({
            icon: 'success',
            title: 'Your offer sent successfully !',
            showConfirmButton: false,
            timer: 1500,
          });
        } else if (this.lang == 'ar') {
          Swal.fire({
            icon: 'success',
            title: '!تم ارسال عرضك بنجاح',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else if (res.status.code != 200) {
        this.display = false;
        this.display2 = false;

        Swal.fire({
          icon: 'error',
          title: res.status.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  }

  sendAnswer(formvalue) {
    let body = {
      contact_id: this.contact_ID,
      answer: formvalue.answer,
      contact_question_id: this.Question_ID,
    };
    this.service
      .post('api/contacts_question/answers/store', body, {})
      .subscribe((res: any) => {
        if (res.status.code == 200) {
          this.display = false;
          this.display2 = false;

          if (this.lang == 'en') {
            Swal.fire({
              icon: 'success',
              title: 'Your answer has been sent successfully !',
              showConfirmButton: false,
              timer: 1500,
            });
          } else if (this.lang == 'ar') {
            Swal.fire({
              icon: 'success',
              title: '!تم ارسال اجابتك بنجاح',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else if (res.status.code != 200) {
          this.display = false;
          this.display2 = false;

          Swal.fire({
            icon: 'error',
            title: res.status.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  }

  logout() {
    if (this.lang == 'en') {
      Swal.fire({
        title: this.translate.instant('Are you sure you want to Logout ?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('logout'),
        cancelButtonText: this.translate.instant('cancel'),
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem('token');
          localStorage.removeItem('userType');
          localStorage.removeItem('hasTasks');
          localStorage.removeItem('hasCv');
          localStorage.removeItem('RuleID');
          localStorage.removeItem('Loggedin');

          Swal.fire({
            icon: 'success',
            title: this.translate.instant('You have been logged out !'),
            showConfirmButton: false,
            timer: 1500,
          });

          this.router.navigate(['', this.lang]);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    } else {
      Swal.fire({
        title: this.translate.instant('هل أنت متأكد أنك تريد تسجيل الخروج ؟'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('logout'),
        cancelButtonText: this.translate.instant('cancel'),
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem('token');
          localStorage.removeItem('userType');
          localStorage.removeItem('hasTasks');
          localStorage.removeItem('hasCv');
          localStorage.removeItem('RuleID');
          localStorage.removeItem('Loggedin');

          Swal.fire({
            icon: 'success',
            title: this.translate.instant('لقد تم تسجيل الخروج !'),
            showConfirmButton: false,
            timer: 1500,
          });

          this.router.navigate(['', this.lang]);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Swal.fire(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // )
        }
      });
    }

    this.getNotificationsList();
    localStorage.clear();

  }

  navigateplaces(item) {
    this.router.navigate(['places', this.lang], {
      queryParams: {
        city_id: item.id,
      },
    });
  }

  navBarTogglerIsVisible() {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }

  collapseNav() {
    if (this.navBarTogglerIsVisible()) {
      this.navbarToggler.nativeElement.click();
    }
  }

  // to change language
  changeLang() {
    const lang = this.languageService.currentLang.getValue();
    const url = window.location.href;

    if (lang === 'ar') {
      this.isArabic = true;
      localStorage.setItem('lang', 'en');
      this.languageService.updateLang('en');
      window.location.href = url.replace('/ar', '/en');
    } else if (lang === 'en') {
      this.isArabic = false;
      localStorage.setItem('lang', 'ar');
      this.languageService.updateLang('ar');
      window.location.href = url.replace('/en', '/ar');
    }
  }
}
