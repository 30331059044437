<section>
  <div class="background">
    <div class="container no-mar">
      <div class="row">
        <div class="container no-mar">
          <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6}" ></ng-otp-input>
          <button (click)="verify()" class="btn btn-success btn-custom">{{'Verify'|translate}}</button>
          <button (click)="resendCode()" class="btn btn-primary btn-custom">{{'resend Verification Code'|translate}}</button>
          <div id="captcha"></div>
        </div>
      </div>
    </div>
  </div>
</section>
