

    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="tabset-section panel panel-form">
              <tabset [justified]="true">

                  <tab heading=" {{'Home.login'|translate}}" >

                      <tabset [justified]="true">

<!-- ********************Start Company Login ************************* -->

                          <!-- <tab heading="{{'User Login' | translate}}"> -->
                              <form id="loginForm" #myform="ngForm" action="#" method="post" [formGroup]="loginForm" >
                                <input class="form-check-input" value="3"  type="radio" name="flexRadioDefault" id="flexRadioDefault25" checked style="display: none;">

                                <!-- <div class="row">
                                      <div class="col-md-6">
                                      <div class="form-check">
                                          <input class="form-check-input" value="1" (change)="onLoginItemChange($event.target.value)" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                                          <label class="form-check-label" for="flexRadioDefault2">
                                              {{'User Login' | translate}}
                                          </label>
                                        </div>
                                      </div>
                                      <div class="col-md-6">

                                        <div class="form-check">
                                          <input class="form-check-input" value="3" (change)="onLoginItemChange($event.target.value)" type="radio" name="flexRadioDefault" id="flexRadioDefault22" checked>
                                          <label class="form-check-label" for="flexRadioDefault22">
                                              {{'Company Login' | translate}}
                                          </label>
                                        </div>
                                        </div>
                                  </div> -->
                                  <div class="row">
                                      <div class="col-md-12">
                                          <!-- <div class="wrapper"> -->
                                              <ngx-intl-tel-input [cssClass]="'col-md-12 form-control'" [preferredCountries]="preferredCountries"
                                                  [searchCountryFlag]="true"
                                                  [customPlaceholder]="currentlang == 'en' ? 'Please Enter Your Phone Number' : 'من فضلك قم بادخال رقم الهاتف'"
                                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                                  [selectedCountryISO]="CountryISO.Egypt" [maxLength]="15"
                                                  [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                                  [numberFormat]="PhoneNumberFormat.International" name="phone" formControlName="mobile">
                                              </ngx-intl-tel-input>
                                          <!-- </div> -->
                                          <!-- <pre>{{ myform.form.controls['mobile'].errors | json }}</pre> -->
                                          <span *ngIf="loginForm.controls['mobile']?.errors?.required && myform.submitted" class="error-msg">
                                              {{'phoneReqMes'|translate}}
                                          </span>
                                          <span *ngIf="(loginForm.controls['mobile']?.value?.number?.length < 10 || loginForm.controls['mobile']?.value?.number?.length > 11) && loginForm.controls['mobile']?.value?.dialCode == '+20' && myform.submitted" class="error-msg">
                                              {{'phonePatMes'|translate}}.
                                          </span>
                                          <span *ngIf="loginForm.controls['mobile']?.value?.number?.length != 8 && loginForm.controls['mobile']?.value?.dialCode == '+966' && myform.submitted" class="error-msg">
                                              {{'phonePatMes'|translate}}.
                                          </span>
                                      </div>
                                  </div>

                                  <div class="form-group">
                                      <input  type="password" name="password" formControlName="password" id="login-password" tabindex="2" class="form-control" placeholder="{{'Password'|translate}}">

                                      <span
                                          *ngIf="loginForm.controls['password']?.errors?.required && myform.submitted"
                                          class="error-msg">
                                          {{'passwordReqMes'|translate}}
                                      </span>
                                      <span
                                          *ngIf="loginForm.controls['password']?.errors?.minlength && myform.submitted"
                                          class="error-msg">
                                          {{'passwordMinLengMes'|translate}}.
                                      </span>
                                  </div>
                                  <div class="form-group ">
                                      <input type="checkbox" tabindex="3" class="" name="remember" id="remember" (click)="RememberMe($event)">
                                      <label for="remember" class="remember_label">{{'RememberMe'|translate}}</label>
                                  </div>




                                  <div class="form-group">
                                      <!-- <input type="submit" name="login-submit" id="login-submit" tabindex="4" class="form-control btn btn-submit" value="Log In"> -->
                                      <button (click)="login()"
                                      class="form-control btn btn-submit">
                                      <i *ngIf="isEnabled" class="fa fa-spinner fa-spin"></i>
                                      {{'Home.login'|translate}}</button>
                                  </div>
                                  <div class="form-group">
                                      <div class="row">
                                          <div class="col-lg-12">
                                              <div class="text-center">
                                                  <a [routerLink]="['../../forgot-password', currentlang]" tabindex="5" class="login-forgot-password">{{'ForgotPassword'|translate}}</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </form>
                              <!-- </tab> -->

<!-- ********************End Company Login ************************* -->

<!-- ********************Start Company Login ************************* -->

                              <!-- <tab heading="{{'Company Login' | translate}}">
                                  <form id="loginForm" #myform="ngForm" action="#" method="post" [formGroup]="loginForm" >
                                      <div class="row">


                                          <div class="col-md-12">
                                                  <ngx-intl-tel-input
                                                      [cssClass]="'col-md-12 form-control'"
                                                      [customPlaceholder]="currentlang == 'en' ? 'Please Enter Your Phone Number' : 'من فضلك قم بادخال رقم الهاتف'"
                                                      [preferredCountries]="preferredCountries"
                                                      [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                                                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                                      [selectedCountryISO]="CountryISO.Egypt" [maxLength]="15"
                                                      [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                                      [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="mobile">
                                                  </ngx-intl-tel-input>
                                              <span *ngIf="loginForm.controls['mobile']?.errors?.required && myform.submitted" class="error-msg">
                                                  {{'phoneReqMes'|translate}}
                                              </span>
                                              <span *ngIf="loginForm.controls['mobile']?.value?.number?.length != 10 && loginForm.controls['mobile']?.value?.dialCode == '+20' && myform.submitted" class="error-msg">
                                                  {{'phonePatMes'|translate}}.
                                              </span>
                                              <span *ngIf="loginForm.controls['mobile']?.value?.number?.length != 8 && loginForm.controls['mobile']?.value?.dialCode == '+966' && myform.submitted" class="error-msg">
                                                  {{'phonePatMes'|translate}}.
                                              </span>
                                          </div>



                                      </div>

                                      <div class="form-group">
                                          <input  type="password" name="password" formControlName="password" id="login-password" tabindex="2" class="form-control" placeholder="{{'Password'|translate}}">

                                          <span
                                              *ngIf="loginForm.controls['password']?.errors?.required && myform.submitted"
                                              class="error-msg">
                                              {{'passwordReqMes'|translate}}
                                          </span>
                                          <span
                                              *ngIf="loginForm.controls['password']?.errors?.minlength && myform.submitted"
                                              class="error-msg">
                                              {{'passwordMinLengMes'|translate}}.
                                          </span>
                                      </div>



                                  <div class="row">
                                      <div class="col-md-6">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3">
                                          <label class="form-check-label" for="flexRadioDefault3">
                                              User Login
                                          </label>
                                        </div>
                                      </div>
                                      <div class="col-md-6">

                                        <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault23" checked>
                                          <label class="form-check-label" for="flexRadioDefault23">
                                              Company Login
                                          </label>
                                        </div>
                                        </div>
                                  </div>

                                      <div class="form-group ">
                                          <input type="checkbox" tabindex="3" class="" name="remember" id="remember" (click)="RememberMe($event)">
                                          <label for="remember" class="remember_label">{{'RememberMe'|translate}}</label>
                                      </div>
                                      <div class="form-group">
                                          <button (click)="login('company')"
                                          class="form-control btn btn-submit">
                                          <i *ngIf="isEnabled" class="fa fa-spinner fa-spin"></i>
                                          {{'Home.login'|translate}}</button>
                                      </div>
                                      <div class="form-group">
                                          <div class="row">
                                              <div class="col-lg-12">
                                                  <div class="text-center">
                                                      <a [routerLink]="['../Forgot-Password', currentlang]" tabindex="5" class="login-forgot-password">{{'ForgotPassword'|translate}}</a>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </form>
                              </tab> -->

<!-- ********************End Company Login ************************* -->

                              </tabset>

                  </tab>



                  <tab heading=" {{'Home.register'|translate}}">
                      <tabset [justified]="true">

<!-- ********************Start User Register ************************* -->

                          <!-- <tab heading=" {{'User Register' | translate}}">


                              <form [formGroup]="registerForm" #myRegisterform="ngForm" >
                                  <legend>{{'Basic Information'| translate}}</legend>
                                  <div class="row">
                                      <div class="col-md-12 col-sm-6 col-xs-12">
                                          <div class="form-group">
                                              <label >{{'Full Name'|translate}} <span class="required">*</span></label>
                                              <input  type="text" formControlName="full_name" name="fullname" id="register-fullname" tabindex="1" class="form-control" placeholder="{{'Full Name'|translate}}" value="">

                                              <span *ngIf="registerForm.controls['full_name']?.errors?.required && myRegisterform.submitted" class="error-msg">
                                                  {{'nameReqMes'|translate}}
                                              </span>

                                              <span *ngIf="registerForm.controls['full_name']?.errors?.pattern && myRegisterform.submitted" class="error-msg">
                                                  {{'nameMustBeCharc'|translate}}.
                                              </span>

                                              <span *ngIf="errorList?.full_name" class="error-msg">
                                                  {{ errorList?.full_name}}</span>
                                          </div>
                                      </div>

                                      <div class="col-md-12 col-sm-6 col-xs-12">
                                          <div class="form-group">
                                              <label>{{'email'|translate}} <span class="required">*</span></label>
                                              <input  type="email" name="email" formControlName="email" id="register-email" tabindex="3" class="form-control" placeholder="{{'email'|translate}}" value="">

                                              <span *ngIf="registerForm.controls['email']?.errors?.required && myRegisterform.submitted" class="error-msg">
                                                  {{'emailReqMes'|translate}}
                                              </span>
                                              <span *ngIf="registerForm.controls['email']?.errors?.email && myRegisterform.submitted" class="error-msg">
                                                  {{'emailmustbe'|translate}}.
                                              </span>
                                          </div>
                                      </div>

                                  </div>
                                  <div class="row">

                                      <div class="col-md-12 col-sm-12 col-xs-12">

                                          <div class="form-group">
                                              <label>{{'phoneno'|translate}} <span class="required">*</span></label>

                                              <ngx-intl-tel-input
                                                  [cssClass]="'col-md-12 form-control'"
                                                  [customPlaceholder]="currentlang == 'en' ? 'Please Enter Your Phone Number' : 'من فضلك قم بادخال رقم الهاتف'"
                                                  [preferredCountries]="preferredCountries"
                                                  [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                                  [selectedCountryISO]="CountryISO.Egypt" [maxLength]="15"
                                                  [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                                  [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="mobile">
                                              </ngx-intl-tel-input>
                                              <span *ngIf="registerForm.controls['mobile']?.errors?.required && myRegisterform.submitted" class="error-msg">
                                                  {{'phoneReqMes'|translate}}
                                              </span>
                                              <span *ngIf="registerForm.controls['mobile']?.value?.number?.length != 10 && registerForm.controls['mobile']?.value?.dialCode == '+20' && myRegisterform.submitted" class="error-msg">
                                                  {{'phonePatMes'|translate}}.
                                              </span>
                                              <span *ngIf="registerForm.controls['mobile']?.value?.number?.length != 8 && registerForm.controls['mobile']?.value?.dialCode == '+966' && myRegisterform.submitted" class="error-msg">
                                                  {{'phonePatMes'|translate}}.
                                              </span>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="row">
                                      <div class="col-md-6 col-sm-6 col-xs-12">
                                          <div class="form-group">
                                              <label>{{'Password'|translate}} <span class="required">*</span></label>
                                              <input  type="password" formControlName="password" name="password" id="register-password" tabindex="10" class="form-control" placeholder="{{'Password'|translate}}">

                                              <span *ngIf="registerForm.controls['password']?.errors?.required && myRegisterform.submitted" class="error-msg">
                                                  {{'passwordReqMes'|translate}}
                                              </span>
                                              <span *ngIf="registerForm.controls['password']?.errors?.minlength && myRegisterform.submitted" class="error-msg">
                                                  {{'passwordMinLengMes'|translate}}.
                                              </span>
                                          </div>
                                      </div>
                                      <div class="col-md-6 col-sm-6 col-xs-12">
                                          <div class="form-group">
                                              <label>{{'repeatPassword'|translate}} <span class="required">*</span></label>
                                              <input  type="password" formControlName="confirm_password" name="confirm-password" id="register-confirm-password" tabindex="11" class="form-control" placeholder="{{'repeatPassword'|translate}}">

                                              <span *ngIf="registerForm.controls['confirm_password']?.errors?.required && myRegisterform.submitted"
                                                  class="error-msg">
                                                  {{'repeatPasswordReqMes'|translate}}
                                              </span>
                                              <span *ngIf="registerForm.controls['confirm_password']?.errors?.minlength && myRegisterform.submitted"
                                                  class="error-msg">
                                                  {{'repeatPasswordMinLengMes'|translate}}.
                                              </span>
                                          </div>
                                      </div>
                                  </div>


                                  <div class="row">
                                      <div class="col-md-6">
                                      <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4">
                                          <label class="form-check-label" for="flexRadioDefault4">
                                              User Register
                                          </label>
                                        </div>
                                      </div>
                                      <div class="col-md-6">

                                        <div class="form-check">
                                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault24" checked>
                                          <label class="form-check-label" for="flexRadioDefault24">
                                              Company Register
                                          </label>
                                        </div>
                                        </div>
                                  </div>



                                  <div class="row">
                                      <div class="col-md-12 col-sm-12 col-xs-12">
                                          <div class="form-group">
                                              <button  (click)="register('user')" class="form-control btn btn-submit"><i *ngIf="isRegistered" class="fa fa-spinner fa-spin"></i>{{'createaccount'|translate}}</button>
                                          </div>
                                      </div>
                                  </div>
                              </form>
                              </tab> -->

<!-- ********************End User Register ************************* -->


<!-- ********************Start Company Register ************************* -->


                              <!-- <tab heading=" {{'Company Register' | translate}}"> -->

                                  <form [formGroup]="registerForm" #myRegisterform="ngForm" >
                                      <legend>{{'Basic Information'| translate}}</legend>
                                      <input class="form-check-input" value="3"  type="radio" name="flexRadioDefault" id="flexRadioDefault25" checked style="display: none;">

                                      <!-- <div class="row">
                                          <div class="col-md-6">
                                          <div class="form-check">
                                              <input class="form-check-input" value="1" (change)="onRegisterItemChange($event.target.value)" type="radio" name="flexRadioDefault" id="flexRadioDefault5">
                                              <label class="form-check-label" for="flexRadioDefault5">
                                                  {{'User Register' | translate}}
                                              </label>
                                            </div>
                                          </div>
                                          <div class="col-md-6">

                                            <div class="form-check">
                                              <input class="form-check-input" value="3" (change)="onRegisterItemChange($event.target.value)" type="radio" name="flexRadioDefault" id="flexRadioDefault25" checked>
                                              <label class="form-check-label" for="flexRadioDefault25">
                                                  {{'Company Register' | translate}}
                                              </label>
                                            </div>
                                            </div>
                                      </div> -->
                                      <div class="row">
                                          <div class="col-md-12 col-sm-6 col-xs-12">
                                              <div class="form-group">
                                                  <label *ngIf="!show">{{'Full Name'|translate}} <span class="required">*</span></label>
                                                  <label *ngIf="show">{{'Full Name of company'|translate}} <span class="required">*</span></label>
                                                  <input  type="text" *ngIf="!show" formControlName="full_name" name="fullname" id="register-fullname" tabindex="1" class="form-control" placeholder="{{'Full Name'|translate}}" value="">
                                                  <input  type="text" *ngIf="show" formControlName="full_name" name="fullname" id="register-fullname" tabindex="1" class="form-control" placeholder="{{'Full Name of company'|translate}}" value="">

                                                  <span *ngIf="registerForm.controls['full_name']?.errors?.required && myRegisterform.submitted" class="error-msg">
                                                      {{'nameReqMes'|translate}}
                                                  </span>

                                                  <span *ngIf="registerForm.controls['full_name']?.errors?.pattern && myRegisterform.submitted" class="error-msg">
                                                      {{'nameMustBeCharc'|translate}}.
                                                  </span>

                                                  <span *ngIf="errorList?.full_name" class="error-msg">
                                                      {{ errorList?.full_name}}</span>
                                              </div>
                                          </div>

                                          <div class="col-md-12 col-sm-6 col-xs-12">
                                              <div class="form-group">
                                                  <label>{{'email'|translate}} <span class="required">*</span></label>
                                                  <input  type="email" name="email" formControlName="email" id="register-email" tabindex="3" class="form-control" placeholder="{{'email'|translate}}" value="">

                                                  <span *ngIf="registerForm.controls['email']?.errors?.required && myRegisterform.submitted" class="error-msg">
                                                      {{'emailReqMes'|translate}}
                                                  </span>
                                                  <span *ngIf="registerForm.controls['email']?.errors?.email && myRegisterform.submitted" class="error-msg">
                                                      {{'emailmustbe'|translate}}.
                                                  </span>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="row">

                                          <div class="col-md-12 col-sm-12 col-xs-12">

                                              <div class="form-group">
                                                  <label>{{'phoneno'|translate}} <span class="required">*</span></label>

                                                  <ngx-intl-tel-input
                                                      [cssClass]="'col-md-12 form-control'"
                                                      [customPlaceholder]="currentlang == 'en' ? 'Please Enter Your Phone Number' : 'من فضلك قم بادخال رقم الهاتف'"
                                                      [preferredCountries]="preferredCountries"
                                                      [enableAutoCountrySelect]="true" [searchCountryFlag]="true"
                                                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                                      [selectedCountryISO]="CountryISO.Egypt" [maxLength]="15"
                                                      [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                                      [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="mobile">
                                                  </ngx-intl-tel-input>
                                                  <span *ngIf="registerForm.controls['mobile']?.errors?.required && myRegisterform.submitted" class="error-msg">
                                                      {{'phoneReqMes'|translate}}
                                                  </span>
                                                  <span *ngIf="(registerForm.controls['mobile']?.value?.number?.length < 10 || registerForm.controls['mobile']?.value?.number?.length > 11) && registerForm.controls['mobile']?.value?.dialCode == '+20' && myRegisterform.submitted" class="error-msg">
                                                      {{'phonePatMes'|translate}}.
                                                  </span>
                                                  <span *ngIf="registerForm.controls['mobile']?.value?.number?.length != 8 && registerForm.controls['mobile']?.value?.dialCode == '+966' && myRegisterform.submitted" class="error-msg">
                                                      {{'phonePatMes'|translate}}.
                                                  </span>
                                              </div>
                                          </div>
                                      </div>

                                      <div class="row" *ngIf="show">
                                          <div class="col-md-12 col-sm-6 col-xs-12">
                                              <div class="form-group">
                                                  <label for="services">{{'Home.servicess' | translate}}</label>

                                                  <div>
                                                      <!-- <p-multiSelect
                                                          [options]="services"
                                                          id="services"
                                                          defaultLabel="{{'select Service' | translate}}"
                                                          formControlName="services"
                                                          optionLabel="name"
                                                          [style]="{minWidth:'25em'}"
                                                          [panelStyle]="{minWidth:'25em'}"
                                                          [filter]="false"
                                                      ></p-multiSelect>  -->

                                                      <mat-form-field appearance="outline">
                                                          <mat-label>{{'select Service' | translate}}</mat-label>
                                                          <mat-select msInfiniteScroll (infiniteScroll)="getNextBatch()" multiple formControlName="services" [complete]="offset === services.length">
                                                              <mat-option>
                                                                  <ngx-mat-select-search placeholderLabel="{{ 'searchService' | translate}}" (keyup)="onKey(value)" [(ngModel)]="value" [ngModelOptions]="{standalone: true}">
                                                                    <mat-icon ngxMatSelectSearchClear>X</mat-icon>
                                                                  </ngx-mat-select-search>
                                                                </mat-option>
                                                              <mat-option *ngFor="let option of services" [value]="option">{{option.name}}</mat-option>
                                                          </mat-select>
                                                      </mat-form-field>
                                                  </div>

                                                  <span *ngIf="registerForm.controls['services']?.errors?.required && myRegisterform.submitted" class="error-msg">
                                                      {{'serviceReqMes'|translate}}
                                                  </span>
                                              </div>
                                          </div>
                                      </div>

                                      <div class="row" *ngIf="show">
                                          <div class="col-md-6 col-sm-6 col-xs-12">
                                              <div class="form-group">
                                                  <label> {{'Birthdate'|translate}} <span class="required">*</span></label>
                                                  <div class="input-group" style="display:flex">
                                                          <input
                                                              formControlName="birthdate"
                                                              type="date"
                                                              name="dp"
                                                              [ngClass]="currentlang == 'ar'?'text-right':''"
                                                              placeholder="{{'Birthdate'|translate}}"
                                                              class="form-control">
                                                 </div>

                                                  <span *ngIf="registerForm.controls['birthdate']?.errors?.required && myRegisterform.submitted" class="error-msg">
                                                      {{'birthdateReqMes'|translate}}
                                                  </span>
                                              </div>
                                          </div>
                                          <div class="col-md-6 col-sm-6 col-xs-12">
                                              <div class="form-group">
                                                  <label> {{'Gender'|translate}} <span class="required">*</span></label>
                                                  <div class="row">
                                                      <div class="col-xs-12">
                                                          <input type="radio" formControlName="gender_id" id="male" name="gender_id" value="1" tabindex="7"> &nbsp;
                                                          <label for="male">{{'Male'|translate}}</label> &nbsp; &nbsp;
                                                          <input type="radio" formControlName="gender_id" id="female" name="gender_id" value="2" tabindex="8"> &nbsp;
                                                          <label for="female">{{'Female'|translate}}</label>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="row">
                                          <div class="col-md-6 col-sm-6 col-xs-12">
                                              <div class="form-group">
                                                  <label>{{'Password'|translate}} <span class="required">*</span></label>
                                                  <input  type="password" formControlName="password" name="password" id="register-password" tabindex="10" class="form-control" placeholder="{{'Password'|translate}}">

                                                  <span *ngIf="registerForm.controls['password']?.errors?.required && myRegisterform.submitted" class="error-msg">
                                                      {{'passwordReqMes'|translate}}
                                                  </span>
                                                  <span *ngIf="registerForm.controls['password']?.errors?.minlength && myRegisterform.submitted" class="error-msg">
                                                      {{'passwordMinLengMes'|translate}}.
                                                  </span>
                                              </div>
                                          </div>
                                          <div class="col-md-6 col-sm-6 col-xs-12">
                                              <div class="form-group">
                                                  <label>{{'repeatPassword'|translate}} <span class="required">*</span></label>
                                                  <input  type="password" formControlName="confirm_password" name="confirm-password" id="register-confirm-password" tabindex="11" class="form-control" placeholder="{{'repeatPassword'|translate}}">

                                                  <span *ngIf="registerForm.controls['confirm_password']?.errors?.required && myRegisterform.submitted"
                                                      class="error-msg">
                                                      {{'repeatPasswordReqMes'|translate}}
                                                  </span>
                                                  <span *ngIf="registerForm.controls['confirm_password']?.errors?.minlength && myRegisterform.submitted"
                                                      class="error-msg">
                                                      {{'repeatPasswordMinLengMes'|translate}}.
                                                  </span>
                                              </div>
                                          </div>
                                      </div>



                                      <!-- soical media links -->
                                      <hr>
                                      <legend *ngIf="show">{{'Social Media Links'|translate}}</legend>
                                      <div class="row" *ngIf="show">
                                          <div class="col-md-6 col-sm-6">
                                            <div class="socialMedia d-flex social-icons form-group">
                                              <div class="kb-icon"><i class="fa fa-facebook"></i></div>
                                              <input type="text" class="form-control" formControlName="facebook" placeholder="https://wwww.facebook.com/">

                                          </div>
                                          <span *ngIf="registerForm.controls['facebook']?.errors?.pattern && myRegisterform.submitted" class="error-msg">
                                              {{'facebookmustbe' | translate}}
                                          </span>
                                            <div class="socialMedia d-flex social-icons form-group">
                                              <div class="kb-icon"><i class="fa fa-twitter"></i></div>
                                              <input type="text" class="form-control " formControlName="twitter" placeholder="https://wwww.twitter.com/">
                                            </div>
                                            <span *ngIf="registerForm.controls['twitter']?.errors?.pattern && myRegisterform.submitted" class="error-msg">
                                              {{'twittermustbe' | translate}}
                                          </span>
                                          </div>

                                          <div class="col-md-6 col-sm-6">
                                            <div class="socialMedia d-flex social-icons form-group">
                                              <div class="kb-icon"><i class="fa fa-linkedin"></i></div>
                                              <input type="text" class="form-control" formControlName="linkedin" placeholder="https://wwww.linkedin.com/">
                                            </div>
                                            <span *ngIf="registerForm.controls['linkedin']?.errors?.pattern && myRegisterform.submitted" class="error-msg">
                                              {{'linkedinmustbe' | translate}}
                                              </span>
                                            <div class="socialMedia d-flex social-icons form-group">
                                              <div class="kb-icon"><i class="fa fa-instagram"></i></div>
                                              <input type="text" class="form-control" formControlName="instagram" placeholder="https://wwww.instagram.com/">
                                            </div>
                                            <span *ngIf="registerForm.controls['instagram']?.errors?.pattern && myRegisterform.submitted" class="error-msg">
                                              {{'instagrammustbe' | translate}}
                                          </span>

                                          </div>


                                      </div>

                                      <div class="row">
                                          <div class="col-md-12 col-sm-12 col-xs-12">
                                              <div class="form-group">
                                                  <!-- <input type="submit" name="register-submit" id="register-submit" tabindex="12" class="form-control btn btn-submit" value="Register Now"> -->
                                                  <button  (click)="register()" class="form-control btn btn-submit"><i *ngIf="isRegistered" class="fa fa-spinner fa-spin"></i>{{'createaccount'|translate}}</button>

                                              </div>
                                          </div>
                                      </div>
                                  </form>
                              <!-- </tab> -->

<!-- ********************End Company Register ************************* -->

                          </tabset>


                  </tab>

                </tabset>
              </div>
        </div>
  </div>

