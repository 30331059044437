<div class="container-fluid pg-header" style="background-image: url(./assets/images/16.jpg);">
    <div class="pg-header-content">
      <h1 class="pg-title">
        {{'Tasks'|translate}}
      </h1>
      <h4 class="pg-subtitle">
        
      </h4>
    </div>
    <!-- <div class="pg-header-icon icon-bubbles"></div> -->
</div>
  <!-- End Page Header -->
  <!-- Start Contact Map -->
<div class="container-fluid map-contact">
    <div class="container page_bg pt-50 pb-50 pr-50 pl-50 container-margin">
        <div class="row">
            <div class="col-md-12">
                <div class="panel panel-form">
                    <div class="panel-heading">
                        <button class="search-btn" *ngIf="tasks?.length>0" (click)="open(content)">{{'Advanced Filter'|translate}}</button>
                        <button class="search-btn" [routerLink]="['/tasks-create', currentlang]">{{'create task'|translate}}</button>
                    </div>
                    <div class="panel-body">
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade active in" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <table class="table" cellspacing="0" *ngIf="tasks?.length>0">
                                    <thead>
                                        <tr>
                                            <th>{{'Request Name'|translate}}</th>
                                            <th>{{'Type'|translate}}</th>
                                            <th>{{'contactname'|translate}}</th>
                                            <th>{{'Date'|translate}}</th>
                                            <th>{{'Time'|translate}}</th>
                                            <th>{{'Status'|translate}}</th>
                                            <th>{{'Made by'|translate}}</th>
                                            <th>{{'Actions'|translate}}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let task of tasks  | paginate: { itemsPerPage: pageSize, currentPage: p, totalItems: totalapi }">
                                            <td>{{task.name}}</td>
                                            <td>{{task.request_type_value}}</td>
                                            <td>
                                                {{task.item_name}}
                                            </td>
                                            <td>
                                                {{task.date}}
                                            </td>
                                            <td class="date">
                                                {{task.time | convertFrom24To12Format}}
                                            </td>
                                            <td>
                                                {{task.request_status_id}}
                                            </td>
                                            <td>
                                                {{task.created_by}}
                                            </td>
                                            <td>
                                              <div class="btns-cell-td">

                                                <button class="view-btn" (click)="viewTask(task.id)"><i class="fa fa-eye"></i></button>
                                                <button class="edit-btn" (click)="editTask(task.id)"><i class="fa fa-edit"></i></button>
                                                <!-- <button class="history-btn"><i class="fas fa-list-alt"></i></button> -->
                                                <button class="delete-btn" (click)="delete(task.id)"><i class="fa fa-trash"></i></button>
                                          </div>
                                              </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                                <h2 *ngIf="tasks?.length==0">{{'no result'|translate}}</h2>

                                <div class="col-md-12 col-sm-12 text-center" *ngIf="tasks?.length>0">
                                    <pagination-controls 
                                               (pageChange)="onPageChange($event)"
                                               previousLabel="{{'previous'|translate}}"
                                               nextLabel="{{'next'|translate}}">
                                    </pagination-controls>
                            
                                  </div>
                            </div>

                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

 <div class="col-lg-12">

      <ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">{{'advanced Search' |translate}}</h4>
          <button type="button" style="opacity: 1;" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true" class="cancel-btn">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="searchForm"(ngSubmit)="search(searchForm.value)">
         

           


            <!-- Text input-->
           
            <div class="form-group">
                <div class="col-sm-4 inputGroupContainer">
                <div class="input-group">
                    <label style="display: table-caption;"> {{'name' | translate}}</label>

                    <span class="input-group-addon"><i class="fas fa-file-signature"></i></span>
                    <input  formControlName="name" type="text" placeholder="{{'name' | translate}}"class="form-control">
    
                   
                </div>
              </div>
            </div>

            <div class="form-group">
                <div class="col-sm-4 inputGroupContainer">
                <div class="input-group">
                    <label style="display: table-caption;"> {{'Date'|translate}}</label>
                    <span class="input-group-addon"><i class="fas fa-calendar-alt"></i></span>
                    <input  formControlName="date" type="date"  placeholder="{{'Date'|translate}}" class="form-control">
    
                   
                </div>
              </div>
            </div>
         
          
            <div class="form-group">
                <div class="col-sm-4 inputGroupContainer">
                    <div class="input-group">
                    <label style="display: table-caption;"> {{'Status'|translate}}</label>

                        <span class="input-group-addon">
                            <i class="fas fa-chart-bar"></i>
                        </span>
                        <select  formControlName="status_id" class="form-control" >
                            <option  value="" disabled selected> {{'select Status' | translate}} </option>

                            <option  *ngFor="let item of approvedList" [value]="item.id">{{item.name}}</option>

                      
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                  <div class="input-group">
                    <label style="display: table-caption;"> {{'Requests.TypeRequests'|translate}}</label>

                    <span class="input-group-addon">
                      <i class="fas fa-ellipsis-v"></i>
                    </span>
                    <select formControlName="request_type_id" name="request" id="request"  class="form-control">
                      <!-- <option  value="" disabled selected> {{'contactname'|translate}} </option> -->
                    
                      <option [ngValue]="type.id" *ngFor="let type of types">{{type.name}}</option>
                    </select>
                   
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-6 inputGroupContainer">
                  <div class="input-group">
                    <label style="display: table-caption;"> {{'contactname'|translate}}</label>

                    <span class="input-group-addon">
                      <i class="fas fa-address-book"></i>
                    </span>
                    <!-- <select formControlName="item_id" name="item" id="item" class="form-control">
                    
                      <option [ngValue]="item.id" #val *ngFor="let item of items">{{item.title}}</option>
                    </select> -->

                    <mat-form-field appearance="outline" style="height: 55px;">
                      <mat-label>{{'contactname'|translate}}</mat-label>
                      <mat-select (infiniteScroll)="getNextBatch()" [complete]="offset === items.length" msInfiniteScroll
                        formControlName="item_id">
                        <!-- <input type="search" [(ngModel)]="value" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="{{ 'searchContact' | translate}}" (keyup)="onKey(value)" name="search"> -->
                        <mat-option>
                          <ngx-mat-select-search placeholderLabel="{{ 'searchContact' | translate}}" (keyup)="onKey(value)"
                            [(ngModel)]="value" [ngModelOptions]="{standalone: true}">
                            <mat-icon ngxMatSelectSearchClear>X</mat-icon>
                          </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let item of items" [value]="item.id">{{item.title}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  
                  </div>
                </div>
              </div>
            <div class="footer">
                <button type="submit" class="btn btn-outline-dark submit-btn" >{{'Submit'|translate}}</button>
                <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">{{'cancel'|translate}}</button>
        
              </div>
          </form>
        </div>
       
      </ng-template>
      
     
      
  </div>