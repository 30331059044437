<div class="container-fluid pg-header" style="background-image: url(././assets/images/11.jpg);">
    <div class="pg-header-content">
      <h1 class="pg-title">
        {{'places' | translate}}
      </h1>
      <h4 class="pg-subtitle">
        {{'cat_title'|translate}}
      </h4>
    </div>
    <!-- <div class="pg-header-icon">
      <i class="fas fa-map-marker-alt"></i>
    </div> -->
  </div>
  
  <!-- Start Listings Container  -->
  <div class="container-fluid listing-block listings-has-sidebar">
    <div class="row listing grid6 no-addr-on-mobile white">
      <!-- Start Listing -->
      <div class="col-md-9 text-center">
        <div class="row custom-row">
        <!-- Start Listing Item Col -->
        <div class="col-md-4" *ngFor="let item of places | paginate : config">

          <!-- <div class="listing-item">
            <i class="category-icon glyphicon glyphicon-map-marker  category-style-ar"></i>
            <a  (click)="navigateplace(item)"   class="listing-item-link">
              <img [alt]="item.title" [src]="item.image" />
              <div class="listing-item-title-centralizer">
                <div class="listing-item-title">
                  {{item.title}}
                </div>
                <div class="feat-data">
                </div>
                <div class="listing-item-data">
                  <div class="listing-item-address  ar-address">
                   {{item.address}}
                  </div>
                  <div class="listing-item-excerpt">
                    {{item.content}}
                    {{item.description}}
                  </div>
                </div>
              </div>
              <div class="listing-category-name">
                {{item?.categories[0]?.value}}
              </div>
              
              <button *ngIf="userType==3 && hasTasks==1" (click)="addTask(item?.id)" [ngClass]="lang=='ar'?'btn-ar':'btn-en'" class="view-btn"><i class="fa fa-plus"></i></button>
              <button *ngIf="userType==1" (click)="addQuestion(item?.id)" [ngClass]="lang=='ar'?'btn-ar':'btn-en'" class="view-btn"><i class="fa fa-plus"></i></button>
              <i *ngIf="item.verified" [ngClass]="lang=='ar'?'icon-ar':'icon-en'" class="fas fa-check-double"></i>
            </a>

            
          </div> -->

          <div  class="card" style="cursor:pointer"  (click)="navigateplace(item)" >
            <img [alt]="item.title" [src]="item.image" loading="lazy" />
            <h3>{{item.title}}</h3>
            
            <p>
              {{item.content}}
              {{item.description}}
              <span *ngIF="item?.categories[0]?.value">(  {{item?.categories[0]?.value}} ) </span>
            </p>

            <h4> {{item.address}}</h4>

            <button *ngIf="userType==3 && hasTasks==1" (click)="addTask(item?.id)" [ngClass]="lang=='ar'?'btn-ar':'btn-en'" class="view-btn"><i class="fa fa-plus"></i></button>
            <button *ngIf="userType==1" (click)="addQuestion(item?.id)" [ngClass]="lang=='ar'?'btn-ar':'btn-en'" class="view-btn"><i class="fa fa-plus"></i></button>
            <i *ngIf="item.verified" [ngClass]="lang=='ar'?'icon-ar':'icon-en'" class="fas fa-check-double"></i>

          </div>
          

        </div>
        </div>
        <!-- End Listing Item Col -->

        <div class="col-sm-12">
          <!-- <pagination-controls 
                      (pageChange)="p = $event"
                      maxSize="10"
                      directionLinks="true"
                      autoHide="true"
                      responsive="true"
                      previousLabel="Previous"
                      nextLabel="Next"
                      screenReaderPaginationLabel="Pagination"
                      screenReaderPageLabel="page"
                      screenReaderCurrentLabel="You're on page">
          </pagination-controls> -->
          <pagination-controls  id='placesID'
                      (pageChange)="pageChanged($event)"
                      (pageBoundsCorrection)="pageChanged($event)"
                      class="list-pagination"
                      
                      directionLinks="true"
                      autoHide="true"
                      responsive="true"
                      previousLabel="{{'previous'|translate}}"
                      nextLabel="{{'next'|translate}}"
                      screenReaderPaginationLabel="Pagination"
                      screenReaderPageLabel="page"
                      screenReaderCurrentLabel="You're on page">
           </pagination-controls>
        </div> 
       
       
       
        
      </div>
      <!-- End Listing -->
      <!-- Start Sidebar -->
      <div class="col-md-3 sidebar-right">
        <div class="widget-area">
          <!--Start Widget Container-->
          <div class="widget-container">
            <!--Start Widget-->
            <div class="widget news-archive"> <!--start widget-->
              <div class="block">
                <div class="widget-title">
                  <div class="the-title">
                    {{'Home.cities'| translate}}
                  </div>
                </div>
                <div class="widget-content">

                  <!-- <ul>
                    <li class="item" *ngFor="let item of CitiesData">
                      <a (click)="GetPlacesofCity(item.id)">{{item.name}}</a>
                    </li>
                  </ul> -->

                  <!-- <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {{'Home.cities'| translate}} <i class="fa fa-angle-down" aria-hidden="true"></i>

                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                      <div *ngFor="let item of CitiesData">
                      <a class="dropdown-item" (click)="GetPlacesofCity(item.id)">
                        <i class="fa fa-map-o" aria-hidden="true"></i>

                        {{item.name}}
                      </a>
                    </div>
                    </div>
                  </div> -->



                  <div class="wrapper">
                    <select class="form-select" [(ngModel)]="val" (change)="CategoryFilterFunction($event.target.value)" aria-label="Default select example">
                      <option value="" selected disabled hidden>{{'Choose_City' | translate}}</option>

                      <option *ngFor="let item of CitiesData" [value]="item.id">  
                     
                          <i class="fa fa-map-o" aria-hidden="true"></i>

                          {{item.name}}
                      
                      </option>
                    </select>
                  </div>

                </div>
              </div>
            </div>
            <!--End Widget-->
            <!--Start Widget-->
            <!-- <div class="widget news-categories">
              <div class="block">
                <div class="widget-title">
                  <div class="the-title">
                    {{'Home.categories' | translate}}
                  </div>
                </div>
                <div class="widget-content">
                  <div class="wrapper">
                    <select class="form-select" (change)="CategoryFilterFunction($event.target.value)" aria-label="Default select example">
                      <option value=""> {{'Choose_Category' | translate}} </option>
                      
                      <option *ngFor="let item of CategoriesData"  [value]="item.id">  
                    
                          <i class="fa fa-heart-o" aria-hidden="true"></i>

                          {{item.title}}
                   
                      </option>
                    </select>
                  </div>

                </div>
              </div>
            </div> -->
            <!--End Widget-->
            <!--Start Widget-->
            <!-- <div class="widget newest-posts"> 
              <div class="block">
                <div class="widget-title">
                  <div class="the-title">
                    {{'MOST RECENT'|translate}}
                  </div>
                </div>
                <div class="widget-content">
                  <div class="item" >
                    <a href="#">
                      <div class="img">
                        <img alt="" src="./assets/images/single-blog/1.jpg" />
                      </div>
                      <div class="text">
                        <div class="title">
                          From Beach Glitz to Urban Ritz, Miami's Hotels Shine
                        </div>
                        <div class="date">
                          Feb 12, 2017
                        </div>
                      </div>
                    </a>
                  </div>

                  

                </div>
              </div>
            </div> -->
            <!--End Widget-->
            <!--Start Widget-->
            <div class="widget tags"> 
              <div class="block">
                <div class="widget-title">
                  <div class="the-title">
                    {{'TAGS'|translate}}
                  </div>
                </div>
                <div class="widget-content list zoom-text">


                  <!-- <ul class="">
                    <li *ngFor="let tag of TagsData; index as i">
                      <a (click)="getTagsvalues($event,tag.id,i)" id="tagID_{{i}}">{{tag.title}}</a>
                    </li>
                  </ul> -->

                  <div class="wrapper">
                    <select class="form-select" (change)="getTagsvalues($event,$event.target.value)" aria-label="Default select example">
                      <option value="">{{'Choose_Tag' | translate}}</option>
                      
                      <option *ngFor="let tag of TagsData; index as i" [value]="tag.id" id="tagID_{{i}}">  
            
                          <i class="fa fa-hashtag" aria-hidden="true"></i>
                          {{tag.title}}
                
                      </option>
                    </select>
                  </div>


                  <!-- <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {{'TAGS'|translate}} <i class="fa fa-angle-down" aria-hidden="true"></i>

                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                      <div *ngFor="let tag of TagsData; index as i">
                      <a class="dropdown-item" (click)="getTagsvalues($event,tag.id,i)" id="tagID_{{i}}">
                        <i class="fa fa-hashtag" aria-hidden="true"></i>

                        {{tag.title}}
                      </a>
                    </div>
                    </div>
                  </div> -->





                </div>
              </div>
            </div>
            <!--End Widget-->

             <!--Start Widget-->
             <div class="widget tags"> 
              <div class="block">
                <div class="widget-title">
                  <div class="the-title">
                    {{'Verified'|translate}}
                  </div>
                </div>
                <div class="widget-content list zoom-text">
                  <div class="wrapper">
                    <input type="checkbox" [(ngModel)]="isSelected" (change)="getEvent(isSelected?'A':'B')"><label 
                    style="color: #000;
                    padding: 10px;">{{'verifiedAccount'|translate}}</label>
                  </div>
                </div>
              </div>
            </div>
            <!--End Widget-->
          </div>
          <!--End Widget Container-->
        </div>
      </div>
      <!-- End Sidebar -->
    </div>
    <!-- <div class="more-listing icon-plus" title="More Listings" data-toggle="tooltip" data-placement="top"></div> -->
  </div>
  <!-- End Listings Container  -->
  
  
  