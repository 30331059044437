import { Component, OnInit } from '@angular/core';
import {PlacesService} from '../../Shared/Services/places.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AddingMetaService } from 'src/app/Shared/Services/adding-meta.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-place-details',
  templateUrl: './place-details.component.html',
  styleUrls: ['./place-details.component.scss'],
})
export class PlaceDetailsComponent implements OnInit {
  place;
  placename;
  placecontent;
  address;
  description;
  placeimage;
  placecategory;
  RelatedUsers: any[] = [];
  phoneNumber: string;
  PlaceTextCardData;
  placeCommercialRegisterData;

  placefacebooklink;
  placetwitterlink;
  placepaintrestlink;
  placeinstagramlink;
  placeSiteLink;

  RelatedPlaces: any[];

  placeskeywords: any[] = [];

  listArray = [];
  lang: string;
  placeId: number;

  projects: any[] = [];
  products: any[] = [];
  services: any[] = [];
  jobs: any[] = [];
  p: number = 1;
  lattiude: any;
  longitude: any;
  userType: number;
  hasTasks: number;
  PlaceTaxCardFile: any;
  placeCommercialRegisterFile: any;
  comapnyName: any;
  totalapi: number;
  pageSize: number = 6;

  constructor(
    private placeservice: PlacesService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private languageService: LanguageService,
    private metaService: AddingMetaService
  ) {}

  title = 'app';
  lat: number = 39.742043;
  lng: number = -104.991531;

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.placeId = +res['id'];
    });
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.userType = +localStorage.getItem('userType');
    this.hasTasks = +localStorage.getItem('hasTasks');
    this.GetPlaceDetails();
  }

  GetPlaceDetails() {
    this.activatedRoute.queryParams.subscribe((res) => {
      if (this.activatedRoute.snapshot.queryParams['id']) {
        this.placeservice
          .GetPlace(this.activatedRoute.snapshot.queryParams['id'])
          .subscribe((res: any) => {
            this.place = res.content;
            console.log('place details:----', this.place);
            this.placename = res.content[0].title;
            this.placecontent = res.content[0].content;
            this.address = res.content[0].address;
            this.jobs = res.content[0].jobs;
            // if(res.content[0].creator.related_company){
            //   this.comapnyName= res.content[0].creator.related_company.name;
            //   this.jobs = res.content[0].creator.related_company.jobs;
            // }
            this.description = res.content[0].description;
            this.placeimage = res.content[0].image;
            this.placecategory = res.content[0].categories;
            this.lattiude = res.content[0].latitude;
            this.longitude = res.content[0].longitude;
            this.RelatedUsers =
              res.content[0].more[0].value[1].value.value_array;
            this.RelatedUsers.map((element) => {
              element.mobile = element.mobile.toString().slice(2, -2);
            });

            this.projects = res.content[0].projects;
            this.products = res.content[0].products;
            this.services = res.content[0].services;

            console.log(this.RelatedUsers);

            this.PlaceTextCardData = res.content[0].tax_card;
            this.PlaceTaxCardFile = res.content[0].tax_card_file;
            this.placeCommercialRegisterData =
              res.content[0].commercial_register;
            this.placeCommercialRegisterFile =
              res.content[0].commercial_registry_file;

            this.placefacebooklink =
              res.content[0].more[2].value[18].value.value_string;
            this.placeinstagramlink =
              res.content[0].more[2].value[22].value.value_string;
            this.placetwitterlink =
              res.content[0].more[2].value[19].value.value_string;
            this.placepaintrestlink =
              res.content[0].more[2].value[23].value.value_string;
            this.placeSiteLink = res.content[0].link;

            this.RelatedPlaces = res.content[0].related_places;
            this.totalapi = this.RelatedPlaces.length;
            this.placeskeywords = res.content[0].keywords;
            this.phoneNumber =
              res.content[0].more[2].value[12].value.value_string;
            this.metaService.updateMeta({
              title: res.content[0]?.seo[0]?.title,
              description: res.content[0]?.seo[1]?.keyword,
              url: window.location.href,
              compTitle: res.content[0]?.seo[0]?.keyword,
            });
            console.log('Tags Are', this.placeskeywords);

            // console.log("place name", this.placename)

            // console.log("testtttttttttt",this.placeinstagramlink,this.placepaintrestlink)
          });
      }
    });
  }

  onPageChange(event) {
    this.p = event;
  }

  navigateplace(item) {
    this.router.navigate(['../place-details', this.lang], {
      queryParams: {
        id: item.id,
      },
    });
  }

  getPlaceProjects() {
    this.placeservice.getPlaceProjects(this.placeId, 1).subscribe((res) => {
      this.projects = res['content'];
    });
  }

  getPlaceProducts() {
    this.placeservice.getPlaceProducts(this.placeId, 1).subscribe((res) => {
      this.products = res['content'];
    });
  }

  getPlaceServices() {
    this.placeservice.getPlaceServices(this.placeId, 1).subscribe((res) => {
      this.services = res['content'];
    });
  }

  addTask() {
    this.Counts();
    this.router.navigate(['tasks-create', this.lang], {
      queryParams: { id: this.placeId },
    });
  }

  addQuestion() {
    this.Counts();
    this.router.navigate(['chat', this.lang]);
  }

  QuickQuestion() {
    this.Counts();
    this.router.navigate(['ask-question', this.lang], {
      queryParams: { id: this.placeId },
    });
  }

  openPlace(lat, long) {
    this.Counts();
    window.open(
      'https://www.google.com/maps/place/' + lat + 'N' + long + 'E',
      '_blank'
    );
  }

  gotoCategory(id) {
    this.router.navigate(['categories/places', this.lang], {
      queryParams: { category_value_id: id },
    });
  }

  gotoPlacesByTag(id) {
    this.router.navigate(['categories/places', this.lang], {
      queryParams: { tag_id: id },
    });
  }

  goToSite() {
    window.open(this.placeSiteLink, '_blank');
  }

  Counts() {
    console.log('enter click count function');
    let obj = {
      id: this.placeId,
    };
    this.placeservice.SendNumberOfClicks(obj).subscribe((res: any) => {});
  }
}
