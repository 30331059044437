import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-register-new',
  templateUrl: './register-new.component.html',
  styleUrls: ['./register-new.component.scss'],
})
export class RegisterNewComponent implements OnInit {
  lang;
  currentlang: string;

  constructor(
    private languageService: LanguageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });

    this.currentlang = localStorage.getItem('lang');
  }

  NavigateIndividualRegister() {
    document.getElementById('individual').classList.add('selected');
    document.getElementById('Corporate').classList.remove('selected');
    document.getElementById('next').classList.add('selected-btn');
    localStorage.removeItem('register-type-id');
    this.router.navigate(['../../register-individual', this.lang]);

    // setTimeout(function(){
    //   this.router.navigate(['../../Register-Individual', this.lang])
    // }, 2000);
  }

  NavigateCorporateRegister() {
    document.getElementById('Corporate').classList.add('selected');
    document.getElementById('individual').classList.remove('selected');
    document.getElementById('next').classList.add('selected-btn');
    localStorage.setItem('register-type-id', '3');
    this.router.navigate(['../../register-corporate', this.lang]);
  }
}
