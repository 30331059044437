import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { HttpHeaders } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class HomeService {

  header: HttpHeaders = new HttpHeaders();
  token;


  constructor(private httpClientService: HttpClientService) {
    this.token = localStorage.getItem('token');
  }

  GetTopRated() {

    let body={
      "home_page":1
    }
    return this.httpClientService.post('api/cms', body,  {});
  }

  GetTrending() {

    let body={
      "trending":1
    }
    return this.httpClientService.post('api/cms', body,  {});
  }

  GetSocialLinks(){
    let body={
    }
    return this.httpClientService.post('api/cms', body,  {});
  }

  searchPlaces(search) {
    let body={
    }
    return this.httpClientService.post('api/cms', body,{title: search});

  }

  globalSearch(search) {
    return this.httpClientService.get('api/search', {text: search} , {});
  }

  GetCategoryList() {

    let body={
      "home_page":1

    }
    return this.httpClientService.post('api/category_values', body,  {});
  }

  GetallplacesCount(){
    let body={
      "is_public":1
    }
    return this.httpClientService.post('api/cms', body,  {});

  }

  getTypes(){
    let body={}
    return this.httpClientService.post('api/request_types', body, []);
  }

  getDistricts(){
    let body={}
    return this.httpClientService.post('api/districts', body, []);
  }

  getItems(page){
    let body={}
    return this.httpClientService.post('api/cms', body, {'page': page});
  }
  getPriority(body){
    return this.httpClientService.post('api/requests/priorities', body, []);
  }

  createTask(body){
    return this.httpClientService.post('api/requests/store', body, []);
  }

  getTaskStatuses(){
    let body={}
    return this.httpClientService.post('api/requests/statuses', body,[]);
  }

  updateTask(body){
    return this.httpClientService.post('api/requests/update', body, []);
  }

  getAllTasks(body,param?){

    return this.httpClientService.post('api/requests', body, param);
  }
   filterTasks(body){

    return this.httpClientService.post('api/requests', body, []);
  }
  getAllEmployee(){
    let body={}
    return this.httpClientService.post('api/employees', body, []);
  }

  showTask(id){
    let body={
      "id":id
    }
    return this.httpClientService.post('api/requests/show', body, []);
  }

  getRelatedContacts(id: number, page: number){
    let body={
      "cms_id":id
    }
    return this.httpClientService.post('api/cms/related_contacts', body, {'page': page});
  }

  getAllJobs(page: any){
    let body={
      "lang_id":2
    };
    let setHeaders = this.header.set('access-token', this.token);

    return this.httpClientService.post('api/company_jobs/all', body, { 'page': page }, setHeaders);
  }

  getAllProducts(page: any){
    let body={

    };
    return this.httpClientService.post('api/contacts_products', body, {'page': page});
  }

  getAllServices(page: any){
    let body={

    };
    return this.httpClientService.post('api/contacts_services', body, {'page': page});
  }

  getHomeData(){
    let body={
      "page_name":"home"
    }
    return this.httpClientService.post('api/pages', body,[]);
  }

  GetHomepageData(langID){
    let body={
      "lang_id":langID
    };

    return this.httpClientService.get('api/home', body , {});

  }

  GetCompanyJobs(){
    let body={
     "lang_id": 2
    };
    return this.httpClientService.post('api/company_jobs', body,[]);
  }

}
