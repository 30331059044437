import { Component, OnInit,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {HomeService} from '../../Shared/Services/home.service';


@Component({
  selector: 'app-place-circle',
  templateUrl: './place-circle.component.html',
  styleUrls: ['./place-circle.component.scss']
})
export class PlaceCircleComponent implements OnInit {

  TrendingData;
  // @Input() trendings: trendings;


  constructor(
    private homeservice:HomeService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.homeservice.GetTrending().subscribe((res:any)=>{
      this.TrendingData = res.content
    })
  }

}
