import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyGuard implements CanActivate {
  userType: number;
  hasTasks: number;

  constructor(
    private router: Router
  ){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.userType = +localStorage.getItem('userType');
    this.hasTasks = +localStorage.getItem('hasTasks');
    if(this.userType == 3){
      return true;
    }else{
      return this.router.navigate(['']);
    }
  }
  
}
