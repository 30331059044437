<section class="our-clients">
    <div class="container">
        <h2 class="text-center" data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-out-cubic"
            data-aos-duration="​1800">{{'Clients'|translate}}</h2>



        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                <img src="https://global-uploads.webflow.com/5cf99793572333491230f7a8/5d07deea98631159d47d455b_Google.svg"
                    alt="">
            </ng-template>
            <ng-template carouselSlide>
                <img src="https://global-uploads.webflow.com/5cf99793572333491230f7a8/5d07dee99c43981c89b40ce0_NBC.svg"
                    alt="">
            </ng-template>
            <ng-template carouselSlide>
                <img src="https://global-uploads.webflow.com/5cf99793572333491230f7a8/5d07deea986311233a7d455c_Coke.svg"
                    alt="">
            </ng-template>

            <ng-template carouselSlide>
                <img src="https://global-uploads.webflow.com/5cf99793572333491230f7a8/5d07deeafd6f22326e6ae246_Redbull.svg"
                    alt="">
            </ng-template>
            <ng-template carouselSlide>
                <img src="https://global-uploads.webflow.com/5cf99793572333491230f7a8/5d07dee99863117ffb7d455a_Visa.svg"
                    alt="">
            </ng-template>
            <ng-template carouselSlide>
                <img src="./assets/images/client-1.png" alt="">
            </ng-template>
            <ng-template carouselSlide>
                <img src="https://global-uploads.webflow.com/5cf99793572333491230f7a8/5d094367a4ef8f58c82fa89b_Microsoft.svg"
                    alt="">
            </ng-template>
            <ng-template carouselSlide>
                <img src="https://global-uploads.webflow.com/5cf99793572333491230f7a8/5d09436d8876072f975ae1c3_Dreamworks.svg"
                    alt="">
            </ng-template>

            <ng-template carouselSlide>
                <img src="https://global-uploads.webflow.com/5cf99793572333491230f7a8/5d0944ec88760736d75ae652_Maserati.svg"
                    alt="">
            </ng-template>

            <ng-template carouselSlide>
                <img src="https://global-uploads.webflow.com/5cf99793572333491230f7a8/5d09437f06d04cf6b10fe77a_facebook.svg"
                    alt="">
            </ng-template>

            <ng-template carouselSlide>
                <img src="https://global-uploads.webflow.com/5cf99793572333491230f7a8/5d095301b9278b41cf449e68_Ford.svg"
                    alt="">
            </ng-template>



        </owl-carousel-o>


    </div>
</section>