import {Component, OnInit} from '@angular/core';
import {UserService} from '../../Shared/Services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../Shared/Services/authentication.service';
import {SearchCountryField, CountryISO, PhoneNumberFormat} from 'ngx-intl-tel-input';
import {CitiesService} from '../../Shared/Services/cities.service';
import {HomeService} from '../../Shared/Services/home.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {HttpClientService} from 'src/app/Shared/Services/http-client.service';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import {environment} from "../../../environments/environment";
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-register-individual',
  templateUrl: './register-individual.component.html',
  styleUrls: ['./register-individual.component.scss'],
})
export class RegisterIndividualComponent implements OnInit {
  registerForm1: FormGroup;
  errors: any[] = [];
  errorList;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Egypt, CountryISO.SaudiArabia];

  lang_id;
  lang;
  currentlang: string;

  codelist;

  page: number = 1;
  jobs: any[] = [];
  recaptchVerifier: any;
  phoneNumber: any;

  constructor(
    private userservice: UserService,
    private translateservice: TranslateService,
    private toastrService: ToastrService,
    private authservice: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private cityservices: CitiesService,
    private homeservices: HomeService,
    private httpClientService: HttpClientService,
    private languageService: LanguageService
  ) {
    this.getCountryTelephoneCode();
  }

  ngOnInit(): void {
    this.GetAllJobs();

    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    if (this.lang == 'en') {
      this.lang_id = 1;
    } else {
      this.lang_id = 2;
    }
    this.initRegisterForm1();
    this.getCountryTelephoneCode();
    this.currentlang = localStorage.getItem('lang');
    console.log('prefered Country', this.preferredCountries);

    this.GetAllJobs();

    document.getElementById('step2').classList.add('hide');
  }

  initRegisterForm1() {
    this.registerForm1 = new FormGroup({
      full_name: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]*'),
      ]),
      // tele_code: new FormControl(''),
      // Validators.pattern('[0-9]{11}$')
      mobile: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      job: new FormControl(''),
    });
  }

  getCountryTelephoneCode() {
    this.cityservices.GetCountryCodeList().subscribe((res: any) => {
      this.codelist = res.content;
      console.log(this.codelist);
    });
  }

  GetAllJobs() {
    console.log('Calling the jobs api:-');
    this.homeservices.GetCompanyJobs().subscribe((res: any) => {
      this.jobs = res.content;
      console.log('Company Jobs:---', res);
    });
  }

  SubmitForm(value: any) {
    console.log('The Form Value:----', this.registerForm1.value);
    if (value.invalid && value.pristine) {
      value.markAllAsTouched();
    }
    // else if (value.invalid && value.dirty) {
    //   Object.keys(value.controls).forEach(field => {
    //     const control = value.get(field);
    //     control.markAsTouched({onlySelf: true});
    //   });

    //    if(this.lang == 'en'){
    //     this.toastrService.error('Please enter the rest of the data');
    //     console.log("form is still invalid")
    //    }
    //    else{
    //     this.toastrService.error('من فضلك ادخل باقي البيانات');
    //     console.log("form is still invalid")
    //   }

    // }
    else if (value.valid == true) {
      // call the api

      let submitobj = {
        full_name: this.registerForm1.get('full_name').value,
        mobile: this.registerForm1.value.mobile.number,
        password: this.registerForm1.get('password').value,
        tele_code: this.registerForm1.value.mobile.dialCode,
        lang_id: this.lang_id,
        job_id: this.registerForm1.get('job').value,
        company_id: this.registerForm1.get('job').value,
        rule_id: 5,
      };

      console.log('The Submitted Object is :------', submitobj);

      console.log('The Submitted Object is :------', submitobj);
      this.userservice.registeruser(submitobj).subscribe(
        (res: any) => {
          //  console.log("the error is :-----", res.error.error)
          console.log('The Idividual Register API Res:----', res);
          if (res.status.code === 200) {
            localStorage.setItem('UserID', res.content.id);
            this.userservice.getOtp(this.registerForm1.value.mobile.e164Number);
            localStorage.setItem(
              'phoneNumber',
              this.registerForm1.value.mobile.e164Number.toString()
            );
            this.toastrService.success(res.status.message);
            document.getElementById('step1').classList.add('hide');
            document.getElementById('titlestep1').classList.add('hide');
            document.getElementById('title2step1').classList.add('hide');

            document.getElementById('step2').classList.remove('hide');
            document
              .getElementById('stepcircle')
              .classList.add('step-selected');
          } else if (res.status.code === 401) {
            let validation_error = res.status.validation_errors;
            console.log(res.status.validation_errors);
            validation_error.forEach((element) => {
              if (element.field == 'full_name') {
                this.toastrService.error(element.message);
              } else if (element.field == 'mobile') {
                this.toastrService.error(element.message);
              } else if (element.field == 'password') {
                this.toastrService.error(element.message);
              } else if (element.field == 'tele_code') {
                this.toastrService.error(element.message);
              } else if (element.field == 'lang_id') {
                this.toastrService.error(element.message);
              } else if (element.field == 'job_id') {
                this.toastrService.error(element.message);
              } else if (element.field == 'company_id') {
                this.toastrService.error(element.message);
              }
            });
          } else if (res.status.code === 403) {
            this.toastrService.error(res.status.error_details);
            console.log(res.status.validation_errors);
          }
        },
        (error) => {
          let validation_error = error.error.status.validation_errors;
          console.log(error.error.status.validation_errors);
          validation_error.forEach((element) => {
            if (element.field == 'full_name') {
              this.toastrService.error(element.message);
            } else if (element.field == 'mobile') {
              this.toastrService.error(element.message);
            } else if (element.field == 'password') {
              this.toastrService.error(element.message);
            } else if (element.field == 'tele_code') {
              this.toastrService.error(element.message);
            } else if (element.field == 'lang_id') {
              this.toastrService.error(element.message);
            } else if (element.field == 'job_id') {
              this.toastrService.error(element.message);
            } else if (element.field == 'company_id') {
              this.toastrService.error(element.message);
            }
          });
        }
      );
      //   console.log("The Form Value:----",this.registerForm1.value)
    } else {
      let submitobj = {
        full_name: this.registerForm1.get('full_name').value,
        mobile: this.registerForm1.value.mobile.number,
        password: this.registerForm1.get('password').value,
        tele_code: this.registerForm1.value.mobile.dialCode,
        lang_id: this.lang_id,
        job_id: this.registerForm1.get('job').value,
        company_id: this.registerForm1.get('job').value,
        rule_id: 5,
      };
    }
  }

  NavigateToHome() {
    this.router.navigate(['../../home', this.lang]);
  }
}
