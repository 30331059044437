import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { isNumeric } from 'rxjs/internal-compatibility';
import { AddingMetaService } from 'src/app/Shared/Services/adding-meta.service';
import { CategoriesService } from 'src/app/Shared/Services/categories.service';
import { CitiesService } from 'src/app/Shared/Services/cities.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';
import { PlacesService } from 'src/app/Shared/Services/places.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-requests-list',
  templateUrl: './requests-list.component.html',
  styleUrls: ['./requests-list.component.scss'],
})
export class RequestsListComponent implements OnInit {
  lang: string;

  constructor(
    private translateservice: TranslateService,
    private service: HttpClientService,
    private cityservice: CitiesService,
    private catservice: CategoriesService,
    private placesService: PlacesService,
    private languageService: LanguageService,
    private meta: AddingMetaService
  ) {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
  }
  requestList = [];
  approvedRequest = [];
  declinedRequest = [];
  inProgress = [];
  newRequest = [];
  user_id;
  negotiationTilte;
  negotiationDesc;
  item_id;
  display = false;
  display2 = false;
  display3 = false;
  requestPrice: FormGroup;
  priceNotValid: boolean = false;
  oldPrice = '';
  isUser: boolean = false;
  companyName: any;
  companyEmail: any;
  companyPhone: any;
  messages: any[] = [];
  cities: any[] = [];
  CategoriesData: any[] = [];
  places: any[] = [];
  ngOnInit(): void {
    this.user_id = localStorage.getItem('UserID');
    console.log('User Type ::::::::::::', localStorage.getItem('userType'));

    if (
      localStorage.getItem('userType') == '1' ||
      localStorage.getItem('userType') == '5'
    ) {
      this.isUser = true;
    }

    this.getRequest();
    this.getNonNegotiotion();
    this.initForm();
    this.getcitieslist();
    this.getAllCategories();
    this.getAllPlaces();

    this.meta.updateMeta({
      compTitle: 'Requests',
    });
  }
  initForm() {
    this.requestPrice = new FormGroup({
      price: new FormControl(this.oldPrice, [
        Validators.required,
        this.validatePrice.bind(this),
      ]),
      comment: new FormControl(''),
    });
  }

  getRequest() {
    let query;
    if (this.isUser) {
      query = 'received';
    } else {
      query = 'sent';
    }
    this.service
      .get('api/negotiations/' + query, {}, {})
      .subscribe((res: any) => {
        this.requestList = res.content;
        // console.log("request list :::::::::::::::",res )
        this.inProgress = [];
        this.approvedRequest = [];
        this.declinedRequest = [];
        this.requestList.forEach((element) => {
          if (element.approved_at == null && element.declined_at == null) {
            this.inProgress.push(element);
          } else if (element.approved_at == null) {
            this.declinedRequest.push(element);
          } else if (element.declined_at == null) {
            this.approvedRequest.push(element);
          }
        });
        console.log('request =>', this.inProgress);
        // console.log("request =>",this.requestList);
      });
  }

  getNonNegotiotion() {
    this.service.post('api/items', {}, { negotiations: 0 }).subscribe((res) => {
      this.newRequest = res['content'].data;
      console.log('Requests list :::::::::::', this.newRequest);
    });
  }

  closeRequest(item, approved) {
    if (approved == 1) {
      let body = {
        negotiation_id: item.id,
        approve: 1,
        decline: 0,
      };
      this.service
        .post('api/negotiations/close', body, {})
        .subscribe((res: any) => {
          if (res.status.code == 200) {
            if (localStorage.getItem('lang') == 'en') {
              Swal.fire({
                icon: 'success',
                title: 'You approved the request!',
                showConfirmButton: false,
                timer: 1500,
              });
            } else if (localStorage.getItem('lang') == 'ar') {
              Swal.fire({
                icon: 'success',
                title: '! لقد قبلت الطلب ',
                showConfirmButton: false,
                timer: 1500,
              });
            }
            this.getRequest();
          } else if (res.status.code != 200) {
            Swal.fire({
              icon: 'error',
              title: res.status.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    }
    if (approved == 0) {
      let body = {
        negotiation_id: item.id,
        approve: 0,
        decline: 1,
      };
      this.service
        .post('api/negotiations/close', body, {})
        .subscribe((res: any) => {
          if (res.status.code == 200) {
            if (localStorage.getItem('lang') == 'en') {
              Swal.fire({
                icon: 'success',
                title: 'You declined the request!',
                showConfirmButton: false,
                timer: 1500,
              });
            } else if (localStorage.getItem('lang') == 'ar') {
              Swal.fire({
                icon: 'success',
                title: '! لقد رفضت الطلب ',
                showConfirmButton: false,
                timer: 1500,
              });
            }
            this.getRequest();
          } else if (res.status.code != 200) {
            Swal.fire({
              icon: 'error',
              title: res.status.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    }
  }
  showDialog(item) {
    this.item_id = item.id;
    this.negotiationTilte = item.item.title;
    this.negotiationDesc = item.item.description;
    this.oldPrice = item.last_offer;
    this.requestPrice.setValue({
      price: this.oldPrice,
      comment: '',
    });
    this.display = true;
  }

  showCompanyInfo(item) {
    this.display2 = true;
    this.companyName = item.creator.related_company.name;
    this.companyEmail = item.creator.related_company.email;
    this.companyPhone = item.creator.related_company.mobile_number_1;
    console.log(item);
  }

  showMessages(item) {
    this.display3 = true;
    this.messages = item.negotiation_offers;
  }

  showUserInfo(item) {
    this.display2 = true;
    this.companyName = item.item.creator.full_name;
    this.companyEmail = item.item.creator.email;
    this.companyPhone = item.item.creator.mobile;
    console.log(item);
  }

  validatePrice(controle: FormControl): { [p: string]: boolean } {
    this.priceNotValid = false;
    let m = controle.value;
    if (m.length > 0) {
      if (isNumeric(m) == true) {
        return null;
      } else if (m !== '') {
        this.priceNotValid = true;
        return { invalid_number: true };
      }
    }

    return null;
  }
  sendPrice(formdata) {
    let body = {
      negotiation_id: this.item_id,
      offer: formdata.price,
      item_id: this.item_id,
      comment: formdata.comment,
    };
    console.log('body =', body);

    this.service
      .post('api/negotiations/update', body, {})
      .subscribe((res: any) => {
        console.log(res);

        if (res.status.code == 200) {
          this.display = false;

          if (localStorage.getItem('lang') == 'en') {
            Swal.fire({
              icon: 'success',
              title: 'Your offer sent successfully !',
              showConfirmButton: false,
              timer: 1500,
            });
          } else if (localStorage.getItem('lang') == 'ar') {
            Swal.fire({
              icon: 'success',
              title: '!تم ارسال عرضك بنجاح',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else if (res.status.code != 200) {
          this.display = false;
          Swal.fire({
            icon: 'error',
            title: res.status.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  }

  getcitieslist() {
    this.cityservice.GetCityList().subscribe((res: any) => {
      this.cities = res.content;
      console.log(this.cities);
    });
  }

  getAllCategories() {
    this.catservice.GetCategoryList().subscribe((res: any) => {
      this.CategoriesData = res.content;
    });
  }

  getAllPlaces() {
    this.placesService.getAllPlaces().subscribe((res) => {
      this.places = res['content'];
    });
  }

  CityFilter(event) {
    console.log(event);
    this.service
      .post('api/items', {}, { negotiations: 0, city_id: event })
      .subscribe((res) => {
        this.newRequest = res['content'];
      });
  }

  CategoryFilter(event) {
    console.log(event);
    this.service
      .post('api/items', {}, { negotiations: 0, category_id: event })
      .subscribe((res) => {
        this.newRequest = res['content'];
      });
  }

  ContactFilter(event) {
    console.log(event);
    this.service
      .post('api/items', {}, { negotiations: 0, contact_id: event })
      .subscribe((res) => {
        this.newRequest = res['content'];
      });
  }

  cancelRequest(id) {
    let body = {
      negotiation_id: id,
    };
    this.service
      .post('api/negotiations/cancel', body, [])
      .subscribe((res: any) => {
        console.log(res);
        // if(res.status)
      });
  }
}
