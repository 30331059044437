<!-- Start How It Works -->
<div class="container-fluid how color-bg">
    <div class="row row-title">
      <h1>How it Works</h1>
      <h4>New experiences and opportunities to both sides</h4>
    </div>
    <div class="row how-items">
      <!-- Start How Item Col -->
      <div class="col-sm-3">
        <div class="how-item">
          <a href="#">
            <img alt="" src="assets/images/miscellaneous/icon-1.png" />
            <h3 class="how-item-title">Announcers</h3>
            <div class="how-item-excerpt">
              Publish a listing to show everyone what are the goodies of your establishment. Add amenities and tags to promote every detail of your listing.
            </div>
          </a>
        </div>
      </div>
      <!-- End How Item Col -->
      <!-- Start How Item Col -->
      <div class="col-sm-3">
        <div class="how-item">
          <a href="#">
            <img alt="" src="assets/images/miscellaneous/icon-2.png" />
            <h3 class="how-item-title">Consumers</h3>
            <div class="how-item-excerpt">
              Easily find interesting hand-picked places by local experts. Save your time by organizing your trip Online, don't miss any point.
            </div>
          </a>
        </div>
      </div>
      <!-- End How Item Col -->
      <!-- Start How Item Col -->
      <div class="col-sm-3">
        <div class="how-item">
          <a href="#">
            <img alt="" src="assets/images/miscellaneous/icon-3.png" />
            <h3 class="how-item-title">Everyone</h3>
            <div class="how-item-excerpt">
              Exchange experiences and tastes, extend your personal and profissional network creating long-term business relationship and new friendships.
            </div>
          </a>
        </div>
      </div>
      <!-- End How Item Col -->
      <!-- Start How Item Col -->
      <div class="col-sm-3">
        <div class="how-item">
          <a href="#">
            <img alt="" src="assets/images/miscellaneous/icon-4.png" />
            <h3 class="how-item-title">Free Vs Premium</h3>
            <div class="how-item-excerpt">
              You can start it free right now and migrate to a premium account anytime you want. Click to see all the plans and resources.
            </div>
          </a>
        </div>
      </div>
      <!-- End How Item Col -->
    </div>
  </div>
  <!-- End How It Works -->