<section>
   <div class="container">
       <div class="row">

        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12 blue-cloud">
                        <h1 class="main-title">{{'Corporate Services'|translate}}</h1>
                        <h3 class="main-paragraph">{{'main-section'|translate}}</h3>
                        <a [routerLink]="['../servicescorporate', lang]"  class="main-btn"> {{'Read More'|translate}}</a>
                    </div>

                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <img class="main-img" src="assets/images/main.PNG">
                    </div>

                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12 blue-cloud">
                        <h1 class="main-title">{{'Individual Services'|translate}}</h1>
                        <h3 class="main-paragraph">{{'main-section2'|translate}}</h3>
                        <a [routerLink]="['../servicesindividual', lang]" class="main-btn"> {{'Read More'|translate}}</a>
                    </div>

                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <img class="main-img" src="assets/images/main2.png">
                    </div>

                </div>
            </ng-template>




        </owl-carousel-o>


       </div>
   </div>
</section>
