<section>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-6 col-xs-6 pull-left">
        <h2 class="services-title">{{ "Home.projects" | translate }}</h2>
        <h3 class="services-desc">
          {{ "Check Out Our Latest Projects" | translate }}
        </h3>
        <p class="services-desc2">
          {{ "Join now and explore companies Recent Projects" | translate }}
        </p>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-6 pull-right">
        <!-- <a class="red-more-btn" >{{'Read More'|translate}}</a> -->
      </div>
    </div>

    <div class="row">
      <div
        class="col-lg-2 col-md-2 col-sm-12 col-xs-12 pull-left"
        style="margin: 0px"
        *ngFor="let item of projects | slice : 0 : 5"
      >
        <div style="cursor: pointer" (click)="navigateplace(item)">
          <img class="services-img" src="{{ item.image }}" />
          <div class="card-desc" *ngIf="item.company !== null">
            <h4 *ngIf="item.company !== null">
              {{ "Corporate" | translate }} : {{ item.company }}
            </h4>
          </div>
          <h3 class="services-text">{{ item.name }}</h3>
          <h4 class="services-text2">{{ item.description }}</h4>
        </div>
      </div>

      <div
        class="col-md-12 col-sm-12 col-xs-12"
        style="text-align: center"
        *ngIf="!projects?.length"
      >
        <img class="no-data-img" src="assets/images/empty-chat2.png" />
        <h2 style="padding-top: 15px">
          {{ "There is no projects" | translate }}
        </h2>
      </div>
    </div>
  </div>
</section>
