import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-home-products',
  templateUrl: './home-products.component.html',
  styleUrls: ['./home-products.component.scss'],
})
export class HomeProductsComponent implements OnInit {
  @Input() products: any = [];

  lang;

  constructor(
    private router: Router,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
  }

  navigateplace(item) {
    this.router.navigate(['../place-details', this.lang], {
      queryParams: {
        id: item.contact_id,
      },
    });
  }
}
