<section style="margin-top: 60px">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-sm-12 col-xs-12 pull-left">
        <h1 class="services-title">
          {{ "What can we offer you ?" | translate }}
        </h1>
      </div>

      <div class="col-md-6 col-sm-12 col-xs-12 pull-left">
        <div class="hero-search">
          <form>
            <fieldset>
              <!-- <input type="text"  class="form-control" data-placeholder="Explore and Enjoy..."> -->
              <input
                [(ngModel)]="searchQuery"
                class="form-control"
                placeholder="{{ 'What are you looking for?' | translate }}"
                type="text"
                aria-label="Search"
                id="menu1"
                [ngModelOptions]="{ standalone: true }"
              />

              <!-- <input *ngIf="currentlang === 'en'" [(ngModel)]="searchQuery" (ngModelChange)="search($event)"
                                      class="form-control" placeholder="عما تبحث؟" type="text"
                        aria-label="Search" id="menu1" [ngModelOptions]="{standalone: true}"> -->

              <div class="search-submit">
                <!-- <input type="submit" value=" "> -->
                <!-- <i class="hero-search-icon"></i> -->

                <i class="fa fa-search" aria-hidden="true"></i>
              </div>
            </fieldset>

            <!-- End Search Cities -->
          </form>
          <ul *ngIf="searchRes?.length > 0" id="search_id2" class="list-group">
            <li *ngIf="showSpin" style="background-color: white">
              <i
                class="fa fa-spinner fa-spin"
                style="color: #2196f3; font-size: 24px"
              ></i>
            </li>
            <div *ngIf="!showSpin">
              <li *ngFor="let itemsearch of searchRes">
                <a
                  (click)="navigateplace(itemsearch)"
                  class="list-group-item"
                  (click)="MenuHide()"
                >
                  <!-- <img width="40px" height="40px" [alt]="itemsearch.title" [src]="itemsearch.image"> -->
                  {{ itemsearch.title }}
                </a>
              </li>
            </div>
          </ul>
          <ul *ngIf="showSpin" class="list-group1">
            <li style="background-color: white">
              <i
                class="fa fa-spinner fa-spin"
                style="color: #2196f3; font-size: 24px; line-height: 95px"
              ></i>
            </li>
          </ul>

          <ul
            *ngIf="searchRes?.length == 0 && isVisible"
            id="search_id2"
            [ngClass]="isVisible ? 'hide_div' : ''"
            class="list-group1"
          >
            <li>
              {{ "no result" | translate }}
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md-2 col-sm-12 col-xs-12 pull-right">
        <button class="search-btn" (click)="SearchSubmit(searchQuery)">
          {{ "search" | translate }}
        </button>
      </div>
    </div>

    <div class="row" style="margin-top: 60px">
      <div class="col-md-6 col-sm-6 col-xs-6 pull-left">
        <h2 class="services-title">{{ "Home.servicess" | translate }}</h2>
        <h3 class="services-desc">
          {{ "Find our services near you" | translate }}
        </h3>
        <p class="services-desc2">
          {{
            "Qpedia provides a variety of services to individuals and companies"
              | translate
          }}
        </p>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-6 pull-right">
        <a class="red-more-btn" [routerLink]="['../all-services', lang]">{{
          "Read More" | translate
        }}</a>
      </div>
    </div>

    <div class="row">
      <div
        class="col-lg-2 col-md-2 col-sm-12 col-xs-12 pull-left"
        *ngFor="let item of servicesList | slice : 0 : 5"
      >
        <div
          class="item-card"
          style="cursor: pointer"
          (click)="navigateplacedetails(item)"
        >
          <img class="services-img" src="{{ item.image }}" />
          <div
            class="card-desc"
            *ngIf="item.contact?.title !== null && item.name != ''"
          >
            <h4 *ngIf="item.contact?.title !== null">
              {{ "Corporate" | translate }} : {{ item.contact?.title }}
            </h4>
            <h4 *ngIf="item.category?.name !== null">
              {{ item.category?.name }}
            </h4>
          </div>
          <h3 class="services-text">{{ item.name }}</h3>
          <h4 class="services-text2">{{ item.description }}</h4>
        </div>
      </div>

      <div
        class="col-md-12 col-sm-12 col-xs-12"
        style="text-align: center"
        *ngIf="!servicesList?.length"
      >
        <img class="no-data-img" src="assets/images/empty-chat2.png" />
        <h2 style="padding-top: 15px">
          {{ "There is no services" | translate }}
        </h2>
      </div>
    </div>
  </div>
</section>
