import { Component, OnInit } from '@angular/core';
import {UserService} from '../../Shared/Services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl,FormBuilder,Validators } from '@angular/forms';
import {Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {AuthenticationService} from '../../Shared/Services/authentication.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})



export class ForgotPasswordComponent implements OnInit {
  forgetpasswordForm: FormGroup;
   Email;

   currentlang: string;



  constructor(private userservice: UserService,
    private translateservice:TranslateService,
    private toastrService: ToastrService,
    private authservice:AuthenticationService,
    private router: Router) { }

  ngOnInit(): void {
     this.initForm();
     this.currentlang = localStorage.getItem('lang');


  }

  initForm(){

    this.forgetpasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      // email_verification_code:new FormControl('', [Validators.required]),
      // new_password: new FormControl('', [Validators.required, Validators.minLength(9)]),
      // confirm_new_password: new FormControl('', [Validators.required, Validators.minLength(9)])

    })
  }

  ForgetPassword(){
    this.userservice.SendVerificationCode(this.forgetpasswordForm.value).subscribe((res:any)=>{
      if(res.status.code === 200){
        // this.Email = res.content.email;
        this.authservice.setEmail(res.content.email);
        this.toastrService.success("sent"); // toasr services
        localStorage.setItem('mobile',res.content.mobile)
        localStorage.setItem('telecode',res.content.tele_code)

        this.router.navigate(['/verification-code', this.currentlang]);

      }
      // else if (res.status.code === 401){
      //   this.toastrService.error(res.status.error_details); // toasr services
      //   //  console.log(res.status)
      // }
      //  console.log(res)
      // this.toastrService.success("sent"); // toasr services
      // this.router.navigate(['/Verification-code']);

    },error => {
      console.log(error.error.status.error_details);
      this.toastrService.error(error.error.status.error_details);
      if(error.validation_errors.field == 'email'){
        this.toastrService.error(error); // toasr services
      }

    }
    )
  }



}
