<div
  class="container-fluid pg-header"
  style="background-image: url(./assets/images/16.jpg)"
>
  <div class="pg-header-content">
    <h1 class="pg-title">
      {{ "Communicate with the sender of the request" | translate }}
    </h1>
    <h4 class="pg-subtitle">
      {{ "You can easily contact the sender of the request" | translate }}
    </h4>
  </div>
  <!-- <div class="pg-header-icon icon-bubbles"></div> -->
</div>

<div class="container-fluid my-5">
  <div class="card border-0 border-rad-0 card-shadow mb-4">
    <div class="row" style="padding: 60px 40px">
      <div class="tabset-section">
        <tabset [justified]="true">
          <tab heading="{{ 'My Requests' | translate }}">
            <div class="row">
              <div class="col-md-4 col-sm-12 col-xs-12 pull-left">
                <div class="places-list" *ngIf="My_requests_show.length > 0">
                  <div class="card" *ngFor="let item of My_requests_show">
                    <div
                      class="row justify-content-start card-style"
                      *ngIf="item.contact"
                      (click)="OpenCompaniesRequests(item)"
                    >
                      <div class="col-md-10">
                        <h3 style="color: #1d1d1d">
                          {{ item.question }}
                        </h3>
                        <h4 *ngIf="item.contact.id" class="ID-position">
                          ID:
                          <span style="color: #1d1d1d; font-weight: bold">{{
                            item.contact.id
                          }}</span>
                        </h4>
                      </div>
                      <div class="col-md-2 col-sm-2 col-xs-2 p-0">
                        <img
                          class="company-logo"
                          src="{{ item.contact.image }}"
                          alt="company-logo"
                        />
                      </div>

                      <div class="col-md-12 col-sm-12 col-xs-12">
                        <!-- <h4 *ngIf="item.contact.id" style="padding-top: 10px;color:#1D1D1D;font-weight: bold;">ID: <span style="color:#1D1D1D;font-weight: bold;">{{item.contact.id}}</span></h4> -->
                      </div>

                      <div class="col-md-12 col-sm-12 col-xs-12">
                        <p *ngIf="item.contact.address" style="color: #727272">
                          {{ "Address" | translate }} :
                          {{ item.contact.address }}
                        </p>
                      </div>

                      <div class="col-md-12 col-sm-12 col-xs-12">
                        <p
                          *ngIf="item.contact.description"
                          style="color: #1d1d1d"
                          class="three-line-description"
                        >
                          {{ "description" | translate }} :
                          {{ item.contact.description }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div
                class="col-md-8 col-sm-12 col-xs-12 col-padding"
                *ngIf="Company_requests_show.length == 0 && userType == '1'"
                style="text-align: center"
              >
                <img src="assets/images/empty-chat.png" />
                <h3 class="empty-chat-text">
                  {{ "Start chatting now easily" | translate }}
                </h3>
              </div> -->

              <!-- <div
                class="col-md-8 col-sm-12 col-xs-12 col-padding"
                *ngIf="Company_requests_show.length == 0 && userType != '1'"
                style="text-align: center"
              >
                <img src="assets/images/empty-chat.png" />
                <h3 class="empty-chat-text">
                  {{ "Start chatting now easily" | translate }}
                </h3>
              </div> -->

              <div class="col-md-8 col-sm-12 col-xs-12">
                <div
                  class="msg_history_frame"
                  id="style-1"
                  infiniteScroll
                  [infiniteScrollDistance]="2"
                  [infiniteScrollThrottle]="50"
                >
                  <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12 border-b">
                      <h5
                        *ngIf="placename !== ''"
                        (click)="Navigatetoplace(placeID)"
                        class="place-float place-name"
                      >
                        {{ "Corporate Name" | translate }} :
                        <span
                          style="
                            font-weight: 600;
                            font-size: 19px;
                            color: #3495ae;
                            padding: 0px 5px;
                          "
                          >{{ placename }}</span
                        >
                      </h5>
                    </div>

                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div
                        class="incoming_msg"
                        *ngIf="Company_requests_show.length > 0"
                      >
                        <div
                          class="incoming_msg_img"
                          *ngIf="Company_requests_show[0].question"
                        >
                          <img src="assets/images/usr.jpg" alt="" />
                        </div>
                        <div class="received_msg">
                          <div class="received_withd_msg">
                            <p>{{ Company_requests_show[0].question }}</p>
                            <span class="time_date">{{
                              Company_requests_show[0].created_at
                                | date : "short"
                            }}</span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="msg_history"
                        #scrollMe
                        [scrollTop]="scrollMe.scrollHeight"
                        *ngFor="let question of Company_requests_show"
                      >
                        <div *ngFor="let answer of question.answers">
                          <div
                            class="incoming_msg"
                            *ngIf="
                              userId !== answer.created_by && userType != '1'
                            "
                          >
                            <div class="incoming_msg_img">
                              <img src="assets/images/usr.jpg" alt="" />
                            </div>
                            <div class="received_msg">
                              <div class="received_withd_msg">
                                <p>{{ answer?.answer }}</p>
                                <span class="time_date">{{
                                  answer.created_at | date : "short"
                                }}</span>
                              </div>
                            </div>
                          </div>

                          <div
                            class="outgoing_msg"
                            *ngIf="
                              userId == answer.created_by && userType != '1'
                            "
                          >
                            <div class="incoming_msg_img">
                              <img src="assets/images/usr.jpg" alt="" />
                            </div>
                            <div class="sent_msg">
                              <p>
                                {{ answer?.answer }}
                              </p>
                              <span class="time_date">{{
                                answer.created_at | date : "short"
                              }}</span>
                            </div>
                          </div>

                          <div
                            class="incoming_msg"
                            *ngIf="
                              userId == answer.created_by && userType == '1'
                            "
                          >
                            <div class="incoming_msg_img">
                              <img src="assets/images/usr.jpg" alt="" />
                            </div>
                            <div class="received_msg">
                              <div class="received_withd_msg">
                                <p>{{ answer?.answer }}</p>
                                <span class="time_date">{{
                                  answer.created_at | date : "short"
                                }}</span>
                              </div>
                            </div>
                          </div>

                          <div
                            class="outgoing_msg"
                            *ngIf="
                              userId !== answer.created_by && userType == '1'
                            "
                          >
                            <div class="incoming_msg_img">
                              <img src="assets/images/usr.jpg" alt="" />
                            </div>
                            <div class="sent_msg">
                              <p>
                                {{ answer?.answer }}
                              </p>
                              <span class="time_date">{{
                                answer.created_at | date : "short"
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="type_msg">
                        <form
                          [formGroup]="questionForm"
                          (ngSubmit)="
                            AddFirstCompanyQuestion(questionForm.value)
                          "
                        >
                          <div class="input_msg_write">
                            <textarea
                              type="text"
                              formControlName="question"
                              class="write_msg"
                              placeholder=""
                            ></textarea>
                            <button class="msg_send_btn" type="submit">
                              <i
                                class="fa fa-paper-plane-o"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div class="col-md-12 col-sm-12 col-xs-12"></div>
                  </div>
                </div>
              </div>
            </div>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>
