<!-- Start Trending -->
<div class="container-fluid trending">
    <div class="row row-title">
      <h1>{{'Home.home_trending_title' | translate}}</h1>
      <h4>{{'Home.home_trending_subtitle' | translate}}</h4>
    </div>
    <div class="row listing">

      <!-- Start Trending Item Col -->
      <div class="col-md-2" *ngFor="let item of TrendingData">
        <!-- <app-place-circle ></app-place-circle> -->
        
        <div class="listing-item" (click)="navigateplace(item)">
          <span class="category-icon">
            <i class="fa fa-heart-o" aria-hidden="true"></i>

          </span>
          <!-- <div class="listing-item-rating">{{item.avg_rate}}</div> -->
          <a    class="listing-item-link">
            <div class="listing-item-title-centralizer">
              <div class="listing-item-title">
                  <!-- {{'Home.place_name' | translate}} -->
                  {{item.title}}
              </div>
            </div>
            <img [alt]="item.title" [src]="item.image"/>
            <div class="listing-black-overlay"></div>
          </a>
          <div class="listing-item-data">
            <a class="listing-item-address" href="#">
              <!-- {{'Home.place_address' | translate}} -->
              {{item?.address}}
            </a>
            <!-- <div class="listing-item-excerpt">
              The love of coffee shines all the woody ambience
            </div> -->
          </div>
          <div class="listing-category-name">
            <a >
              <!-- {{'Home.category_name' | translate}} -->
              {{item?.categories[0]?.value}}
            </a>
          </div>
        </div>

      </div>
      <!-- End Trending Item Col -->
      
    </div>
  </div>
  <!-- End Trending -->
