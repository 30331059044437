import { AfterViewChecked, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { ChatService } from 'src/app/Shared/Services/chat.service';
import { HttpClientService } from 'src/app/Shared/Services/http-client.service';
import { UserService } from 'src/app/Shared/Services/user.service';
import {PlacesService} from '../../Shared/Services/places.service';
import { LanguageService } from 'src/app/Shared/Services/language.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  lang;
  id;
  userId;
  questionForm: FormGroup;
  questions: any[] = [];
  userList: any[] = [];
  userQuestions: any[] = [];
  userType;
  lastId;
  searchedId: number;
  page = 1;
  total: any;
  counter: number = 1;
  container: HTMLElement;

  Place_ID;
  place;
  placename;

  placeID;

  Company_requests;
  Individual_requests;
  General_requests;

  @ViewChild('scrollMe') public scrollMe: ElementRef;

  Company_requests_show: any[] = [];
  AddQuestionResponse;
  Company_Contact_Question_ID;

  general_request_show: any[] = [];
  AddCommentResponse;

  Negotation_ID;

  My_requests;
  My_requests_show: any[] = [];

  Contacts_list: any[] = [];
  Contacts_IDs: any[] = [];

  general_req_name: string;

  constructor(
    private translate: TranslateService,
    private toaster: ToastrService,
    private service: HttpClientService,
    private route: ActivatedRoute,
    private placeservice: PlacesService,
    private chatservice: ChatService,
    private router: Router,
    private userservice: UserService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    });
    this.userId = +localStorage.getItem('UserID');
    this.userType = localStorage.getItem('userType');

    this.userservice.viewprofile().subscribe((res: any) => {
      if (res.content.contacts) {
        res.content.contacts.forEach((element: any) => {
          this.Contacts_list.push(element.id);
          console.log('element ::::::::', element);
        });

        console.log('Contact List IDs ::::::::', this.Contacts_list);
        this.Contacts_IDs = this.Contacts_list;
        this.GetMyRequestsList();
      } else {
        this.GetMyRequestsList();
      }
    });

    this.translate.use(this.lang);
    // this.scrollBottom();
    this.initForm();

    this.GetCompaniesRequests();
    this.GetGeneralRequestsList();
  }

  initForm() {
    this.questionForm = new FormGroup({
      question: new FormControl(''),
    });
  }

  getQuestions() {
    if (this.userType == '1') {
      let body = {
        created_by: this.userId,
        contact_id: this.id,
      };
      this.service.post('api/contacts_question', body, {}).subscribe((res) => {
        console.log('Response is:--->>>', res);
        this.questions = res['content'];
        this.lastId = this.questions[0]?.id;
        this.Place_ID = this.questions[0].contact_id;
        console.log('Place ID ::::::::', this.Place_ID);
        this.placeservice.GetPlace(this.id).subscribe((res: any) => {
          this.place = res.content;
          console.log('place details:----', this.place);
          this.placename = res.content[0].title;
        });

        this.questions = this.questions.reverse();
      });
    } else if (this.userType == '3') {
      let body = {
        contact_id: this.id,
      };
      this.service.post('api/contacts_question', body, {}).subscribe((res) => {
        console.log('Response is:--->>>', res);
        let questions = res['content'];
        this.Place_ID = this.questions[0].contact_id;
        console.log('Place ID ::::::::', this.Place_ID);
        this.placeservice.GetPlace(this.id).subscribe((res: any) => {
          this.place = res.content;
          console.log('place details:----', this.place);
          this.placename = res.content[0].title;
        });

        questions = questions.reverse();

        if (this.userList.length == 0) {
          for (let i = 0; i < questions.length; i++) {
            if (questions[i]?.created_by != questions[i + 1]?.created_by) {
              this.userList.push(questions[i]);
            }
          }
        }

        console.log('User List', this.userList);
        this.getQuestionsForUser(this.userList[0].created_by);
        // this.lastId = this.questions[0].id;
        // this.questions = this.questions.reverse();
      });
    }
  }

  getQuestionsForUser(createdBy) {
    this.searchedId = createdBy;
    this.userId = createdBy;
    this.counter = 1;
    this.page = 1;
    let params = {
      pagination: 2,
      page: this.page,
    };
    if (this.userType == '3') {
      let body = {
        contact_id: this.id,
        created_by: createdBy,
      };
      this.service
        .post('api/contacts_question', body, params)
        .subscribe((res) => {
          console.log('Questions for Specific User is:--->>>', res);
          this.questions = res['content'];
          this.Place_ID = this.questions[0].contact_id;

          this.lastId = this.questions[0].id;
          this.questions = this.questions.reverse();

          this.total = 2;
        });
    }
  }

  addQuestion(formData) {
    if (this.userType == '1') {
      if (this.questions.length == 0) {
        let body = {
          contact_id: this.id,
          question: formData.question,
        };
        this.service
          .post('api/contacts_question/store', body, {})
          .subscribe((res) => {
            console.log('Response is:--->>>', res);
            this.questionForm.reset();
            this.getQuestions();
          });
      } else if (this.questions.length > 0) {
        let body = {
          contact_question_id: this.lastId,
          answer: formData.question,
          contact_id: this.id,
          created_by: this.userId,
        };

        this.service
          .post('api/contacts_question/answers/store', body, {})
          .subscribe((res) => {
            console.log('Response ISSSS====>', res);
            this.questionForm.reset();
            this.getQuestions();
          });
      }
    } else if (this.userType == '3') {
      let body = {
        contact_question_id: this.lastId,
        answer: formData.question,
        created_by: this.userId,
      };

      this.service
        .post('api/contacts_question/answers/store', body, {})
        .subscribe((res) => {
          console.log('Response is:--->>>', res);
          this.questionForm.reset();
          this.getQuestionsForUser(this.searchedId);
        });
    }
  }

  onScroll(event) {
    console.log('Evenr', event);
  }

  onUp(ev) {
    console.log('Questions', this.questions);
    let ques: any[] = [];
    if (this.page <= this.total) {
      this.page = this.page + 1;
    }

    if (this.page > this.total) {
      this.page = this.total;
    }

    let params = {
      pagination: 2,
      page: this.page,
    };
    if (this.userType == '3') {
      let body = {
        contact_id: this.id,
        created_by: this.searchedId,
      };
      if (this.counter < this.total) {
        this.service
          .post('api/contacts_question', body, params)
          .subscribe((res) => {
            console.log('Questions for Specific User is:--->>>', res);
            ques = res['content'];
            this.counter++;
            this.total = 2;
            this.questions = this.questions.reverse();
            ques.forEach((element) => {
              this.questions.push(element);
              console.log('Ek=le', element);
            });
            console.log('nnn', this.questions);
            this.lastId = this.questions[0].id;
            this.questions = this.questions.reverse();
            this.onUp(event);
          });
      } else {
        console.log('HHHHHHeeeeeh');
      }
    }
  }

  // ngAfterViewChecked() {
  //   this.scrollBottom()
  // }

  // public scrollBottom() {
  //   this.scrollMe.nativeElement.scrollTop = this.scrollMe.nativeElement.scrollHeight;
  // }

  Navigatetoplace(place_ID) {
    this.router.navigate(['../place-details', this.lang], {
      queryParams: {
        id: place_ID,
      },
    });
  }

  GetCompaniesRequests() {
    this.chatservice
      .GetCompanyRequestsList(this.userId)
      .subscribe((res: any) => {
        this.Company_requests = res.content;
        console.log('Company Requests ::::::::', this.Company_requests);
      });
  }

  OpenCompaniesRequests(company_request) {
    this.placename = company_request.contact.name;
    this.placeID = company_request.contact.id;
    this.Company_Contact_Question_ID = company_request.id;

    this.chatservice
      .showCompanyRequest(company_request.id)
      .subscribe((res: any) => {
        this.Company_requests_show = res.content;
        this.My_requests_show = res.content;

        console.log(
          'Company Requests show item ::::::::',
          this.Company_requests_show
        );
        console.log(
          'My Company Requests show item ::::::::',
          this.My_requests_show
        );
      });
  }

  AddCompanyQuestion(formData) {
    this.chatservice
      .AddCompanyQuestion(
        formData,
        this.userId,
        this.placeID,
        this.Company_Contact_Question_ID
      )
      .subscribe((res: any) => {
        this.AddQuestionResponse = res.content;
        this.questionForm.reset();

        this.chatservice
          .showCompanyRequest(this.Company_Contact_Question_ID)
          .subscribe((res: any) => {
            this.Company_requests_show = res.content;
            this.My_requests_show = res.content;

            console.log(
              'Company Requests show item ::::::::',
              this.Company_requests_show
            );
          });

        console.log(
          'Company Send Question response ::::::::',
          this.AddQuestionResponse
        );
      });

    //  window.location.reload()
  }

  GetGeneralRequestsList() {
    this.chatservice.GetGeneralRequestList().subscribe((res: any) => {
      this.General_requests = res.content;
      console.log('General Requests ::::::::', this.General_requests);
    });
  }

  OpenGeneralRequests(item_ID, name?) {
    this.Negotation_ID = item_ID;

    this.general_req_name = name;

    this.chatservice.ShowGeneralRequest(item_ID).subscribe((res: any) => {
      this.general_request_show = res.content;
      console.log(
        'General Requests show item ::::::::',
        this.general_request_show
      );
    });
  }

  AddGeneralRequestComment(formData) {
    this.chatservice
      .SendGeneralRequest(this.Negotation_ID, formData.question)
      .subscribe((res: any) => {
        this.AddCommentResponse = res.content;
        this.questionForm.reset();

        console.log(
          'General Request Send Comment response ::::::::',
          this.AddCommentResponse
        );
      });

    this.OpenGeneralRequests(this.Negotation_ID);

    //  window.location.reload()
  }

  GetMyRequestsList() {
    this.chatservice
      .GetMyRequestsList(this.Contacts_IDs)
      .subscribe((res: any) => {
        this.My_requests = res.content;
        console.log(
          'Company Requests uding contacys List IDs :::::::::::',
          this.My_requests
        );
      });
  }
}
