<!-- Start header (topbar) -->
<header class="header">
  <!-- Logo -->
  <div class="logo">
    <a [routerLink]="['/', lang]" class="logo-color-bg">
      <img alt="" src="assets/images/logo1.png" />
      {{ "Home.logo" | translate }}
    </a>
  </div>
  <!-- Start Navbar -->
  <div class="navbar navbar-inverse" role="navigation" id="slide-nav">
    <div class="container">
      <div class="navbar-header">
        <button
          #navbarToggler
          type="button"
          class="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#bs-example-navbar-collapse-1"
          aria-expanded="false"
        >
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" href="#">
          <img alt="" src="assets/images/logo2.png" (click)="collapseNav()" />
          {{ "Home.logo" | translate }}
        </a>
      </div>
      <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
        <div id="slidemenu">
          <ul class="nav navbar-nav navbar-right">
            <li>
              <a [routerLink]="['/', lang]" (click)="collapseNav()">{{
                "Home.home" | translate
              }}</a>
            </li>
            <li class="dropdown">
              <a class="dropdown-toggle" data-toggle="dropdown"
                >{{ "Discover" | translate }}<b class="caret"></b
              ></a>
              <ul class="dropdown-menu">
                <li>
                  <a
                    [routerLink]="['categories', lang]"
                    (click)="collapseNav()"
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    >{{ "Home.categories" | translate }}</a
                  >
                </li>
                <li>
                  <a
                    class="nav-link"
                    [routerLink]="['all-services', lang]"
                    (click)="collapseNav()"
                    >{{ "Home.servicess" | translate }}</a
                  >
                </li>
                <li>
                  <a
                    class="nav-link"
                    [routerLink]="['products', lang]"
                    (click)="collapseNav()"
                    >{{ "Home.products" | translate }}</a
                  >
                </li>
              </ul>
            </li>
            <li *ngIf="authService.getToken()">
              <a
                class="nav-link"
                [routerLink]="['jobs', lang]"
                (click)="collapseNav()"
                >{{ "Jobs" | translate }}</a
              >
            </li>
            <li class="dropdown">
              <a
                [routerLink]="['cities', lang]"
                (click)="collapseNav()"
                class="dropdown-toggle"
                data-toggle="dropdown"
                >{{ "Home.cities" | translate }}<b class="caret"></b
              ></a>
              <ul class="dropdown-menu city-menu">
                <li *ngFor="let item of CitiesData">
                  <a (click)="navigateplaces(item); collapseNav()">{{
                    item?.name
                  }}</a>
                </li>
              </ul>
            </li>
            <li *ngIf="authService.getToken()">
              <a
                class="nav-link"
                [routerLink]="['missing', lang]"
                (click)="collapseNav()"
                >{{ "Home.missing" | translate }}</a
              >
            </li>
            <li *ngIf="authService.getToken()">
              <a
                class="nav-link"
                [routerLink]="['chat', lang]"
                (click)="collapseNav()"
                >{{ "chat" | translate }}</a
              >
            </li>
            <li class="dropdown">
              <a class="dropdown-toggle" data-toggle="dropdown"
                >{{ "Home.about" | translate }}<b class="caret"></b
              ></a>
              <ul class="dropdown-menu">
                <li>
                  <a [routerLink]="['about', lang]" (click)="collapseNav()">{{
                    "Home.about" | translate
                  }}</a>
                </li>
                <li>
                  <a [routerLink]="['about/faq', lang]" (click)="collapseNav()">{{
                    "Home.faq" | translate
                  }}</a>
                </li>
                <li>
                  <a [routerLink]="['about/contact', lang]" (click)="collapseNav()">{{
                    "Home.contact" | translate
                  }}</a>
                </li>
              </ul>
            </li>
            <li
              *ngIf="userType == 1"
              class="req-service-li"
              style="margin: 0px 5px"
            >
              <a
                [routerLink]="['services', lang]"
                class="request-service-item"
                (click)="collapseNav()"
                >{{ "Home.services" | translate }}</a
              >
            </li>
            <li *ngIf="!authService.getToken()" style="margin: 0px 5px">
              <button
                [routerLink]="['login', lang]"
                (click)="collapseNav()"
                class="login-btn"
              >
                {{ "Home.login" | translate }}
              </button>
            </li>
            <li *ngIf="!authService.getToken()" style="margin: 0px 5px">
              <button
                [routerLink]="['register', lang]"
                (click)="collapseNav()"
                class="register-btn"
              >
                {{ "Home.register" | translate }}
              </button>
            </li>
            <!-- Start Notifications -->
            <li class="dropdown" *ngIf="authService.getToken()">
              <a class="dropdown-toggle" data-toggle="dropdown">
                <i class="fa fa-bell" aria-hidden="true">
                  <span *ngIf="counter > 0">{{ counter }}</span></i
                >
              </a>
              <ul
                class="dropdown-menu city-menu"
                [ngClass]="lang == 'ar' ? 'new' : ''"
              >
                <li class="unread" *ngFor="let item of notificationList">
                  <a
                    [ngClass]="{ 'is-read': item.read_at == null }"
                    (click)="showDialog(item)"
                  >
                    <p>
                      {{ item?.notification_text }}
                      <small>{{ item?.created_at }}</small>
                    </p>
                  </a>
                </li>
                <li class="text-center">
                  <a [routerLink]="['notification', lang]" (click)="collapseNav()">{{
                    "seeAll" | translate
                  }}</a>
                </li>
                <li
                  class="nonotif"
                  [ngClass]="lang == 'ar' ? 'nonotif' : ''"
                  *ngIf="notificationList?.length == 0"
                >
                  {{ "Home.no notification" | translate }}
                </li>
              </ul>
            </li>
            <li class="dropdown" *ngIf="authService.getToken()">
              <a
                *ngIf="!authService.getToken()"
                class="dropdown-toggle"
                data-toggle="dropdown"
                >{{ "Home.signin" | translate }}
              </a>
              <a
                *ngIf="authService.getToken() && userType == 1"
                [routerLink]="['profile', lang]"
                class="dropdown-toggle"
                data-toggle="dropdown"
                ><i class="fa fa-user"></i> {{ "Home.signin" | translate }}
                <b class="caret"></b
              ></a>
              <a
                *ngIf="authService.getToken() && userType != 1"
                [routerLink]="['profile', lang]"
                class="dropdown-toggle"
                data-toggle="dropdown"
                ><i class="fa fa-user"></i> {{ "My Company" | translate }}
                <b class="caret"></b
              ></a>

              <ul class="dropdown-menu dropdown-rtl">
                <!-- <li *ngIf="authService.getToken()">
                  <a
                    *ngIf="
                      authService.getToken() &&
                      userType != 1 &&
                      complete_profile == true
                    "
                    [routerLink]="['place-new', lang]"
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    ><i class="fa fa-user"></i>
                    {{ "complete your profile" | translate }} <b class="caret"></b
                  ></a>
                </li> -->
                <li *ngIf="authService.getToken()">
                  <a
                    class="nav-link"
                    [routerLink]="['profile', lang]"
                    (click)="collapseNav()"
                    >{{ "My Profile" | translate }}</a
                  >
                </li>
                <li *ngIf="authService.getToken() && userType != 1">
                  <a
                    class="nav-link"
                    [routerLink]="['employee', lang]"
                    (click)="collapseNav()"
                    >{{ "Employees" | translate }}</a
                  >
                </li>
                <li
                  *ngIf="
                    authService.getToken() &&
                    authService.gethasTasks() == '1' &&
                    userType != 1
                  "
                >
                  <a [routerLink]="['tasks', lang]" (click)="collapseNav()">{{
                    "Home.tasks" | translate
                  }}</a>
                  <ul class="dropdown-menu">
                    <li>
                      <a [routerLink]="['tasks-create', lang]">{{
                        "create task" | translate
                      }}</a>
                    </li>
                  </ul>
                </li>
                <li
                  *ngIf="
                    authService.getToken() &&
                    authService.gethasTasks() == '1' &&
                    userType != 1
                  "
                >
                  <a [routerLink]="['employee', lang]" (click)="collapseNav()">{{
                    "Employees" | translate
                  }}</a>
                  <ul class="dropdown-menu">
                    <li>
                      <a [routerLink]="['employee-create', lang]">{{
                        "add employee" | translate
                      }}</a>
                    </li>
                  </ul>
                </li>
                <li
                  *ngIf="
                    authService.getToken() &&
                    authService.gethasTasks() == '1' &&
                    userType != 1
                  "
                >
                  <a [routerLink]="['users', lang]" (click)="collapseNav()">{{
                    "Home.users" | translate
                  }}</a>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        [routerLink]="['company-jobs', lang]"
                        (click)="collapseNav()"
                        >{{ "company jobs" | translate }}</a
                      >
                      <a
                        [routerLink]="['create-job', lang]"
                        (click)="collapseNav()"
                        >{{ "add job" | translate }}</a
                      >
                    </li>
                  </ul>
                </li>
                <li *ngIf="authService.getToken()">
                  <a (click)="logout(); collapseNav()">{{
                    "logout" | translate
                  }}</a>
                </li>
              </ul>
            </li>
            <li>
              <button class="lang-btn fa fa-globe" (click)="changeLang()">
                <span *ngIf="isArabic"> عربي </span>
                <span *ngIf="!isArabic"> en </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- End Navbar -->
</header>
<!-- End header (topbar) -->

<p-dialog [(visible)]="display" [baseZIndex]="10000">
  <p-header> {{ "title" | translate }}: {{ negotiationTilte }} </p-header>
  <p>
    <span>{{ "description" | translate }}:</span> {{ negotiationDesc }}
  </p>
  <form
    class="form-horizontal request-form"
    [formGroup]="requestPrice"
    (ngSubmit)="sendPrice(requestPrice.value)"
  >
    <!-- Text input-->
    <div class="form-group">
      <div class="col-sm-12 inputGroupContainer">
        <div class="input-group">
          <span class="input-group-addon"><i class="fa fa-money"></i></span>
          <input
            name="price"
            id="price"
            formControlName="price"
            placeholder="{{ 'price' | translate }}"
            class="form-control"
            required
          />
        </div>
        <div
          *ngIf="
            requestPrice.controls.price.touched &&
            requestPrice.controls.price.errors?.required
          "
          class="text text-danger"
        >
          {{ "price" | translate }} {{ "required" | translate }}
        </div>
        <div
          *ngIf="priceNotValid && requestPrice.get('price').dirty"
          class="text text-danger"
        >
          {{ "VALIDATORS.price" | translate }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-12 inputGroupContainer">
        <div class="input-group">
          <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
          <textarea
            name="comment"
            rows="5"
            id="comment"
            formControlName="comment"
            placeholder="{{ 'Your Message...........' | translate }}"
            class="form-control"
          ></textarea>
        </div>
      </div>
    </div>
    <p-footer></p-footer>
    <div class="form-group sub">
      <div class="col-sm-12">
        <button [disabled]="!requestPrice.valid" class="btn btn-warning submit">
          {{ "Submit" | translate }}
        </button>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog [(visible)]="display2" [baseZIndex]="10000">
  <p-header>
    <span style="color: gray"> {{ "Question" | translate }}: </span>
    {{ question_text_last }} ?
  </p-header>
  <form
    class="form-horizontal request-form"
    [formGroup]="questionaswerform"
    (ngSubmit)="sendAnswer(questionaswerform.value)"
  >
    <!-- Text input-->
    <div class="form-group">
      <div class="col-sm-12 inputGroupContainer">
        <div class="input-group">
          <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
          <textarea
            name="answer"
            rows="5"
            id="answer"
            formControlName="answer"
            placeholder="{{ 'Your Answer...........' | translate }}"
            class="form-control"
          ></textarea>
        </div>
      </div>
    </div>

    <p-footer></p-footer>
    <div class="form-group sub">
      <div class="col-sm-12">
        <button
          [disabled]="!questionaswerform.valid"
          class="btn btn-sucess submit"
          style="width: 125px"
        >
          {{ "Submit" | translate }}
        </button>
      </div>
    </div>
  </form>
</p-dialog>
