import {Inject, Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {metaConfiguration} from './metaConfig';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AddingMetaService {

  constructor(private meta: Meta, private compTitle: Title,@Inject(DOCUMENT) private dom) { }

  updateMeta(config: metaConfiguration) {
    this.meta.updateTag({name: 'description', content: config.description});
    this.meta.updateTag({name: 'twitter:card', content: 'summary'});
    this.meta.updateTag({name: 'twitter:site', content: '@pentavalue'});
    this.meta.updateTag({name: 'twitter:title', content: config.title});
    this.meta.updateTag({name: 'twitter:description', content: config.description});
    this.meta.updateTag({name: 'twitter:text:description', content: config.description});
    this.meta.updateTag({name: 'twitter:image', content: config.image});
    this.meta.updateTag({property:"og:type",content:'article'});
    this.meta.updateTag({property:"og:site_name",content:'@pentavalue'});
    this.meta.updateTag({property:"og:title",content:config.title});
    this.meta.updateTag({property:"og:image",content:config.image});
    this.meta.updateTag({property:"og:url",content:config.url});
    this.compTitle.setTitle(config.compTitle)

  }
  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }

}
