<!-- Start Front Categories -->
<div class="container front-categories">
    <div class="row">
      <h1>{{'Home.home_categories_title' | translate}}</h1>
      <h4>{{'Home.home_categories_subtitle' | translate}}</h4>
    </div>
    <div class="row cat-itens">
      <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 home-categories-arabic" *ngFor="let item of CategoryData | slice:10:22">
        <div class="cat-item"   >
          <div class="cat-overlay"></div>
          <div  class="cat-image" [style.backgroundImage]="'url(' + item.image + ')'"></div>
          <!-- <div class="cat-icon icon-bus">
            <i class="fas fas fa-{{item.icon}}" aria-hidden="true"></i>
          </div> -->
          <div class="cat-counter">{{item.contacts_count}}</div>
          <a (click)="navigateplaces(item)">
            <div class="cat-text">{{item.title}}</div>
          </a>
        </div>
      </div>

      <!-- <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6">
        <div class="cat-item"  routerLink="Categories" >
          <div class="cat-overlay"></div>
          <div class="cat-image" style="background-image:url('./assets/images/categories/2.jpg')"></div>
          <div class="cat-icon icon-dinner"></div>
          <div class="cat-counter">7</div>
          <a routerLink="Categories" href="#">
            <div class="cat-text">{{'Home.category_name' | translate}}</div>
          </a>
        </div>
      </div> -->

      <!-- <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6">
        <div class="cat-item"  routerLink="Categories" >
          <div class="cat-overlay"></div>
          <div  class="cat-image" style="background-image:url('./assets/images/categories/3.jpg')"></div>
          <div class="cat-icon icon-bed"></div>
          <div class="cat-counter">47</div>
          <a routerLink="Categories" href="#">
            <div class="cat-text">{{'Home.category_name' | translate}}</div>
          </a>
        </div>
      </div> -->


      <!-- <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6">
        <div class="cat-item"  routerLink="Categories" >
          <div class="cat-overlay"></div>
          <div  class="cat-image" style="background-image:url('./assets/images/categories/5.jpg')"></div>
          <div class="cat-icon icon-heart-pulse"></div>
          <div class="cat-counter">61</div>
          <a routerLink="Categories" href="#">
            <div class="cat-text">{{'Home.category_name' | translate}}</div>
          </a>
        </div>
      </div> -->

      <!-- <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6">
        <div class="cat-item"  routerLink="Categories" >
          <div class="cat-overlay"></div>
          <div  class="cat-image" style="background-image:url('./assets/images/categories/6.jpg')"></div>
          <div class="cat-icon icon-bag"></div>
          <div class="cat-counter">29</div>
          <a routerLink="Categories" href="#">
            <div class="cat-text">{{'Home.category_name' | translate}}</div>
          </a>
        </div>
      </div> -->

      <!-- <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6">
        <div class="cat-item"  routerLink="Categories" >
          <div class="cat-overlay"></div>
          <div  class="cat-image" style="background-image:url('./assets/images/categories/8.jpg')"></div>
          <div class="cat-icon icon-haircut"></div>
          <div class="cat-counter">53</div>
          <a routerLink="Categories" href="#">
            <div class="cat-text">{{'Home.category_name' | translate}}</div>
          </a>
        </div>
      </div> -->

      <!-- <div class="col-lg-3 col-md-3 col-sm-4 col-xs-6">
        <div class="cat-item"  routerLink="Categories" >
          <div class="cat-overlay"></div>
          <div  class="cat-image" style="background-image:url('./assets/images/categories/9.jpg')"></div>
          <div class="cat-icon icon-trumpet"></div>
          <div class="cat-counter">166</div>
          <a routerLink="Categories" href="#">
            <div class="cat-text">{{'Home.category_name' | translate}}</div>
          </a>
        </div>
      </div> -->

      <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6 home-categories-arabic">
        <div class="cat-item"  routerLink="Categories" >
          <div class="cat-overlay"></div>
          <div  class="cat-image" style="background-image:url('./assets/images/categories/10.jpg')"></div>
          <!-- <div class="cat-icon icon-city">
            <i class="fa fa-building-o" aria-hidden="true"></i>

          </div> -->
          <div class="cat-counter">{{TotalCategoryNO}}</div>
          <a routerLink="Categories">
            <div class="cat-text">{{'Home.other' | translate}}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- End Front Categories -->