<div class="container-fluid pg-header" style="background-image: url(././assets/images/11.jpg);">
  <div class="pg-header-content">
    <h1 class="pg-title">
      {{'Jobs' | translate}}
    </h1>
  </div>
  <!-- <div class="pg-header-icon">
    <i class="fa fa-briefcase"></i>
  </div> -->
</div>

<!-- Start Listings Container  -->
<div class="container-fluid listing-block listings-has-sidebar">

  <div class="search">

    <form [formGroup]="FilterForm">
 
      <div class="row">

        <div class="col-md-6 col-sm-12 col-xs-12 pull-left" style="margin-bottom: 20px !important; margin-right: 0px; margin-left: 0px;">
          <input type="text" class="form-control" formControlName="title"  placeholder="{{'Search for Job'|translate}}" />
  
          <!-- <ul *ngIf="(searchedProduct.length > 0 || searchedProduct.length != null) && showMenu"
                  id="search_id2" class="list-group">
                  <a *ngFor="let product of searchedProduct"
                      class="list-group-item text-left">
                      <img width="40px" height="40px"
                          [src]="product?.image" [alt]="product?.name">
                      <span class="search-text">
                          {{ product.name }}
                      </span>
                  </a>
              </ul> -->
        </div>
  
        <div class="col-md-3 col-sm-12 col-xs-12 pull-left" style=" margin-bottom: 20px !important; margin-right: 0px; margin-left: 0px;">
          <!-- <input type="text" class="form-control" [(ngModel)]="price" (ngModelChange)="filterByPrice($event)"
            on-focusout="menuHide($event)" on-focus="menuShow()" placeholder="{{'Job Salary'|translate}}" /> -->
  
            <select class="form-control" formControlName="price">
              <option *ngFor="let item of jobs" value="{{item.salary}}"> {{item.salary}}</option>
            </select>
  
        </div>
  
        <div class="col-md-2 col-sm-12 col-xs-12 pull-left" style=" margin-bottom: 20px !important; margin-right: 0px; margin-left: 0px;">
          <button class="search-btn" (click)="FilterServices()">{{'search'|translate}}</button>
  
        </div>
  
  
      </div>


    </form>

  </div>

  <div class=" listing grid2 no-addr-on-mobile white mt-2">


    <!-- Start Listing -->



    <!-- Start Listing Item Col -->
    <div class="row mt-2">
      <div class="col-md-3 col-sm-12 col-xs-12 pull-left" *ngFor="let item of jobs | paginate: { itemsPerPage: 10, currentPage: p }">

        <div class="card" style="cursor:pointer;" >

          <img alt="" [src]="item.company.logo" loading="lazy"  (click)="navigateplacedetails(item)" />

          <h2 (click)="navigateplacedetails(item)">{{item.title}}</h2>

          <p *ngIf="item.company.name">{{'Company name' | translate}}: {{item.company.name}}</p>
          <p *ngIf="item.company.address">{{'Address' | translate}}: {{item.company.address}}</p>
          <p *ngIf="item.title">{{'jobdesc' | translate}}: {{item.title}}</p>
          <p *ngIf="item.salary">{{'salary' | translate}}: {{item.salary}} {{'currency' | translate}}</p>
          <p *ngIf="item.notes">{{'notes' | translate}}: {{item.notes}}</p>

          <button *ngIf="corporatelogincheck != 'Corporate'  && item.is_applied == 0 "  class="btn-apply" (click)="open(item?.id)">{{'Apply' |translate}}</button>



        </div>

        <!-- <div class="listing-item">


          <div class="listing-item-link">
            <img alt="" [src]="item.company.logo" loading="lazy" />
            <div class="listing-item-title-centralizer">
              <div class="listing-item-title">
                {{item.title}}
                <button *ngIf="corporatelogincheck != 'Corporate' " style="z-index: 1000;" (click)="open(item?.id)">{{'Apply' |translate}}</button>
              </div>

              <div class="listing-item-data">

                <div class="listing-item-excerpt">

                  <p *ngIf="item.company.name">{{'Company name' | translate}}: {{item.company.name}}</p>
                  <p *ngIf="item.company.address">{{'Address' | translate}}: {{item.company.address}}</p>
                  <p *ngIf="item.title">{{'jobdesc' | translate}}: {{item.title}}</p>
                  <p *ngIf="item.salary">{{'salary' | translate}}: {{item.salary}} {{'currency' | translate}}</p>
                  <p *ngIf="item.notes">{{'notes' | translate}}: {{item.notes}}</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->

      </div>

      <div *ngIf="jobs.length == 0" class="col-md-12 text-center">{{'NoJobs' | translate}}</div>

    </div>
    <!-- End Listing Item Col -->
    <div class="row text-center" *ngIf="jobs.length > 0">
      <div class="col-sm-12">
        <pagination-controls (pageChange)="p = $event" previousLabel="{{'previous'|translate}}"
          nextLabel="{{'next'|translate}}">
        </pagination-controls>
      </div>
    </div>
    <!-- End Listing -->
    <div class="col-md-1"></div>
  </div>
</div>
<!-- End Listings Container  -->

<div class="col-lg-12 text-center">
  <p-dialog class="dialog-header" header="{{'sendRequest' |translate}}" [(visible)]="displayResponsive"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false">

    <p *ngIf="!hasCv" class="important important-txt">
      {{'popupMessage' | translate}}
    </p>
    <form [formGroup]="requestForm" class="requestForm">

      <div class="form-group clear-fix">
        <div class="col-sm-8 inputGroupContainer" [ngClass]="lang == 'ar' ? 'float-right' : '' ">
          <div class="input-group">
            <!-- <span class="input-group-addon" [ngClass]="lang == 'ar' ? 'ar-icon' : '' ">
                        <i class="fas fa-file"></i>
                    </span> -->
            <input type="file" accept="application/pdf" (change)="readImage($event)" class="form-control" id="cv"
              formControlName="attached_cv"  />
            <label *ngIf="!fileName && CV_File == '' " class="form-control lbl" for="cv"> <i class="fas fa-file"></i> {{'Attach CV' |
              translate}}</label>
            <label class="form-control" *ngIf="fileName">{{fileName}}</label>

          </div>
        </div>
      </div>

    </form>
    <ng-template pTemplate="footer">
      <button type="submit" *ngIf="!hasCv" (click)="sendRequest(requestForm)"
        class="btn btn-outline-dark submit-btn">{{'Submit'|translate}}</button>
      <button type="submit" *ngIf="hasCv" [disabled]="!hasCv || !fileuploaded" (click)="sendRequest(requestForm)"
        class="btn btn-outline-dark submit-btn">{{'Submit'|translate}}</button>
      <button type="button" class="btn btn-outline-dark"
        (click)="displayResponsive=false">{{'cancel'|translate}}</button>
    </ng-template>
  </p-dialog>
</div>
